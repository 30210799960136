import { computed, type Ref, unref } from 'vue';

const formatCharsRegex = /[$,\s]/g;
const groupingCharsRegex = /,/g;
const leadingZerosRegex = /^-?0+\d/;

interface NumberInputOptions {
  allowNegative?: boolean | Ref<boolean>;
  precision?: number | Ref<number>;
  formatOnInput?: boolean | Ref<boolean>;
}

export function useNumberInput(options?: NumberInputOptions) {
  const _options = {
    allowNegative: options?.allowNegative ?? false,
    precision: options?.precision ?? 0,
    formatOnInput: options?.formatOnInput ?? true,
  };
  const formatter = computed(
    () =>
      new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: unref(_options.precision),
        maximumFractionDigits: unref(_options.precision),
      }),
  );
  const validInputRegex = computed(() => (unref(_options.allowNegative) ? /^[\d.,-]*$/ : /^[\d.,]*$/));

  function formatValue(value: number | undefined) {
    return value === undefined || isNaN(value) ? '' : formatter.value.format(value);
  }

  function parseValue(value: string, applyPrecision: boolean): number {
    // note: Number("") and Number(" ") return 0 not NaN
    const cleanValue = value.replace(formatCharsRegex, '');
    if (!cleanValue) {
      return NaN;
    } else {
      const parsed = Number(cleanValue);
      // apply precision
      return isNaN(parsed) || !applyPrecision ? parsed : Number(parsed.toFixed(unref(_options.precision)));
    }
  }

  function handleBeforeInput(event: Event) {
    const inputEvent = event as InputEvent;
    const el = event.target as HTMLInputElement;

    // use default handling for enter key to allow form submission
    if (inputEvent.inputType === 'insertLineBreak') {
      return;
    }

    // cancel default behaviour
    event.preventDefault();

    let insertText = '';
    switch (inputEvent.inputType) {
      case 'insertText':
      case 'insertFromPaste': {
        let inputData = inputEvent.data;
        if (inputData && inputEvent.inputType === 'insertFromPaste') {
          // clean pasted input to allow copying of formatted numbers
          // e.g. "$ 1,234" will be pasted as "1234"
          inputData = inputData.replace(formatCharsRegex, '');
        }
        if (!inputData || !validInputRegex.value.test(inputData)) {
          // invalid input
          return;
        }
        insertText = inputData;
        break;
      }

      case 'deleteContent':
      case 'deleteContentBackward':
      case 'deleteContentForward':
        // continue
        break;

      case 'historyUndo':
      case 'historyRedo':
        // note: undo/redo history not set for programmatic value updates
        return;

      default:
        console.warn(`Unhandled inputType: ${inputEvent.inputType}`, inputEvent);
        return;
    }

    const origText = el.value;
    let newText: string;
    let pos: number;
    if (el.selectionStart !== null && el.selectionEnd !== null && origText) {
      const selStart = Math.min(el.selectionStart, el.selectionEnd);
      const selEnd = Math.max(el.selectionStart, el.selectionEnd);
      if (selEnd > selStart || insertText) {
        newText = origText.substring(0, selStart) + insertText + origText.substring(selEnd);
        pos = selStart + insertText.length;
      } else if (inputEvent.inputType === 'deleteContentBackward' && selStart > 0) {
        newText = origText.substring(0, selStart - 1) + origText.substring(selStart);
        pos = selStart - 1;
      } else if (inputEvent.inputType === 'deleteContentForward' && selStart < origText.length) {
        newText = origText.substring(0, selStart) + origText.substring(selStart + 1);
        pos = selStart;
      } else {
        newText = origText;
        pos = selStart;
      }
    } else {
      newText = insertText;
      pos = insertText.length;
    }

    if (newText === origText) {
      // no change
      return;
    }

    // note: allow input of leading negative symbol
    if (newText && newText !== '-') {
      // remove any formatting
      let cleanValue = newText.replace(formatCharsRegex, '');
      if (!cleanValue) {
        // cancel invalid input - formatting characters only
        return;
      }
      if (cleanValue === '.') {
        cleanValue = '0.';
        pos++;
      }
      const newValue = Number(cleanValue);
      if (isNaN(newValue)) {
        // cancel invalid input - NaN
        return;
      }
      if (unref(_options.formatOnInput)) {
        let formattedText = cleanValue;
        // check for leading zeros before formatting
        // e.g. 1,000 to ,000 should become 000 not 0
        if (!leadingZerosRegex.test(cleanValue)) {
          // note: split input into integral and fractional parts to
          // preserve all characters after decimal point e.g. 123.0
          const decPos = cleanValue.indexOf('.');
          formattedText =
            decPos === -1
              ? newValue.toLocaleString('en-US', {
                  style: 'decimal',
                })
              : Math.trunc(newValue).toLocaleString('en-US', {
                  style: 'decimal',
                }) + cleanValue.substring(decPos);
        }
        // fix caret position
        if (pos > 0 && newText.substring(0, pos) !== formattedText.substring(0, pos)) {
          // adjust caret pos to remove original grouping characters
          const groupingChars = newText.substring(0, pos).match(groupingCharsRegex);
          if (groupingChars && groupingChars.length) {
            pos = pos - groupingChars.length;
          }
          // adjust caret pos to add new grouping characters
          let index = 0;
          let adjIndex = 0;
          for (const c of [...formattedText]) {
            if (c !== ',') {
              if (adjIndex === pos) {
                break;
              }
              adjIndex++;
            }
            index++;
          }
          pos = pos + (index - adjIndex);
        }
        // allow delete forward over grouping character
        if (
          inputEvent.inputType === 'deleteContentForward' &&
          formattedText.length > pos &&
          formattedText[pos] === ','
        ) {
          pos++;
        }
        newText = formattedText;
      }
    }

    //update value and selection
    el.value = newText;
    el.selectionStart = pos;
    el.selectionEnd = pos;

    // dispatch input event to trigger Vue update
    // note: element does not fire input event when value is set by script
    el.dispatchEvent(
      new Event('input', {
        bubbles: true,
      }),
    );
  }

  return {
    formatValue,
    parseValue,
    handleBeforeInput,
  };
}
