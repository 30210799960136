import type { PeriodKey, PeriodicValue } from '@/types';
import type { FrequencyType } from '@/types/api';

const frequency: Record<FrequencyType, PeriodKey> = {
  weekly: 'week',
  fortnightly: 'fortnight',
  monthly: 'month',
  quarterly: 'quarter',
  annually: 'year',
};

export const periodic = (amount?: number, freq?: FrequencyType): PeriodicValue => {
  return {
    amount: amount ?? 0,
    frequency: freq ? frequency[freq] : 'month',
  };
};
