<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { Datepicker } from 'vanillajs-datepicker';
import { CalendarDaysIcon } from '@heroicons/vue/24/solid';
import { nanoid } from 'nanoid';
import { useDateInput } from '@/helpers/dateInput';
import dayjs from '@/helpers/dayjs';

const props = withDefaults(
  defineProps<{
    id?: string;
    modelValue?: string | Date; // YYYY-MM-DD
    minDate?: string | Date;
    maxDate?: string | Date;
    label?: string;
    labelType?: 'default' | 'overlapping' | 'floating';
    errorMessage?: string;
    highlightError?: boolean;
    helpText?: string;
    disabled?: boolean;
    placeholder?: string;
  }>(),
  {
    id: () => nanoid(8),
    modelValue: undefined,
    minDate: undefined,
    maxDate: undefined,
    label: '',
    labelType: 'default',
    errorMessage: undefined,
    highlightError: false,
    helpText: undefined,
    disabled: false,
    placeholder: undefined,
  },
);
const { handleBeforeInput } = useDateInput({
  format: 'DD/MM/YYYY',
  twoDigitYearMax: 49,
  formatOnInput: true,
});

const emit = defineEmits<{
  (e: 'update:modelValue', v: string | Date): void;
}>();

const inputRef = ref<HTMLElement>();
const datePicker = ref<Datepicker>();
const dateValue = ref();
const minDate = computed(() =>
  typeof props.minDate === 'string' || typeof props.minDate === 'undefined'
    ? props.minDate
    : dateToStr(props.minDate, 'yyyy-mm-dd'),
);
const maxDate = computed(() =>
  typeof props.maxDate === 'string' || typeof props.maxDate === 'undefined'
    ? props.maxDate
    : dateToStr(props.maxDate, 'yyyy-mm-dd'),
);
const { parseValue } = useDateInput({});
const dateToStr = (dt: Date, format: string) => Datepicker.formatDate(dt, format);

onMounted(() => {
  if (inputRef.value) {
    datePicker.value = new Datepicker(inputRef.value, {
      format: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        toValue: (dateStr) => parseValue(dateStr as any) as any,
        toDisplay: (date) => dayjs(date).format('DD/MM/YYYY'),
      },
      autohide: true,
      minDate: props.minDate,
      maxDate: props.maxDate,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputRef.value.addEventListener('changeDate', (e: any) => {
      const newDate = e.detail.date;

      emit('update:modelValue', typeof props.modelValue === 'object' ? newDate : dateToStr(newDate, 'yyyy-mm-dd'));
    });
  }

  if (props.modelValue) {
    datePicker.value?.setDate(
      typeof props.modelValue === 'object' ? props.modelValue : dateToStr(new Date(props.modelValue), 'dd/mm/yyyy'),
      { clear: true },
    );
  }
});
watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      const v = typeof val === 'object' ? val : dateToStr(new Date(val), 'dd/mm/yyyy');
      datePicker.value?.setDate(v, { clear: true });
      dateValue.value = val;
    }
  },
);
watch(dateValue, (val) => emit('update:modelValue', val));
</script>

<template>
  <div>
    <label v-if="labelType === 'default'" :for="id" class="block text-sm font-medium text-gray-700">
      {{ label }}
    </label>
    <div class="group relative mt-1">
      <input
        :id="id"
        ref="inputRef"
        :data-test-id="id"
        type="text"
        class="peer w-full rounded-md border-gray-300 text-base placeholder-gray-500 shadow-sm focus:border-bridgit-royalBlue focus:outline-none focus:ring-bridgit-royalBlue disabled:bg-gray-50 disabled:text-gray-300 disabled:placeholder:text-gray-300"
        :class="[
          errorMessage ? '!border-rose-500 focus:!ring-rose-500' : null,
          labelType === 'floating' ? 'placeholder-transparent group-focus-within:placeholder-gray-500' : null,
        ]"
        :placeholder="labelType === 'floating' ? placeholder || ' ' : placeholder"
        autocomplete="off"
        :aria-invalid="!!errorMessage"
        :aria-describedby="`${id}-error`"
        :disabled="disabled"
        @beforeinput="handleBeforeInput"
      />
      <div class="absolute inset-y-0 right-0 flex items-center">
        <div class="p-4" @click="datePicker?.show()">
          <CalendarDaysIcon class="h-5 w-5 cursor-pointer" />
        </div>
      </div>
      <input
        :id="id + '-date-picker'"
        v-model="dateValue"
        class="absolute inset-0 opacity-0 min-[480px]:hidden"
        type="date"
        :min="minDate"
        :max="maxDate"
        onclick="this.showPicker()"
      />

      <label
        v-if="labelType !== 'default'"
        for="id"
        class="pointer-events-none absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
        :class="
          labelType === 'floating'
            ? [
                'transition-all',
                'peer-placeholder-shown:top-2 peer-placeholder-shown:mt-0 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-placeholder-shown:text-gray-700',
                'group-focus-within:!-top-2 group-focus-within:!-mt-px group-focus-within:!text-xs group-focus-within:!font-medium group-focus-within:!text-gray-900',
              ]
            : []
        "
      >
        {{ label }}
      </label>
    </div>

    <p v-if="helpText" class="mt-2 text-sm">{{ helpText }}</p>
    <transition :enter-active-class="!highlightError ? 'animate-fadeIn' : ''">
      <p
        v-show="!!errorMessage"
        :id="`${id}-error`"
        :data-test-id="`${id}-error`"
        class="mt-1 text-sm text-rose-500"
        :class="{ 'animate-shakeX': highlightError }"
      >
        {{ errorMessage }}
      </p>
    </transition>
  </div>
</template>
