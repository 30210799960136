import { POST } from '../../_http';
import type { operations } from '@/types/schemas/api.main';
type controller = operations['BrokerAuthenticateVerifyOtpController_post'];

export type VerifyCredentialsBodyDto = controller['requestBody']['content']['application/json'];
export type VerifyCredentialsResponseDto = controller['responses']['200']['content']['application/json'];

export const verifyCredentials = POST('/brokers/authenticate/verify-otp')<
  VerifyCredentialsBodyDto,
  VerifyCredentialsResponseDto
>;
