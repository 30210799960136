<script setup lang="ts">
import { ref } from 'vue';
import { cloneDeep } from 'lodash-es';
import type { ApplicantFinancials, ApplicantId } from '@/types';
import { useTemplateRefsList } from '@vueuse/core';
import { getFullName } from '@/helpers/format';
import { createFinancials } from '@/helpers/builder';
import { useBrokerLoanAppProcess } from '@/helpers/useBrokerLoanAppProcess';
import { CheckIcon, XMarkIcon } from '@heroicons/vue/20/solid';
import IncomeForm from './modules/Income/IncomeForm.vue';
import { ApplicantType, EmploymentType, RelationshipType } from '@/helpers/const';

const { authorisedUser, loanApp, loanApplicants, saveAndGoNext, goBack } = useBrokerLoanAppProcess();
const isSecondaryDeclaration = false;
const isIncomeExpenseRequest = false;
const error = ref('');

const applicantsMarried = loanApp.value.applicants.some(
  (applicant) => applicant.relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO,
);

const validApplicants = loanApp.value.applicants
  .filter(
    (a) =>
      loanApp.value.userType === 'Broker' ||
      (loanApp.value.userType === 'Primary' && (a.type === ApplicantType.PRIMARY || applicantsMarried)) ||
      (loanApp.value.userType === 'Secondary' && a.type === ApplicantType.SECONDARY && applicantsMarried) ||
      (isIncomeExpenseRequest && applicantsMarried) ||
      a.id === authorisedUser.id ||
      (isSecondaryDeclaration && applicantsMarried),
  )
  .map((a) => a.id);

const initFinancials = validApplicants.reduce((obj, item) => {
  obj[item] = createFinancials();
  return obj;
}, {} as ApplicantFinancials);

const financials = ref({ ...initFinancials, ...cloneDeep(loanApp.value.financials) });
const incomeRefs = useTemplateRefsList<InstanceType<typeof IncomeForm>>();
const activeTab = ref<ApplicantId | undefined>(validApplicants.length === 1 ? validApplicants[0] : undefined);

const validateIncome = async () => {
  let isValid = true;
  const refCount = incomeRefs.value.length;
  for (let i = 0; i < refCount; i++) {
    isValid = await incomeRefs.value[i].update();
    if (!isValid) {
      window.scrollTo(0, 0);
      activeTab.value = validApplicants[i];
      break;
    }
  }
  return isValid;
};

const saveAndContinue = async () => {
  if (await validateIncome()) {
    if (!financials.value['applicant-0'].income?.employment.length) {
      error.value = 'Income source is required.';
      return;
    }
    error.value = '';
    return saveAndGoNext({
      financials: financials.value,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cardTitle = (employment: any) => {
  let description = '';
  if (employment.type === EmploymentType.SELF_EMPLOYED) {
    description = employment.tradingName;
  } else if (
    employment.type === EmploymentType.FULL_TIME ||
    employment.type === EmploymentType.PART_TIME ||
    employment.type === EmploymentType.CASUAL
  ) {
    description = employment.employerName;
  }
  return `${employment.type.charAt(0).toUpperCase() + employment.type.slice(1).replace('_', ' ')}: ${description}`;
};

const cardDescription = (applicantId: ApplicantId) => {
  return financials.value[applicantId].income?.employment?.map((item) => {
    return cardTitle(item);
  });
};
</script>

<template>
  <div class="w-full">
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">
        What are your
        <BxHighlight>income</BxHighlight>
        sources?
      </h2>
    </div>
    <div v-if="loanApp.applicants.length > 1 && loanApp.actionType === 'createNew'" class="my-6">
      <p v-if="loanApp.applicants[1].relationshipToPrimary !== RelationshipType.MARRIED_OR_DEFACTO">
        Only include your income, we will request this information from
        {{ loanApp.applicants[1].preferredName }} via email.
      </p>
    </div>
    <div class="my-6 grid gap-4">
      <div v-for="applicantId in validApplicants" :key="applicantId">
        <BxCard :class="applicantId !== activeTab && 'hidden'">
          <h2 class="mb-4 text-xl font-medium">
            {{ getFullName(loanApplicants[applicantId]) }}
          </h2>
          <IncomeForm
            :ref="incomeRefs.set"
            v-model="financials[applicantId].income"
            :applicant-id="applicantId"
            :animate="applicantId === activeTab"
            :show-buttons="validApplicants.length > 1"
            @save="activeTab = undefined"
            @cancel="activeTab = undefined"
          />
        </BxCard>
        <BxCard :class="`${applicantId === activeTab && 'hidden'} employment-card`">
          <div class="flex items-center">
            <div class="card-details">
              <div class="mb-2 font-medium">{{ getFullName(loanApplicants[applicantId]) }}</div>
              <div class="flex items-center gap-2">
                <div class="h-5 w-5">
                  <CheckIcon v-if="financials[applicantId].income?.employment.length" class="text-green-600" />
                  <XMarkIcon v-else class="text-rose-600" />
                </div>
                Employment details
              </div>
              <div class="flex items-center gap-2">
                <div class="h-5 w-5">
                  <CheckIcon
                    v-if="financials[applicantId].income?.expectIncomeChange !== undefined"
                    class="text-green-600"
                  />
                  <XMarkIcon v-else class="text-rose-600" />
                </div>
                Additional income
              </div>
            </div>
            <div class="employment-descriptions ml-6">
              <div>
                <div v-for="description in cardDescription(applicantId)" :key="description">
                  <span class="text-gray-400">{{ description }}</span>
                </div>
              </div>
            </div>
            <div class="card-button">
              <BxButton
                :id="applicantId + '-add-income'"
                variant="secondary"
                :disabled="
                  (activeTab && activeTab !== applicantId) ||
                  (loanApplicants[applicantId].type === 'primary' && isSecondaryDeclaration)
                "
                @click="activeTab = applicantId"
              >
                {{ financials[applicantId].income?.employment.length ? 'Edit' : 'Add' }} income
              </BxButton>
            </div>
          </div>
        </BxCard>
        <BxCard :class="`${applicantId === activeTab && 'hidden'} employment-card-mobile`">
          <div class="items-left flex flex-col">
            <div class="card-details">
              <div class="mb-2 font-medium">{{ getFullName(loanApplicants[applicantId]) }}</div>
              <div class="items-left flex gap-2">
                <div class="h-5 w-5">
                  <CheckIcon v-if="financials[applicantId].income?.employment.length" class="text-green-600" />
                  <XMarkIcon v-else class="text-rose-600" />
                </div>
                Employment details
              </div>
              <div class="items-left flex gap-2">
                <div class="h-5 w-5">
                  <CheckIcon
                    v-if="financials[applicantId].income?.expectIncomeChange !== undefined"
                    class="text-green-600"
                  />
                  <XMarkIcon v-else class="text-rose-600" />
                </div>
                Additional income
              </div>
            </div>
            <div class="employment-descriptions ml-6">
              <div>
                <div v-for="description in cardDescription(applicantId)" :key="description">
                  <span class="text-gray-400">{{ description }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="card-button">
            <BxButton
              variant="secondary"
              :disabled="
                (activeTab && activeTab !== applicantId) ||
                (loanApplicants[applicantId].type === 'primary' && isSecondaryDeclaration)
              "
              @click="activeTab = applicantId"
            >
              {{ financials[applicantId].income?.employment.length ? 'Edit' : 'Add' }} income
            </BxButton>
          </div>
        </BxCard>
        <span v-if="error" class="mt-6 block text-sm text-rose-500">
          {{ error }}
        </span>
      </div>
    </div>

    <div class="mt-6">
      <BxButton id="income-continue" variant="primary" @click="saveAndContinue()">Save and continue</BxButton>
      <BxButton variant="link" class="ml-4" @click="goBack()">Back</BxButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.employment-descriptions {
  @media (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.employment-card {
  @media (max-width: 767px) {
    display: none;
  }
}

.employment-card-mobile {
  @media (min-width: 767px) {
    display: none;
  }
}

.card-button {
  flex-grow: 1;
  @media (min-width: 767px) {
    text-align: right;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
  flex-basis: 200px;
}
</style>
