<script setup lang="ts">
import { watch } from 'vue';
import { useForm } from 'vee-validate';
import { additionalIncomeSchema } from '@/validation';
import type { ApplicantIncome, ApplicantIncomeEntry } from '@/types';
import { defaultPeriodicValue } from '@/helpers/builder';

const props = withDefaults(
  defineProps<{
    applicantId: string;
    modelValue: ApplicantIncome | null;
  }>(),
  {},
);

const emit = defineEmits<{
  (e: 'update:modelValue', v: ApplicantIncome): void;
}>();

const { meta, values, submitCount, handleSubmit, resetForm } = useForm<ApplicantIncomeEntry>({
  validationSchema: additionalIncomeSchema,
  validateOnMount: false,
});

watch(
  () => props.modelValue,
  (data) => {
    const values: ApplicantIncomeEntry = data
      ? {
          ...data,
          additionalIncome: !!(
            data.centrelinkPayments.amount ||
            data.childSupportMaintenance.amount ||
            data.familyAllowance.amount ||
            data.familyTaxBenefit.amount ||
            data.governmentPension.amount ||
            data.dividendsInvestments.amount ||
            data.superannuation.amount ||
            data.other.amount
          ),
        }
      : { employment: [] };
    resetForm({ values });
  },
  { immediate: true, deep: true },
);

const update = handleSubmit(
  (values) => {
    const income: ApplicantIncome = {
      id: values.id || undefined,
      incomeExpectation: values.incomeExpectation || {},
      employmentDetail: values.employmentDetail || {},
      applicantId: values.applicantId ?? '',
      employment: values.employment,
      shortTermChange: values.shortTermChange ?? false,
      shortTermEmploymentChange: values.shortTermEmploymentChange ?? '',
      centrelinkPayments: values.centrelinkPayments ?? defaultPeriodicValue(),
      childSupportMaintenance: values.childSupportMaintenance ?? defaultPeriodicValue(),
      familyAllowance: values.familyAllowance ?? defaultPeriodicValue(),
      familyTaxBenefit: values.familyTaxBenefit ?? defaultPeriodicValue(),
      governmentPension: values.governmentPension ?? defaultPeriodicValue(),
      dividendsInvestments: values.dividendsInvestments ?? defaultPeriodicValue(),
      superannuation: values.superannuation ?? defaultPeriodicValue(),
      other: values.other ?? defaultPeriodicValue(),
      expectIncomeChange: values.expectIncomeChange ?? false,
      effectOnIncomeChange: values.effectOnIncomeChange ?? '',
    };
    emit('update:modelValue', income);
    return true;
  },
  () => false,
);

const cancel = () => resetForm();

defineExpose({ update, cancel });
</script>

<template>
  <div>
    <div class="form-control my-10">
      <BxField v-slot="{ field }" name="shortTermChange">
        <BxRadio
          v-bind="field"
          :id="applicantId + '-employment-change'"
          label="Will your employment situation change in the short term?"
          label-type="default"
          class="form-control"
          boolean
        />
      </BxField>
      <Transition name="fade">
        <div v-if="values.shortTermChange">
          <BxField v-slot="{ field }" name="shortTermEmploymentChange">
            <BxInput
              v-bind="field"
              :id="applicantId + '-employment-change-reason'"
              inputmode="text"
              label="Future employment details"
              label-type="floating"
              class="mt-4"
            />
          </BxField>
        </div>
      </Transition>
    </div>
    <div class="form-control my-10">
      <BxField v-slot="{ field }" name="additionalIncome">
        <BxRadio
          v-bind="field"
          :id="applicantId + '-additional-income'"
          label="Do you have any additional income?"
          label-type="default"
          class="form-control"
          boolean
        />
      </BxField>
      <Transition name="fade">
        <div v-if="values.additionalIncome" class="mt-8">
          <BxField v-slot="{ field }" name="centrelinkPayments">
            <BxPeriodicAmount
              v-bind="field"
              :id="applicantId + '-centerlink'"
              label="Centrelink payments"
              label-type="floating"
              leading-symbol="$"
              class="form-control mt-4"
            />
          </BxField>
          <BxField v-slot="{ field }" name="childSupportMaintenance">
            <BxPeriodicAmount
              v-bind="field"
              :id="applicantId + '-child-support'"
              label="Child support maintenance"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </BxField>
          <BxField v-slot="{ field }" name="familyAllowance">
            <BxPeriodicAmount
              v-bind="field"
              :id="applicantId + '-family-allowance'"
              label="Family allowance"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </BxField>
          <BxField v-slot="{ field }" name="familyTaxBenefit">
            <BxPeriodicAmount
              v-bind="field"
              :id="applicantId + '-family-tax-benefits'"
              label="Family tax benefits"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </BxField>
          <BxField v-slot="{ field }" name="governmentPension">
            <BxPeriodicAmount
              v-bind="field"
              :id="applicantId + '-government-pension'"
              label="Government pension"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </BxField>
          <BxField v-slot="{ field }" name="dividendsInvestments">
            <BxPeriodicAmount
              v-bind="field"
              :id="applicantId + '-dividends'"
              label="Dividends & investments"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </BxField>
          <BxField v-slot="{ field }" name="superannuation">
            <BxPeriodicAmount
              v-bind="field"
              :id="applicantId + '-superannuation'"
              label="Superannuation"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </BxField>
          <BxField v-slot="{ field }" name="other">
            <BxPeriodicAmount
              v-bind="field"
              :id="applicantId + '-other'"
              label="Other"
              label-type="floating"
              leading-symbol="$"
              class="form-control"
            />
          </BxField>
        </div>
      </Transition>
    </div>

    <div class="form-control my-10">
      <BxField v-slot="{ field }" name="expectIncomeChange">
        <BxRadio
          v-bind="field"
          :id="applicantId + '-income-change'"
          label="Do you expect any changes to your income in the next 12 months?"
          label-type="default"
          class="form-control"
          boolean
        />
      </BxField>
      <Transition name="fade">
        <div v-if="values.expectIncomeChange">
          <BxField v-slot="{ field }" name="effectOnIncomeChange">
            <BxTextarea
              v-bind="field"
              :id="applicantId + '-income-change-reason'"
              label="Please describe how this change will affect your financial position"
              label-type="floating"
              class="mt-4"
            />
          </BxField>
        </div>
      </Transition>
    </div>

    <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
      Please review the error messages above.
    </BxErrorPanel>
  </div>
</template>
