import type { operations } from '@/types/schemas/api.main';
import { POST } from '../../_http';
type controller = operations['CustomerAuthenticateVerifyOtpController_post'];

export type CustomerAuthenticationVerifyOtpBody = controller['requestBody']['content']['application/json'];
export type CustomerAuthenticationVerifyOtpResponse = controller['responses']['200']['content']['application/json'];

export const verifyOtp = POST('/users/authenticate/verify-otp')<
  CustomerAuthenticationVerifyOtpBody,
  CustomerAuthenticationVerifyOtpResponse
>;
