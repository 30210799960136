<template>
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.1472 9.98828L35.9995 17.1567"
      stroke="#020038"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1 17.2542L10.4765 8.18066L12.7602 6.00692L18.0968 1L28.1473 9.98804"
      stroke="#020038"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.6416 19.9399V14.0781"
      stroke="#020038"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.33374 14.0781V31.9199H19.3911"
      stroke="#020038"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.0795 40.7015C36.9288 40.7015 40.8599 36.7704 40.8599 31.9211C40.8599 27.0718 36.9288 23.1406 32.0795 23.1406C27.2302 23.1406 23.2991 27.0718 23.2991 31.9211C23.2991 36.7704 27.2302 40.7015 32.0795 40.7015Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.1973 31.9199H34.9491"
      stroke="#020038"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.0796 34.7909V29.0391"
      stroke="#020038"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.9247 18.8535H10.6843V25.2526H16.9247V18.8535Z"
      stroke="#54B7F9"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
