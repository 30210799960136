import { yupAddress, yupArray, yupBoolean, yupMixed, yupNumber, yupObject, yupString } from '../../yup';
import dayjs from '@/helpers/dayjs';
import type { OutgoingProperty } from '@/types';
import { SaleStatus as removeHardCodedSoldStatus } from '@/helpers/const';
import type { PropertyType, SoldStatus } from '@/api/enums';

export const outgoingPropertySchema = yupObject<OutgoingProperty>({
  propertyType: yupMixed<PropertyType>()
    .notOneOf(['vacant_land'], 'We currently do not accept vacant land as security')
    .required()
    .label('Property type'),
  address: yupAddress().required().label('Property address'),
  propertyPurpose: yupString().required().label('Property purpose'),
  outstandingLoanAmount: yupNumber().required().label('Outstanding mortgage'),
  saleStatus: yupMixed<SoldStatus>()
    .oneOf(Object.values(removeHardCodedSoldStatus))
    .required()
    .label('Property sold response'),
  settlementDate: yupString()
    .when('saleStatus', {
      is: removeHardCodedSoldStatus.SOLD,
      then: (schema) =>
        schema
          .required()
          .isoDate()
          .test('validDate', '${path} must be within 12 months', (value: string) => {
            const date = dayjs(value);
            return !(date.isBefore(dayjs(), 'day') || date.isAfter(dayjs().add(1, 'year'), 'day'));
          }),
      otherwise: (schema) => schema,
    })
    .label('Settlement date'),
  saleAmount: yupNumber().required().label('Sale price'),
  depositAmount: yupNumber()
    .when(['saleStatus', 'saleAmount'], ([saleStatus, saleAmount], schema) =>
      saleStatus === removeHardCodedSoldStatus.SOLD
        ? schema.max(saleAmount, `The deposit cannot be more than the sale price`).required()
        : schema,
    )
    .label('Deposit amount'),
  depositInTrust: yupBoolean()
    .when('saleStatus', {
      is: removeHardCodedSoldStatus.SOLD,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema,
    })
    .label('Deposit held in trust response'),
});

export const outgoingPropertiesSchema = yupArray<OutgoingProperty>()
  .of(outgoingPropertySchema)
  .min(1)
  .required()
  .label('Selling properties');
