export const australianState = (value?: string) => {
  const state = value?.trim().toLowerCase();
  switch (state) {
    case 'act':
    case 'nsw':
    case 'nt':
    case 'qld':
    case 'sa':
    case 'tas':
    case 'vic':
    case 'wa':
      return state;
    default:
      return 'other';
  }
};
