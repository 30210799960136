/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ApplicantIncome, PeriodKey, SalaryEmployment, SelfEmployment } from '@/types';
import type { ApiSchema, FrequencyType } from '@/types/api';
import { mapToApi } from '@/mappers';

const mapFrequency: Record<PeriodKey, FrequencyType> = {
  week: 'weekly',
  fortnight: 'fortnightly',
  month: 'monthly',
  quarter: 'quarterly',
  year: 'annually',
};

export const fundsSalaried = (data: SalaryEmployment, applicantIndex: number): any => {
  return {
    id: data.id,
    applicantIndex,
    salaryPackagedAmount: 0,
    salaryAmount: data.salary?.amount,
    salaryFrequency: data.salary?.frequency ? mapFrequency[data.salary?.frequency] : undefined,
    commissionsAmount: data.commission?.amount,
    commissionsFrequency: data.commission?.frequency ? mapFrequency[data.commission?.frequency] : undefined,
    bonusesAmount: data.bonuses?.amount,
    bonusesFrequency: data.bonuses?.frequency ? mapFrequency[data.bonuses.frequency] : undefined,
    overtimeAmount: data.overtime?.amount,
    overtimeFrequency: data.overtime?.frequency ? mapFrequency[data.overtime?.frequency] : undefined,
    nameOfEmployer: data.employerName,
    employerLine1: data.employerAddress?.addressLine,
    employerLine2: data.employerAddress?.addressLine2 ?? '',
    employerCity: data.employerAddress?.locality,
    employerState: mapToApi.australianState(data.employerAddress?.state),
    employerCountry: 'au',
    employerPostCode: data.employerAddress?.postcode ?? undefined,
    isSalarySacrifice: data.salarySacrifice,
    jobType: data.type,
    employedSince: data.employedSince,
  };
};

export const fundsSelfEmployed = (data: SelfEmployment, applicantIndex: number): any => {
  return {
    id: data.id,
    applicantIndex,
    salaryAmount: data.salary.amount,
    salaryFrequency: mapFrequency[data.salary.frequency],
    line1: data.businessAddress.addressLine,
    line2: data.businessAddress.addressLine2 ?? '',
    city: data.businessAddress.locality,
    state: mapToApi.australianState(data.businessAddress.state),
    postCode: data.businessAddress.postcode,
    country: 'au',
    businessType: data.businessType,
    tradingName: data.tradingName,
    occupation: data.occupation,
    industry: data.industry,
    isGSTRegistered: data.gstRegistered,
    australianBusinessNumber: data.abn,
    isSoleTrader: data.soleTrader,
    hasTaxReturnsLastTwoFinancialYears: data.hasTaxReturns,
    employedSince: data.employedSince,
  };
};

export const fundsAdditionalIncome = (data: ApplicantIncome | null, applicantIndex: number): any[] =>
  data
    ? [
        {
          id: data.id,
          applicantIndex,
          centerlinkPayments: data.centrelinkPayments.amount,
          centerlinkPaymentsFrequency: mapFrequency[data.centrelinkPayments.frequency],
          childSupportMaintenance: data.childSupportMaintenance.amount,
          childSupportMaintenanceFrequency: mapFrequency[data.childSupportMaintenance.frequency],
          familyAllowance: data.familyAllowance.amount,
          familyAllowanceFrequency: mapFrequency[data.familyAllowance.frequency],
          familyTaxBenefits: data.familyTaxBenefit.amount,
          familyTaxBenefitsFrequency: mapFrequency[data.familyTaxBenefit.frequency],
          governmentPension: data.governmentPension.amount,
          governmentPensionFrequency: mapFrequency[data.governmentPension.frequency],
          dividends: data.dividendsInvestments.amount,
          dividendsFrequency: mapFrequency[data.dividendsInvestments.frequency],
          superannuation: data.superannuation.amount,
          superannuationFrequency: mapFrequency[data.superannuation.frequency],
          other: data.other.amount,
          otherFrequency: mapFrequency[data.other.frequency],
        },
      ]
    : [];

export const incomeExpectation = (
  data: ApplicantIncome | null,
  applicantIndex: number,
): (
  | ApiSchema['CustomerApplicationsSubmitApplicantIncomeExpectationDtoV1']
  | ApiSchema['CustomerApplicationsSubmitApplicantIncomeExpectationDtoV2']
)[] =>
  data
    ? [
        {
          id: data.incomeExpectation?.id,
          applicantIndex,
          expectedChangesInNext12Months: data.expectIncomeChange,
          financialPosition: data.effectOnIncomeChange,
        },
      ]
    : [];

export const employmentDetail = (data: ApplicantIncome | null, applicantIndex: number): any[] =>
  data
    ? [
        {
          id: data.employmentDetail?.id,
          applicantIndex,
          employmentChangeInShortTerm: data.shortTermChange,
          detailsOnChange: data.shortTermEmploymentChange,
        },
      ]
    : [];
