import type { IncomingProperty, LoanApplication } from '@/types';
import { computed, ref } from 'vue';
import api from '@/api';
import { mapToApi } from '@/mappers';

export function useCalcApi() {
  const isProcessing = ref(0);

  const getStampDuty = async (data: IncomingProperty): Promise<number> => {
    isProcessing.value++;
    try {
      const response = await api.calculator.formulas.stampDuty({
        postCode: data.address.postcode,
        purchasePrice: data.purchaseAmount as never,
      });
      return response.status === 200 ? Number(response.data.result) : 0;
    } catch (err) {
      console.error(err);
      return 0;
    } finally {
      isProcessing.value--;
    }
  };

  const getLoanAmount = async (loanApp: LoanApplication): Promise<number> => {
    isProcessing.value++;
    try {
      const payload = mapToApi.createApplication(loanApp);
      const response = await api.calculator.formulas.loanAmount(payload);
      return response.data.result;
    } catch (err) {
      console.error(err);
      return 0;
    } finally {
      isProcessing.value--;
    }
  };

  const getEndDebt = async (loanApp: LoanApplication) => {
    isProcessing.value++;
    try {
      const payload = mapToApi.createApplication(loanApp);
      const response = await api.calculator.formulas.endDebt(payload);
      return response.result;
    } catch (err) {
      console.error(err);
    } finally {
      isProcessing.value--;
    }
  };

  return {
    isLoading: computed(() => isProcessing.value > 0),
    getStampDuty,
    getLoanAmount,
    getEndDebt,
  };
}
