<script setup lang="ts">
import api from '@/api';
import BxField from '@/components/common/BxField.vue';
import BxInput from '@/components/common/BxInput.vue';
import BxModal from '@/components/common/BxModal.vue';
import BxSelect from '@/components/common/BxSelect.vue';
import BxSpinner from '@/components/common/BxSpinner.vue';
import { stateList } from '@/helpers/const';
import type { Address } from '@/types/Address';
import { addressSchema } from '@/validation';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { HomeIcon } from '@heroicons/vue/24/outline';
import { useDebounceFn, useFetch } from '@vueuse/core';
import { nanoid } from 'nanoid';
import { useForm } from 'vee-validate';
import { computed, ref, watch } from 'vue';

type KleberAddress = {
  RecordId: string;
  AddressLine: string;
  Locality: string;
  State: string;
  Postcode: string;
};
type KleberResponse<T> = {
  DtResponse: {
    ErrorMessage: string;
    RequestId: string;
    Result: T[];
    ResultCount: string;
  };
};
type AddressOption = Address & { recordId: string; index: number; id: string };
const props = withDefaults(
  defineProps<{
    id?: string;
    label: string;
    labelType?: 'default' | 'overlapping' | 'floating';
    placeholder?: string;
    modelValue?: Address;
    errorMessage?: string;
    highlightError?: boolean;
  }>(),
  {
    id: undefined,
    labelType: 'default',
    placeholder: 'Start typing to search',
    modelValue: undefined,
    errorMessage: undefined,
    highlightError: false,
  },
);
const postcodeError = ref('');
const emit = defineEmits<{
  (e: 'update:modelValue', value: Address | undefined): void;
}>();

const inputId = computed(() => props.id ?? nanoid(8));
const query = ref('');
const addressModalOpen = ref(false);
const selectedAddress = ref<Address | undefined>(props.modelValue);

watch(
  () => props.modelValue,
  (value) => {
    if (selectedAddress.value != value) {
      selectedAddress.value = value;
    }
  },
);

function displayValue(item: unknown) {
  if (!item) {
    return '';
  }
  return [(item as Address).addressLine, (item as Address).addressLine2].filter((x) => x).join(', ');
}

function compare(item1?: Address | null, item2?: Address | null) {
  return (
    item1 &&
    item2 &&
    item1.addressLine === item2.addressLine &&
    item1.addressLine2 === item2.addressLine2 &&
    item1.locality === item2.locality &&
    item1.state === item2.state &&
    item1.postcode === item2.postcode
  );
}

const searchUrl = computed(() => {
  const params = new URLSearchParams({
    AddressLine: query.value,
    ResultLimit: '20',
    RequestKey: import.meta.env.VITE_DATATOOLS_API_KEY,
  });
  // https://kleberbrowser.datatoolscloud.net.au/kleberbrowser/KleberMethodDescription.aspx?Method=DataTools.Capture.Address.Predictive.AuPaf.SearchAddress
  return `https://kleber.datatoolscloud.net.au/KleberWebService/DtKleberService.svc/ProcessQueryStringRequest?Method=DataTools.Capture.Address.Predictive.AuPaf.SearchAddress&OutputFormat=json&${params.toString()}`;
});
const { data, isFetching, execute } = useFetch(searchUrl, {
  immediate: false,
  afterFetch: (ctx) => {
    const data = ctx.data as KleberResponse<KleberAddress>;
    const mapped: AddressOption[] =
      data?.DtResponse?.Result?.map((item, index) => ({
        id: `${inputId.value}-option-${index}`,
        index,
        recordId: item.RecordId,
        addressLine: item.AddressLine,
        addressLine2: undefined,
        locality: item.Locality,
        state: item.State,
        postcode: item.Postcode,
        countryCode: 'AU',
        addressManuallyCreated: false,
      })) || [];
    ctx.data = mapped;
    return ctx;
  },
}).json<AddressOption[]>();

let lastSelectTimestamp = 0;
const _debouncedSearch = useDebounceFn((event: Event, timestamp: number) => {
  // cancel any search queued prior to last address selection
  if (lastSelectTimestamp > timestamp) {
    return;
  }

  const value = (event.target as HTMLInputElement).value;
  if (query.value === value) {
    return;
  }
  query.value = value;
  if (query.value) {
    execute();
  } else {
    data.value = [];
  }
}, 250);

function handleInputChange(event: Event) {
  _debouncedSearch(event, new Date().valueOf());
}

function handleAddressSelect(value: AddressOption | null) {
  if (compare(value, props.modelValue)) {
    return;
  }

  lastSelectTimestamp = new Date().valueOf();

  if (!value) {
    query.value = '';
    data.value = [];
    selectedAddress.value = undefined;
    emit('update:modelValue', undefined);
    return;
  }

  const { recordId, ...address } = value;
  selectedAddress.value = address;
  emit('update:modelValue', address);

  // background request to retrieve address details
  // note: this request is required by Kleber to comply with data licencing conditions
  const params = new URLSearchParams({
    RecordId: recordId,
    RequestId: lastSelectTimestamp.toString(),
    RequestKey: import.meta.env.VITE_DATATOOLS_API_KEY,
  });
  useFetch(
    // https://kleberbrowser.datatoolscloud.net.au/kleberbrowser/KleberMethodDescription.aspx?Method=DataTools.Capture.Address.Predictive.AuPaf.RetrieveAddress
    `https://kleber.datatoolscloud.net.au/KleberWebService/DtKleberService.svc/ProcessQueryStringRequest?Method=DataTools.Capture.Address.Predictive.AuPaf.RetrieveAddress&OutputFormat=json&${params.toString()}`,
    {
      afterFetch: (ctx) => {
        const response = (ctx.data as KleberResponse<Record<string, string>>)?.DtResponse;
        if (
          // check that response is for latest selected address
          response?.RequestId === lastSelectTimestamp.toString() &&
          response.Result?.length
        ) {
          address.addressData = response.Result[0];
          selectedAddress.value = address;
          emit('update:modelValue', address);
        }
        return ctx;
      },
    },
  ).json();
}

const {
  handleSubmit: validateAddressModalSubmit,
  setValues: setAddressModalValues,
  setFieldValue: setAddressModalFieldValue,
} = useForm<Address>({
  validationSchema: addressSchema,
  validateOnMount: false,
});

function openAddressModal() {
  setAddressModalValues({
    addressLine: selectedAddress.value?.addressLine,
    addressLine2: selectedAddress.value?.addressLine2,
    locality: selectedAddress.value?.locality,
    state: selectedAddress.value?.state,
    postcode: selectedAddress.value?.postcode,
    countryCode: 'AU',
  });
  addressModalOpen.value = true;
}

const handleAddressModalSubmit = validateAddressModalSubmit(async (values) => {
  if (values.postcode?.length !== 4) {
    postcodeError.value = 'Invalid post code';
    return;
  }
  try {
    const validation = await api.backend.public.postCode({ postCode: values.postcode });
    if (validation.data?.message === 'success') {
      if (validation.data?.postCode?.state !== values.state.toLowerCase()) {
        postcodeError.value = 'Post code is not in state';
      } else {
        postcodeError.value = '';
        addressModalOpen.value = false;
        selectedAddress.value = { ...values } as Address;
        emit('update:modelValue', selectedAddress.value);
      }
      return;
    } else {
      postcodeError.value = 'Invalid post code';
    }
  } catch (error) {
    postcodeError.value = 'Invalid post code';
  }
});

const wrapperRef = ref<HTMLDivElement>();
function handleFocusOut(event: Event) {
  // cancel event if focus remains within component
  const target = (event as FocusEvent).relatedTarget as HTMLElement;
  if (target && wrapperRef.value?.contains(target)) {
    event.stopImmediatePropagation();
  }
}

function formatAddressFields(event: Event) {
  const id = (event.target as HTMLInputElement)?.id;
  const value = (event.target as HTMLInputElement)?.value;
  if (!id || !value) {
    return;
  }
  switch (id) {
    case `locality-${inputId.value}`:
      setAddressModalFieldValue('locality', value.toUpperCase());
      break;
    case `addressLine-${inputId.value}`:
      setAddressModalFieldValue('addressLine', titleCase(value));
      break;
    case `addressLine2-${inputId.value}`:
      setAddressModalFieldValue('addressLine2', titleCase(value));
      break;
  }
}

function titleCase(value: string) {
  return value
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(' ');
}
</script>

<template>
  <div ref="wrapperRef">
    <Combobox
      v-slot="{ open }"
      :model-value="((modelValue || null) as any) /* note: value must be set to null to clear */"
      as="div"
      :by="compare"
      nullable
      @update:model-value="handleAddressSelect($event as AddressOption | null)"
      @focusout="handleFocusOut"
    >
      <ComboboxLabel v-if="labelType === 'default'" class="block text-sm font-medium text-gray-700">
        {{ label }}
      </ComboboxLabel>
      <div class="group relative mt-1">
        <ComboboxInput
          :id="inputId"
          :data-test-id="inputId"
          :display-value="displayValue"
          class="peer w-full rounded-md border border-gray-300 bg-white px-2 pl-3 pr-10 text-base shadow-sm transition-[padding-bottom] focus:border-bridgit-royalBlue focus:outline-none focus:ring-1 focus:ring-bridgit-royalBlue"
          :class="[
            errorMessage ? '!border-rose-500 focus:!ring-rose-500' : null,
            labelType === 'floating' ? 'placeholder-transparent group-focus-within:placeholder-gray-500' : null,
            modelValue && !open ? 'pb-6 group-focus-within:pb-2' : null,
          ]"
          :placeholder="labelType === 'floating' ? placeholder || ' ' : placeholder"
          autocomplete="off"
          @change="handleInputChange"
        />
        <ComboboxButton
          v-show="query || open"
          class="absolute inset-y-0 right-0 flex cursor-default items-center rounded-r-md px-2 focus:outline-none"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>
        <div
          v-if="modelValue && !open"
          class="pointer-events-none absolute bottom-1.5 left-3 right-10 text-xs group-focus-within:hidden"
        >
          {{ modelValue.locality }}, {{ modelValue.state }}
          {{ modelValue.postcode }}
        </div>
        <label
          v-if="labelType !== 'default'"
          :for="inputId"
          class="pointer-events-none absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
          :class="
            labelType === 'floating'
              ? [
                  'transition-all',
                  'peer-placeholder-shown:top-2 peer-placeholder-shown:mt-0 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-placeholder-shown:text-gray-700',
                  'group-focus-within:!-top-2 group-focus-within:!-mt-px group-focus-within:!text-xs group-focus-within:!font-medium group-focus-within:!text-gray-900',
                ]
              : []
          "
        >
          {{ label }}
        </label>
        <ComboboxOptions
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ComboboxOption :disabled="true">
            <div class="flex items-center px-3">
              <div v-if="data" class="flex-1">
                <div v-show="!data.length" class="py-2">No matching addresses found.</div>
              </div>
              <BxSpinner v-show="isFetching && !data?.length" class="py-2 text-gray-500">
                <span v-if="!data">Searching...</span>
              </BxSpinner>
            </div>
          </ComboboxOption>
          <ComboboxOption
            v-for="item in data"
            v-slot="{ active, selected }"
            :key="item.recordId"
            :value="item"
            as="template"
          >
            <li
              :id="item.id"
              :data-test-id="item.id"
              :class="[
                'relative cursor-default select-none py-2 pl-3 pr-9',
                active ? 'bg-bridgit-royalBlue text-white' : 'text-gray-900',
              ]"
            >
              <span class="flex flex-col">
                <span
                  :id="`${item.id}-span1`"
                  :data-test-id="`${item.id}-span1`"
                  class="leading-tight"
                  :class="{ 'font-semibold': selected }"
                >
                  {{ item.addressLine }}
                </span>
                <span
                  :id="`${item.id}-span2`"
                  :data-test-id="`${item.id}-span2`"
                  class="pt-1 text-xs"
                  :class="selected ? 'font-bold' : 'font-medium'"
                >
                  {{ item.locality }}, {{ item.state }} {{ item.postcode }}
                </span>
              </span>
              <span
                v-if="selected"
                :class="[
                  'absolute inset-y-0 right-0 flex items-center pr-4',
                  active ? 'text-white' : 'text-bridgit-royalBlue',
                ]"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </div>
    </Combobox>
    <transition :enter-active-class="!highlightError ? 'animate-fadeIn' : ''">
      <p
        v-show="!!errorMessage"
        :id="`${inputId}-error`"
        class="mt-1 text-sm text-rose-500"
        :class="{ 'animate-shakeX': highlightError }"
      >
        {{ errorMessage }}
      </p>
    </transition>
    <!-- postcodeError -->
    <div class="mt-2 text-sm">
      <span class="italic">Can't find an address?</span>&nbsp;
      <a
        href="#"
        class="rounded-md text-sky-600 underline hover:no-underline focus:text-bridgit-royalBlue focus:outline-none focus-visible:ring-2 focus-visible:ring-bridgit-royalBlue focus-visible:ring-offset-2"
        @click.prevent="openAddressModal"
      >
        Enter it manually
      </a>
    </div>

    <BxModal
      :open="addressModalOpen"
      :icon="HomeIcon"
      title="Property Address"
      confirm-label="Use address"
      cancel-label="Cancel"
      @close="addressModalOpen = false"
      @confirm="handleAddressModalSubmit"
    >
      <form @submit="handleAddressModalSubmit">
        <div class="mt-4 grid grid-cols-6 gap-x-4 gap-y-3">
          <BxField v-slot="{ field }" name="addressLine">
            <BxInput
              :id="`addressLine-${inputId}`"
              v-bind="field"
              label="Address line 1"
              label-type="floating"
              class="col-span-6"
              @focusout="formatAddressFields"
            />
          </BxField>
          <BxField v-slot="{ field }" name="addressLine2">
            <BxInput
              :id="`addressLine2-${inputId}`"
              v-bind="field"
              label="Address line 2"
              label-type="floating"
              class="col-span-6"
              @focusout="formatAddressFields"
            />
          </BxField>
          <BxField v-slot="{ field }" name="locality">
            <BxInput
              :id="`locality-${inputId}`"
              v-bind="field"
              label="Suburb"
              label-type="floating"
              class="col-span-4"
              @focusout="formatAddressFields"
            />
          </BxField>
          <BxField v-slot="{ field }" name="state">
            <BxSelect
              :id="`state-${inputId}`"
              v-bind="field"
              :list-items="stateList"
              label="State"
              label-type="floating"
              class="col-span-2 col-start-1"
            />
          </BxField>
          <div class="col-span-2">
            <BxField v-slot="{ field }" name="postcode">
              <BxInput
                :id="`postcode-${inputId}`"
                v-bind="field"
                label="Postcode"
                label-type="floating"
                class="col-span-2"
                minlength="4"
                maxlength="4"
              />
            </BxField>
            <p
              v-show="!!postcodeError"
              :id="`${inputId}-error`"
              class="mt-1 text-sm text-rose-500"
              :class="{ 'animate-shakeX': highlightError }"
            >
              {{ postcodeError }}
            </p>
          </div>
        </div>
      </form>
    </BxModal>
  </div>
</template>
