<script setup lang="ts">
import api from '@/api';
import type { AdditionalFundsRequiredDto, ListAdditionalFundsPurposes } from '@/api/backend/public';
import { defaultAdditionalFundRequired, defaultIncomingProperty } from '@/helpers/builder';
import { PropertyType, StampDutyStatus, PropertyToFundsPurposeMap } from '@/helpers/const';
import { useCalcApi } from '@/helpers/useCalcApi';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import type { IncomingProperty, IncomingPropertyEntry } from '@/types';
import { incomingPropertySchemaV1 } from '@/validation/schemas/v1/incoming-property';
import { incomingPropertySchemaV2 } from '@/validation/schemas/v2/incoming-property';
import { ArrowTopRightOnSquareIcon } from '@heroicons/vue/20/solid';
import { snakeCase } from '@/helpers/format';
import { useForm } from 'vee-validate';
import { ref, watchEffect, onBeforeMount } from 'vue';
import IncomingPropertyAddress from '@/components/application/incoming-property-address.vue';
import IncomingPropertyDepositAmount from '@/components/application/incoming-property-deposit-amount.vue';
import IncomingPropertyDepositType from '@/components/application/incoming-property-deposit-type.vue';
import IncomingPropertyEstimatedDepositAmount from '@/components/application/incoming-property-estimated-deposit-amount.vue';
import IncomingPropertyEstimatedSettlementDate from '@/components/application/incoming-property-estimated-settlement-date.vue';
import IncomingPropertyEstimatedPurchasePrice from '@/components/application/incoming-property-estimated-purchase-price.vue';
import IncomingPropertyPropertyPurpose from '@/components/application/incoming-property-property-purpose.vue';
import IncomingPropertyPurchasePrice from '@/components/application/incoming-property-purchase-price.vue';
import IncomingPropertyReceiveRentalIncome from '@/components/application/incoming-property-receive-rental-income.vue';
import IncomingPropertyExpectedRentalIncome from '@/components/application/incoming-property-expected-rental-income.vue';
import IncomingPropertySettlementDate from '@/components/application/incoming-property-settlement-date.vue';
import IncomingPropertyStampDuty from '@/components/application/incoming-property-stamp-duty.vue';
import IncomingPropertyType from '@/components/application/incoming-property-type.vue';
type IncomingPropertyWithSettlementDate = IncomingPropertyEntry & { settlementDate: string };

const { state, loanApp, saveAndGoNext, goBack, send } = useLoanAppProcess();
const calc = useCalcApi();
const isListReady = ref(false);

let listAdditionalFundsPurposes: ListAdditionalFundsPurposes = [];
let propertyCounter = 0;
const scheduleModalOpen = ref(false);

const {
  values,
  setFieldValue,
  meta,
  submitCount,
  handleSubmit: validateSubmit,
  resetForm,
} = useForm<IncomingPropertyWithSettlementDate>({
  validationSchema: loanApp.value.structuralVersionNumber === 1 ? incomingPropertySchemaV1 : incomingPropertySchemaV2,
  initialValues: loanApp.value.incomingProperties.length
    ? { ...loanApp.value.incomingProperties[0] }
    : { id: `incoming-property-${propertyCounter++}` },
  validateOnMount: false,
});

if (loanApp.value.incomingProperties.length) {
  resetForm({ values: loanApp.value.incomingProperties[0] });
}

watchEffect(() => {
  if (!values.contractForSale && values.stampDutyStatus === StampDutyStatus.ALREADY_PAID) {
    setFieldValue('stampDutyStatus', undefined);
  }
});

const handleSubmit = validateSubmit(async (values) => {
  const property: IncomingProperty = Object.assign(defaultIncomingProperty(), values);
  if (
    values.propertyType === PropertyType.RETIREMENT_LAND_OR_COMMUNITY_HOME ||
    values.propertyType === PropertyType.VACANT_LAND
  ) {
    const additionalFundsPurposeId = listAdditionalFundsPurposes?.find(
      (item) => snakeCase(item.title) === PropertyToFundsPurposeMap[values.propertyType ?? PropertyType.VACANT_LAND],
    )?.id;
    send({
      type: 'UPDATE',
      loanApp: {
        ...loanApp.value,
        loanType: 'single_security',
      },
    });

    const additionalFundsRequired: AdditionalFundsRequiredDto = Object.assign(defaultAdditionalFundRequired(), {
      fundsRequiredDate: values.settlementDate,
      fundsRequiredAmount: values.purchaseAmount ?? 0,
      additionalFundsPurposeId: additionalFundsPurposeId ?? '',
    });

    saveAndGoNext({ additionalFundsRequired, incomingProperties: [property] });
  } else {
    send({
      type: 'UPDATE',
      loanApp: {
        ...loanApp.value,
        loanType: 'buy_now',
      },
    });

    /*
     * If single security property saved and then customer changes incoming property,
     * it will still have fundsRequired. This needs to be removed when saving buy now property.
     */
    delete loanApp.value.additionalFundsRequired;

    property.stampDuty = await calc.getStampDuty(property);
    loanApp.value.settlementDate = values.settlementDate;
    saveAndGoNext({ incomingProperties: [property] });
  }
});

onBeforeMount(async () => {
  isListReady.value = false;
  try {
    listAdditionalFundsPurposes = await api.backend.public.listAdditionalFundsPurposes();
  } finally {
    isListReady.value = true;
  }
});
</script>

<template>
  <div>
    <div class="mb-8 pb-2">
      <h2 class="text-2xl font-medium">
        Tell us about the property you are
        <BxHighlight>buying</BxHighlight>?
      </h2>
    </div>
    <form @submit="handleSubmit">
      <div>
        <BxField v-slot="{ field }" name="contractForSale">
          <BxRadio
            v-bind="field"
            :id="values.id + '-contract-of-sale'"
            label="Do you have a contract for sale?"
            label-type="default"
            class="form-control"
            boolean
          />
        </BxField>
        <!-- if there is no contract of sale, and the user has not entered with the `noContractOfSale` parameter -->
        <div v-if="values.contractForSale === false && !loanApp.allowNoContractOfSale" class="no-contract">
          <div class="no-contract-header text-1xl font-medium">
            <BxIcon name="circleInfo" />
            <p>Get a quote</p>
          </div>
          <div class="no-contract-info">
            With no contract of sale, we require you to either speak with one of our customer success team members or
            complete the quote application first. This will allow you to understand our solution and avoid having a
            credit check done.
            <br />
            <br />
            Schedule a call with our team or call us directly, and we will provide a same day quote (Monday-Friday,
            9am-5pm) so you can understand how the Bridgit solution can help you!
            <br />
            <br />
            <div class="no-contract-buttons">
              <BxLinkButton variant="primary" href="https://www.bridgit.com.au/get-a-quote">
                Get a quote<ArrowTopRightOnSquareIcon class="mb-1 ml-1 inline h-4 w-4" />
              </BxLinkButton>
              <BxLinkButton variant="tertiary" @click="scheduleModalOpen = true"> Schedule a call </BxLinkButton>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="values.contractForSale === true" class="incoming-margin-top">
            <IncomingPropertyType :id="values.id" />
          </div>
          <!-- if there is a contract for sale-->
          <div v-if="values.contractForSale === true" class="has-contract">
            <div v-if="values.propertyType" class="incoming-margin-top">
              <IncomingPropertyAddress :id="values.id" />
              <IncomingPropertyPropertyPurpose
                v-if="
                  values.propertyType === PropertyType.RESIDENTIAL ||
                  values.propertyType === PropertyType.RURAL_RESIDENTIAL ||
                  values.propertyType === PropertyType.OFF_PLAN_RESIDENTIAL
                "
                :id="values.id"
              />
              <IncomingPropertyReceiveRentalIncome :id="values.id" />
              <IncomingPropertyExpectedRentalIncome v-if="values.receiveRentalIncome" :id="values.id" />
              <IncomingPropertySettlementDate :id="values.id" />
              <IncomingPropertyPurchasePrice :id="values.id" />
              <IncomingPropertyDepositAmount :id="values.id" />
              <IncomingPropertyDepositType :id="values.id" :contract-for-sale="true" />
              <IncomingPropertyStampDuty
                v-if="values.propertyType !== PropertyType.RETIREMENT_LAND_OR_COMMUNITY_HOME"
                :id="values.id"
                :contract-for-sale="true"
              />
            </div>
          </div>
          <!-- if there is no contract for sale but the user has entered with the 'noContractOfSale' parameter-->
          <div v-else-if="values.contractForSale === false && loanApp.allowNoContractOfSale" class="no-contract">
            <div class="incoming-margin-top">
              <IncomingPropertyType :id="values.id" />
              <IncomingPropertyAddress :id="values.id" />
              <div v-if="values.propertyType" class="incoming-margin-top">
                <div
                  v-if="values.propertyType === PropertyType.RETIREMENT_LAND_OR_COMMUNITY_HOME"
                  class="incoming-margin-top"
                >
                  <IncomingPropertyEstimatedSettlementDate :id="values.id" />
                  <IncomingPropertyEstimatedPurchasePrice :id="values.id" />
                  <IncomingPropertyEstimatedDepositAmount :id="values.id" />
                  <IncomingPropertyDepositType :id="values.id" :contract-for-sale="false" />
                </div>
                <div v-else-if="values.propertyType === PropertyType.VACANT_LAND" class="incoming-margin-top">
                  <IncomingPropertyEstimatedSettlementDate :id="values.id" />
                  <IncomingPropertyEstimatedPurchasePrice :id="values.id" />
                  <IncomingPropertyEstimatedDepositAmount :id="values.id" />
                  <IncomingPropertyDepositType :id="values.id" :contract-for-sale="false" />
                  <IncomingPropertyStampDuty :id="values.id" :contract-for-sale="false" />
                </div>
                <!-- residential, rural residential, off-plan residential -->
                <div v-else>
                  <IncomingPropertyPropertyPurpose :id="values.id" />
                  <IncomingPropertyReceiveRentalIncome :id="values.id" />
                  <IncomingPropertyExpectedRentalIncome v-if="values.receiveRentalIncome" :id="values.id" />
                  <IncomingPropertyEstimatedSettlementDate :id="values.id" />
                  <IncomingPropertyEstimatedPurchasePrice :id="values.id" />
                  <IncomingPropertyEstimatedDepositAmount :id="values.id" />
                  <IncomingPropertyDepositType :id="values.id" :contract-for-sale="false" />
                  <IncomingPropertyStampDuty :id="values.id" :contract-for-sale="false" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <BxErrorPanel v-if="values.contractForSale" v-show="!meta.valid && submitCount > 0" class="mt-6">
          Please review the error messages above.
        </BxErrorPanel>
      </div>
      <div v-if="values.contractForSale === true || loanApp.allowNoContractOfSale" class="mt-6">
        <BxButton id="incoming-properties-continue" variant="primary" type="submit">Save and continue</BxButton>
        <BxButton v-if="state.nextEvents.includes('BACK')" variant="link" class="ml-4" @click="goBack()">Back</BxButton>
      </div>
    </form>
  </div>
  <BxModal :open="scheduleModalOpen" title="Schedule a call" cancel-label="Close" @close="scheduleModalOpen = false">
    <iframe
      src="https://letsmeet.io/bridgit9/quote-consultation"
      style="border: none; min-height: 700px; width: 1px; min-width: 100%; *width: 100%"
      name="booking"
      scrolling="no"
      frameborder="0"
      marginheight="0px"
      marginwidth="0px"
      width="100%"
      height="100%"
      referrerpolicy="unsafe-url"
      allowfullscreen
    ></iframe>
    <template #buttons>
      <div class="schedule-buttons">
        <BxButton variant="link" class="ml-6" @click="scheduleModalOpen = false">Close</BxButton>
      </div>
    </template>
  </BxModal>
</template>

<style lang="scss" scoped>
.has-contract {
  margin-top: 1.5rem;
}
.no-contract {
  margin-top: 1.5rem;
  margin: 17px 16px 17px 0px;
}
.no-contract-info {
  border-radius: 8px 8px 0px 0px;
  background-color: white;
  padding: 15px;
}
.no-contract-header {
  border-radius: 8px 8px 0px 0px;
  background-color: #cccccc;
  display: flex;
  gap: 10px;
  padding: 15px;

  p {
    margin-top: 3px;
  }
}
.no-contract-buttons {
  display: flex;
  gap: 15px;
}
.incoming-margin-top {
  margin-top: 1.5rem;
}
.link {
  @apply cursor-pointer text-bridgit-navy underline hover:text-bridgit-vibrantGreen;
}

.schedule-buttons {
  width: 100%;
  text-align: right;
}
</style>
