import type { operations } from '@/types/schemas/api.main';
import { GET } from '../../_http';
type controllerV1 = operations['PublicExpenseTypesListExpenseTypesControllerV1_get'];
type controllerV2 = operations['PublicExpenseTypesListExpenseTypesControllerV2_get'];
type ListExpenseTypesResponseV1 = controllerV1['responses']['200']['content']['application/json'];
type ListExpenseTypesResponseV2 = controllerV2['responses']['200']['content']['application/json'];

export const listExpenseTypesV1 = GET('/v1/public/expense-types/list-expense-types')<ListExpenseTypesResponseV1>;
export const listExpenseTypesV2 = GET('/v2/public/expense-types/list-expense-types')<ListExpenseTypesResponseV2>;

export const listExpenseTypes = async (
  structuralVersionNumber?: number,
): Promise<ListExpenseTypesResponseV1 | ListExpenseTypesResponseV2> => {
  structuralVersionNumber = structuralVersionNumber || parseInt(import.meta.env.VITE_STRUCTURAL_VERSION_NUMBER || '0');
  if (structuralVersionNumber === 1) {
    const { data } = await listExpenseTypesV1();
    return data;
  }
  const { data } = await listExpenseTypesV2();
  return data;
};
