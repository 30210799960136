<template>
  <svg viewBox="0 0 30 30" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.3793 27.6H8.50375C7.45167 27.6 6.59998 26.7484 6.59998 25.6963V16.979C6.59998 15.9269 7.45167 15.0752 8.50375 15.0752H21.3793C22.4314 15.0752 23.2831 15.9269 23.2831 16.979V25.6963C23.2831 26.7484 22.4314 27.6 21.3793 27.6Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.40552 14.0732V7.91093C9.40552 4.85486 11.8604 2.39999 14.9165 2.39999C17.9725 2.39999 20.4274 4.85486 20.4274 7.91093V14.0732"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.9165 23.1412C15.9126 23.1412 16.7201 22.3337 16.7201 21.3376C16.7201 20.3415 15.9126 19.534 14.9165 19.534C13.9204 19.534 13.1129 20.3415 13.1129 21.3376C13.1129 22.3337 13.9204 23.1412 14.9165 23.1412Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
