import type { AdditionalFundsRequiredDto } from '@/api/backend/public';
import type { ApplicationDto, UtmMetricDto } from '@/api/backend/users/applications';
import { mapFromApi } from '@/mappers';
import type { Applicant, ApplicantFinancials, Financials, LoanApplication, LoanTypeEnum } from '@/types';

const mapFinancials = (applicantId: string, application: ApplicationDto): Financials => {
  return {
    income: mapFromApi.income(applicantId, application),
    expenses: mapFromApi.expenses(applicantId, application),
    assets: mapFromApi.assets(applicantId, application),
    liabilities: mapFromApi.liabilities(applicantId, application),
  };
};

export const application = (data: ApplicationDto): LoanApplication => {
  const applicants = data.applicants
    .map(mapFromApi.applicant)
    .sort((applicant) => (applicant.type === 'primary' ? -1 : 1));
  const financials = applicants.reduce((obj: Record<string, Financials>, applicant: Applicant) => {
    obj[applicant.id] = mapFinancials(applicant.id, data);
    return obj;
  }, {} as ApplicantFinancials);

  return {
    cancelPriorApplications: false,
    settlementDate: data.settlementDate,
    additionalFundsRequired: (data.additionalFundsRequired ?? {}) as unknown as AdditionalFundsRequiredDto,
    applicationId: data.id ?? '',
    userType: 'Secondary', // ???
    actionType: 'updateExisting',
    loanType: data.loanPurpose as LoanTypeEnum,
    applicants,
    incomingProperties: data.incomingProperties?.map(mapFromApi.incomingProperty) ?? [],
    outgoingProperties: data.outgoingProperties?.map(mapFromApi.outgoingProperty) ?? [],
    propertiesOwnership: mapFromApi.propertyOwnership(data.propertiesOwnership ?? []),
    loanDetails: mapFromApi.additionalFunds(data),
    financials,
    endDebt: data.calculatedValue?.endDebt ?? 0,
    verificationCode: '',
    brokerId: data.brokerId ?? '',
    structuralVersionNumber: data.structuralVersionNumber ?? 0,
    summaryNotes: data.summaryNotes ?? '',
    utmMetrics: {} as UtmMetricDto,
  };
};
