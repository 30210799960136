import { stateList } from '@/helpers/const';
import { yupObject, yupString } from '../../yup';

const australiaPostcodeRegex = /^(?:0[289][0-9]{2})|(?:[1-9][0-9]{3})$/;

export const addressSchema = yupObject({
  addressLine: yupString().required().label('Street address'),
  locality: yupString().required().label('Suburb'),
  state: yupString().oneOf(stateList).required().label('State'),
  postcode: yupString()
    .matches(australiaPostcodeRegex, {
      excludeEmptyString: true,
      message: '${path} is not valid',
    })
    .required()
    .label('Postcode'),
  countryCode: yupString().oneOf(['AU', 'au']).required().label('Country code'),
});
