<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useForm } from 'vee-validate';
import { employmentSchema } from '@/validation';
import type { EmploymentEntry, EmploymentTypeEnum } from '@/types';
import { EmploymentType, employmentTypeOptions } from '@/helpers/const';
import { TrashIcon } from '@heroicons/vue/24/outline';
import SalaryForm from './SalaryForm.vue';
import SelfEmployedForm from './SelfEmployedForm.vue';

const props = withDefaults(
  defineProps<{
    applicantId: string;
    modelValue: EmploymentEntry;
    isMultiple?: boolean;
  }>(),
  {
    isMultiple: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', v: EmploymentEntry): void;
  (e: 'save'): void;
  (e: 'remove', id: string): void;
  (e: 'employmentType', type: EmploymentTypeEnum): void;
}>();

const employmentOptions = computed(() => {
  const options: Partial<Record<EmploymentTypeEnum, { label: string; description: string; employed: boolean }>> = {
    ...employmentTypeOptions,
  };
  if (props.isMultiple) {
    delete options[EmploymentType.RETIRED];
    delete options[EmploymentType.UNEMPLOYED];
  }
  return options;
});

const newItem = ref(!props.modelValue.type);
const showEntry = ref(!props.modelValue.type);

const { values, handleSubmit, resetForm } = useForm<EmploymentEntry>({
  validationSchema: employmentSchema,
  validateOnMount: false,
});

const employmentInput = ref<EmploymentEntry>(props.modelValue);
const salaryRef = ref<InstanceType<typeof SalaryForm>>();
const selfEmployedRef = ref<InstanceType<typeof SelfEmployedForm>>();

const cardTitle = computed(() => {
  const desc = values.type ? employmentTypeOptions[values.type].description : '';
  const name =
    values.type === EmploymentType.SELF_EMPLOYED
      ? values.tradingName ?? ''
      : values.type === EmploymentType.FULL_TIME ||
        values.type === EmploymentType.PART_TIME ||
        values.type === EmploymentType.CASUAL
      ? values.employerName ?? ''
      : '';
  return newItem.value ? 'New source of income' : name ? `${desc} - ${name}` : desc;
});

const editItem = () => {
  showEntry.value = true;
};

const checkEmployeeTypeIsValid = handleSubmit(
  () => true,
  () => false,
);
const update = async () => {
  if (!(await checkEmployeeTypeIsValid())) {
    return false;
  }

  let saved = false;

  if (values.type === EmploymentType.RETIRED || values.type === EmploymentType.UNEMPLOYED) {
    emit('update:modelValue', { id: props.modelValue.id, type: values.type });
    saved = true;
  } else if (values.type === EmploymentType.SELF_EMPLOYED) {
    saved = (await selfEmployedRef.value?.update()) ?? false;
    if (saved) {
      emit('update:modelValue', employmentInput.value);
    }
  } else {
    saved = (await salaryRef.value?.update()) ?? false;
    if (saved) {
      emit('update:modelValue', employmentInput.value);
    }
  }
  if (saved) {
    showEntry.value = false;
    newItem.value = false;
  }
  return saved;
};

const cancelChanges = () => {
  if (newItem.value) {
    if (values.type) {
      openModal();
    } else {
      removeItem();
    }
  } else {
    salaryRef.value?.cancel();
    selfEmployedRef.value?.cancel();
    resetForm();
    showEntry.value = false;
  }
};

const showModal = ref(false);
const openModal = () => {
  showModal.value = true;
};
const removeItem = () => {
  showModal.value = false;
  emit('remove', props.modelValue.elementId || props.modelValue.id || '');
};

defineExpose({ update, cancel: cancelChanges });

watch(
  () => props.modelValue,
  (val) => {
    resetForm({ values: val });
    employmentInput.value = props.modelValue;
  },
  { immediate: true, deep: true },
);

watch(
  () => values.type,
  (val) => {
    if (val === EmploymentType.FULL_TIME || val === EmploymentType.PART_TIME || val === EmploymentType.CASUAL) {
      employmentInput.value.type = val;
    }
    if (val) {
      emit('employmentType', val);
    }
  },
);
</script>

<template>
  <div>
    <BxExpander v-model="showEntry" separator>
      <template #header>
        <div class="px-4 py-5 sm:px-6">
          <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              {{ cardTitle }}
            </h3>
            <div v-if="!showEntry" class="flex-shrink-0">
              <BxButton v-if="!newItem" variant="secondary" @click="editItem()">Edit</BxButton>
              <BxButton variant="link" class="ml-4" :disabled="!props.isMultiple" @click="openModal()">
                Remove
              </BxButton>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div class="p-6">
          <BxField v-slot="{ field }" name="type">
            <BxRadio
              v-bind="field"
              :id="props.applicantId + '-job-type'"
              :options="employmentOptions"
              label="Please select your employment status"
              label-type="default"
              block
            />
          </BxField>
          <div class="mt-10">
            <Transition name="fade">
              <SalaryForm
                v-if="
                  values.type === EmploymentType.FULL_TIME ||
                  values.type === EmploymentType.PART_TIME ||
                  values.type === EmploymentType.CASUAL
                "
                ref="salaryRef"
                v-model="employmentInput"
                :applicant-id="props.applicantId"
                :type="values.type"
              />
              <SelfEmployedForm
                v-else-if="values.type === EmploymentType.SELF_EMPLOYED"
                ref="selfEmployedRef"
                v-model="employmentInput"
                :applicant-id="props.applicantId"
              />
              <div v-else></div>
            </Transition>
          </div>
          <div class="mt-10 flex-shrink-0">
            <BxButton :id="props.applicantId + '-income-save'" variant="secondary" @click="update()">Save</BxButton>
            <BxButton variant="link" class="ml-4" @click="cancelChanges()">Cancel</BxButton>
          </div>
        </div>
      </template>
    </BxExpander>
    <BxModal
      :open="showModal"
      :icon="TrashIcon"
      title="Remove employment"
      confirm-label="Remove"
      cancel-label="Cancel"
      @close="showModal = false"
      @confirm="removeItem()"
    >
      Do you want to remove
      <span class="text-bridgit-green">{{ cardTitle }}</span
      >?
    </BxModal>
  </div>
</template>
