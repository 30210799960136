import type { operations } from '@/types/schemas/api.main';
import { PATCH, setParams } from '../../_http';
type controllerV1 = operations['CustomerApplicationsSubmitApplicantControllerV1_patch'];
type controllerV2 = operations['CustomerApplicationsSubmitApplicantControllerV2_patch'];

export type CustomerSubmitApplicantQueryV1 = controllerV1['parameters']['query'];
export type CustomerSubmitApplicantBodyV1 = controllerV1['requestBody']['content']['application/json'];
export type CustomerSubmitApplicantResponseV1 = controllerV1['responses']['200']['content']['application/json'];
export type CustomerSubmitApplicantQueryV2 = controllerV2['parameters']['query'];
export type CustomerSubmitApplicantBodyV2 = controllerV2['requestBody']['content']['application/json'];
export type CustomerSubmitApplicantResponseV2 = controllerV2['responses']['200']['content']['application/json'];

export const submitApplicantV1 = PATCH(
  '/v1/users/applications/submit-applicant',
  setParams<CustomerSubmitApplicantQueryV1>,
)<CustomerSubmitApplicantBodyV1, CustomerSubmitApplicantResponseV1>;

export const submitApplicantV2 = PATCH(
  '/v2/users/applications/submit-applicant',
  setParams<CustomerSubmitApplicantQueryV2>,
)<CustomerSubmitApplicantBodyV2, CustomerSubmitApplicantResponseV2>;

export const submitApplicant = async (
  submitApplicantQuery: CustomerSubmitApplicantQueryV1 | CustomerSubmitApplicantQueryV2,
  submitApplicantBody: CustomerSubmitApplicantBodyV1 | CustomerSubmitApplicantBodyV2,
  structuralVersionNumber?: number,
): Promise<CustomerSubmitApplicantResponseV1 | CustomerSubmitApplicantResponseV2> => {
  structuralVersionNumber = structuralVersionNumber || parseInt(import.meta.env.VITE_STRUCTURAL_VERSION_NUMBER || '0');
  if (structuralVersionNumber === 1) {
    const { data } = await submitApplicantV1(submitApplicantQuery, submitApplicantBody);
    return data;
  }
  const { data } = await submitApplicantV2(submitApplicantQuery, submitApplicantBody);
  return data;
};
