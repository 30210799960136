import { convertExpensePeriod } from '@/helpers/convertExpensePeriod';
import { convertPeriod } from '@/helpers/convertPeriod';
import type { ExpensePeriodicValue, Financials, PeriodicValue } from '@/types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fundsExpenses = (data: Financials, applicantIndex: number): any[] => {
  let expenses = data.expenses;
  const apiExpenses = [];
  if (expenses) {
    if (expenses.fundsExpenses) {
      expenses = { ...expenses, ...expenses.fundsExpenses };
      for (const [propertyKey, propertyValue] of Object.entries(expenses)) {
        if (['numberOfDependants', 'predictedExpenseChange', 'fundsExpenses'].includes(propertyKey)) continue;

        if (propertyKey)
          apiExpenses.push({
            applicantIndex,
            expenseTypeId: (propertyValue as ExpensePeriodicValue).expenseTypeId,
            expenseAmount: convertExpensePeriod(propertyValue, 'month').amount,
          });
      }
    } else {
      for (const [propertyKey, propertyValue] of Object.entries(expenses)) {
        if (['numberOfDependants', 'predictedExpenseChange'].includes(propertyKey)) continue;

        if (propertyKey)
          apiExpenses.push({
            applicantIndex,
            expenseTypeId: (propertyValue as ExpensePeriodicValue).expenseTypeId,
            expenseAmount: convertPeriod(propertyValue as PeriodicValue, 'month').amount,
          });
      }
    }
  }
  return apiExpenses;
};
