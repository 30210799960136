import {
  RequestIncomeExpenseProcessStep,
  RequestIncomeExpenseProcessStepNames,
  RequestIncomeExpenseProcessSteps,
  StepStatus,
  type ActionType,
  type AppUser,
  type LoanApplication,
  type RequestIncomeExpenseProcessStatus,
  type RequestIncomeExpenseProcessStepEnum,
} from '@/types';
import { assign, createMachine } from 'xstate';
import { createLoanApp } from './builder';

export interface RequestIncomeExpenseMachineContext {
  loanApp: LoanApplication;
  from?: string;
  authorisedUser: Record<string, string>;
  // targetApplicant: ApplicantId;
  status: RequestIncomeExpenseProcessStatus;
  current: RequestIncomeExpenseProcessStepEnum;
}

export const initRequestIncomeExpenseMachine = (params?: {
  actionType: ActionType;
  userType: AppUser;
  brokerId?: string;
}): RequestIncomeExpenseMachineContext => ({
  loanApp: createLoanApp(params),
  // targetApplicant: '',
  authorisedUser: {},
  status: Object.fromEntries(
    RequestIncomeExpenseProcessStepNames.map((step) => [step]),
  ) as RequestIncomeExpenseProcessStatus,
  current: RequestIncomeExpenseProcessStep.Summary,
});

export type RequestIncomeExpenseMachineEvent =
  | { type: 'NEXT'; loanApp: LoanApplication }
  | { type: 'UPDATE'; loanApp: LoanApplication }
  | { type: 'BACK' }
  | { type: 'READY' }
  | { type: 'GOTO'; step: RequestIncomeExpenseProcessStepEnum }
  | { type: 'RELOAD'; state: RequestIncomeExpenseMachineContext }
  | { type: 'RESTART' };

export const loanRequestMachine = createMachine<RequestIncomeExpenseMachineContext, RequestIncomeExpenseMachineEvent>(
  {
    id: 'loanApplicationRequest',
    predictableActionArguments: true,
    schema: {
      context: {} as RequestIncomeExpenseMachineContext,
    },
    context: initRequestIncomeExpenseMachine(),
    initial: 'start',
    states: {
      start: {
        on: {
          NEXT: {
            target: RequestIncomeExpenseProcessStep.Summary,
          },
        },
      },
      [RequestIncomeExpenseProcessStep.Summary]: {
        on: {
          NEXT: [
            {
              target: RequestIncomeExpenseProcessStep.Income,
              actions: 'saveData',
            },
          ],
        },
      },
      [RequestIncomeExpenseProcessStep.Income]: {
        on: {
          NEXT: {
            target: RequestIncomeExpenseProcessStep.Expenses,
            actions: 'saveData',
          },
        },
      },
      [RequestIncomeExpenseProcessStep.Expenses]: {
        on: {
          NEXT: {
            target: RequestIncomeExpenseProcessStep.Assets,
            actions: 'saveData',
          },
        },
      },
      [RequestIncomeExpenseProcessStep.Assets]: {
        on: {
          NEXT: [
            {
              target: RequestIncomeExpenseProcessStep.SubmitApplication,
              actions: 'saveData',
            },
          ],
        },
      },
      [RequestIncomeExpenseProcessStep.SubmitApplication]: {
        on: {
          NEXT: {
            target: RequestIncomeExpenseProcessStep.Finished,
            actions: 'saveData',
          },
        },
      },
      [RequestIncomeExpenseProcessStep.Finished]: {
        on: {
          NEXT: {
            target: RequestIncomeExpenseProcessStep.Summary,
            actions: 'resetLoanApplication',
          },
        },
      },
      prevStage: {
        always: [...RequestIncomeExpenseProcessStepNames]
          .sort((a, b) => RequestIncomeExpenseProcessSteps[b].order - RequestIncomeExpenseProcessSteps[a].order)
          .map((step) => ({
            target: step,
            cond: (ctx) =>
              RequestIncomeExpenseProcessSteps[ctx.current]?.order > RequestIncomeExpenseProcessSteps[step].order &&
              ctx.status[step] === StepStatus.Completed,
          })),
      },
      gotoReady: {
        always: RequestIncomeExpenseProcessStepNames.map((step) => ({
          target: step,
          cond: (ctx) => ctx.status[step] === StepStatus.Ready,
        })),
      },
    },
    on: {
      BACK: {
        target: 'prevStage',
        cond: (ctx) => ctx.current !== RequestIncomeExpenseProcessStep.Summary,
      },
      READY: {
        target: 'gotoReady',
      },
      UPDATE: {
        actions: 'saveData',
      },
      GOTO: RequestIncomeExpenseProcessStepNames.map((step) => ({
        target: step,
        cond: (ctx, evt) => evt.step === step,
      })),
      RELOAD: {
        target: 'gotoReady',
        actions: 'updateContext',
      },
      RESTART: {
        target: RequestIncomeExpenseProcessStep.Summary,
        actions: 'resetLoanApplication',
      },
    },
  },
  {
    actions: {
      saveData: assign((context, event) => {
        return event.type === 'NEXT' || event.type === 'UPDATE'
          ? {
              loanApp: event.loanApp,
              status: Object.fromEntries(
                Object.entries(context.status).map(([step, status]) => [
                  step,
                  step === context.current
                    ? StepStatus.Completed
                    : RequestIncomeExpenseProcessSteps[step as RequestIncomeExpenseProcessStepEnum].order <
                      RequestIncomeExpenseProcessSteps[context.current].order
                    ? status
                    : StepStatus.Incomplete,
                ]),
              ) as RequestIncomeExpenseProcessStatus,
            }
          : {};
      }),
      updateContext: assign((context, event) => (event.type === 'RELOAD' ? event.state : {})),
      resetLoanApplication: assign(() =>
        initRequestIncomeExpenseMachine({ userType: 'Primary', actionType: 'createNew' }),
      ),
    },
    guards: {
      showIncomeExpenses: (ctx) => {
        return ctx.loanApp.endDebt > 0 || (ctx.from ? ctx.from === 'crm' : false);
      },
      hasNoBroker: (ctx) => ctx.loanApp.userType !== 'Broker',
    },
  },
);
