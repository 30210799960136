import { type Ref, unref } from 'vue';
import dayjs from '@/helpers/dayjs';

const validInputRegex = /^[\d/]*$/;
const dateInputRegex = /^(?<d>\d{1,2})(?:\/(?:(?<m>\d{1,2})(?:\/(?<y>\d{1,4})?)?)?)?$/;

interface NumberInputOptions {
  format?: 'D/M/YYYY' | 'DD/MM/YYYY' | Ref<'D/M/YYYY' | 'DD/MM/YYYY'>;
  twoDigitYearMax?: number | Ref<number>;
  formatOnInput?: boolean | Ref<boolean>;
}

export function useDateInput(options: NumberInputOptions) {
  const _options = {
    format: options?.format ?? 'DD/MM/YYYY',
    twoDigitYearMax: options?.twoDigitYearMax ?? 49,
    formatOnInput: options?.formatOnInput ?? true,
  };

  function formatValue(value: Date | undefined) {
    return value === undefined || isNaN(value.valueOf()) ? '' : dayjs(value).format(unref(_options.format));
  }

  function parseValue(value: string): Date {
    if (!value) {
      return new Date(NaN);
    } else {
      return dayjs.parseDate(value, { twoDigitYearMax: unref(_options.twoDigitYearMax) }).toDate();
    }
  }

  function handleBeforeInput(event: Event) {
    const inputEvent = event as InputEvent;
    const el = event.target as HTMLInputElement;

    // use default handling for enter key to allow form submission
    if (inputEvent.inputType === 'insertLineBreak') {
      return;
    }

    // cancel default behaviour
    event.preventDefault();

    let insertText = '';
    switch (inputEvent.inputType) {
      case 'insertText':
      case 'insertFromPaste': {
        const inputData = inputEvent.data;
        if (!inputData || !validInputRegex.test(inputData)) {
          // invalid input
          return;
        }
        insertText = inputData;
        break;
      }

      case 'deleteContent':
      case 'deleteContentBackward':
      case 'deleteContentForward':
        // continue
        break;

      case 'historyUndo':
      case 'historyRedo':
        // note: undo/redo history not set for programmatic value updates
        return;

      default:
        console.warn(`Unhandled inputType: ${inputEvent.inputType}`, inputEvent);
        return;
    }

    const origText = el.value;
    let newText: string;
    let pos: number;
    if (el.selectionStart !== null && el.selectionEnd !== null && origText) {
      const selStart = Math.min(el.selectionStart, el.selectionEnd);
      const selEnd = Math.max(el.selectionStart, el.selectionEnd);
      if (selEnd > selStart || insertText) {
        newText = origText.substring(0, selStart) + insertText + origText.substring(selEnd);
        pos = selStart + insertText.length;
      } else if (inputEvent.inputType === 'deleteContentBackward' && selStart > 0) {
        newText = origText.substring(0, selStart - 1) + origText.substring(selStart);
        pos = selStart - 1;
      } else if (inputEvent.inputType === 'deleteContentForward' && selStart < origText.length) {
        newText = origText.substring(0, selStart) + origText.substring(selStart + 1);
        pos = selStart;
      } else {
        newText = origText;
        pos = selStart;
      }
    } else {
      newText = insertText;
      pos = insertText.length;
    }

    if (newText === origText) {
      // no change
      return;
    }

    // auto-format only if cursor is at text end
    if (
      unref(_options.formatOnInput) &&
      newText &&
      pos === newText.length &&
      (inputEvent.inputType === 'insertText' || inputEvent.inputType === 'insertFromPaste')
    ) {
      const match = newText.match(dateInputRegex);
      if (!match?.groups) {
        // cancel invalid input if previous value was valid
        if (!origText || dateInputRegex.test(origText)) {
          return;
        }
      } else if (match.groups['y']) {
        // add century to 2 digit year
        if (match.groups['y'].length == 2 && match.groups['y'] !== '19' && match.groups['y'] !== '20') {
          newText = `${newText.substring(0, newText.length - 2)}${
            +match.groups['y'] <= unref(_options.twoDigitYearMax) ? '20' : '19'
          }${match.groups['y']}`;
          pos = pos + 2;
        }
      } else {
        let group: string | undefined = undefined;
        // add separator for completed month or day
        if (match.groups['m']) {
          group = match.groups['m'];
          if ((group.length === 2 || +group > 1) && !newText.endsWith('/')) {
            newText += '/';
            pos++;
          }
        } else if (match.groups['d']) {
          group = match.groups['d'];
          if ((group.length === 2 || +group > 3) && !newText.endsWith('/')) {
            newText += '/';
            pos++;
          }
        }
        // force 2 digit month or day
        if (newText.endsWith('/') && unref(_options.format) === 'DD/MM/YYYY' && group?.length === 1) {
          newText = `${newText.substring(0, newText.length - 2)}0${newText.substring(newText.length - 2)}`;
          pos++;
        }
      }
    }

    //update value and selection
    el.value = newText;
    el.selectionStart = pos;
    el.selectionEnd = pos;

    // dispatch input event to trigger Vue update
    // note: element does not fire input event when value is set by script
    el.dispatchEvent(
      new Event('input', {
        bubbles: true,
      }),
    );
  }

  return {
    formatValue,
    parseValue,
    handleBeforeInput,
  };
}
