<script setup lang="ts">
import {
  ApplicantType,
  Gender,
  RelationshipStatus,
  RelationshipType,
  genderOptions,
  relationshipStatusOptions,
  relationshipTypeOptions,
} from '@/helpers/const';
import { capitalise } from '@/helpers/format';
import { noWhiteSpaceKeys } from '@/helpers/noWhiteSpaceKeys';
import { useBrokerLoanAppProcess } from '@/helpers/useBrokerLoanAppProcess';
import type { Applicant, ApplicantEntry, ApplicantTypeEnum } from '@/types/Applicant';
import { applicantSchema } from '@/validation';
import { useForm } from 'vee-validate';
import { computed, watch } from 'vue';

const { loanApp } = useBrokerLoanAppProcess();
const props = withDefaults(
  defineProps<{
    type: ApplicantTypeEnum;
    modelValue: ApplicantEntry;
    primary?: ApplicantEntry;
  }>(),
  {
    primary: undefined,
  },
);
const emit = defineEmits<{
  (e: 'update:modelValue', value: ApplicantEntry): void;
}>();
const brokerId = loanApp.value.brokerId ?? '';
const schema = computed(() => applicantSchema(props.primary));

const { meta, submitCount, values, handleSubmit, resetForm, setFieldValue } = useForm<Applicant>({
  validationSchema: schema,
  validateOnMount: false,
});

watch(
  () => props.modelValue,
  (applicant) => {
    const values = { ...applicant, type: props.type } as Applicant;
    resetForm({ values });
  },
  { immediate: true },
);
watch(
  () => values.relationshipToPrimary,
  (val) => {
    if (val === RelationshipType.MARRIED_OR_DEFACTO) {
      setFieldValue('relationshipStatus', RelationshipStatus.MARRIED_OR_DEFACTO);
    }
  },
);

const update = handleSubmit(
  (values) => {
    emit('update:modelValue', values);
    return true;
  },
  () => false,
);
const cancel = () => resetForm();

const updatePreferredName = () => {
  values.firstName = capitalise(values.firstName);
  if (values.firstName && !values.preferredName) {
    setFieldValue('preferredName', values.firstName);
  }
};

const onlyValidPhoneKeys = (event: KeyboardEvent) => {
  if ((event.key < '0' || event.key > '9') && event.key !== '+') {
    event.preventDefault();
  }
};

defineExpose({ update, cancel });
</script>

<template>
  <form>
    <div>
      <div class="form-group">
        <BxField v-slot="{ field }" name="firstName">
          <BxInput
            :id="values.id + '-first-name'"
            class="form-control"
            v-bind="field"
            label="First name"
            label-type="floating"
            @blur="updatePreferredName()"
          />
        </BxField>
        <BxField v-slot="{ field }" name="middleName">
          <BxInput
            :id="values.id + '-middle-name'"
            class="form-control"
            v-bind="field"
            label="Middle name"
            label-type="floating"
            @blur="values.middleName = capitalise(values.middleName)"
          />
        </BxField>
        <BxField v-slot="{ field }" name="lastName">
          <BxInput
            :id="values.id + '-last-name'"
            class="form-control"
            v-bind="field"
            label="Last name"
            label-type="floating"
            @blur="values.lastName = capitalise(values.lastName)"
          />
        </BxField>
        <BxField v-slot="{ field }" name="preferredName">
          <BxInput
            :id="values.id + '-preferred-name'"
            class="form-control"
            v-bind="field"
            label="Preferred name"
            label-type="floating"
          />
        </BxField>
      </div>
      <div v-if="type === ApplicantType.SECONDARY" class="mb-10">
        <BxField v-slot="{ field }" name="relationshipToPrimary">
          <BxRadio
            :id="values.id + '-relationship-to-primary'"
            class="form-control !mt-10"
            v-bind="field"
            :options="relationshipTypeOptions"
            :label="`What is your relationship with ${props.primary?.preferredName}?`"
            label-type="default"
          />
        </BxField>
        <BxField
          v-if="values.relationshipToPrimary === RelationshipType.OTHER"
          v-slot="{ field }"
          name="relationshipOtherInfo"
        >
          <BxInput v-bind="field" label="Other relationship info" label-type="floating" class="mt-3" />
        </BxField>
      </div>
      <BxField v-slot="{ field }" name="relationshipStatus">
        <BxRadio
          :id="values.id + '-relationship-status'"
          class="form-control !mt-10"
          v-bind="field"
          :options="relationshipStatusOptions"
          label="What is your relationship status?"
          label-type="default"
          :disabled="values.relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO"
        />
      </BxField>
      <div class="form-control !mt-10">
        <BxField v-slot="{ field }" name="gender">
          <BxRadio
            :id="values.id + '-gender'"
            v-bind="field"
            :options="genderOptions"
            label="Gender"
            label-type="default"
            block
          />
        </BxField>
        <Transition name="fade">
          <div v-show="values.gender === Gender.OTHER">
            <BxField v-slot="{ field }" name="genderOther">
              <BxInput class="ml-7 mt-3" v-bind="field" label="" placeholder="Describe your gender identity" />
            </BxField>
          </div>
        </Transition>
      </div>
      <BxField v-slot="{ field }" name="birthdate">
        <BxDateInput
          :id="values.id + '-date-of-birth'"
          class="form-control !mt-10"
          v-bind="field"
          label="Your date of birth"
          label-type="floating"
          placeholder="DD/MM/YYYY"
        />
      </BxField>
      <BxField v-slot="{ field }" name="address">
        <BxAddressInput
          :id="values.id + '-address'"
          class="form-control"
          v-bind="field"
          label="Residential Address"
          label-type="floating"
        />
      </BxField>
      <div v-if="brokerId" class="applicant-note">
        <strong>Contact details</strong>
        <p>
          These details must belong to the applicant. <br />
          You will not be able to submit using your own details.
        </p>
      </div>
      <BxField v-slot="{ field }" name="mobile">
        <BxInput
          :id="values.id + '-mobile-phone-number'"
          class="form-control"
          inputmode="tel"
          v-bind="field"
          label="Mobile number"
          label-type="floating"
          minlength="10"
          :maxlength="values.mobile?.startsWith('+') ? 15 : 10"
          @keypress="onlyValidPhoneKeys"
        />
      </BxField>
      <BxField v-slot="{ field }" name="email">
        <BxInput
          :id="values.id + '-email'"
          class="form-control"
          inputmode="email"
          v-bind="field"
          label="Email address"
          label-type="floating"
          @keypress="noWhiteSpaceKeys"
        />
      </BxField>
      <BxField v-slot="{ field }" name="confirmEmail">
        <BxInput
          :id="values.id + '-confirm-email'"
          class="form-control"
          inputmode="email"
          v-bind="field"
          label="Confirm email address"
          label-type="floating"
          @keypress="noWhiteSpaceKeys"
        />
      </BxField>
    </div>
    <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
      Please review the error messages above.
    </BxErrorPanel>
  </form>
</template>

<style lang="scss" scoped>
.applicant-note {
  background: #f5f9fc;
  width: fit-content;
  padding: 0.6rem;
  border-radius: 0.3rem;
  margin: 1rem 0;

  strong,
  p {
    font-size: 1rem;
    line-height: 1.3rem;
  }

  strong {
    color: #0c1744;
  }

  p {
    font-weight: 500;
    color: #02864e;
    font-size: 0.8rem;
    margin-top: 0.2rem;
  }
}
</style>
