import type { Address } from '@/types';
import type { AddressDto } from '@/types/api';

export const address = (data: Partial<AddressDto>): Address => ({
  addressLine: data.line1 || '',
  addressLine2: data.line2 || undefined,
  locality: data.city ?? '',
  state: data.state?.toUpperCase() ?? '',
  postcode: data.postCode?.toString() ?? '',
  countryCode: 'au',
});
