import type {
  ActionType,
  Address,
  Applicant,
  ApplicantEntry,
  ApplicantExpense,
  ApplicantIncome,
  ApplicantTypeEnum,
  AppUser,
  AssetAccount,
  Financials,
  IncomingProperty,
  LoanApplication,
  OutgoingProperty,
  PeriodicValue,
  PropertyAsset,
} from '@/types';
import { ApplicantType, AssetType, Gender, RelationshipStatus } from './const';
import type { UtmMetricDto } from '@/api/backend/users/applications';
import type { AdditionalFundsRequiredDto } from '@/api/backend/public';

let applicantIndex = 0;

/**
 * Checks `allowNoContractOfSale` from the URL
 * @returns {boolean}
 */
export const allowNoContractOfSale = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('allowNoContractOfSale') === 'true';
};

export const createApplicant = (type: ApplicantTypeEnum = ApplicantType.SECONDARY): ApplicantEntry => {
  if (type === ApplicantType.PRIMARY) {
    applicantIndex = 0;
  }
  return {
    id: `applicant-${applicantIndex++}`,
    type,
  };
};

export const createFinancials = (): Financials => ({
  income: null,
  expenses: null,
  assets: [],
  liabilities: [],
});

export function createLoanApp(params?: {
  authorisedUser?: Record<string, string>;
  actionType?: ActionType;
  userType?: AppUser;
  brokerId?: string;
}): LoanApplication {
  const newApplicant = createApplicant(ApplicantType.PRIMARY);
  return {
    additionalFundsRequired: {} as AdditionalFundsRequiredDto,
    structuralVersionNumber: parseInt(import.meta.env.VITE_STRUCTURAL_VERSION_NUMBER || '0'),
    cancelPriorApplications: false,
    applicationId: 'application',
    actionType: params?.actionType ?? 'createNew',
    userType: params?.userType ?? null,
    incomingProperties: [],
    outgoingProperties: [],
    applicants: [],
    propertiesOwnership: [],
    financials: { [newApplicant.id]: createFinancials() },
    endDebt: 0,
    verificationCode: '',
    brokerId: params?.brokerId ?? '',
    summaryNotes: '',
    utmMetrics: {} as UtmMetricDto,
    allowNoContractOfSale: allowNoContractOfSale(),
  };
}

export const defaultAdditionalFundRequired = () => ({
  description: '',
  fundsRequiredDate: '',
  fundsRequiredAmount: 0,
  additionalFundsPurposeId: '',
});

export const defaultLoanDetails = () => ({
  savingsAmount: 0,
  giftedAmount: 0,
  reasonDescription: '',
  brokerFeePercentage: 0,
  borrowAmount: 0,
  loanPeriod: undefined,
  loanDate: '',
  calcLoanAmount: 0,
});

export const defaultPeriodicValue = (): PeriodicValue => ({
  amount: 0,
  frequency: 'month',
});

export const defaultAddress = (): Address => ({} as Address);

export const defaultIncomingProperty = (): IncomingProperty => ({
  id: '',
  settlementDate: '',
  propertyType: 'residential',
  address: defaultAddress(),
  propertyPurpose: 'owner_occupied',
  contractForSale: false,
  purchaseAmount: 0,
  depositAmount: 0,
  depositType: 'cash_payment',
  stampDutyStatus: 'already_paid',
  stampDuty: 0,
});

export const defaultOutgoingProperty = (): OutgoingProperty => ({
  id: '',
  propertyType: 'residential',
  address: defaultAddress(),
  propertyPurpose: 'owner_occupied',
  outstandingLoanAmount: 0,
  saleStatus: 'no',
  settlementDate: '',
  saleAmount: 0,
  depositAmount: 0,
  depositInTrust: false,
});

export const defaultApplicant = (): Applicant => ({
  id: '',
  type: ApplicantType.PRIMARY,
  firstName: '',
  middleName: '',
  lastName: '',
  preferredName: '',
  gender: Gender.PREFER_NOT_TO_SAY,
  genderOther: '',
  birthdate: '',
  address: defaultAddress(),
  mobile: '',
  email: '',
  confirmEmail: '',
  numberOfDependants: 0,
  relationshipStatus: RelationshipStatus.SINGLE,
  relationshipToPrimary: undefined,
  relationshipOtherInfo: '',
});

export const defaultIncome = (): ApplicantIncome => ({
  applicantId: '',
  employment: [],
  shortTermChange: false,
  shortTermEmploymentChange: '',
  centrelinkPayments: defaultPeriodicValue(),
  childSupportMaintenance: defaultPeriodicValue(),
  familyAllowance: defaultPeriodicValue(),
  familyTaxBenefit: defaultPeriodicValue(),
  governmentPension: defaultPeriodicValue(),
  dividendsInvestments: defaultPeriodicValue(),
  superannuation: defaultPeriodicValue(),
  other: defaultPeriodicValue(),
  expectIncomeChange: false,
  effectOnIncomeChange: '',
  incomeExpectation: {},
  employmentDetail: {},
});

export const defaultExpenses = (): ApplicantExpense => ({
  numberOfDependants: 0,
  groceries: defaultPeriodicValue(),
  clothing: defaultPeriodicValue(),
  education: defaultPeriodicValue(),
  insurancePremium: defaultPeriodicValue(),
  childcare: defaultPeriodicValue(),
  medical: defaultPeriodicValue(),
  recreation: defaultPeriodicValue(),
  communication: defaultPeriodicValue(),
  homePropertyCost: defaultPeriodicValue(),
  investmentPropertyCost: defaultPeriodicValue(),
  transport: defaultPeriodicValue(),
  other: defaultPeriodicValue(),
  predictedExpenseChange: defaultPeriodicValue(),
});

export const defaultPropertyAsset = (): PropertyAsset => ({
  id: '',
  type: AssetType.PROPERTY,
  address: defaultAddress(),
  propertyValue: 0,
  propertyPurpose: 'investment_property',
  rentalIncome: defaultPeriodicValue(),
  amountOwing: 0,
  assetNumber: 0,
});

export const defaultAssetAccount = (): AssetAccount => ({
  id: '',
  type: AssetType.SAVINGS,
  institution: '',
  value: 0,
  assetNumber: 0,
});

export const defaultApplicantIncome = (): ApplicantIncome => ({
  applicantId: '',
  employment: [],
  shortTermChange: false,
  shortTermEmploymentChange: '',
  centrelinkPayments: defaultPeriodicValue(),
  childSupportMaintenance: defaultPeriodicValue(),
  familyAllowance: defaultPeriodicValue(),
  familyTaxBenefit: defaultPeriodicValue(),
  governmentPension: defaultPeriodicValue(),
  dividendsInvestments: defaultPeriodicValue(),
  superannuation: defaultPeriodicValue(),
  other: defaultPeriodicValue(),
  expectIncomeChange: false,
  effectOnIncomeChange: '',
  incomeExpectation: {},
  employmentDetail: {},
});
