<template>
  <svg
    width="132"
    height="154"
    viewBox="0 0 132 154"
    fill="none"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M90.6328 122.606L99.6136 131.657L114.26 116.93"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M64.5343 112.485H9.54434C7.41292 112.485 5.36879 111.633 3.86165 110.118C2.35451 108.603 1.50781 106.547 1.50781 104.404V9.61596C1.50781 7.4728 2.35451 5.41753 3.86165 3.90209C5.36879 2.38664 7.41292 1.53516 9.54434 1.53516H82.3151C84.4465 1.53516 86.4907 2.38664 87.9978 3.90209C89.505 5.41753 90.3517 7.4728 90.3517 9.61596V83.8184"
      stroke="#020038"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3242 20.6289H49.9881"
      stroke="currentColor"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3242 33.375H73.5553"
      stroke="#020038"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.1367 53.6758H72.3504"
      stroke="#020038"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.1367 69.7188H72.3504"
      stroke="#020038"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M34.1367 85.7773H72.3504"
      stroke="#020038"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3242 53.6758H23.0055"
      stroke="#020038"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3242 69.7188H23.0055"
      stroke="#020038"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3242 85.7773H23.0055"
      stroke="#020038"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M100.375 21.7773H103.007C105.138 21.7773 107.183 22.6288 108.69 24.1443C110.197 25.6597 111.043 27.715 111.043 29.8582V84.7069"
      stroke="#020038"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.6281 132.747H30.2162C28.0848 132.747 26.0407 131.896 24.5335 130.38C23.0264 128.865 22.1797 126.81 22.1797 124.666V122.484"
      stroke="#020038"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M102.446 152.486C117.936 152.486 130.493 139.86 130.493 124.284C130.493 108.709 117.936 96.082 102.446 96.082C86.9557 96.082 74.3984 108.709 74.3984 124.284C74.3984 139.86 86.9557 152.486 102.446 152.486Z"
      stroke="#54B7F9"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M10.0234 141V149.054" stroke="#020038" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M118.023 7V15.0539" stroke="#020038" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M126.023 64V72.0539" stroke="#020038" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M14.0215 145.016H6" stroke="#020038" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M122.022 11.0156H114" stroke="#020038" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M130.022 68.0156H122" stroke="#020038" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
