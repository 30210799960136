import type { operations } from '@/types/schemas/api.main';
import { PATCH, setParams } from '../../_http';
type controllerV1 = operations['CustomerApplicationsSubmitApplicantsControllerV1_patch'];
type controllerV2 = operations['CustomerApplicationsSubmitApplicantsControllerV2_patch'];

export type CustomerSubmitApplicantsQueryV1 = controllerV1['parameters']['query'];
export type CustomerSubmitApplicantsBodyV1 = controllerV1['requestBody']['content']['application/json'];
export type CustomerSubmitApplicantsResponseV1 = controllerV1['responses']['200']['content']['application/json'];
export type CustomerSubmitApplicantsQueryV2 = controllerV2['parameters']['query'];
export type CustomerSubmitApplicantsBodyV2 = controllerV2['requestBody']['content']['application/json'];
export type CustomerSubmitApplicantsResponseV2 = controllerV2['responses']['200']['content']['application/json'];

export const submitApplicantsV1 = PATCH(
  '/v1/users/applications/submit-applicants',
  setParams<CustomerSubmitApplicantsQueryV1>,
)<CustomerSubmitApplicantsBodyV1, CustomerSubmitApplicantsResponseV1>;

export const submitApplicantsV2 = PATCH(
  '/v2/users/applications/submit-applicants',
  setParams<CustomerSubmitApplicantsQueryV2>,
)<CustomerSubmitApplicantsBodyV2, CustomerSubmitApplicantsResponseV2>;

export const submitApplicants = async (
  submitApplicantsQuery: CustomerSubmitApplicantsQueryV1 | CustomerSubmitApplicantsQueryV2,
  submitApplicantsBody: CustomerSubmitApplicantsBodyV1 | CustomerSubmitApplicantsBodyV2,
  structuralVersionNumber?: number,
): Promise<CustomerSubmitApplicantsResponseV1 | CustomerSubmitApplicantsResponseV2> => {
  structuralVersionNumber = structuralVersionNumber || parseInt(import.meta.env.VITE_STRUCTURAL_VERSION_NUMBER || '0');
  if (structuralVersionNumber === 1) {
    const { data } = await submitApplicantsV1(submitApplicantsQuery, submitApplicantsBody);
    return data;
  }
  const { data } = await submitApplicantsV2(submitApplicantsQuery, submitApplicantsBody);
  return data;
};
