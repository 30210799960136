import type {
  ApplicationDto,
  FundsAdditionalIncomeDto as BaseFundsAdditionalIncomeDto,
  FundsSalariedDto as BaseFundsSalariedDto,
  FundsSelfEmployedDto as BaseFundsSelfEmployedDto,
} from '@/api/backend/users/applications';
import { mapFromApi } from '@/mappers';
import { periodic } from '@/mappers/helpers';
import type { ApplicantIncome, SalaryEmployment, SalaryTypeEmum, SelfEmployment, Unemployment } from '@/types';

export type FundsSalariedDto = BaseFundsSalariedDto & {
  bonusesAmount?: number;
  commissionsAmount?: number;
  overtimeAmount?: number;
  salaryAmount?: number;
  salaryPackagedAmount?: number;
};

const mapSalary = (data: FundsSalariedDto): SalaryEmployment => {
  return {
    id: data.id ?? '',
    type: data.jobType as SalaryTypeEmum,
    salary: periodic(data.salaryAmountApplicationValue, data.salaryFrequency),
    commission: periodic(data.commissionsAmountApplicationValue, data.commissionsFrequency),
    bonuses: periodic(data.bonusesAmountApplicationValue, data.bonusesFrequency),
    overtime: periodic(data.overtimeAmountApplicationValue, data.overtimeFrequency),
    salarySacrifice: data.isSalarySacrifice ?? false,
    employedSince: data.employedSince ?? '',
    employerName: data.nameOfEmployer ?? '',
    employerAddress: {
      addressLine: data.employerLine1 ?? '',
      addressLine2: data.employerLine2 ?? '',
      locality: data.employerCity ?? '',
      state: data.employerState?.toUpperCase() ?? '',
      postcode: data.employerPostCode ?? '',
      countryCode: 'au',
    },
  };
};

export type FundsSelfEmployedDto = BaseFundsSelfEmployedDto & {
  salaryAmount?: number;
  salaryAmountShaded?: number;
};

const mapSelfEmployment = (data: FundsSelfEmployedDto): SelfEmployment => {
  return {
    id: data.id ?? '',
    type: 'self_employed',
    salary: periodic(data.salaryAmountApplicationValue, data.salaryFrequency),
    businessAddress: mapFromApi.address(data),
    businessType: data.businessType ?? '',
    tradingName: data.tradingName ?? '',
    occupation: data.occupation ?? '',
    industry: data.industry ?? '',
    gstRegistered: data.isGSTRegistered ?? false,
    abn: data.australianBusinessNumber ?? '',
    soleTrader: data.isSoleTrader ?? false,
    hasTaxReturns: data.hasTaxReturnsLastTwoFinancialYears ?? false,
    employedSince: data.employedSince ?? '',
  };
};

export type FundsAdditionalIncomeDto = BaseFundsAdditionalIncomeDto & {
  centerlinkPayments?: number;
  centerlinkPaymentsShaded?: number;
  childSupportMaintenance?: number;
  childSupportMaintenanceShaded?: number;
  dividends?: number;
  dividendsShaded?: number;
  familyAllowance?: number;
  familyAllowanceShaded?: number;
  familyTaxBenefits?: number;
  familyTaxBenefitsShaded?: number;
  governmentPension?: number;
  governmentPensionShaded?: number;
  other?: number;
  otherShaded?: number;
  superannuation?: number;
  superannuationShaded?: number;
};

const mapAdditionalIncome = (data?: FundsAdditionalIncomeDto) => {
  return {
    id: data?.id ?? '',
    centrelinkPayments: periodic(data?.centerlinkPayments, data?.centerlinkPaymentsFrequency),
    childSupportMaintenance: periodic(data?.childSupportMaintenance, data?.childSupportMaintenanceFrequency),
    familyAllowance: periodic(data?.familyAllowance, data?.familyAllowanceFrequency),
    familyTaxBenefit: periodic(data?.familyTaxBenefits, data?.familyTaxBenefitsFrequency),
    governmentPension: periodic(data?.governmentPension, data?.governmentPensionFrequency),
    dividendsInvestments: periodic(data?.dividends, data?.dividendsFrequency),
    superannuation: periodic(data?.superannuation, data?.superannuationFrequency),
    other: periodic(data?.other, data?.otherFrequency),
  };
};

export const income = (applicantId: string, application: ApplicationDto): ApplicantIncome | null => {
  const sal: SalaryEmployment[] = (application.fundsSalaried ?? [])
    .filter((s: FundsSalariedDto) => s.applicantId === applicantId)
    .map(mapSalary);

  const sal2: SelfEmployment[] = (application.fundsSelfEmployed ?? [])
    .filter((s: FundsSelfEmployedDto) => s.applicantId === applicantId)
    .map(mapSelfEmployment);

  const unemployed: Unemployment[] = [];
  (application.applicants ?? [])
    .filter((applicant) => applicant.id === applicantId)
    .forEach((applicant) => {
      if (applicant.isRetired) {
        unemployed.push({ id: `${applicant.id}-retired`, type: 'retired' });
      }
      if (applicant.isUnemployed) {
        unemployed.push({ id: `${applicant.id}-unemployed`, type: 'unemployed' });
      }
    });

  const details = (application.employmentDetails ?? []).find(
    (employmentDetail) => employmentDetail.applicantId === applicantId,
  );
  const additional = (application.fundsAdditionalIncome ?? []).find(
    (additionalIncome) => additionalIncome.applicantId === applicantId,
  );
  const expect = (application.incomeExpectations ?? []).find((e) => e.applicantId === applicantId);
  const employment = [...sal, ...sal2, ...unemployed];
  return employment.length === 0
    ? null
    : {
        incomeExpectation:
          application.incomeExpectations.find((expectation) => expectation.applicantId === applicantId) || {},
        employmentDetail: application.employmentDetails.find((detail) => detail.applicantId === applicantId) || {},
        applicantId,
        employment: [...sal, ...sal2, ...unemployed],
        ...mapAdditionalIncome(additional),
        shortTermChange: details?.employmentChangeInShortTerm ?? false,
        shortTermEmploymentChange: details?.detailsOnChange ?? '',
        expectIncomeChange: expect?.expectedChangesInNext12Months ?? false,
        effectOnIncomeChange: expect?.financialPosition ?? '',
      };
};
