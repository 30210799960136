<script setup lang="ts">
import { computed } from 'vue';
import { useForm } from 'vee-validate';
import { useAsyncState } from '@vueuse/core';
import type { AdditionalFundsRequiredEntry, LoanDetails, LoanDetailsEntry } from '@/types';
import { loanDetailsSchema } from '@/validation';
import { LoanPeriod, LoanType, PropertyType } from '@/helpers/const';
import dayjs from '@/helpers/dayjs';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import { useCalcApi } from '@/helpers/useCalcApi';
import type { AdditionalFundsRequiredDto } from '@/api/backend/public';

const { state, loanApp, saveAndGoNext, goBack } = useLoanAppProcess();
const DEFAULT_BROKER_FEE = 0.25;

type LoanDetailsEntryWithAdditionalRequiredFundsEntry = LoanDetailsEntry & {
  additionalFundsRequiredEntry: AdditionalFundsRequiredEntry;
};

const {
  meta,
  submitCount,
  values,
  handleSubmit: validateSubmit,
  resetForm,
} = useForm<LoanDetailsEntryWithAdditionalRequiredFundsEntry>({
  validationSchema: loanDetailsSchema,
  validateOnMount: false,
});

resetForm({
  values: {
    ...loanApp.value.loanDetails,
    additionalFundsRequiredEntry: loanApp.value.additionalFundsRequired,
    brokerFeePercentage: loanApp.value.loanDetails?.brokerFeePercentage ?? DEFAULT_BROKER_FEE,
    loanType: loanApp.value.loanType ?? LoanType.BRIDGING,
  },
});

const calc = useCalcApi();

const { state: loanAmount } = useAsyncState(calc.getLoanAmount(loanApp.value), 0);

const isBridgingLoan = computed(() => loanApp.value.loanType === LoanType.BRIDGING);

const handleSubmit = validateSubmit(async (values) => {
  const loanDetails: LoanDetails = {
    savingsAmount: values.savingsAmount ?? 0,
    giftedAmount: values.giftedAmount ?? 0,
    reasonDescription: values.reasonDescription ?? '',
    brokerFeePercentage: values.brokerFeePercentage ?? 0,
    borrowAmount: values.borrowAmount ?? 0,
    loanPeriod: values.loanPeriod,
    loanDate: values.loanDate,
    calcLoanAmount: loanAmount.value,
  };

  const additionalFundsRequired: AdditionalFundsRequiredDto = {
    ...values.additionalFundsRequiredEntry,
  };

  if (isBridgingLoan.value) {
    loanDetails.loanPeriod = undefined;
    loanDetails.loanDate = undefined;
  } else {
    if (loanDetails.loanPeriod !== LoanPeriod.EXACT_DATE) {
      loanDetails.loanDate = dayjs().add(Number(loanDetails.loanPeriod), 'month').format('YYYY-MM-DD');
    }
  }

  const propertyType = loanApp.value.incomingProperties[0]?.propertyType;
  if (propertyType !== PropertyType.RETIREMENT_LAND_OR_COMMUNITY_HOME && propertyType !== PropertyType.VACANT_LAND) {
    additionalFundsRequired.fundsRequiredAmount = 0;
    additionalFundsRequired.description = '';
    loanDetails.reasonDescription = '';
  }

  if (loanApp.value.userType !== 'Broker') {
    loanDetails.brokerFeePercentage = 0;
  }

  loanDetails.calcLoanAmount =
    additionalFundsRequired.fundsRequiredAmount === 0 ? 0 : await calc.getLoanAmount(loanApp.value);

  saveAndGoNext({ additionalFundsRequired, loanDetails });
});
</script>

<template>
  <div>
    <div class="mb-8 pb-2">
      <h2 class="text-2xl font-medium">
        Do you have <BxHighlight>additional funds</BxHighlight> to contribute towards your purchase?
      </h2>
    </div>

    <form @submit.prevent="handleSubmit">
      <div>
        <BxField v-slot="{ field }" name="savingsAmount">
          <BxNumberInput
            v-bind="field"
            id="additional-funds-amount"
            label="Additional funds amount"
            label-type="floating"
            leading-symbol="$"
            :allow-negative="false"
            class="form-control"
            help-text="Leave blank if you have no additional funds."
          />
        </BxField>

        <p class="mt-2 text-xs">
          * Do not include the deposit you are going to pay on your new property or the proceeds from the sale of your
          existing property
        </p>

        <template v-if="values.savingsAmount">
          <h3 class="text-md mb-4 mt-6 font-medium">How much of these funds have been gifted to you?</h3>
          <BxField v-slot="{ field }" name="giftedAmount">
            <BxNumberInput
              v-bind="field"
              id="additional-funds-gifted-amount"
              label="Gifted amount"
              label-type="floating"
              leading-symbol="$"
              :allow-negative="false"
              class="form-control"
            />
          </BxField>
        </template>
      </div>

      <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
        Please review the error messages above.
      </BxErrorPanel>
      <div class="mt-10">
        <BxButton id="loan-details-continue" variant="primary" type="submit">Save and continue</BxButton>
        <BxButton v-if="state.nextEvents.includes('BACK')" variant="link" class="ml-4" @click="goBack()">Back</BxButton>
      </div>
    </form>
  </div>
</template>
