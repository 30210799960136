import type { operations } from '@/types/schemas/api.main';
import { POST } from '../../_http';
type controllerV1 = operations['CustomerApplicationsActiveApplicationsControllerV1_post'];
type controllerV2 = operations['CustomerApplicationsActiveApplicationsControllerV2_post'];

export type CustomerActiveApplicationsBodyV1 = controllerV1['requestBody']['content']['application/json'];
export type CustomerActiveApplicationsResponseV1 = controllerV1['responses']['200']['content']['application/json'];
export type CustomerActiveApplicationsBodyV2 = controllerV2['requestBody']['content']['application/json'];
export type CustomerActiveApplicationsResponseV2 = controllerV2['responses']['200']['content']['application/json'];

export const activeApplicationsV1 = POST('/v1/users/applications/active-applications')<
  CustomerActiveApplicationsBodyV1,
  CustomerActiveApplicationsResponseV1
>;

export const activeApplicationsV2 = POST('/v2/users/applications/active-applications')<
  CustomerActiveApplicationsBodyV2,
  CustomerActiveApplicationsResponseV2
>;

export const activeApplications = async (
  activeApplicationBody: CustomerActiveApplicationsBodyV1 | CustomerActiveApplicationsBodyV2,
  structuralVersionNumber?: number,
): Promise<CustomerActiveApplicationsResponseV1 | CustomerActiveApplicationsResponseV2> => {
  structuralVersionNumber = structuralVersionNumber || parseInt(import.meta.env.VITE_STRUCTURAL_VERSION_NUMBER || '0');
  if (structuralVersionNumber === 1) {
    const { data } = await activeApplicationsV1(activeApplicationBody);
    return data;
  }
  const { data } = await activeApplicationsV2(activeApplicationBody);
  return data;
};
