import { AssetType } from '@/helpers/const';
import { yupAddress, yupNumber, yupObject, yupPeriodicValue, yupString } from '../../yup';

export const assetSchema = yupObject({
  type: yupString().required().label('Asset type'),
  address: yupAddress()
    .when('type', { is: AssetType.PROPERTY, then: (schema) => schema.required() })
    .label('Address'),
  propertyValue: yupNumber()
    .when('type', { is: AssetType.PROPERTY, then: (schema) => schema.required() })
    .label('Property value'),
  rentalIncome: yupPeriodicValue(yupNumber().required())
    .when('type', { is: AssetType.PROPERTY, then: (schema) => schema.required() })
    .label('Rental income'),
  amountOwing: yupNumber()
    .when('type', { is: AssetType.PROPERTY, then: (schema) => schema.required() })
    .label('Amount owing'),
  institution: yupString()
    .when('type', { is: (val: string) => val !== AssetType.PROPERTY, then: (schema) => schema.required() })
    .label('Institution'),
  value: yupNumber()
    .when('type', { is: (val: string) => val !== AssetType.PROPERTY, then: (schema) => schema.required() })
    .label('Asset value'),
});
