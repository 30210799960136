import type { ApplicationDto, FundsExpenseDto } from '@/api/backend/users/applications';
import type { ApplicantExpense, PeriodicValue } from '@/types';

export const expenses = (applicantId: string, application: ApplicationDto): ApplicantExpense | null => {
  const applicant = (application.applicants ?? []).find((applicant) => applicant.id === applicantId);
  const predictedExpenseChange: PeriodicValue = {
    amount: applicant
      ? (applicant.expenseIncreaseMonthlyAmount ?? 0) - (applicant.expenseDecreaseMonthlyAmount ?? 0)
      : 0,
    frequency: 'month',
  };

  const fundsExpenses =
    (application.fundsExpenses ?? []).filter((expense: FundsExpenseDto) => expense.applicantId === applicantId) || [];

  return fundsExpenses.length === 0
    ? null
    : Object.assign(
        {},
        {
          fundsExpenses,
          numberOfDependants: applicant?.numberOfDependants ?? 0,
          predictedExpenseChange,
        },
      );
};
