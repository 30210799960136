import { addMethod, array, boolean, mixed, number, object, setLocale, string, StringSchema } from 'yup';

declare module 'yup' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface StringSchema<TType, TContext, TDefault, TFlags> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isoDate(message?: any): this;
  }
}

// initialise custom validation error messages
// https://github.com/jquense/yup/blob/master/src/locale.ts
setLocale({
  mixed: {
    required: '${path} is required',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    notType: ({ path, type, value, originalValue }) => {
      return `${path} is not a valid ${type}`;
    },
  },
  string: {
    email: '${path} is not a valid email address',
  },
});

const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
addMethod(StringSchema, 'isoDate', function isoDate(message?: any) {
  return this.test({
    name: 'isoDate',
    message: message ? message : '${path} is not a valid date',
    test: (value) => {
      if (!value || value === 'Invalid Date' || !isoDateRegex.test(value)) {
        return false;
      }
      const date = new Date(`${value}T00:00:00`);
      return !isNaN(date.valueOf());
    },
  });
});

export {
  array as yupArray,
  boolean as yupBoolean,
  mixed as yupMixed,
  number as yupNumber,
  object as yupObject,
  string as yupString,
};
