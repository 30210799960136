<script setup lang="ts">
import { watch } from 'vue';
import { useForm } from 'vee-validate';
import type { OutgoingPropertyEntry } from '@/types';
import { outgoingPropertySchema } from '@/validation';
import { outgoingPropertyTypeOptions, propertyPurposeOptions, saleStatusOptions, SaleStatus } from '@/helpers/const';
import dayjs from '@/helpers/dayjs';

const props = defineProps<{
  modelValue: OutgoingPropertyEntry;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: OutgoingPropertyEntry): void;
}>();

const { meta, submitCount, values, handleSubmit, resetForm } = useForm<OutgoingPropertyEntry>({
  validationSchema: outgoingPropertySchema,
  validateOnMount: false,
});

const update = handleSubmit(
  () => {
    emit('update:modelValue', { ...values });
    return true;
  },
  () => false,
);

const cancel = () => {
  resetForm();
};

watch(
  () => props.modelValue,
  (val) => {
    resetForm({ values: val });
  },
  { immediate: true },
);

defineExpose({ update, cancel });
</script>

<template>
  <form>
    <div class="">
      <BxField v-slot="{ field }" name="propertyType">
        <BxSelect
          v-bind="field"
          :id="values.id + '-outgoing-property-type'"
          label="Property Type"
          label-type="floating"
          placeholder="Select property type..."
          :list-items="outgoingPropertyTypeOptions"
          class="form-control"
        />
      </BxField>
      <BxField v-slot="{ field }" name="address">
        <BxAddressInput
          :id="values.id + '-outgoing-property-address'"
          v-bind="field"
          label="Property Address"
          label-type="floating"
          class="form-control"
        />
      </BxField>
      <BxField v-slot="{ field }" name="propertyPurpose">
        <BxRadio
          v-bind="field"
          :id="values.id + '-property-purpose'"
          :options="propertyPurposeOptions"
          label="What is the purpose of this property?"
          label-type="default"
          class="form-control"
          block
        />
      </BxField>
      <BxField v-slot="{ field }" name="outstandingLoanAmount">
        <BxNumberInput
          v-bind="field"
          :id="values.id + '-outstanding-mortgage'"
          label="Outstanding Mortgage"
          label-type="floating"
          leading-symbol="$"
          class="form-control"
          help-text="Please enter zero if there is no outstanding mortgage."
        />
      </BxField>
      <BxField v-slot="{ field }" name="saleStatus">
        <BxRadio
          v-bind="field"
          :id="values.id + '-sold-status'"
          :options="saleStatusOptions"
          label="Has the property been sold?"
          label-type="default"
          class="form-control"
        />
      </BxField>
      <BxField v-if="values.saleStatus === SaleStatus.SOLD" v-slot="{ field }" name="settlementDate">
        <BxDatePicker
          v-bind="field"
          :id="values.id + '-settlement-date'"
          label="Settlement Date"
          label-type="floating"
          placeholder="DD/MM/YYYY"
          :min-date="new Date()"
          :max-date="dayjs().add(1, 'year').toDate()"
          class="form-control"
        />
      </BxField>
      <BxField v-if="values.saleStatus !== undefined" v-slot="{ field }" name="saleAmount">
        <BxNumberInput
          v-bind="field"
          :id="values.id + '-sale-price'"
          :label="values.saleStatus === SaleStatus.SOLD ? 'Sale Price' : 'Estimated Sale Price'"
          label-type="floating"
          leading-symbol="$"
          class="form-control"
        />
      </BxField>
      <BxField v-if="values.saleStatus === SaleStatus.SOLD" v-slot="{ field }" name="depositAmount">
        <BxNumberInput
          v-bind="field"
          :id="values.id + '-deposit-amount-received'"
          label="Deposit Amount Received"
          label-type="floating"
          leading-symbol="$"
          class="form-control"
        />
      </BxField>
      <BxField v-if="values.saleStatus === SaleStatus.SOLD" v-slot="{ field }" name="depositInTrust">
        <BxRadio
          v-bind="field"
          :id="values.id + '-deposit-held-in-trust'"
          label="Is the deposit being held in trust?"
          label-type="default"
          class="form-control"
          boolean
        />
      </BxField>
    </div>
    <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
      Please review the error messages above.
    </BxErrorPanel>
  </form>
</template>
