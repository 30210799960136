<script setup lang="ts">
import { computed, watch } from 'vue';
import { refAutoReset } from '@vueuse/core';
import { useField, useSubmitCount } from 'vee-validate';

const props = defineProps<{ name: string; disabled?: boolean }>();
// note: use type 'any' to allow binding to input value
const { value, meta, errorMessage, setTouched, handleChange, validate } =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useField<any>(props.name, undefined, {
    validateOnMount: false,
    validateOnValueUpdate: false,
  });

const formSubmitCount = useSubmitCount();
const highlightError = refAutoReset(false, 1000);
watch(formSubmitCount, () => {
  highlightError.value = true;
});

let validateOnChange = false;
function handleUpdateModelValue(value: unknown) {
  if (!validateOnChange && errorMessage.value) {
    validateOnChange = true;
  }
  handleChange(value, validateOnChange);
}
function handleFocusOut() {
  setTouched(true);
  if (validateOnChange) {
    // field already validated by update
    validateOnChange = !meta.valid;
  } else {
    validate().then((result) => {
      validateOnChange = !result.valid;
    });
  }
}

const fieldProps = computed(() => ({
  modelValue: value.value,
  errorMessage: meta.touched ? errorMessage.value : undefined,
  highlightError: highlightError.value,
  'onUpdate:modelValue': handleUpdateModelValue,
  onFocusout: handleFocusOut,
}));
</script>

<template>
  <slot :value="value" :field="fieldProps"></slot>
</template>
