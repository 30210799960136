<script setup lang="ts">
import type { PropertyType } from '@/api/enums';

const incomingPropertyTypeOptions: Record<Exclude<PropertyType, 'commercial'>, string> = {
  ['residential']: 'Residential',
  ['rural_residential']: 'Rural Residential',
  ['off_plan_residential']: 'Off-the-plan Residential',
  ['retirement_land_lease_or_community_home']: 'Retirement, land-lease or community home',
  ['vacant_land']: 'Vacant Land',
};

defineProps<{
  id: string;
}>();
</script>
<template>
  <BxField v-slot="{ field }" name="propertyType">
    <BxSelect
      v-bind="field"
      :id="id + '-incoming-property-type'"
      label="Property Type"
      label-type="floating"
      placeholder="Select property type..."
      :list-items="incomingPropertyTypeOptions"
      class="form-control"
    />
  </BxField>
</template>
