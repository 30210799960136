import type { operations } from '@/types/schemas/api.main';
import { POST } from '../../_http';
type controller = operations['CustomerRegisterRequestOtpController_post'];

export type CustomerRegistrationRequestOtpBody = controller['requestBody']['content']['application/json'];
export type CustomerRegistrationRequestOtpResponse = controller['responses']['200']['content']['application/json'];

export const requestOtp = POST('/users/register/request-otp')<
  CustomerRegistrationRequestOtpBody,
  CustomerRegistrationRequestOtpResponse
>;
