import {
  DeclarationProcessStep,
  DeclarationProcessStepNames,
  DeclarationProcessSteps,
  StepStatus,
  type ActionType,
  type AppUser,
  type DeclarationProcessStatus,
  type DeclarationProcessStepEnum,
  type LoanApplication,
} from '@/types';
import { assign, createMachine } from 'xstate';
import { LoanType } from './const';

export interface DeclarationMachineContext {
  loanApp: LoanApplication;
  from?: string;
  authorisedUser: Record<string, string>;
  // targetApplicant: ApplicantId;
  status: DeclarationProcessStatus;
  current: DeclarationProcessStepEnum;
}

export const initDeclarationMachine = (params?: {
  actionType: ActionType;
  userType: AppUser;
  application: LoanApplication;
}): DeclarationMachineContext => {
  return {
    loanApp: params?.application || <LoanApplication>{},
    authorisedUser: {},
    status: Object.fromEntries(DeclarationProcessStepNames.map((step) => [step])) as DeclarationProcessStatus,
    current: DeclarationProcessStep.Summary,
  };
};

export type DeclarationMachineEvent =
  | { type: 'NEXT'; loanApp: LoanApplication }
  | { type: 'UPDATE'; loanApp: LoanApplication }
  | { type: 'BACK' }
  | { type: 'READY' }
  | { type: 'GOTO'; step: DeclarationProcessStepEnum }
  | { type: 'RELOAD'; state: DeclarationMachineContext }
  | { type: 'RESTART' };

export const loanAppDeclarationMachine = createMachine<DeclarationMachineContext, DeclarationMachineEvent>(
  {
    id: 'loanApplicationDeclaration',
    predictableActionArguments: true,
    schema: {
      context: {} as DeclarationMachineContext,
    },
    context: initDeclarationMachine(),
    initial: 'start',
    states: {
      start: {
        on: {
          NEXT: {
            target: DeclarationProcessStep.Summary,
          },
        },
      },
      [DeclarationProcessStep.Summary]: {
        on: {
          NEXT: [
            {
              target: 'checkDebt',
              actions: 'saveData',
              // cond: 'showIncomeExpenses',
            },
          ],
        },
      },
      checkDebt: {
        always: [
          {
            target: DeclarationProcessStep.Income,
            cond: 'showIncomeExpenses',
          },
          {
            target: DeclarationProcessStep.VerifyIdentity,
            actions: 'saveData',
          },
          {
            target: DeclarationProcessStep.SubmitApplication,
            actions: 'saveData',
          },
        ],
      },
      [DeclarationProcessStep.Income]: {
        on: {
          NEXT: {
            target: DeclarationProcessStep.Expenses,
            actions: 'saveData',
          },
        },
      },
      [DeclarationProcessStep.Expenses]: {
        on: {
          NEXT: {
            target: DeclarationProcessStep.Assets,
            actions: 'saveData',
          },
        },
      },
      [DeclarationProcessStep.Assets]: {
        on: {
          NEXT: [
            {
              target: DeclarationProcessStep.VerifyIdentity,
              actions: 'saveData',
            },
          ],
        },
      },
      [DeclarationProcessStep.VerifyIdentity]: {
        on: {
          NEXT: {
            target: DeclarationProcessStep.SubmitApplication,
            actions: 'saveData',
          },
        },
      },
      [DeclarationProcessStep.SubmitApplication]: {
        on: {
          NEXT: {
            target: DeclarationProcessStep.Finished,
            actions: 'saveData',
          },
        },
      },
      [DeclarationProcessStep.Finished]: {
        on: {
          NEXT: {
            target: DeclarationProcessStep.Finished,
            actions: 'resetLoanApplication',
          },
        },
      },
      prevStage: {
        always: [...DeclarationProcessStepNames]
          .sort((a, b) => DeclarationProcessSteps[b].order - DeclarationProcessSteps[a].order)
          .map((step) => ({
            target: step,
            cond: (ctx) =>
              DeclarationProcessSteps[ctx.current]?.order > DeclarationProcessSteps[step].order &&
              ctx.status[step] === StepStatus.Completed,
          })),
      },
      gotoReady: {
        always: DeclarationProcessStepNames.map((step) => ({
          target: step,
          cond: (ctx) => ctx.status[step] === StepStatus.Ready,
        })),
      },
    },
    on: {
      BACK: {
        target: 'prevStage',
        cond: (ctx) => ctx.current !== DeclarationProcessStep.Summary,
      },
      READY: {
        target: 'gotoReady',
      },
      UPDATE: {
        actions: 'saveData',
      },
      GOTO: DeclarationProcessStepNames.map((step) => ({
        target: step,
        cond: (ctx, evt) => evt.step === step,
      })),
      RELOAD: {
        target: 'gotoReady',
        actions: 'updateContext',
      },
      RESTART: {
        target: DeclarationProcessStep.Summary,
        actions: 'resetLoanApplication',
      },
    },
  },
  {
    actions: {
      saveData: assign((context, event) => {
        return event.type === 'NEXT' || event.type === 'UPDATE'
          ? {
              loanApp: event.loanApp,
              status: Object.fromEntries(
                Object.entries(context.status).map(([step, status]) => [
                  step,
                  step === context.current
                    ? StepStatus.Completed
                    : DeclarationProcessSteps[step as DeclarationProcessStepEnum].order <
                      DeclarationProcessSteps[context.current].order
                    ? status
                    : StepStatus.Incomplete,
                ]),
              ) as DeclarationProcessStatus,
            }
          : {};
      }),
      updateContext: assign((context, event) => (event.type === 'RELOAD' ? event.state : {})),
    },
    guards: {
      hasEndDebt: (ctx) => ctx.loanApp.endDebt > 0,
      showIncomeExpenses: (ctx) => {
        return (
          (ctx.loanApp.endDebt > 0 || (ctx.from ? ctx.from === 'crm' : false)) &&
          ctx.loanApp.loanType !== LoanType.SINGLE_SECURITY
        );
      },
      hasNoBroker: (ctx) => ctx.loanApp.userType !== 'Broker',
    },
  },
);
