<template>
  <svg width="320" height="108" viewBox="0 0 320 108" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_4751_237184" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="3" width="59" height="79">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.09723H58.5672V81.3247H0V3.09723Z" fill="white" />
    </mask>
    <g mask="url(#mask0_4751_237184)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1726 70.2351H31.03C40.9072 70.2351 46.2951 65.5394 46.2951 58.446C46.2951 51.6523 41.0076 46.9566 30.232 46.9566H12.1726V70.2351ZM12.1726 36.1667H27.5381C37.6152 36.1667 42.7028 31.471 42.7028 24.977C42.7028 18.483 38.2133 14.1868 28.2367 14.1868H12.1726V36.1667ZM-0.00012207 3.09723H29.8326C37.4154 3.09723 43.5019 4.89554 48.0908 8.59199C52.6805 12.2887 54.9751 17.2839 54.9751 23.678C54.9751 25.3765 54.6758 27.1748 54.1771 29.0732C53.08 32.6699 49.3886 38.0648 43.7005 40.5624C49.0885 42.2611 52.8804 44.9583 55.1747 48.4552C57.4693 51.952 58.5672 55.6484 58.5672 59.4449C58.5672 72.6328 48.8896 81.3247 30.8302 81.3247H-0.00012207V3.09723Z"
        fill="#0C1744"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M96.8528 39.9629C95.8552 39.7633 94.7581 39.6632 93.4604 39.6632C84.381 39.6632 79.5924 44.9583 79.5924 54.2499V81.3247H67.7197V27.9741H79.2923V36.4661C82.5853 30.4717 87.4743 27.4745 93.9591 27.4745C95.2571 27.4745 96.1542 27.5746 96.8528 27.7742V39.9629Z"
      fill="#0C1744"
    />
    <mask
      id="mask1_4751_237184"
      style="mask-type: alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="320"
      height="108"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 108H319.527V0H0V108Z" fill="white" />
    </mask>
    <g mask="url(#mask1_4751_237184)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M106.927 81.3247H118.8V27.9742H106.927V81.3247ZM107.326 5.19527C110.419 1.99818 115.208 1.99818 118.301 5.19527C121.393 8.29228 121.393 13.0878 118.301 16.1848C115.208 19.2821 110.419 19.2821 107.326 16.1848C104.232 13.0878 104.232 8.29228 107.326 5.19527Z"
        fill="#0C1744"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M144.753 42.5608C141.859 45.8577 140.363 49.8539 140.363 54.5496C140.363 59.2453 141.859 63.2414 144.753 66.5386C147.746 69.7357 151.637 71.3341 156.326 71.3341C161.015 71.3341 164.907 69.7357 167.8 66.5386C170.793 63.2414 172.29 59.2453 172.29 54.5496C172.29 49.8539 170.793 45.8577 167.8 42.5608C164.806 39.2639 161.015 37.6652 156.326 37.6652C151.637 37.6652 147.746 39.2639 144.753 42.5608ZM135.574 35.3672C140.462 29.7727 146.948 26.9751 154.83 26.9751C157.723 26.9751 160.816 27.6744 164.208 29.1732C167.7 30.5717 170.294 32.6698 172.09 35.5671V0.00012207H183.963V81.3246H172.389V73.0323C170.494 76.1296 167.799 78.4275 164.109 79.9258C160.517 81.4247 157.324 82.1239 154.53 82.1239C147.047 82.1239 140.761 79.4265 135.773 73.9317C130.784 68.4367 128.29 61.9427 128.29 54.5495C128.29 47.3563 130.685 40.9623 135.574 35.3672Z"
        fill="#0C1744"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M231.748 42.4606C228.855 39.2635 224.964 37.6651 220.274 37.6651C215.585 37.6651 211.694 39.2635 208.8 42.4606C205.907 45.5576 204.41 49.554 204.41 54.2497C204.41 58.9454 205.907 62.9416 208.8 66.2387C211.793 69.4356 215.585 71.0342 220.274 71.0342C224.964 71.0342 228.756 69.4356 231.649 66.2387C234.642 62.9416 236.139 58.9454 236.139 54.2497C236.139 49.554 234.642 45.6577 231.748 42.4606ZM199.821 73.6317C194.932 68.2368 192.538 61.8426 192.538 54.4494C192.538 47.3563 194.932 41.0619 199.722 35.4671C204.611 29.8722 210.997 27.0749 218.878 27.0749C221.771 27.0749 224.864 27.8743 228.257 29.3729C231.649 30.8712 234.342 33.0693 236.238 36.0665V27.9741H247.812V80.6253C247.812 89.0175 245.218 95.7114 240.13 100.607C235.041 105.502 228.356 108 219.975 108C207.005 108 198.025 102.605 193.137 91.815L203.014 86.1201C206.706 93.6133 212.293 97.4099 219.776 97.4099C229.055 97.4099 235.939 91.815 235.939 81.125V72.7326C234.043 75.8298 231.349 78.0277 227.857 79.5263C224.366 81.0249 221.272 81.7241 218.479 81.7241C210.997 81.7241 204.71 79.0269 199.821 73.6317Z"
        fill="#0C1744"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M263.769 81.3247H275.643V27.9742H263.769V81.3247Z"
        fill="#2CDD80"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M306.457 38.6642V62.2424C306.457 68.6366 309.549 71.1342 315.636 71.1342C316.833 71.1342 318.129 71.0344 319.427 70.7347V80.8253C317.531 81.5246 315.336 81.9241 313.042 81.9241C301.368 81.9241 294.583 75.7298 294.583 64.1406V38.6642H284.805V27.9741H294.583V11.4893H306.457V27.9741H319.527V38.6642H306.457Z"
        fill="#2CDD80"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M247.963 19.805C247.963 15.4416 251.508 11.8915 255.866 11.8915C260.224 11.8915 263.769 15.4416 263.769 19.805V19.8053H275.644V19.805C275.644 8.88433 266.772 0 255.866 0C244.96 0 236.087 8.88433 236.087 19.805V19.8053H247.963V19.805Z"
        fill="#2CDD80"
      />
    </g>
  </svg>
</template>
