<script setup lang="ts">
import { LoanType } from '@/helpers/const';
import { useRequestIncomeExpenseProcess } from '@/helpers/useRequestIncomeExpenseProcess';
import router from '@/router';
import { RequestIncomeExpenseProcessSteps, type RequestIncomeExpenseProcessStepEnum } from '@/types';
import { onBeforeMount } from 'vue';
import type { RouteParamsRaw } from 'vue-router';
const { send, service, state } = useRequestIncomeExpenseProcess();
onBeforeMount(() => {
  send('READY');
});
service.onTransition((transition) => {
  if (transition.value !== 'gotoReady' && transition.value !== 'start') {
    const loanApp = transition.context.loanApp;
    const key = transition.value as RequestIncomeExpenseProcessStepEnum;
    const name = RequestIncomeExpenseProcessSteps[key].route;
    let params: RouteParamsRaw | undefined;
    if (name === 'completed') {
      let finishType: string;
      if (loanApp.userType === 'Broker') {
        finishType = 'broker';
      } else if (loanApp.actionType === 'updateExisting') {
        if (state.value.context.from !== 'crm') {
          finishType = 'user_declaration';
        } else {
          finishType = 'income_expense';
        }
      } else {
        if (loanApp.loanType === LoanType.BRIDGING) {
          finishType = 'bridge_loan';
        } else {
          finishType = 'equity_release';
        }
      }
      params = { finishType };
    }
    router.push({ name, params });
  }
});
</script>

<template>
  <div></div>
</template>
