import type { ApiSchema } from '@/types/api';
import type { IncomingProperty, OutgoingProperty, PropertyOwnership } from '@/types';
import { mapFromApi } from '@/mappers';
import type { PropertyType, PropertyPurpose, StampDutyStatus, DepositPaidType, SoldStatus } from '@/api/enums';

export type IncomingPropertyDto = ApiSchema['CustomerApplicationsRetrieveApplicationIncomingPropertyDto'] & {
  depositAmount?: number;
  purchasePrice?: number;
  purchasePriceShaded?: number;
  stampDuty?: number;
};

export const incomingProperty = (data: IncomingPropertyDto): IncomingProperty => {
  return {
    id: data.id ?? '',
    propertyType: data.propertyType as PropertyType,
    address: mapFromApi.address(data),
    propertyPurpose: data.propertyPurpose as PropertyPurpose,
    contractForSale: data.contractForSale ?? false,
    purchaseAmount: data.purchasePrice ?? 0,
    depositAmount: data.depositAmount ?? 0,
    depositType: data.depositPaidType as DepositPaidType,
    settlementDate: '',
    stampDutyStatus: data.stampDutyStatus as StampDutyStatus,
    stampDuty: data.stampDuty ?? 0,
  };
};

export type OutgoingPropertyDto = ApiSchema['OutgoingPropertyDto'] & {
  depositAmountReceived?: number;
  estimatedValuation?: number;
  estimatedValuationShading?: number;
  salePrice?: number;
  salePriceShaded?: number;
  settlementDate?: string;
  outstandingMortgageAmount?: number;
};

export const outgoingProperty = (data: OutgoingPropertyDto): OutgoingProperty => {
  return {
    id: data.id ?? '',
    propertyType: data.propertyType as PropertyType,
    address: mapFromApi.address(data),
    propertyPurpose: data.propertyPurpose as PropertyPurpose,
    outstandingLoanAmount: data.outstandingMortgageAmount ?? 0,
    saleStatus: data.soldStatus as SoldStatus,
    settlementDate: data.settlementDate ?? '',
    saleAmount: data.estimatedValuation || data.salePrice || 0,
    depositAmount: data.depositAmountReceived ?? 0,
    depositInTrust: data.depositHeldInTrust ?? false,
  };
};

export type PropertyOwnershipDto = ApiSchema['PropertyOwnershipDto'] & {
  propertyId?: string;
};

export const propertyOwnership = (data: PropertyOwnershipDto[]): PropertyOwnership[] => {
  const propertyOwnership: PropertyOwnership[] = [];
  for (const current of data) {
    const po = propertyOwnership.find((p) => p.id === current.propertyId);
    if (current.applicantId && current.propertyId) {
      if (po) {
        po.share = { ...po.share, [current.applicantId]: current.share };
      } else {
        let ownershipType: PropertyOwnership['type'];
        if (current.assetPropertyId) {
          ownershipType = 'asset';
        } else if (current.incomingPropertyId) {
          ownershipType = 'incoming';
        } else ownershipType = 'outgoing';

        propertyOwnership.push({
          id: current.propertyId,
          type: ownershipType,
          share: { [current.applicantId]: current.share ?? 0 },
        });
      }
    }
  }
  return propertyOwnership;
};
