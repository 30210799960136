<script setup lang="ts">
import { LoanType } from '@/helpers/const';
import { useRequestIncomeExpenseProcess } from '@/helpers/useRequestIncomeExpenseProcess';
import ApplicantSummary from './ApplicantSummary.vue';
import BuyingSummary from './BuyingSummary.vue';
import LoanDetailSummary from './LoanDetailSummary.vue';
import PropertyOwnershipSummary from './PropertyOwnershipSummary.vue';
import SellingSummary from './SellingSummary.vue';

const { loanApp, loanProperties, loanApplicants } = useRequestIncomeExpenseProcess();
</script>

<template>
  <div>
    <section class="summary-details">
      <h2>Loan Purpose</h2>
      <h3>Loan type</h3>
      <dl>
        <dt>Your loan purpose</dt>
        <dd>{{ loanApp.loanType === LoanType.BRIDGING ? 'Buy now, sell later' : 'Unlock equity before I sell' }}</dd>
      </dl>
    </section>

    <BuyingSummary :data="loanApp.incomingProperties" :settlement-date="loanApp.settlementDate || ''" />
    <SellingSummary :data="loanApp.outgoingProperties" />
    <LoanDetailSummary v-if="loanApp.loanDetails" :data="loanApp.loanDetails" />
    <ApplicantSummary :data="loanApp.applicants" />
    <PropertyOwnershipSummary
      :data="loanApp.propertiesOwnership"
      :properties="loanProperties"
      :applicants="loanApplicants"
    />
    <!-- <IncomeSummary :data="loanApp.financials" :applicants="loanApplicants" /> -->
  </div>
</template>
