<script setup lang="ts">
import AppFooter from '@/components/layout/AppFooter.vue';
import AppHeader from '@/components/layout/AppHeader.vue';
import { useDeclarationProcess } from '@/helpers/useDeclarationProcess';
import type { DeclarationProcessStepEnum } from '@/types';
import DeclarationProgressNav from '@/views/applicant-declaration/modules/DeclarationProgressNav.vue';
import { useElementSize, useScroll } from '@vueuse/core';
import { computed, provide, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

const { y, directions } = useScroll(window);

const hideProgressNav = ref(false);
watch(y, (currY, oldY) => {
  const delta = Math.abs(currY - oldY);
  if (directions.bottom && currY > 100) {
    hideProgressNav.value = true;
  }
  if (directions.top && (currY <= 100 || delta > 10)) {
    hideProgressNav.value = false;
  }
});

provide('navInfo', { mainScrollOffset: y });

const navRef = ref(null);
const { height } = useElementSize(navRef);
const navheight = computed(() => `${height.value - y.value}px`);

const appVersion = computed(() => import.meta.env.VITE_APP_VERSION);

const route = useRoute();
const { send, state, stepper, currentStep } = useDeclarationProcess();

const showNav = computed(() => route.path !== '/' && !route.path.startsWith('/confirmation'));

const gotoStep = (step: DeclarationProcessStepEnum) => {
  send({ type: 'GOTO', step });
};

watch(
  () => route.name,
  (newRoute, oldRoute) => {
    if (newRoute !== oldRoute) {
      const step = stepper.value.find((s) => s.route === newRoute)?.id;
      if (step) {
        gotoStep(step);
      }
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="app-layout" :class="{ 'app-layout--hide-nav': !showNav }">
    <header class="app-layout__header">
      <AppHeader />
    </header>
    <nav ref="navRef" class="app-layout__nav" :class="{ hide: hideProgressNav }">
      <div class="nav-container">
        <DeclarationProgressNav
          v-if="showNav"
          :model-value="currentStep"
          :end-debt="state.context.loanApp.endDebt"
          :steps="stepper"
          class="my-6"
          @update:model-value="gotoStep"
        />
      </div>
    </nav>
    <main class="app-layout__main">
      <RouterView v-slot="{ Component }">
        <Transition name="fade">
          <component :is="Component" />
        </Transition>
      </RouterView>
    </main>
    <footer class="app-layout__footer">
      <AppFooter />
      <div class="app-version">v{{ appVersion }}</div>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.app-layout {
  display: grid;
  grid-template:
    'header' minmax(64px, auto)
    'nav   ' 100px
    'main  ' 1fr
    'footer' auto / 1fr;

  width: 100vw;
  height: 100vh;

  overflow-x: hidden;

  &__header {
    @apply z-30 border-b border-gray-100 bg-white;
    grid-area: header;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    height: 68px;
  }

  &__nav {
    @apply bg-bridgit-navy;
    @apply pl-0 md:pl-4 lg:pl-8;
    @apply z-20 flex justify-end;
    grid-area: nav;

    transition: top 0.3s ease-in-out;
  }

  &__main {
    @apply bg-bridgit-paleBlue;
    @apply relative flex px-4 py-6 md:px-6 lg:px-8;
    grid-area: main;
  }

  &__footer {
    @apply relative z-30 bg-white;
    grid-area: footer;
  }

  &.app-layout--hide-nav {
    grid-template:
      'header' minmax(64px, auto)
      'main  ' 1fr
      'footer' auto / 1fr;

    .app-layout__nav {
      display: none;
    }

    .app-layout__main {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    &__nav {
      position: fixed;
      top: 68px;
      left: 0;
      right: 0;
      z-index: 100;

      height: 100px;

      &.hide {
        top: -4rem;

        transition: top 0.3s ease-in-out;
      }
    }
  }

  @screen md {
    grid-template:
      'header header' minmax(64px, auto)
      'nav main     ' 1fr
      'footer footer' minmax(140px, auto) / 2fr 5fr;
  }
}

.nav-container {
  @apply w-full overflow-auto md:fixed md:top-16 md:w-auto;

  @screen md {
    height: v-bind(navheight);
  }
}

.app-version {
  @apply text-gray-200;
  position: absolute;
  bottom: 0;
  right: 0;

  font-size: 8px;
}
</style>
