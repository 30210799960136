import type { EmploymentTypeEnum, PeriodicValue, PeriodKey } from '@/types';
import { periodType } from '@/helpers/const';

const multiplier = (frequency: PeriodKey, jobType?: EmploymentTypeEnum) =>
  frequency === 'week' && jobType === 'casual' ? 46 : periodType[frequency].multiplier;

export const convertPeriod = (
  value: PeriodicValue | undefined,
  toPeriod: PeriodKey,
  jobType?: EmploymentTypeEnum,
): PeriodicValue => {
  if (value === undefined) {
    return { amount: 0, frequency: 'month' };
  }
  if (value.amount === undefined || isNaN(value.amount) || toPeriod === value.frequency) {
    return { amount: value.amount, frequency: toPeriod };
  }
  return {
    amount: (value.amount * multiplier(value.frequency, jobType)) / multiplier(toPeriod, jobType),
    frequency: toPeriod,
  };
};
