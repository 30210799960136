import dayjs from '@/helpers/dayjs';
import { yupAddress, yupBoolean, yupMixed, yupNumber, yupObject, yupPeriodicValue, yupString } from '../../yup';
import type { DepositTypeEnum, IncomingProperty, PropertyTypeEnum, StampDutyStatusEnum } from '@/types';
import { DepositType, StampDutyStatus, standardBridgeTypes } from '@/helpers/const';

export const incomingPropertySchemaV2 = yupObject<IncomingProperty>({
  contractForSale: yupBoolean().required().label('Contract for sale response'),
  propertyType: yupMixed<PropertyTypeEnum>().required().label('Property type'),
  address: yupAddress().when('propertySelected', {
    is: (propertySelected: boolean) => propertySelected === undefined || propertySelected === true,
    then: (schema) => schema.required().label('Property address'),
  }),
  propertyPurpose: yupString().when('propertyType', {
    is: (type: PropertyTypeEnum) =>
      standardBridgeTypes.includes(type as 'residential' | 'rural_residential' | 'off_plan_residential'),
    then: (schema) => schema.required().label('Property purpose'),
  }),
  receiveRentalIncome: yupBoolean().when('propertyType', {
    is: (type: PropertyTypeEnum) =>
      standardBridgeTypes.includes(type as 'residential' | 'rural_residential' | 'off_plan_residential'),
    then: (schema) => schema.required().label('Rental income response'),
  }),
  rentalIncome: yupPeriodicValue(yupNumber().required())
    .when('receiveRentalIncome', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Expected rental income'),
  settlementDate: yupString()
    .required()
    .isoDate()
    .test('validDate', '${path} must be within 12 months', (value) => {
      const date = dayjs(value);
      return date.isAfter(dayjs(), 'day') && date.isBefore(dayjs().add(1, 'year'), 'day');
    })
    .label('Purchase date'),
  purchaseAmount: yupNumber().required().label('Purchase price'),
  depositAmount: yupNumber()
    .when('purchaseAmount', ([purchaseAmount], schema) =>
      schema.max(purchaseAmount, `The deposit cannot be more than the purchase price`),
    )
    .required()
    .label('Deposit amount'),
  depositType: yupMixed<DepositTypeEnum>().oneOf(Object.values(DepositType)).required().label('Deposit payment method'),
  stampDutyStatus: yupMixed<StampDutyStatusEnum>()
    .oneOf(Object.values(StampDutyStatus))
    // already_paid is only valid if contract for sale exists
    .when('propertyType', {
      is: (type: PropertyTypeEnum) => type !== 'retirement_land_lease_or_community_home',
      then: (schema) =>
        schema
          .when('contractForSale', {
            is: true,
            then: (schema) => schema.oneOf(Object.values(StampDutyStatus)).required().label('Stamp duty response'),
            otherwise: (schema) =>
              schema.oneOf(Object.values(StampDutyStatus).filter((item) => item !== StampDutyStatus.ALREADY_PAID)),
          })
          .required()
          .label('Stamp duty response'),
    }),
});
