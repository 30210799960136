/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CreditCardLiability, OtherLiability, VehicleLiability } from '@/types';
import { convertPeriod } from '@/helpers/convertPeriod';

export const liabilityCard = (data: CreditCardLiability, applicantIndex: number): any => {
  return {
    applicantIndex,
    institution: data.institution,
    creditLimit: data.creditLimit,
    amountOwed: data.amountOwing,
  };
};

export const liabilityVehicle = (data: VehicleLiability, applicantIndex: number): any => {
  return {
    applicantIndex,
    make: data.make,
    model: data.model,
    yearBought: Number(data.yearBought),
    assetValue: data.assetValue,
    liabilityLimit: data.liabilityLimit,
    amountOwed: data.amountOwing,
    monthlyRepayment: convertPeriod(data.periodicRepayment, 'month').amount,
  };
};

export const liabilityOther = (data: OtherLiability, applicantIndex: number): any => {
  return {
    applicantIndex,
    details: data.details,
    assetValue: 0,
    liabilityLimit: data.liabilityLimit,
    amountOwed: data.amountOwing,
    monthlyRepayment: convertPeriod(data.periodicRepayment, 'month').amount,
  };
};
