<template>
  <svg width="27" height="18" viewBox="0 0 27 18" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.9392 6.19611C21.0136 5.44262 18.8368 6.11239 17.581 7.78681C16.1578 9.79611 16.6601 12.6426 18.6694 14.0659C20.6787 15.4891 23.5252 14.9868 24.9485 12.9775C25.1996 12.5589 25.4508 12.1403 25.5345 11.7217"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.0098 9.711L21.6005 11.218L24.9493 7.86914"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.1676 17.4141H2.51178C1.50713 17.4141 0.669922 16.5769 0.669922 15.5722V2.51178C0.669922 1.50713 1.50713 0.669922 2.51178 0.669922H18.1676C19.1722 0.669922 20.0095 1.50713 20.0095 2.51178V3.01411"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.68945 6.36328H10.4662"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.68945 11.6367H10.4662"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
