/* eslint-disable @typescript-eslint/no-explicit-any */
import type { AssetAccount, PropertyAsset } from '@/types';
import { convertPeriod } from '@/helpers/convertPeriod';
import { mapToApi } from '@/mappers';

export const fundsAssets = (data: AssetAccount, applicantIndex: number): any => {
  const additionalDetails = {
    endDebtContribution: 0,
    towardsPurchase: 0,
  };
  return {
    applicantIndex,
    assetType: data.type,
    assetAmount: data.value,
    institution: data.institution,
    ...additionalDetails,
  };
};

export const assetProperty = (data: PropertyAsset, applicantIndex: number): any => {
  return {
    applicantIndex,
    propertyValue: data.propertyValue,
    propertyPurpose: data.propertyPurpose,
    rentalIncome: convertPeriod(data.rentalIncome, 'month').amount,
    amountOwed: data.amountOwing,
    mortgageLimit: data.amountOwing,
    line1: data.address.addressLine,
    line2: data.address.addressLine2 ?? '',
    city: data.address.locality,
    state: mapToApi.australianState(data.address.state),
    postCode: data.address.postcode,
    country: 'au',
    liabilityLimit: 0,
    monthlyRepayment: 0,
    id: data.id ?? undefined,
  };
};
