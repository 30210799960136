import type { operations } from '@/types/schemas/api.main';
import { POST } from '../../_http';
type controllerV1 = operations['BrokerApplicationsActiveApplicationsControllerV1_post'];
type controllerV2 = operations['BrokerApplicationsActiveApplicationsControllerV2_post'];

export type BrokerActiveApplicationsBodyV1 = controllerV1['requestBody']['content']['application/json'];
export type BrokerActiveApplicationsResponseV1 = controllerV1['responses']['200']['content']['application/json'];
export type BrokerActiveApplicationsBodyV2 = controllerV2['requestBody']['content']['application/json'];
export type BrokerActiveApplicationsResponseV2 = controllerV2['responses']['200']['content']['application/json'];

export const activeApplicationsV1 = POST('/v1/brokers/applications/active-applications')<
  BrokerActiveApplicationsBodyV1,
  BrokerActiveApplicationsResponseV1
>;

export const activeApplicationsV2 = POST('/v2/brokers/applications/active-applications')<
  BrokerActiveApplicationsBodyV2,
  BrokerActiveApplicationsResponseV2
>;

export const activeApplications = async (
  applicationBody: BrokerActiveApplicationsBodyV1 | BrokerActiveApplicationsBodyV2,
  structuralVersionNumber?: number,
): Promise<BrokerActiveApplicationsResponseV1 | BrokerActiveApplicationsResponseV2> => {
  structuralVersionNumber = structuralVersionNumber || parseInt(import.meta.env.VITE_STRUCTURAL_VERSION_NUMBER || '0');
  if (structuralVersionNumber === 1) {
    const { data } = await activeApplicationsV1(applicationBody);
    return data;
  }
  const { data } = await activeApplicationsV2(applicationBody);
  return data;
};
