<script setup lang="ts">
import api from '@/api';
import type { ExpenseTypeDto } from '@/api/backend/public/expense-types/expense-type.dto';
import { ApplicantType, RelationshipType } from '@/helpers/const';
import { getFullName } from '@/helpers/format';
import { useRequestIncomeExpenseProcess } from '@/helpers/useRequestIncomeExpenseProcess';
import type { ApplicantId } from '@/types';
import { useTemplateRefsList } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import { onBeforeMount, ref } from 'vue';
import ExpensesForm from './modules/Expenses/ExpensesForm.vue';
const { authorisedUser, loanApp, loanApplicants, saveAndGoNext, goBack } = useRequestIncomeExpenseProcess();

const isReady = ref(false);
const expenseTypes = ref<ExpenseTypeDto[]>([]);

const isSecondaryDeclaration = false;
const isIncomeExpenseRequest = true;

const applicantsMarried = loanApp.value.applicants.some(
  (applicant) => applicant.relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO,
);

const validApplicants = loanApp.value.applicants
  .filter(
    (a) =>
      (loanApp.value.userType === 'Broker' && a.relationshipToPrimary !== RelationshipType.MARRIED_OR_DEFACTO) ||
      (loanApp.value.userType === 'Primary' && a.type === ApplicantType.PRIMARY) ||
      (a.id === authorisedUser.id && !isSecondaryDeclaration) ||
      (a.type === ApplicantType.SECONDARY && isSecondaryDeclaration && !applicantsMarried) ||
      (a.type === ApplicantType.PRIMARY && isSecondaryDeclaration && applicantsMarried),
  )
  .map((a) => a.id);

const financials = ref(cloneDeep(loanApp.value.financials));
const expenseRefs = useTemplateRefsList<InstanceType<typeof ExpensesForm>>();
const activeTab = ref<ApplicantId | undefined>(validApplicants.length === 1 ? validApplicants[0] : undefined);

const isCompleted = (id: ApplicantId) => financials.value[id].expenses?.numberOfDependants !== undefined;

const validateExpenses = async () => {
  // Don't validate if married and secondary declaration
  if (isSecondaryDeclaration && applicantsMarried) return true;

  let isValid = true;
  const refCount = expenseRefs.value.length;
  for (let i = 0; i < refCount; i++) {
    isValid = (await expenseRefs.value[i].update()) ?? false;
    if (!isValid) {
      activeTab.value = validApplicants[i];
      window.scrollTo(0, 0);
      break;
    }
  }
  return isValid;
};

const saveAndContinue = async () => {
  if (await validateExpenses()) {
    saveAndGoNext({
      financials: financials.value,
    });
  }
};

onBeforeMount(async () => {
  isReady.value = false;
  try {
    const expenseTypesResponse = await api.backend.public.listExpenseTypes(loanApp.value.structuralVersionNumber);
    if (expenseTypesResponse.expenseTypes?.length) {
      expenseTypesResponse.expenseTypes.sort((a, b) => {
        const sortOrderA = a.sortOrder || 0;
        const sortOrderB = b.sortOrder || 0;
        return sortOrderA - sortOrderB;
      });
      expenseTypes.value = expenseTypesResponse.expenseTypes;
    }
  } finally {
    isReady.value = true;
  }
});
</script>

<template>
  <div v-if="isReady" class="w-full">
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">
        What are your household
        <BxHighlight>expenses</BxHighlight>?
      </h2>
    </div>
    <div v-if="loanApp.applicants.length > 1" class="my-6">
      <p
        v-if="
          isSecondaryDeclaration && loanApp.applicants[1].relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO
        "
      >
        Please review your combined household expenses with
        {{
          loanApp.applicants[0].preferredName ? loanApp.applicants[0].preferredName : loanApp.applicants[0].firstName
        }}. If you wish to make any changes, please contact us.
      </p>
      <p v-else-if="isIncomeExpenseRequest && authorisedUser.id === loanApp.applicants[1].id && applicantsMarried">
        Please include your combined household expenses including
        {{
          loanApp.applicants[0].preferredName ? loanApp.applicants[0].preferredName : loanApp.applicants[0].firstName
        }}.
      </p>
      <p v-else-if="loanApp.applicants[1].relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO">
        Please include your combined household expenses including
        {{
          loanApp.applicants[1].preferredName ? loanApp.applicants[1].preferredName : loanApp.applicants[1].firstName
        }}.
      </p>
      <p v-else-if="loanApp.actionType === 'createNew'">
        Only include your expenses, we will request this information from
        {{
          loanApp.applicants[1].preferredName ? loanApp.applicants[1].preferredName : loanApp.applicants[1].firstName
        }}
        via email.
      </p>
    </div>

    <div class="my-6 grid gap-4">
      <div v-for="applicantId in validApplicants" :key="applicantId">
        <BxCard :class="applicantId !== activeTab && 'hidden'">
          <h2 class="mb-4 text-xl font-medium">
            {{ getFullName(loanApplicants[applicantId]) }}
          </h2>
          <ExpensesForm
            v-if="isIncomeExpenseRequest && applicantsMarried"
            :ref="expenseRefs.set"
            v-model="financials[loanApp.applicants[0].id].expenses"
            :applicant-id="applicantId"
            :animate="applicantId === activeTab"
            :expense-types="expenseTypes"
            :show-buttons="validApplicants.length > 1"
            :disabled="isSecondaryDeclaration && applicantsMarried"
            @save="activeTab = undefined"
            @cancel="activeTab = undefined"
          />
          <ExpensesForm
            v-else
            :ref="expenseRefs.set"
            v-model="financials[applicantId].expenses"
            :applicant-id="applicantId"
            :animate="applicantId === activeTab"
            :expense-types="expenseTypes"
            :show-buttons="validApplicants.length > 1"
            :disabled="isSecondaryDeclaration && applicantsMarried"
            @save="activeTab = undefined"
            @cancel="activeTab = undefined"
          />
        </BxCard>
        <BxCard :class="applicantId === activeTab && 'hidden'">
          <div class="flex items-center">
            <div class="card-details">
              <div class="font-medium">
                <span>{{ getFullName(loanApplicants[applicantId]) }}</span>
                <span class="ml-6 font-normal text-gray-400"
                  >({{ isCompleted(applicantId) ? 'completed' : 'incomplete' }})</span
                >
              </div>
            </div>
            <div class="card-button">
              <BxButton
                variant="secondary"
                :disabled="activeTab && activeTab !== applicantId"
                @click="activeTab = applicantId"
              >
                {{ isCompleted(applicantId) ? 'Edit' : 'Add' }} expenses
              </BxButton>
            </div>
          </div>
        </BxCard>
      </div>
    </div>

    <div class="mt-6">
      <BxButton id="expenses-continue" variant="primary" @click="saveAndContinue()">Save and continue</BxButton>
      <BxButton variant="link" class="ml-4" @click="goBack()">Back</BxButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-details {
  flex-grow: 1;
}

.card-button {
  flex-basis: 200px;

  text-align: right;
}
</style>
