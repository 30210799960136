<script setup lang="ts">
import { watch } from 'vue';
import { useForm } from 'vee-validate';
import { selfEmployedSchema } from '@/validation';
import { EmploymentType } from '@/helpers/const';
import type { EmploymentEntry } from '@/types';

const props = defineProps<{
  applicantId: string;
  modelValue: EmploymentEntry;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', v: EmploymentEntry): void;
}>();

const { meta, submitCount, handleSubmit, resetForm } = useForm<EmploymentEntry>({
  validationSchema: selfEmployedSchema,
  validateOnMount: false,
});

watch(
  () => props.modelValue,
  (data) => {
    const values = { ...data };
    resetForm({ values });
  },
  { immediate: true, deep: true },
);

const update = handleSubmit(
  (values) => {
    emit('update:modelValue', { ...values, type: EmploymentType.SELF_EMPLOYED });
    return true;
  },
  () => false,
);
const cancel = () => resetForm();

defineExpose({ update, cancel });
</script>

<template>
  <div>
    <BxField v-slot="{ field }" name="salary">
      <BxPeriodicAmount
        :id="applicantId + '-salary'"
        v-bind="field"
        label="Salary"
        label-type="floating"
        leading-symbol="$"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="employedSince">
      <BxDatePicker
        v-bind="field"
        :id="applicantId + '-employed-since'"
        label="Date employment commenced"
        label-type="floating"
        placeholder="DD/MM/YYYY"
        :max-date="new Date()"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="businessAddress">
      <BxAddressInput
        :id="applicantId + '-business-address'"
        v-bind="field"
        label="Business Address"
        label-type="floating"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="businessType">
      <BxInput
        :id="applicantId + '-business-type'"
        v-bind="field"
        label="Your business type"
        label-type="floating"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="tradingName">
      <BxInput
        :id="applicantId + '-trading-name'"
        v-bind="field"
        label="Your trading name"
        label-type="floating"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="occupation">
      <BxInput
        :id="applicantId + '-occupation'"
        v-bind="field"
        label="Your current occupation"
        label-type="floating"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="industry">
      <BxInput
        :id="applicantId + '-employment-industry'"
        v-bind="field"
        label="Employment industry"
        label-type="floating"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="gstRegistered">
      <BxRadio
        v-bind="field"
        :id="applicantId + '-gst-registered'"
        label="Is your business GST registered?"
        label-type="default"
        class="form-control"
        boolean
      />
    </BxField>
    <BxField v-slot="{ field }" name="abn">
      <BxInput
        :id="applicantId + '-australian-business-number'"
        v-bind="field"
        label="ABN number"
        label-type="floating"
        class="form-control"
        maxlength="11"
      />
    </BxField>
    <BxField v-slot="{ field }" name="soleTrader">
      <BxRadio
        :id="applicantId + '-sole-trader'"
        v-bind="field"
        label="Are you a sole trader?"
        label-type="floating"
        class="form-control"
        boolean
      />
    </BxField>
    <BxField v-slot="{ field }" name="hasTaxReturns">
      <BxRadio
        v-bind="field"
        :id="applicantId + '-is-tax-returns-available'"
        label="Do you have individual tax returns available for 2 of the last 3 financial years?"
        label-type="default"
        class="form-control"
        boolean
      />
    </BxField>

    <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
      Please review the error messages above.
    </BxErrorPanel>
  </div>
</template>
