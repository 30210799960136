<template>
  <svg width="46" height="35" viewBox="0 0 46 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M32.7397 19.8124C37.3831 19.8124 41.1473 16.0481 41.1473 11.4047C41.1473 6.7613 37.3831 2.99707 32.7397 2.99707C28.0963 2.99707 24.332 6.7613 24.332 11.4047C24.332 16.0481 28.0963 19.8124 32.7397 19.8124Z"
      stroke="#54B7F9"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M30.7438 13.297C30.7438 14.348 31.8998 14.9785 33.161 14.9785C34.4221 14.9785 35.0527 14.0327 35.0527 13.0868C35.0527 12.1409 33.8966 11.4053 32.8457 11.4053H32.9508C31.6896 11.4053 30.6387 10.4594 30.6387 9.40846C30.6387 8.35751 31.7947 7.83203 32.9508 7.83203C34.1068 7.83203 34.9476 8.5677 34.9476 9.40846"
      stroke="#2CDD80"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.9512 7.83221V6.78125"
      stroke="#2CDD80"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.9512 16.0295V14.9785"
      stroke="#2CDD80"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M32.7389 23.8057C30.2166 29.7962 24.3312 34 17.5 34C8.35669 34 1 26.6433 1 17.5C1 8.35669 8.35669 1 17.5 1C19.707 1 21.914 1.42038 23.8057 2.26115"
      stroke="#020038"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.3945 8.9873V17.5L22.0187 21.9141"
      stroke="#020038"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
