import type { operations } from '@/types/schemas/api.main';
import { POST } from '../../_http';
type controller = operations['CustomersAuthenticateRequestOtpController_post'];

export type CustomerAuthenticationRequestOtpBody = controller['requestBody']['content']['application/json'];
export type CustomerAuthenticationRequestOtpResponse = controller['responses']['200']['content']['application/json'];

export const requestOtp = POST('/users/authenticate/request-otp')<
  CustomerAuthenticationRequestOtpBody,
  CustomerAuthenticationRequestOtpResponse
>;
