<script setup lang="ts">
import { reactive, toRef, watch } from 'vue';
import { useNumberInput } from '@/helpers/numberInput';
import BxInput from '@/components/common/BxInput.vue';

const props = withDefaults(
  defineProps<{
    modelValue?: number;
    allowNegative?: boolean;
    precision?: number;
    formatOnInput?: boolean;
    disabled?: boolean;
    helpText?: string;
  }>(),
  {
    modelValue: undefined,
    allowNegative: false,
    precision: 0,
    formatOnInput: true,
    disabled: false,
    helpText: undefined,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: number | undefined): void;
}>();

const { formatValue, parseValue, handleBeforeInput } = useNumberInput({
  allowNegative: toRef(props, 'allowNegative'),
  precision: toRef(props, 'precision'),
  formatOnInput: toRef(props, 'formatOnInput'),
});

const data = reactive({
  numberValue: props.modelValue,
  inputValue: formatValue(props.modelValue),
});

let inputValueChanged = false;

function updateModelValue(value: string | undefined) {
  if (value === data.inputValue) {
    return;
  }
  data.inputValue = value ?? '';
  inputValueChanged = true;

  const newNumberValue = value ? parseValue(value, true) : undefined;
  // note: use Object.is to allow comparison of NaN values
  if (!Object.is(newNumberValue, data.numberValue)) {
    data.numberValue = newNumberValue;
    emit('update:modelValue', data.numberValue);
  }
}

function handleFocusOut() {
  // apply formatting unless NaN
  if (inputValueChanged && (data.numberValue === undefined || !isNaN(data.numberValue))) {
    data.inputValue = formatValue(data.numberValue);
    inputValueChanged = false;
  }
}

watch(
  () => props.modelValue,
  (value) => {
    // note: use Object.is to allow comparison of NaN values
    if (!Object.is(value, data.numberValue)) {
      data.numberValue = value;
      data.inputValue = formatValue(value);
      inputValueChanged = false;
    }
  },
);
</script>

<template>
  <BxInput
    :disabled="disabled"
    :model-value="data.inputValue"
    :inputmode="precision === 0 ? 'numeric' : 'decimal'"
    :on-before-input="handleBeforeInput"
    :help-text="helpText"
    @update:model-value="updateModelValue($event)"
    @focusout="handleFocusOut"
  >
    <template v-if="$slots.trailing" #trailing>
      <slot name="trailing" :has-input="!!data.inputValue"></slot>
    </template>
  </BxInput>
</template>
