<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 1158">
      <rect id="Rectangle 145" x="0.5" y="0.5" width="7.09992" height="7.1" rx="3.54996" stroke="currentColor" />
      <rect id="Rectangle 148" x="0.5" y="9.5" width="7.99991" height="8" rx="2.5" stroke="currentColor" />
      <rect id="Rectangle 149" x="9.5" y="0.5" width="7.99991" height="8" rx="2.5" stroke="currentColor" />
      <rect
        id="Rectangle 147"
        x="10.3998"
        y="10.3984"
        width="7.09992"
        height="7.1"
        rx="3.54996"
        stroke="currentColor"
      />
    </g>
  </svg>
</template>
