<script setup lang="ts">
import { computed } from 'vue';
import { useForm } from 'vee-validate';
import { useAsyncState } from '@vueuse/core';
import type { AdditionalFundsRequiredEntry, LoanDetails, LoanDetailsEntry } from '@/types';
import { brokerLoanDetailsSchema } from '@/validation';
import {
  LoanPeriod,
  loanPeriodOptions,
  LoanType,
  AdditionalFundsPurpose,
  AdditionalFundsPurposeOptions,
} from '@/helpers/const';
import dayjs from '@/helpers/dayjs';
import { useBrokerLoanAppProcess } from '@/helpers/useBrokerLoanAppProcess';
import { useCalcApi } from '@/helpers/useCalcApi';
import { formatCurrency, snakeCase } from '@/helpers/format';
import { onBeforeMount, ref } from 'vue';
import type { AdditionalFundsRequiredDto, ListAdditionalFundsPurposes } from '@/api/backend/public';
import api from '@/api';
import { defaultAdditionalFundRequired } from '@/helpers/builder';

const { state, loanApp, saveAndGoNext, goBack } = useBrokerLoanAppProcess();
const DEFAULT_BROKER_FEE = 0.25;

type LoanDetailsEntryWithAdditionalRequiredFundsEntry = LoanDetailsEntry & {
  additionalFundsRequiredEntry: AdditionalFundsRequiredEntry;
};

const {
  meta,
  submitCount,
  values,
  handleSubmit: validateSubmit,
  resetForm,
} = useForm<LoanDetailsEntryWithAdditionalRequiredFundsEntry>({
  validationSchema: brokerLoanDetailsSchema,
  initialValues: { ...loanApp.value.loanDetails, additionalFundsRequiredEntry: loanApp.value.additionalFundsRequired },
  validateOnMount: false,
});

resetForm({
  values: {
    ...loanApp.value.loanDetails,
    brokerFeePercentage: loanApp.value.loanDetails?.brokerFeePercentage ?? DEFAULT_BROKER_FEE,
    loanType: loanApp.value.loanType ?? LoanType.BRIDGING,
  },
});

const calc = useCalcApi();

const { state: loanAmount, isReady } = useAsyncState(calc.getLoanAmount(loanApp.value), 0);

const calcLoanAmount = computed(() => {
  const { savingsAmount = 0, giftedAmount = 0 } = values;
  return giftedAmount <= savingsAmount ? loanAmount.value - savingsAmount + giftedAmount : 'invalid amount';
});

const brokerFeeAmount = computed(() => {
  if (loanApp.value.userType !== 'Broker' || !values.brokerFeePercentage) {
    return 0;
  }
  const loanAmount = typeof calcLoanAmount.value === 'number' ? calcLoanAmount.value : 0;
  const totalLoanAmount = loanAmount + (values.additionalFundsRequiredEntry?.fundsRequiredAmount ?? 0);
  return Math.round((totalLoanAmount * values.brokerFeePercentage) / 100);
});

const isBroker = computed(() => loanApp.value.userType === 'Broker');
const isBridgingLoan = computed(() => loanApp.value.loanType === LoanType.BRIDGING);

const handleSubmit = validateSubmit(async (values) => {
  const additionalFundsPurposeId = listAdditionalFundsPurposes?.find(
    (item) => snakeCase(item.title) == values.additionalFundsRequiredEntry.reasonForFundsOption,
  )?.id;

  const additionalFundsRequired: AdditionalFundsRequiredDto = Object.assign(defaultAdditionalFundRequired(), {
    description: values.additionalFundsRequiredEntry.description ?? '',
    fundsRequiredDate: values.additionalFundsRequiredEntry.fundsRequiredDate,
    fundsRequiredAmount: values.additionalFundsRequiredEntry.fundsRequiredAmount ?? 0,
    additionalFundsPurposeId: additionalFundsPurposeId,
  });

  const loanDetails: LoanDetails = {
    savingsAmount: values.savingsAmount ?? 0,
    giftedAmount: values.giftedAmount ?? 0,
    reasonDescription: values.reasonDescription ?? '',
    brokerFeePercentage: values.brokerFeePercentage ?? 0,
    borrowAmount: values.borrowAmount ?? 0,
    loanPeriod: values.loanPeriod,
    loanDate: values.loanDate,
    calcLoanAmount: loanAmount.value,
  };

  if (isBridgingLoan.value) {
    loanDetails.loanPeriod = undefined;
    loanDetails.loanDate = undefined;
  } else {
    if (loanDetails.loanPeriod !== LoanPeriod.EXACT_DATE) {
      loanDetails.loanDate = dayjs().add(Number(loanDetails.loanPeriod), 'month').format('YYYY-MM-DD');
    }
  }

  loanDetails.calcLoanAmount =
    additionalFundsRequired.fundsRequiredAmount === 0 ? 0 : await calc.getLoanAmount(loanApp.value);

  saveAndGoNext({ loanDetails, additionalFundsRequired });
});

const isListReady = ref(false);
let listAdditionalFundsPurposes: ListAdditionalFundsPurposes = [];
onBeforeMount(async () => {
  isListReady.value = false;
  try {
    listAdditionalFundsPurposes = await api.backend.public.listAdditionalFundsPurposes();
  } finally {
    isListReady.value = true;
  }
});
</script>

<template>
  <div>
    <div class="mb-8 pb-2">
      <h2 class="text-2xl font-medium">
        <template v-if="isBridgingLoan">
          Will your client make <BxHighlight>additional financial contributions</BxHighlight> to the purchase of the
          property?
        </template>
        <template v-else> How much do you want to <BxHighlight>borrow</BxHighlight>? </template>
      </h2>
    </div>

    <form @submit.prevent="handleSubmit">
      <div v-if="isBridgingLoan">
        <BxField v-slot="{ field }" name="savingsAmount">
          <BxNumberInput
            v-bind="field"
            id="additional-funds-amount"
            label="Additional contributions amount"
            label-type="floating"
            leading-symbol="$"
            :allow-negative="false"
            class="form-control"
            help-text="leave blank if there are no additional contributions to be made."
          />
        </BxField>

        <p class="mt-2 text-xs">
          * Do not include the deposit to be paid on your new property or the proceeds from the sale of their existing
          property
        </p>

        <template v-if="values.savingsAmount">
          <h3 class="text-md mb-4 mt-6 font-medium">How much of these funds have been gifted to you?</h3>
          <BxField v-slot="{ field }" name="giftedAmount">
            <BxNumberInput
              v-bind="field"
              id="additional-funds-gifted-amount"
              label="Gifted amount"
              label-type="floating"
              leading-symbol="$"
              :allow-negative="false"
              class="form-control"
            />
          </BxField>
        </template>
      </div>

      <div v-if="isBroker && isBridgingLoan" class="my-8">
        <p class="font-medium">The minimum loan amount required to complete the purchase is:</p>
        <p v-if="typeof calcLoanAmount === 'number'" class="my-2 text-2xl font-medium text-bridgit-vibrantGreen">
          <template v-if="isReady">{{ formatCurrency(calcLoanAmount) }}</template>
          <BxSpinner v-else />
        </p>
        <p v-else class="my-2 text-2xl font-medium text-rose-500">Invalid amount</p>
      </div>

      <template v-if="isBroker || !isBridgingLoan">
        <h3 class="text-md mb-1 mt-10 font-medium">
          {{
            isBridgingLoan
              ? 'Are there any additional funds required for other borrowing purposes?'
              : 'How much do you want to borrow?'
          }}
        </h3>
        <p class="mb-3 mt-1 text-xs">
          * Additional borrowing purposes within policy include cosmetic improvements on the property being sold, or
          financial consolidation.
        </p>

        <BxField v-slot="{ field }" name="additionalFundsRequiredEntry.isAdditionalFundsRequired">
          <BxRadio
            id="additional-funds-required"
            v-bind="field"
            label=""
            label-type="default"
            class="form-control"
            boolean
          />
        </BxField>
        <BxField
          v-if="values.additionalFundsRequiredEntry.isAdditionalFundsRequired"
          v-slot="{ field }"
          name="additionalFundsRequiredEntry.reasonForFundsOption"
        >
          <BxSelect
            v-bind="field"
            label="Purpose for the additional funds"
            label-type="floating"
            placeholder="Purpose for the additional funds"
            :list-items="AdditionalFundsPurposeOptions"
            :truncate="false"
            class="form-control"
          />
        </BxField>
        <BxField
          v-if="values.additionalFundsRequiredEntry.reasonForFundsOption"
          v-slot="{ field }"
          name="additionalFundsRequiredEntry.fundsRequiredAmount"
        >
          <BxNumberInput
            v-bind="field"
            label="Funds required"
            label-type="floating"
            leading-symbol="$"
            :allow-negative="false"
            class="form-control"
          />
        </BxField>
        <BxField
          v-if="values.additionalFundsRequiredEntry.reasonForFundsOption"
          v-slot="{ field }"
          name="additionalFundsRequiredEntry.description"
        >
          <p
            v-if="
              values.additionalFundsRequiredEntry.reasonForFundsOption ===
              AdditionalFundsPurpose.FINANCIAL_CONSOLIDATION
            "
            class="mb-2 mt-4"
          >
            Explain the financial consolidation requirements
          </p>
          <p
            v-if="
              values.additionalFundsRequiredEntry.reasonForFundsOption ===
              AdditionalFundsPurpose.COSMETIC_IMPROVEMENTS_BEFORE_SELLING
            "
            class="mb-2 mt-4"
          >
            Explain the cosmetic improvements being done
          </p>
          <p
            v-if="values.additionalFundsRequiredEntry.reasonForFundsOption === AdditionalFundsPurpose.OTHER"
            class="mb-2 mt-4"
          >
            Provide the details of the additional borrowing purposes
          </p>
          <BxTextarea v-bind="field" class="form-control" />
        </BxField>
      </template>

      <div v-if="isBroker && false" class="form-control flex items-center gap-8">
        <BxField v-slot="{ field }" name="brokerFeePercentage">
          <BxNumberInput
            v-bind="field"
            label="Broker fee"
            label-type="floating"
            placeholder="0.00"
            :precision="2"
            :allow-negative="false"
            class="form-control w-40"
          >
            <template #trailing><div class="mx-3">%</div></template>
          </BxNumberInput>
        </BxField>
        <div class="relative w-40 rounded border border-solid border-gray-300 px-3 py-2">
          <label
            class="absolute -top-2 left-2 -mt-px inline-block bg-bridgit-paleBlue px-1 text-xs font-medium text-gray-900"
            >Broker fee amount</label
          >
          <div class="">{{ formatCurrency(brokerFeeAmount) }}</div>
        </div>
      </div>

      <template v-if="!isBridgingLoan">
        <BxField v-slot="{ field }" name="loanPeriod">
          <BxRadio
            class="form-control"
            v-bind="field"
            :options="loanPeriodOptions"
            label="When do you need the funds?"
            label-type="default"
            block
          />
        </BxField>
        <BxField v-if="values.loanPeriod == LoanPeriod.EXACT_DATE" v-slot="{ field }" name="loanDate">
          <BxDatePicker v-bind="field" class="form-control" label="" placeholder="DD/MM/YYYY" />
        </BxField>
      </template>

      <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
        Please review the error messages above.
      </BxErrorPanel>
      <div class="mt-10">
        <BxButton id="loan-details-continue" variant="primary" :loading="!isListReady" type="submit"
          >Save and continue</BxButton
        >
        <BxButton v-if="state.nextEvents.includes('BACK')" variant="link" class="ml-4" @click="goBack()">Back</BxButton>
      </div>
    </form>
  </div>
</template>
