import { createApp } from 'vue';
// import { createPinia } from "pinia";
import router from '@/router';
import App from '@/App.vue';
import LogRocket from 'logrocket';
// import "focus-visible";
import '@/assets/scss/main.scss';

if (window.location.host === 'apply.bridgit.com.au') {
  LogRocket.init('vuowhw/customer-application', {
    release: import.meta.env.VITE_APP_VERSION,
  });
}

// create app
const app = createApp(App);

// setup services
// app.use(createPinia());
app.use(router);

// Register common UI components.
const components = import.meta.glob('./components/common/**/*.vue', { eager: true });
Object.entries(components).forEach(([path, definition]) => {
  const componentName = path
    .split('/')
    .pop()
    ?.replace(/\.\w+$/, '');
  if (componentName) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    app.component(componentName, (definition as any).default);
  }
});

// mount app
router.isReady().then(() => {
  app.mount('#app');
});
