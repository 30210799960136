<script setup lang="ts">
import { useRequestIncomeExpenseProcess } from '@/helpers/useRequestIncomeExpenseProcess';
import SummaryDetails from './modules/Summary/SummaryDetails.vue';

const { saveAndGoNext } = useRequestIncomeExpenseProcess();

const saveAndContinue = async () => {
  // if (await validateIncome()) {
  saveAndGoNext({
    // financials: financials.value,
  });
  // }
};
</script>

<template>
  <div>
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">
        <BxHighlight>Review summary</BxHighlight>
      </h2>
    </div>
    <div class="my-8">
      <SummaryDetails />
    </div>
    <div class="mt-12">
      <BxButton variant="primary" @click="saveAndContinue()">Next</BxButton>
    </div>
  </div>
</template>
