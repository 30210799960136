<script setup lang="ts">
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import type { Applicant } from '@/types';
import { computed, ref } from 'vue';

const { loanApp } = useLoanAppProcess();

const site = computed(() =>
  loanApp.value.userType === 'Broker'
    ? {
        label: 'Broker Portal',
        portalLink: import.meta.env.VITE_TECHLEND_BROKER_URL,
        scheduleLink: import.meta.env.VITE_BROKER_SCHEDULER_URL,
      }
    : {
        label: 'Customer Login',
        portalLink: import.meta.env.VITE_TECHLEND_FE_URL,
        scheduleLink: import.meta.env.VITE_CUSTOMER_SCHEDULER_URL,
      },
);

const applicants = ref<Partial<Applicant>[]>(
  loanApp.value.applicants.map((applicant) => {
    return {
      firstName: applicant.firstName,
      email: applicant.email,
    };
  }),
);
const userType = ref(loanApp.value.userType);
const actionType = ref(loanApp.value.actionType);
</script>

<template>
  <div class="w-full p-8">
    <div class="mb-4 mt-10 flex flex-col items-center pb-2">
      <div class="text-bridgit-vibrantGreen">
        <BxIcon name="reports" />
      </div>
      <h2 class="mt-6 text-center text-2xl font-bold">Thanks for completing the application</h2>
      <div class="instructions mt-8 text-center">
        <p>This is now being reviewed by our team and we'll be in touch to let you know your next steps shortly.</p>
        <template v-if="userType === 'Primary' && actionType === 'createNew' && applicants.length > 1">
          <p>
            We have sent an email to {{ applicants[1]?.firstName ?? '' }} at {{ applicants[1]?.email ?? '' }} for them
            to complete their declaration.
          </p>
          <p>Once this declaration has been submitted, we will then begin processing this application.</p>
        </template>
        <template v-else>
          <p>
            In the meantime, you'll be sent an email with instructions to access your portal where you can see how your
            approval is progressing in real time.
          </p>
        </template>
        <p>You can also schedule a call with one of our leading specialists to discuss next steps.</p>
      </div>
      <div class="mt-12 flex gap-8">
        <BxLinkButton variant="primary" :href="site.scheduleLink">Schedule a call</BxLinkButton>
        <BxLinkButton variant="primary" :href="site.portalLink">{{ site.label }}</BxLinkButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.instructions {
  p:not(:first-of-type) {
    @apply mt-6;
  }
}
</style>
