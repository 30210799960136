import type { ApplicationDto } from '@/api/backend/users/applications';
import { periodic } from '@/mappers/helpers';
import type { ApplicantLiability, CreditCardLiability, OtherLiability, VehicleLiability } from '@/types';
import type { ApiSchema, Institute } from '@/types/api';

type LiabilityCardDto = ApiSchema['LiabilityCardDto'] & {
  institution?: Institute | string;
  amountOwed?: number;
  creditLimit?: number;
  payoutAmount?: number;
};

const mapCreditCardLiability = (data: LiabilityCardDto): CreditCardLiability => {
  return {
    id: data.id ?? '',
    type: 'card',
    institution: (<Institute>data.institution)?.name || <string>data.institution || '',
    creditLimit: data.creditLimit ?? 0,
    amountOwing: data.amountOwed ?? 0,
    liabilityNumber: 0,
  };
};

type LiabilityVehicleDto = ApiSchema['LiabilityVehicleDto'] & {
  vehicleMake?: Institute | string;
  vehicleModel?: Institute | string;
  amountOwed?: number;
  liabilityLimit?: number;
  payoutAmount?: number;
  monthlyRepayment?: number;
};

const mapVehicleLiability = (data: LiabilityVehicleDto): VehicleLiability => {
  return {
    id: data.id ?? '',
    type: 'vehicle',
    make: (<Institute>data.vehicleMake)?.name || <string>data.vehicleMake || '',
    model: (<Institute>data.vehicleModel)?.name || <string>data.vehicleModel || '',
    yearBought: data.yearBought?.toString() ?? '',
    assetValue: data.assetValue ?? 0,
    liabilityLimit: data.liabilityLimitApplicationValue ?? 0,
    amountOwing: data.amountOwedApplicationValue ?? 0,
    periodicRepayment: periodic(data.monthlyRepayment, 'monthly'),
    liabilityNumber: 0,
  };
};

type LiabilityOtherDto = ApiSchema['LiabilityOtherDto'] & {
  amountOwed?: number;
  liabilityLimit?: number;
  payoutAmount?: number;
};

const mapOtherLiability = (data: LiabilityOtherDto): OtherLiability => {
  return {
    id: data.id ?? '',
    type: 'other',
    details: data.details ?? '',
    liabilityLimit: data.liabilityLimitApplicationValue ?? 0,
    amountOwing: data.amountOwedApplicationValue ?? 0,
    periodicRepayment: periodic(data.monthlyRepayment, 'monthly'),
    liabilityNumber: 0,
  };
};

export const liabilities = (applicantId: string, application: ApplicationDto): ApplicantLiability[] => {
  const cards: CreditCardLiability[] = (application.liabilitiesCards ?? [])
    .filter((l: LiabilityCardDto) => l.applicantId === applicantId)
    .map(mapCreditCardLiability);

  const vehicles: VehicleLiability[] = (application.liabilitiesVehicles ?? [])
    .filter((l: LiabilityVehicleDto) => l.applicantId === applicantId)
    .map(mapVehicleLiability);

  const others: OtherLiability[] = (application.liabilitiesOthers ?? [])
    .filter((l: LiabilityOtherDto) => l.applicantId === applicantId)
    .map(mapOtherLiability);

  return [...cards, ...vehicles, ...others];
};
