<template>
  <svg viewBox="0 0 30 30" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.4488 7.0421L22.9814 10.5663L15.9898 17.5579L11.954 13.5221"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M24.6298 19.8316C22.8677 23.3558 19.173 25.8 14.9667 25.8C8.99827 25.8 4.16669 20.9684 4.16669 15C4.16669 9.03158 8.99827 4.2 14.9667 4.2C15.933 4.2 16.8993 4.31368 17.8088 4.59789"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
