<template>
  <svg width="28" height="23" viewBox="0 0 28 23" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9624 20.2852C22.2986 20.2852 24.1924 18.3914 24.1924 16.0552C24.1924 13.719 22.2986 11.8252 19.9624 11.8252C17.6263 11.8252 15.7324 13.719 15.7324 16.0552C15.7324 18.3914 17.6263 20.2852 19.9624 20.2852Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M23.4346 18.4775L26.8846 20.8775" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M10.0922 12.62C13.232 12.62 15.7772 10.0747 15.7772 6.935C15.7772 3.79526 13.232 1.25 10.0922 1.25C6.95249 1.25 4.40723 3.79526 4.40723 6.935C4.40723 10.0747 6.95249 12.62 10.0922 12.62Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.60999 14.3379C3.56174 15.1649 2.71475 16.2189 2.13275 17.4205C1.55076 18.6222 1.24894 19.9402 1.25 21.2754H14.75"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
