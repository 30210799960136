import { EquityReleasePurposeOptions, LoanPeriod, LoanType } from '@/helpers/const';
import { formatCurrency } from '@/helpers/format';
import { yupNumber, yupObject, yupString } from '../../yup';

export const loanDetailsSchema = yupObject({
  loanType: yupString(),
  savingsAmount: yupNumber().min(0).label('Savings amount'),
  giftedAmount: yupNumber()
    .min(0)
    .when('savingsAmount', ([savingsAmount], schema) =>
      savingsAmount > 0
        ? schema
            .max(savingsAmount, `Gifted amount must be less than or equal to ${formatCurrency(savingsAmount)}`)
            .required()
        : schema,
    )
    .label('Gifted amount'),
  additionalFunds: yupNumber()
    .when('loanType', {
      is: LoanType.EQUITY_RELEASE,
      then: (schema) => schema.min(1, 'Funding must be greater than $0').required(),
    })
    .label('Funding amount'),
  fundingReason: yupString()
    .when(['loanType'], ([loanType], schema) =>
      loanType === LoanType.EQUITY_RELEASE
        ? schema.oneOf(
            EquityReleasePurposeOptions.filter((o) => o.eligible).map((o) => o.label),
            'This is not an eligible loan purpose',
          )
        : schema,
    )
    .when(['loanType', 'additionalFunds'], ([loanType, additionalFunds], schema) =>
      additionalFunds > 0 || loanType === LoanType.EQUITY_RELEASE ? schema.required() : schema,
    )
    .label('Reason for additional funding'),
  reasonDescription: yupString()
    .when('fundingReason', {
      is: 'Other',
      then: (schema) => schema.required(),
    })
    .label('Reason for additional funding'),
  brokerFeePercentage: yupNumber().min(0).max(0.25).label('Broker fee percentage').required(),
  loanPeriod: yupString()
    .when('loanType', { is: LoanType.EQUITY_RELEASE, then: (schema) => schema.required() })
    .label('Loan period'),
  loanDate: yupString().when('loanPeriod', { is: LoanPeriod.EXACT_DATE, then: (schema) => schema.required() }),
});
