import type { Dayjs, PluginFunc } from 'dayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/en-au';

dayjs.extend(customParseFormat);

export interface FormatDateOptions {
  twoDigitYearMax?: number;
}

declare module 'dayjs' {
  function parseDate(date: string, options?: FormatDateOptions): dayjs.Dayjs;
}

// change default string parsing with no format specified
// parse as D/M/YYYY instead of ISO8601
const format = /^(?<d>[0-3]?\d)\/(?<m>[01]?\d)\/(?<y>\d{2}|\d{4})$/;
const parseDatePlugin: PluginFunc<FormatDateOptions> = (option, c: typeof Dayjs, d: typeof dayjs) => {
  // set default options
  const _options = option || {};
  _options.twoDigitYearMax ??= 49;

  // parse date using strict D/M/YYYY | D/M/YY format
  d.parseDate = (date: string, options?: FormatDateOptions): Dayjs => {
    // validate basic format
    if (!date) {
      return dayjs(new Date(NaN));
    }
    const match = date.match(format);
    if (!match?.groups) {
      return dayjs(new Date(NaN));
    }

    // handle 2 digit year and remove leading 0 from day and month
    let year = +match.groups['y'];
    if (year < 100) {
      const twoDigitYearMax = options?.twoDigitYearMax ?? (_options.twoDigitYearMax as number);
      year = year <= twoDigitYearMax ? year + 2000 : year + 1900;
    }
    const parsedDate = `${+match.groups['d']}/${+match.groups['m']}/${year}`;
    return dayjs(parsedDate, 'D/M/YYYY', true);
  };
};

dayjs.extend(parseDatePlugin);

export default dayjs;
