import type { Address, Applicant, ApplicantEntry, PeriodicValue } from '@/types';
import { periodType } from './const';

const currencyFormatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  minimumFractionDigits: 0,
});
const numberFormatter = new Intl.NumberFormat('en-AU', { minimumFractionDigits: 0 });
const dateFormatter = new Intl.DateTimeFormat('en-AU');

export const formatCurrency = (val?: number) => currencyFormatter.format(val ?? 0);
export const formatNumber = (val?: number) => numberFormatter.format(val ?? 0);
export const formatDate = (val?: string) => dateFormatter.format(val ? new Date(val) : new Date());

export const formatAddress = (address?: Address) =>
  address
    ? [address.addressLine, address.addressLine2, address.locality, address.state + ' ' + address.postcode]
        .filter((x) => x)
        .join(', ')
    : '';

export const getFullName = ({ firstName, middleName, lastName }: Applicant | ApplicantEntry) =>
  [firstName, middleName, lastName].filter((name) => name).join(' ');

export const possessive = (noun?: string) => {
  if (noun) {
    const lastLetter = noun.slice(-1);
    return noun + (lastLetter === 's' ? "'" : "'s");
  } else return '';
};

export const formatPeriodicAmt = (val: PeriodicValue) =>
  formatCurrency(val.amount) + ' ' + periodType[val.frequency].label;

export const capitalise = (word?: string) => {
  const s = word?.trim() ?? '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const fromSnakeCase = (val: string | undefined | null) => {
  if (!val) return '';
  const sentence = val.replace(/_/g, ' ').trim();
  return (sentence.charAt(0).toUpperCase() + sentence.slice(1)).replace(' cos ', ' COS ');
};

export const snakeCase = (val: string | undefined | null) => {
  if (!val) return '';
  const withoutSpacesAndCommas = val.replace(/[\s,]+/g, '_');
  const withoutHyphens = withoutSpacesAndCommas.replace(/-/g, '_');
  const lowercase = withoutHyphens.toLowerCase();

  return lowercase;
};
