<script setup lang="ts">
import type { StampDutyStatus } from '@/api/enums';
import { computed } from 'vue';

const props = defineProps<{
  id: string;
  contractForSale?: boolean;
}>();

const stampDutyStatusOptions: Record<StampDutyStatus, string> = {
  ['at_settlement']: 'To be paid on settlement',
  ['prior_to_settlement']: 'To be paid prior to settlement',
  ['already_paid']: 'Already paid',
};

const validStampDutyPaymentOptions = computed(() =>
  Object.fromEntries(
    Object.entries(stampDutyStatusOptions).filter(([status]) => props.contractForSale || status !== 'already_paid'),
  ),
);
</script>
<template>
  <BxField v-slot="{ field }" name="stampDutyStatus">
    <BxRadio
      v-bind="field"
      :id="id + '-stamp-duty-paid'"
      :options="validStampDutyPaymentOptions"
      label="How will stamp duty be paid?"
      label-type="default"
      class="form-control incoming-margin-top"
      block
    />
  </BxField>
</template>
