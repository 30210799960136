<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useForm } from 'vee-validate';
import { liabilitySchema } from '@/validation';
import { LiabilityType } from '@/helpers/const';
import type { LiabilityDataEntry } from '@/types';
import { TrashIcon } from '@heroicons/vue/24/outline';

const props = withDefaults(
  defineProps<{
    applicantId: string;
    liabilityNumber: number;
    modelValue: LiabilityDataEntry;
  }>(),
  {},
);

const emit = defineEmits<{
  (e: 'update:modelValue', v: LiabilityDataEntry): void;
  (e: 'save'): void;
  (e: 'remove', liabiltyNumber: number): void;
}>();

const liabilityOptions = {
  [LiabilityType.CARD]: { label: 'Credit/store card', description: 'Credit card' },
  [LiabilityType.VEHICLE]: { label: 'Motor vehicle loan', description: 'Vehicle' },
  [LiabilityType.OTHER]: { label: 'Personal loan/other liability', description: 'Other liability' },
};

const newItem = ref(!props.modelValue.type);
const showEntry = ref(!props.modelValue.type);

const { values, meta, submitCount, handleSubmit, resetForm } = useForm<LiabilityDataEntry>({
  validationSchema: liabilitySchema,
  validateOnMount: false,
});

const cardTitle = computed(() => {
  let title;
  if (values.type) {
    let description = '';
    switch (values.type) {
      case LiabilityType.CARD:
        description = values.institution ?? '';
        break;
      case LiabilityType.VEHICLE:
        description = values.make + ' ' + values.model;
        break;
      case LiabilityType.OTHER:
        description = values.details ?? '';
    }
    title = newItem.value
      ? liabilityOptions[values.type].label
      : `${liabilityOptions[values.type].description}: ${description}`;
  } else {
    title = 'Select liability';
  }
  return title;
});

const update = handleSubmit(
  (values) => {
    showEntry.value = false;
    newItem.value = false;
    emit('update:modelValue', values);
    return true;
  },
  () => false,
);

const cancel = () => {
  resetForm();
  showEntry.value = false;
};

const editItem = () => {
  showEntry.value = true;
};

watch(
  () => props.modelValue,
  (data) => {
    const values = { ...data };
    resetForm({ values });
  },
  { immediate: true, deep: true },
);

defineExpose({ update, cancel });

const showModal = ref(false);
const openModal = () => {
  showModal.value = true;
};
const removeItem = () => {
  showModal.value = false;
  emit('remove', props.modelValue.liabilityNumber || 0);
};
</script>

<template>
  <div>
    <BxExpander v-model="showEntry" separator>
      <template #header>
        <div class="py-5 pl-3 sm:pl-4">
          <div class="flex flex-wrap items-center gap-3 sm:flex-nowrap">
            <BxIcon class="text-rose-600" name="expense" />
            <h3 class="text-lg font-medium leading-6 text-gray-900">
              {{ cardTitle }}
            </h3>
            <div class="flex-grow"></div>
            <div v-if="!showEntry" class="flex-shrink-0">
              <BxButton v-if="!newItem" variant="secondary" @click="editItem()">Edit</BxButton>
              <BxButton variant="link" class="ml-4" @click="openModal()">Remove</BxButton>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div class="p-6">
          <BxField v-slot="{ field }" name="type">
            <BxRadio
              v-bind="field"
              :id="applicantId + '-liability-' + liabilityNumber + '-type'"
              :options="liabilityOptions"
              label="Do you have any of the following liabilities?"
              label-type="default"
              block
              class="form-control mb-12"
            />
          </BxField>
          <div v-if="!values.type"></div>
          <template v-else-if="values.type === LiabilityType.CARD">
            <BxField v-slot="{ field }" name="institution">
              <BxInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-card-institution'"
                label="Name of institution"
                label-type="floating"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="creditLimit">
              <BxNumberInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-card-credit-limit'"
                label="Credit limit"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="amountOwing">
              <BxNumberInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-card-amount-owing'"
                label="Amount owing"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
          </template>
          <template v-else-if="values.type === LiabilityType.VEHICLE">
            <BxField v-slot="{ field }" name="make">
              <BxInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-vehicle-make'"
                label="Vehicle make"
                label-type="floating"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="model">
              <BxInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-vehicle-model'"
                label="Vehicle model"
                label-type="floating"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="yearBought">
              <BxInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-vehicle-year-bought'"
                label="Year bought"
                label-type="floating"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="assetValue">
              <BxNumberInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-vehicle-asset-value'"
                label="Asset value"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="liabilityLimit">
              <BxNumberInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-vehicle-liability-limit'"
                label="Liability limit"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="amountOwing">
              <BxNumberInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-vehicle-amount-owing'"
                label="Amount owing"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="periodicRepayment">
              <BxPeriodicAmount
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-vehicle-periodic-repayment'"
                label="Periodic repayment"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
          </template>
          <template v-else>
            <BxField v-slot="{ field }" name="details">
              <BxInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-other-liability-description'"
                label="Describe your liability"
                label-type="floating"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="liabilityLimit">
              <BxNumberInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-other-liability-limit'"
                label="Liability limit"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="amountOwing">
              <BxNumberInput
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-other-amount-owing'"
                label="Amount owing"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
            <BxField v-slot="{ field }" name="periodicRepayment">
              <BxPeriodicAmount
                v-bind="field"
                :id="applicantId + '-liability-' + liabilityNumber + '-other-periodic-repayment'"
                label="Periodic repayment"
                label-type="floating"
                leading-symbol="$"
                class="form-control"
              />
            </BxField>
          </template>
          <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
            Please review the error messages above.
          </BxErrorPanel>
          <div class="mt-10 flex-shrink-0">
            <BxButton
              :id="applicantId + '-liability-' + liabilityNumber + '-save'"
              variant="secondary"
              @click="update()"
              >Save</BxButton
            >
            <BxButton variant="link" class="ml-4" @click="newItem ? openModal() : cancel()">Cancel</BxButton>
          </div>
        </div>
      </template>
    </BxExpander>
    <BxModal
      :open="showModal"
      :icon="TrashIcon"
      title="Remove liability"
      confirm-label="Remove"
      cancel-label="Cancel"
      @close="showModal = false"
      @confirm="removeItem()"
    >
      Do you want to remove
      <span class="text-bridgit-green">{{ newItem ? 'the new liability' : cardTitle }}</span
      >?
    </BxModal>
  </div>
</template>
