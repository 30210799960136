<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useTemplateRefsList } from '@vueuse/core';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import { defaultOutgoingProperty } from '@/helpers/builder';
import type { OutgoingProperty, OutgoingPropertyEntry } from '@/types';
import { PlusCircleIcon } from '@heroicons/vue/20/solid';
import OutgoingPropertyCard from './modules/OutgoingProperties/OutgoingPropertyCard.vue';

const { loanApp, saveAndGoNext, goBack } = useLoanAppProcess();
const properties = ref<{ item: OutgoingPropertyEntry }[]>(loanApp.value.outgoingProperties.map((item) => ({ item })));
const propertyRefs = useTemplateRefsList<InstanceType<typeof OutgoingPropertyCard>>();
let propertyCounter = 0;

const validateItems = async () => {
  let isValid = false;
  for (let i = 0; i < properties.value.length; i++) {
    isValid = await propertyRefs.value[i].update();
  }
  return isValid;
};

const addItem = async () => {
  if (await validateItems()) {
    const id = `outgoing-property-${propertyCounter++}`;
    properties.value.push({ item: { id } });
  }
};

const isSingle = computed(() => properties.value.length === 1);
const removeItem = (id: string) => {
  properties.value = properties.value.filter((p) => p.item.id !== id);
};

const saveAndContinue = async () => {
  if (await validateItems()) {
    const outgoingProperties: OutgoingProperty[] = properties.value.map((p) =>
      Object.assign(defaultOutgoingProperty(), p.item),
    );
    saveAndGoNext({ outgoingProperties });
  }
};

watch(
  properties,
  (val) => {
    if (val.length === 0) {
      const id = `outgoing-property-${propertyCounter++}`;
      properties.value.push({ item: { id } });
    }
  },
  { immediate: true },
);
</script>

<template>
  <div class="w-full">
    <div class="mb-8 pb-2">
      <h2 class="text-2xl font-medium">
        Tell us about the properties you're
        <BxHighlight>selling</BxHighlight>?
      </h2>
      <p v-if="isSingle" class="mt-2">
        Enter the details for the first property below, you can add additional properties by clicking 'Add another
        property'.
      </p>
    </div>

    <template v-for="property in properties" :key="property.id">
      <OutgoingPropertyCard
        :ref="propertyRefs.set"
        v-model="property.item"
        class="mb-4"
        is-multiple
        @remove="removeItem"
      />
    </template>

    <div class="mt-6">
      <BxButton id="outgoing-properties-add" variant="tertiary" @click="addItem()">
        <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Add another property
      </BxButton>
    </div>

    <div class="mt-6">
      <BxButton id="outgoing-properties-continue" variant="primary" @click="saveAndContinue()"
        >Save and continue</BxButton
      >
      <BxButton variant="link" class="ml-4" @click="goBack()">Back</BxButton>
    </div>
  </div>
</template>
