import { AdditionalFundsPurpose } from '@/helpers/const';
import { yupNumber, yupObject, yupString, yupAddress, yupBoolean, yupMixed } from '../../yup';
import {
  DepositType as removeHardCodedDepositType,
  StampDutyStatus as removeHardcodedStampDutyStatus,
} from '@/helpers/const';
import type { DepositPaidType, StampDutyStatus } from '@/api/enums';

export const loanPurposeSchema = yupObject({
  reasonForFundsOption: yupString().required().label('Loan purpose'),
  propertyAddress: yupAddress()
    .when('reasonForFundsOption', ([reasonForFundsOption], schema) =>
      reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME ||
      reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND
        ? schema.required()
        : schema,
    )
    .label('Property address'),
  settlementDate: yupString()
    .when(
      ['reasonForFundsOption', 'isThereContractForSale'],
      ([reasonForFundsOption, isThereContractForSale], schema) =>
        reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND ||
        (reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME &&
          isThereContractForSale !== undefined)
          ? schema.isoDate().required()
          : schema,
    )
    .label('Settlement date'),
  purchasePrice: yupNumber()
    .min(0)
    .when(
      ['reasonForFundsOption', 'isThereContractForSale'],
      ([reasonForFundsOption, isThereContractForSale], schema) =>
        reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND ||
        (reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME &&
          isThereContractForSale !== undefined)
          ? schema.required()
          : schema,
    )
    .label('Purchase price'),
  depositAmount: yupNumber()
    .min(0)
    .when(
      ['reasonForFundsOption', 'isThereContractForSale'],
      ([reasonForFundsOption, isThereContractForSale], schema) =>
        reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND ||
        (reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME &&
          isThereContractForSale !== undefined)
          ? schema.required()
          : schema,
    )
    .label('Deposit amount'),
  depositType: yupMixed<DepositPaidType>()
    .when('reasonForFundsOption', ([reasonForFundsOption], schema) =>
      reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND ||
      reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME
        ? schema.oneOf(Object.values(removeHardCodedDepositType)).required()
        : schema,
    )
    .label('Deposit payment method'),
  stampDutyStatus: yupMixed<StampDutyStatus>()
    .when('reasonForFundsOption', ([reasonForFundsOption], schema) =>
      reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND
        ? schema.oneOf(Object.values(removeHardcodedStampDutyStatus)).required()
        : schema,
    )
    .label('Stamp duty response'),
  fundsRequiredAmount: yupNumber()
    .min(0)
    .when('reasonForFundsOption', ([reasonForFundsOption], schema) =>
      reasonForFundsOption &&
      reasonForFundsOption !== AdditionalFundsPurpose.PURCHASE_VACANT_LAND &&
      reasonForFundsOption !== AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME
        ? schema.required()
        : schema,
    )
    .label('Funds required amoun'),
  fundsRequiredDate: yupString()
    .when('reasonForFundsOption', ([reasonForFundsOption], schema) =>
      reasonForFundsOption !== AdditionalFundsPurpose.PURCHASE_VACANT_LAND &&
      reasonForFundsOption !== AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME
        ? schema.required().isoDate()
        : schema,
    )
    .label('Funds required by'),
  description: yupString().when('reasonForFundsOption', ([reasonForFundsOption], schema) =>
    reasonForFundsOption === AdditionalFundsPurpose.COSMETIC_IMPROVEMENTS_BEFORE_SELLING ||
    reasonForFundsOption === AdditionalFundsPurpose.OTHER
      ? schema
          .required()
          .label(
            reasonForFundsOption === AdditionalFundsPurpose.COSMETIC_IMPROVEMENTS_BEFORE_SELLING
              ? 'Cosmetic improvement being done details'
              : 'Details of the additional borrowing purposes',
          )
      : schema,
  ),
  contributingFundsTowardsPurchase: yupBoolean()
    .when('reasonForFundsOption', ([reasonForFundsOption], schema) =>
      reasonForFundsOption === AdditionalFundsPurpose.OTHER ||
      reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME ||
      reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND
        ? schema.required()
        : schema,
    )
    .label('Contributing funds towards the purchase'),
  fundsToContributeAmount: yupNumber()
    .min(0)
    .when('contributingFundsTowardsPurchase', ([contributingFundsTowardsPurchase], schema) =>
      contributingFundsTowardsPurchase ? schema.required() : schema,
    )
    .label('Funds to contribute amount'),
  isThereContractForSale: yupBoolean()
    .when('reasonForFundsOption', ([reasonForFundsOption], schema) =>
      reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_RETIREMENT_LAND_LEASE_OR_COMMUNITY_HOME ||
      reasonForFundsOption === AdditionalFundsPurpose.PURCHASE_VACANT_LAND
        ? schema.required()
        : schema,
    )
    .label('Contract for sale'),
});
