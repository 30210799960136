import { POST, setParams } from '../../_http';
import type { operations } from '@/types/schemas/api.main';
type controller = operations['PublicBackupApplicationController_post'];

export type BackupApplicationQuery = controller['parameters']['query'];
export type BackupApplicationResponse = controller['responses']['200']['content']['application/json'];

export const backupApplication = POST('/public/backup', setParams<BackupApplicationQuery>)<
  undefined,
  BackupApplicationResponse
>;
