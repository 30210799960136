import type { operations } from '@/types/schemas/api.main';
import { POST } from '../../_http';
type controller = operations['CustomerRegisterVerifyOtpController_post'];

export type CustomerRegistrationVerifyOtpBody = controller['requestBody']['content']['application/json'];
export type CustomerRegistrationVerifyOtpResponse = controller['responses']['200']['content']['application/json'];

export const verifyOtp = POST('/users/register/verify-otp')<
  CustomerRegistrationVerifyOtpBody,
  CustomerRegistrationVerifyOtpResponse
>;
