<template>
  <svg viewBox="0 0 30 30" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.81921 23.0041C12.5724 26.732 18.6574 26.7319 22.4106 23.0041C26.1637 19.2762 26.1637 13.2322 22.4106 9.50435C18.6574 5.77649 12.5724 5.77646 8.81921 9.50428"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6935 13.3924L14.6551 17.2093L20.4211 16.256"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M4.08301 12.4373H6.966" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.08301 20.0747H6.966" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.20001 16.256H6.00619" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M25.2273 6.71021L22.4017 9.49714" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M24.2657 4.79999L27.1487 7.66354" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M28.1103 14.3458V17.2093" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
