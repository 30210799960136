import dayjs from '@/helpers/dayjs';
import { yupAddress, yupBoolean, yupMixed, yupNumber, yupObject, yupPeriodicValue, yupString } from '../../yup';
import type { DepositTypeEnum, IncomingProperty, PropertyTypeEnum, StampDutyStatusEnum } from '@/types';
import { DepositType, StampDutyStatus, PropertyType } from '@/helpers/const';

const singeSecurityRequired = [PropertyType.RETIREMENT_LAND_OR_COMMUNITY_HOME, PropertyType.VACANT_LAND];
export const brokerIncomingPropertySchema = yupObject<IncomingProperty>({
  propertyType: yupMixed<PropertyTypeEnum>().required().label('Property type'),
  address: yupAddress()
    .when('propertyType', ([propertyType], schema) =>
      singeSecurityRequired.includes(propertyType) ? schema : schema.required(),
    )
    .label('Property address'),
  propertyPurpose: yupString()
    .when('propertyType', ([propertyType], schema) =>
      singeSecurityRequired.includes(propertyType) ? schema : schema.required(),
    )
    .label('Property purpose'),
  contractForSale: yupBoolean()
    .when('propertyType', ([propertyType], schema) =>
      singeSecurityRequired.includes(propertyType) ? schema : schema.required(),
    )
    .label('Contract for sale response'),
  settlementDate: yupString()
    .when('propertyType', ([propertyType], schema) =>
      singeSecurityRequired.includes(propertyType)
        ? schema
        : schema
            .required()
            .isoDate()
            .test('validDate', '${path} must be within 12 months', (value) => {
              const date = dayjs(value);
              return date.isAfter(dayjs(), 'day') && date.isBefore(dayjs().add(1, 'year'), 'day');
            }),
    )
    .label('Purchase date'),
  receiveRentalIncome: yupBoolean()
    .when('propertyType', ([propertyType], schema) =>
      singeSecurityRequired.includes(propertyType) ? schema : schema.required(),
    )
    .label('Rental income response'),
  rentalIncome: yupPeriodicValue(yupNumber().required())
    .when('receiveRentalIncome', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Expected rental income'),
  purchaseAmount: yupNumber()
    .when('propertyType', ([propertyType], schema) =>
      singeSecurityRequired.includes(propertyType) ? schema : schema.required(),
    )
    .label('Purchase price'),
  depositAmount: yupNumber()
    .when('purchaseAmount', ([purchaseAmount], schema) =>
      schema.max(purchaseAmount, `The deposit cannot be more than the purchase price`),
    )
    .when('propertyType', ([propertyType], schema) =>
      singeSecurityRequired.includes(propertyType) ? schema : schema.required(),
    )
    .label('Deposit amount'),
  depositType: yupMixed<DepositTypeEnum>()
    .oneOf(Object.values(DepositType))
    .when('propertyType', ([propertyType], schema) =>
      singeSecurityRequired.includes(propertyType) ? schema : schema.required(),
    )
    .label('Deposit payment method'),
  stampDutyStatus: yupMixed<StampDutyStatusEnum>()
    .when('propertyType', ([propertyType], schema) =>
      singeSecurityRequired.includes(propertyType)
        ? schema
        : schema
            .required()
            .oneOf(Object.values(StampDutyStatus))
            // already_paid is only valid if contract for sale exists
            .when('contractForSale', {
              is: true,
              then: (schema) => schema.oneOf(Object.values(StampDutyStatus)),
              otherwise: (schema) =>
                schema.oneOf(Object.values(StampDutyStatus).filter((item) => item !== StampDutyStatus.ALREADY_PAID)),
            }),
    )
    .label('Stamp duty response'),
});
