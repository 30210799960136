<script setup lang="ts">
import api from '@/api';
import { initDeclarationMachine } from '@/helpers/loanDeclarationAppMachine';
import { useApplicationApi } from '@/helpers/useApplicationApi';
import { useDeclarationProcess } from '@/helpers/useDeclarationProcess';
import {
  DeclarationProcessStep,
  DeclarationProcessStepNames,
  StepStatus,
  type DeclarationProcessStatus,
  type LoanApplication,
} from '@/types';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import { onBeforeMount, ref } from 'vue';
import SummaryDetails from './modules/Summary/SummaryDetails.vue';
import { AppValues } from '@/helpers/const';

const { saveAndGoNext } = useDeclarationProcess();
const applicationApi = useApplicationApi();
const declarationProcess = useDeclarationProcess();
const isReady = ref(false);
const showModal = ref(false);
const modalInfo = ref({
  title: '',
  line1: '',
  line2: '',
});

onBeforeMount(async () => {
  const result = await applicationApi.retrieveApplication();
  const authorisedUser = await api.backend.users.user.currentUser();
  if (result.success && authorisedUser.data.applicant) {
    const newState = initDeclarationMachine({
      actionType: 'updateExisting',
      userType: 'Secondary',
      application: result.value || ({} as LoanApplication),
    });
    newState.authorisedUser = authorisedUser.data.applicant;
    newState.current = DeclarationProcessStep.Summary;
    newState.status = Object.fromEntries(
      DeclarationProcessStepNames.map((step) => [
        step,
        step === DeclarationProcessStep.Summary ? StepStatus.Ready : StepStatus.Incomplete,
      ]),
    ) as DeclarationProcessStatus;
    newState.loanApp = result.value || ({} as LoanApplication);
    declarationProcess.send({ type: 'RELOAD', state: newState });
  } else {
    // const error = (<any>result).error;
    if (result.error === 'expired-link') {
      modalInfo.value = {
        title: 'Loan application link has expired',
        line1: 'Thank you! You have already confirmed your loan details.',
        line2: 'if you wish to speak to customer support.',
      };
    } else if (result.error === 'conversion-fail') {
      modalInfo.value = {
        title: 'Unable to retrieve loan details',
        line1: 'Sorry! We had trouble retrieving your loan details',
        line2: 'and talk to our customer support.',
      };
    } else {
      modalInfo.value = {
        title: 'Unable to retrieve loan details',
        line1: 'Sorry! There appears to be networking issues.',
        line2: 'if this problem persists.',
      };
    }
    showModal.value = true;
  }
  isReady.value = true;
});

const saveAndContinue = async () => {
  // if (await validateIncome()) {
  saveAndGoNext({
    // financials: financials.value,
  });
  // }
};
</script>

<template>
  <div>
    <div v-if="!isReady" class="mt-[20%] text-center">
      <BxSpinner size="4rem" />
      <div>Loading application</div>
    </div>
    <div v-else>
      <div class="mb-4 pb-2">
        <h2 class="text-2xl font-medium">
          <BxHighlight>Review summary</BxHighlight>
        </h2>
      </div>
      <div class="my-8">
        <SummaryDetails />
      </div>
      <div class="mt-12">
        <BxButton variant="primary" @click="saveAndContinue()">Next</BxButton>
      </div>
      <BxModal
        :open="showModal"
        :icon="ExclamationTriangleIcon"
        :title="modalInfo.title"
        cancel-label="Cancel"
        confirm-label="Confirm"
      >
        <div class="mt-6 leading-loose">
          <p>{{ modalInfo.line1 }}</p>
          <p>
            Please contact {{ AppValues.COMPANY_NAME }} on
            <a class="link" :href="`tel:${AppValues.CONTACT}`">{{ AppValues.CONTACT }}</a>
            {{ modalInfo.line2 }}.
          </p>
        </div>
        <template #buttons>
          <BxButton variant="primary" @click="showModal = false">Ok</BxButton>
        </template>
      </BxModal>
    </div>
  </div>
</template>
