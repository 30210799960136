import type { operations } from '@/types/schemas/api.main';
import { GET, setParams } from '../../_http';
type controller = operations['CustomerApplicationsRetrieveApplicationController_get'];

export type CustomerRetrieveApplicationQuery = controller['parameters']['query'];
export type CustomerRetrieveApplicationResponse = controller['responses']['200']['content']['application/json'];

export const retrieveApplication = GET(
  '/users/applications/application',
  setParams<CustomerRetrieveApplicationQuery>,
)<CustomerRetrieveApplicationResponse>;
