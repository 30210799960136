<template>
  <svg width="27" height="24" viewBox="0 0 27 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6 22.4C14.5 22.8 13.2 23 12 23C5.9 23 1 18.1 1 12C1 5.9 5.9 1 12 1C18.1 1 23 5.9 23 12"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.29961 15.0004C9.29961 16.7004 10.7996 17.7004 12.4996 17.7004C14.1996 17.7004 14.8996 16.2004 14.8996 14.6004C14.8996 13.0004 13.3996 12.0004 12.0996 12.0004H12.1996C10.4996 12.0004 9.09961 10.5004 9.09961 8.80039C9.09961 7.10039 10.5996 6.40039 12.1996 6.40039C13.7996 6.40039 14.7996 7.50039 14.7996 8.80039"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.2002 6.4002V4.7002"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.2002 19.4002V17.7002"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.5 19.7006L21.9 23.1006L25.3 19.6006"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.9004 23.1004V15.9004"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
