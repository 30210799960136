import { LoanPeriod, LoanType } from '@/helpers/const';
import { formatCurrency } from '@/helpers/format';
import { yupNumber, yupObject, yupString, yupBoolean } from '../../yup';

export const brokerLoanDetailsSchema = yupObject({
  additionalFundsRequiredEntry: yupObject({
    isAdditionalFundsRequired: yupBoolean().required().label('Borrowing purpose condition'),
    reasonForFundsOption: yupString()
      .when('isAdditionalFundsRequired', ([isAdditionalFundsRequired], schema) =>
        isAdditionalFundsRequired ? schema.required() : schema,
      )
      .label('Reason for additional funding'),
    fundsRequiredAmount: yupNumber()
      .when('isAdditionalFundsRequired', ([isAdditionalFundsRequired], schema) =>
        isAdditionalFundsRequired ? schema.required() : schema,
      )
      .label('Funding amount'),
    description: yupString()
      .when('isAdditionalFundsRequired', ([isAdditionalFundsRequired], schema) =>
        isAdditionalFundsRequired ? schema.required() : schema,
      )
      .label('Reason for additional funding'),
  }),
  loanType: yupString(),
  savingsAmount: yupNumber().min(0).label('Savings amount'),
  giftedAmount: yupNumber()
    .min(0)
    .when('savingsAmount', ([savingsAmount], schema) =>
      savingsAmount > 0
        ? schema
            .max(savingsAmount, `Gifted amount must be less than or equal to ${formatCurrency(savingsAmount)}`)
            .required()
        : schema,
    )
    .label('Gifted amount'),

  brokerFeePercentage: yupNumber().min(0).max(0.25).label('Broker fee percentage').required(),
  loanPeriod: yupString()
    .when('loanType', { is: LoanType.EQUITY_RELEASE, then: (schema) => schema.required() })
    .label('Loan period'),
  loanDate: yupString().when('loanPeriod', { is: LoanPeriod.EXACT_DATE, then: (schema) => schema.required() }),
});
