<script setup lang="ts">
import { reactive, toRef, watch } from 'vue';
import { useDateInput } from '@/helpers/dateInput';
import dayjs from '@/helpers/dayjs';
import BxInput from '@/components/common/BxInput.vue';

const INVALID_DATE = 'Invalid Date';

const props = withDefaults(
  defineProps<{
    modelValue?: string;
    format?: 'D/M/YYYY' | 'DD/MM/YYYY';
    formatOnInput?: boolean;
    twoDigitYearMax?: number;
  }>(),
  {
    modelValue: undefined,
    format: 'DD/MM/YYYY',
    formatOnInput: true,
    twoDigitYearMax: 49,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | undefined): void;
}>();

const { formatValue, parseValue, handleBeforeInput } = useDateInput({
  format: toRef(props, 'format'),
  twoDigitYearMax: toRef(props, 'twoDigitYearMax'),
  formatOnInput: toRef(props, 'formatOnInput'),
});

const data = reactive<{
  value: string | undefined;
  dateValue: Date | undefined;
  inputValue: string | undefined;
}>({
  value: undefined,
  dateValue: undefined,
  inputValue: undefined,
});

let inputValueChanged = false;

watch(
  () => props.modelValue,
  (value) => {
    if (value !== data.value) {
      data.value = value;
      data.dateValue = parseJsonDate(value);
      data.inputValue = formatValue(data.dateValue);
      inputValueChanged = false;
    }
  },
  { immediate: true },
);

function parseJsonDate(value: string | undefined): Date | undefined {
  if (value === undefined) {
    return undefined;
  }
  if (!value || value === INVALID_DATE) {
    return new Date(NaN);
  }
  const [dateOnly] = value.split('T');
  return new Date(`${dateOnly}T00:00:00`); // note: append time to use local time instead of UTC
}

function updateModelValue(value: string | undefined) {
  if (value === data.inputValue) {
    return;
  }
  data.inputValue = value;
  inputValueChanged = true;

  const newDateValue = value ? parseValue(value) : undefined;
  // note: use Object.is to allow comparison of NaN values
  if (!Object.is(newDateValue?.valueOf(), data.dateValue?.valueOf())) {
    data.dateValue = newDateValue;
    data.value =
      newDateValue === undefined
        ? undefined
        : isNaN(newDateValue.valueOf())
        ? INVALID_DATE
        : dayjs(data.dateValue).format('YYYY-MM-DD');
    emit('update:modelValue', data.value);
  }
}

function handleFocusOut() {
  // apply formatting unless NaN
  if (inputValueChanged && (data.dateValue === undefined || !isNaN(data.dateValue.valueOf()))) {
    data.inputValue = formatValue(data.dateValue);
    inputValueChanged = false;
  }
}
</script>

<template>
  <BxInput
    :model-value="data.inputValue"
    :on-before-input="handleBeforeInput"
    @update:model-value="updateModelValue($event)"
    @focusout="handleFocusOut"
  />
</template>
