import type { Applicant, ApplicantTypeEnum, GenderEnum, RelationshipStatusEnum, RelationshipTypeEnum } from '@/types';
import { mapFromApi } from '@/mappers';
import type { ApplicantDto } from '@/api/backend/users/applications';

export const applicant = (data: ApplicantDto): Applicant => {
  const relationships = data.applicantRelationships?.length
    ? data.applicantRelationships
    : data.applicantRelationship ?? [];

  return {
    id: data.id ?? '',
    type: data.applicantType as ApplicantTypeEnum,
    firstName: data.firstName ?? '',
    middleName: data.middleName ?? '',
    lastName: data.lastName ?? '',
    preferredName: data.preferredName ?? '',
    numberOfDependants: data.numberOfDependants ?? 0,
    gender: data.gender as GenderEnum,
    genderOther: '',
    birthdate: data.dateOfBirth ?? '',
    address: mapFromApi.address(data),
    mobile: data.mobilePhoneNumber ?? '',
    email: data.email ?? '',
    confirmEmail: data.email ?? '',
    relationshipStatus: data.relationshipStatus as RelationshipStatusEnum,
    relationshipToPrimary:
      data.applicantType === 'secondary' ? (relationships[0]?.relationship as RelationshipTypeEnum) : undefined,
    relationshipOtherInfo: relationships[0]?.otherRelationshipDescription ?? '',
  };
};
