<script setup lang="ts">
import { computed, inject, onMounted, onUpdated, ref, watch } from 'vue';
import { useMutationObserver } from '@vueuse/core';
import { useElementSize } from '@vueuse/core';

const props = withDefaults(
  defineProps<{
    modelValue?: boolean;
    separator?: boolean;
    toggle?: boolean;
    static?: boolean;
  }>(),
  {
    modelValue: false,
    separator: false,
    toggle: false,
    static: true,
  },
);
const emit = defineEmits(['update:modelValue', 'click']);

const el = ref<HTMLElement>();
const isOpen = ref(props.modelValue);
const height = ref('0');
const showContent = ref(false);
const { mainScrollOffset } = inject('navInfo', { mainScrollOffset: ref(0) });

const updateState = (val?: boolean) => {
  const willOpen = val ?? !isOpen.value;

  if (willOpen) {
    showContent.value = willOpen;
    setTimeout(() => {
      isOpen.value = willOpen;
    }, 50);
  } else {
    isOpen.value = willOpen;
    setTimeout(() => {
      showContent.value = willOpen;
    }, 500);
    if (el.value) {
      mainScrollOffset.value = el.value.offsetTop;
    }
  }
};

const togglePanel = (val?: boolean) => {
  emit('update:modelValue', val ?? !isOpen.value);
  updateState(val);
};
const staticClass = ref(false);
const expClasses = computed(() => [
  {
    'exp-panel--open': isOpen.value,
    'exp-panel--separator': props.separator,
    'exp-panel--toggle': props.toggle,
    'exp-panel--static': staticClass.value,
  },
]);

watch(
  () => props.modelValue,
  (val) => {
    updateState(val);
  },
  { immediate: true },
);
watch(
  () => props.static,
  (val) => {
    setTimeout(
      () => {
        staticClass.value = val;
      },
      val ? 10 : 100,
    );
  },
);

const panel = ref<HTMLElement>();
onUpdated(() => {
  if (isOpen.value) {
    height.value = panel.value?.scrollHeight + 'px';
  }
});

onMounted(() => {
  if (isOpen.value) {
    height.value = panel.value?.scrollHeight + 'px';
  }
});
const { height: panelHeight } = useElementSize(panel);
watch([panelHeight, isOpen], ([panelHeight, isOpen]) => {
  if (isOpen) {
    height.value = panelHeight + 'px';
  }
});

useMutationObserver(
  panel,
  () => {
    if (isOpen.value) {
      height.value = panel.value?.scrollHeight + 'px';
    }
  },
  {
    attributes: true,
    childList: true,
    characterData: true,
    subtree: true,
  },
);
</script>

<template>
  <div ref="el" class="exp-panel" :class="expClasses">
    <div class="exp-panel__header" @click="toggle && togglePanel()">
      <slot name="header" :is-open="isOpen" :toggle="togglePanel" />
    </div>
    <div class="exp-panel__body">
      <div ref="panel" class="exp-panel__content" :style="{ display: showContent ? undefined : 'none' }">
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.exp-panel {
  --border-radius: 0.5rem;
  --border-color: #ccc;

  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);

  background-color: #fff;

  &__header {
    border-radius: var(--border-radius);

    overflow: hidden;
  }

  &__body {
    height: 0;

    border-radius: 0 0 var(--border-radius) var(--border-radius);

    overflow: hidden;

    transition: none;
  }

  &--toggle &__header {
    cursor: pointer;
  }

  &--separator &__body {
    box-shadow: inset 0 1px 0 0 var(--border-color);
  }

  &--static &__body {
    transition: none;
  }

  &--open {
    .exp-panel__header {
      @apply bg-gray-200;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }

    .exp-panel__body {
      height: v-bind('height');

      transition: height 0.3s ease-out;
    }
  }
}
</style>
