<script setup lang="ts">
import { ref } from 'vue';
import { useTemplateRefsList } from '@vueuse/core';
import type { ApplicantIncome, Employment, EmploymentEntry, EmploymentTypeEnum } from '@/types';
import { EmploymentType } from '@/helpers/const';
import { defaultAddress, defaultApplicantIncome, defaultPeriodicValue } from '@/helpers/builder';
import { PlusCircleIcon } from '@heroicons/vue/20/solid';
import EmploymentCard from './EmploymentCard.vue';
import AdditionalIncomeForm from './AdditionalIncomeForm.vue';

const props = withDefaults(
  defineProps<{
    applicantId: string;
    modelValue: ApplicantIncome | null;
    showButtons?: boolean;
    animate?: boolean;
    invalidEmploymentId: string;
  }>(),
  {
    showButtons: false,
    animate: true,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', v: ApplicantIncome): void;
  (e: 'save'): void;
  (e: 'cancel'): void;
}>();

const applicantIncome = ref<ApplicantIncome | null>(props.modelValue);
const employmentList = ref<{ item: EmploymentEntry }[]>(
  (applicantIncome.value?.employment ?? []).map((item) => {
    item.elementId = `${props.applicantId}-employment`;
    return { item };
  }),
);
const employmentRefs = useTemplateRefsList<InstanceType<typeof EmploymentCard>>();
const additionalIncomeRef = ref<InstanceType<typeof AdditionalIncomeForm>>();

const validateEmployment = async () => {
  for (const itemRef of employmentRefs.value) {
    const validEmployment = await itemRef.update();
    if (!validEmployment.saved) {
      return { validated: false, employmentId: validEmployment.employmentId };
    }
  }
  return { validated: true };
};

const addEmployment = async () => {
  const validation = await validateEmployment();
  if (validation.validated) {
    const elementId = `${props.applicantId}-employment-${employmentList.value.length}`;
    employmentList.value.push({ item: { elementId } });
    setTimeout(() => {
      const lastItem = employmentRefs.value.length - 1;
      employmentRefs.value[lastItem].$el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }, 320);
  }
};

if (!employmentList.value?.length) {
  addEmployment();
}
const removeEmployment = (elementId: string) => {
  employmentList.value = employmentList.value.filter((employment) => employment.item.elementId !== elementId);
};

const update = async () => {
  const validEmployment = await validateEmployment();
  const isValidIncome = (await additionalIncomeRef.value?.update()) ?? false;
  const isValid = validEmployment.validated && isValidIncome;
  if (isValid) {
    const employment: Employment[] = employmentList.value.map(({ item }) =>
      item.type === EmploymentType.FULL_TIME ||
      item.type === EmploymentType.PART_TIME ||
      item.type === EmploymentType.CASUAL
        ? {
            id: item.id || undefined,
            type: item.type ?? EmploymentType.FULL_TIME,
            salary: item.salary ?? defaultPeriodicValue(),
            commission: item.commission ?? defaultPeriodicValue(),
            bonuses: item.bonuses ?? defaultPeriodicValue(),
            overtime: item.overtime ?? defaultPeriodicValue(),
            salarySacrifice: item.salarySacrifice ?? false,
            employerName: item.employerName ?? '',
            employerAddress: item.employerAddress ?? defaultAddress(),
            employedSince: item.employedSince ?? undefined,
          }
        : item.type === EmploymentType.SELF_EMPLOYED
        ? {
            id: item.id || undefined,
            type: EmploymentType.SELF_EMPLOYED,
            salary: item.salary ?? defaultPeriodicValue(),
            businessAddress: item.businessAddress ?? defaultAddress(),
            businessType: item.businessType ?? '',
            tradingName: item.tradingName ?? '',
            occupation: item.occupation ?? '',
            industry: item.industry ?? '',
            gstRegistered: item.gstRegistered ?? false,
            abn: item.abn ?? '',
            soleTrader: item.soleTrader ?? false,
            hasTaxReturns: item.hasTaxReturns ?? false,
            employedSince: item.employedSince ?? undefined,
          }
        : {
            id: item.id || undefined,
            type: item.type ?? EmploymentType.UNEMPLOYED,
          },
    );

    const income = Object.assign(defaultApplicantIncome(), applicantIncome.value, { employment });
    emit('update:modelValue', income);
    return { validated: true };
  }
  return { validated: false, employmentId: validEmployment.employmentId };
};

const save = async () => {
  if (await update()) {
    emit('save');
  }
};

const cancel = () => {
  employmentRefs.value.forEach((ref) => {
    ref.cancel();
  });
  additionalIncomeRef.value?.cancel();
  emit('cancel');
};

const showAnotherButton = ref(true);
const showAdditionBtn = (type: EmploymentTypeEnum) => {
  showAnotherButton.value = type !== EmploymentType.RETIRED && type !== EmploymentType.UNEMPLOYED;
};

// watch(
//   employmentList,
//   (val) => {
//     if (val.length === 0) {
//       employmentList.value.push({ item: { elementId: `employment` } });
//     }
//   },
//   { immediate: true },
// );

defineExpose({ update });
</script>

<template>
  <div>
    <template v-for="job in employmentList" :key="job.elementId">
      <EmploymentCard
        :ref="employmentRefs.set"
        v-model="job.item"
        :applicant-id="job.item.elementId || ''"
        :is-multiple="employmentList.length > 1"
        :static="!props.animate"
        :invalid-employment-id="invalidEmploymentId"
        class="mb-4"
        @employment-type="showAdditionBtn"
        @remove="removeEmployment"
      />
    </template>
    <div v-if="showAnotherButton && !(typeof employmentList[0]?.item === 'string')" class="mt-6">
      <BxButton :id="applicantId + '-income-add-source'" variant="tertiary" @click="addEmployment()">
        <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Add another income source
      </BxButton>
    </div>
    <AdditionalIncomeForm ref="additionalIncomeRef" v-model="applicantIncome" :applicant-id="applicantId" />
    <div v-if="props.showButtons" class="mt-10 flex gap-4">
      <BxButton :id="`${applicantId}-income-save-details`" variant="secondary" @click="save()"
        >Save income details</BxButton
      >
      <BxButton variant="link" @click="cancel()">Cancel</BxButton>
    </div>
  </div>
</template>
