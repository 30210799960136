<script setup lang="ts">
import { ref } from 'vue';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import type { LoanTypeEnum } from '@/types';
import type { IconName } from '@/helpers/icons';
import { LoanType } from '@/helpers/const';
import { ToolTip } from '@/helpers/tippy';
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';

const loanTypes = [
  {
    id: LoanType.BRIDGING,
    title: 'Bridging Loan',
    icon: 'buyNow' as IconName,
    description: 'Buy now, sell later - buy your next home before you have sold your existing home',
    tooltip:
      'We take security over both properties so that you can buy before you sell. You will need the funds to pay for the deposit.',
  },
  // {
  //   id: LoanType.EQUITY_RELEASE,
  //   title: "Equity Release",
  //   icon: "cashAdvance" as IconName,
  //   description:
  //     "Unlock it now, sell later - release equity to support the purchase of a property before you have sold your existing home",
  //   tooltip: `We take security over the property you are selling, the loan amount is based on what you need to
  //     support the purchase of a land lease, retirement, community property, or contribute towards a deposit.`,
  // },
];

const { loanApp, saveAndGoNext } = useLoanAppProcess();
const selectedLoanType = ref<LoanTypeEnum | undefined>(loanApp.value.loanType || LoanType.BRIDGING);

function startApplication() {
  if (selectedLoanType.value) {
    const incomingProperties =
      selectedLoanType.value === LoanType.EQUITY_RELEASE ? [] : loanApp.value.incomingProperties;
    saveAndGoNext({ loanType: selectedLoanType.value, incomingProperties });
  }
}
</script>

<template>
  <div>
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">
        Ready to start your
        <BxHighlight>application</BxHighlight>?
      </h2>
    </div>

    <RadioGroup v-model="selectedLoanType">
      <div class="mt-6 flex flex-1 flex-wrap gap-4">
        <RadioGroupOption
          v-for="loanType in loanTypes"
          v-slot="{ checked, active }"
          :key="loanType.id"
          :value="loanType.id"
          class="radio-option hover:border-bridgit-vibrantGreen"
          as="template"
          ><div>
            <ToolTip :options="{ placement: 'bottom', delay: 100, touch: false }">
              <div
                :class="[
                  checked ? 'border-transparent' : 'border-gray-300',
                  active
                    ? 'border-bridgit-vibrantGreen ring-2 ring-bridgit-vibrantGreen'
                    : 'hover:border-bridgit-lightGreen hover:ring-2 hover:ring-bridgit-lightGreen',
                  'relative flex h-full cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none ',
                ]"
              >
                <span class="flex gap-4">
                  <div class="basis-12 pt-2 text-bridgit-vibrantGreen">
                    <BxIcon :name="loanType.icon" />
                  </div>
                  <span class="flex flex-grow flex-col">
                    <RadioGroupLabel as="span" class="mb-2 block text-sm font-medium text-gray-900">
                      {{ loanType.title }}
                    </RadioGroupLabel>
                    <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">
                      {{ loanType.description }}
                    </RadioGroupDescription>
                  </span>
                </span>
                <span
                  :class="[
                    active ? 'border' : 'border-2',
                    checked ? 'border-bridgit-vibrantGreen' : 'border-transparent',
                    'pointer-events-none absolute -inset-px rounded-lg',
                  ]"
                  aria-hidden="true"
                />
              </div>
              <template #content> {{ loanType.tooltip }} </template>
            </ToolTip>
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>

    <div class="relative my-8 ml-4 min-h-[4rem] leading-7">
      <Transition name="fade">
        <div v-if="selectedLoanType === LoanType.BRIDGING">
          <p>Below is what you'll need to complete the application:</p>
          <ul class="ml-0 mt-2 list-none sm:ml-8">
            <li><span>Details of the property you are buying and selling</span></li>
            <li><span>Details of all the applicants</span></li>
            <li><span>We may ask questions about your income and expenses if you are upsizing</span></li>
          </ul>
        </div>
        <div v-else-if="selectedLoanType === LoanType.EQUITY_RELEASE">
          <p>Great, below is what you'll need to complete the application:</p>
          <ul class="ml-0 mt-2 list-none sm:ml-8">
            <li><span>Details of the property you are selling</span></li>
            <li><span>Details of all the applicants</span></li>
            <li><span>The loan amount required and how you will use the funds</span></li>
            <li>
              <span>
                To be eligible you must be selling an existing property and the funds can only be used for a deposit or
                towards the purchase of a new home (for example, the purchase of a retirement home, community home or
                land lease).
              </span>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
    <div class="mt-6">
      <BxButton id="application-start" variant="primary" :disabled="!selectedLoanType" @click="startApplication"
        >Start application</BxButton
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
ul {
  li {
    @apply mt-2;

    &::before {
      @apply text-bridgit-vibrantGreen;
      @apply mr-[-1.125rem] rounded-full font-black;
      content: '✓';

      padding: 2px 4px;

      font-size: 10px;

      background-color: #eeecec;
    }
  }

  span {
    @apply relative  left-8 top-[2px] mr-8 inline-flex;
  }
}

.radio-option {
  flex-basis: 100%;
  @screen sm {
    flex-basis: 480px;
  }
}
</style>
