import { yupNumber, yupObject, yupString } from '@/validation';
import { NumberSchema, ValidationError } from 'yup';

export function yupPeriodicValue(schema?: NumberSchema) {
  const amountSchema = schema ?? yupNumber();

  return yupObject().test(
    'periodic-value',
    ({ label }) => `${label} is required`,
    async (val, ctx) => {
      try {
        if (val) {
          await yupObject({
            amount: amountSchema,
            frequency: yupString(),
          }).validate(val);
        }
        return true;
      } catch (err) {
        if (err instanceof ValidationError) {
          const errorResult = ctx.createError();
          errorResult.errors.push(...err.errors);
          return errorResult;
        }
        throw err;
      }
    },
  );
}

// export function usePeriods() {
//   return {
//     getOptionLabel: (period: PeriodKey) => periodType[period].label,
//     convertPeriod: (value: PeriodicValue, toPeriod: PeriodKey): PeriodicValue => {
//       if (value.amount === undefined || isNaN(value.amount) || toPeriod === value.frequency) {
//         return { amount: value.amount, frequency: toPeriod };
//       }
//       return {
//         amount: (value.amount * periodType[value.frequency].multiplier) / periodType[toPeriod].multiplier,
//         frequency: toPeriod,
//       };
//     },
//   };
// }
