<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { toast } from '@/helpers/toast';
import { isLoanAppComplete } from '@/helpers/typeGuards';
import { useApplicationApi } from '@/helpers/useApplicationApi';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import { useForm } from 'vee-validate';
import type { Applicant, ListOptions } from '@/types';
import { updateToken } from '@/api/backend/_http';
import api from '@/api';
import type { ReferralTypeDto } from '@/api/backend/public';
import { yupObject, yupString } from '@/validation';
import { AppValues } from '@/helpers/const';

interface ReferralEntry {
  isRequired: boolean;
  referralTypeId: string;
  referralOtherDescription: string;
}

const isReady = ref(false);
const { loanApp, send, saveAndGoNext, goBack } = useLoanAppProcess();
const referralTypes = ref<ReferralTypeDto[]>([]);

const referralTypeList = computed(() => {
  const index: ListOptions = {};
  referralTypes.value.map((referralType) => {
    index[referralType.id] = {
      label: referralType.type,
    };
  });
  return index;
});

const { values, handleSubmit } = useForm<ReferralEntry>({
  validationSchema: yupObject({
    referralTypeId: yupString()
      .when('isRequired', {
        is: true,
        then: (schema) => schema.required(),
      })
      .label('An answer'),
    referralOtherDescription: yupString().when('referralTypeId', {
      is: referralTypes.value.find((referralType) => referralType.type?.toLowerCase() === 'other')?.id,
      then: (schema) => schema.required('You must provide '),
    }),
  }),
  initialValues: { isRequired: loanApp.value.userType === 'Primary' },
  validateOnMount: false,
});

const saveAndContinue = handleSubmit(() => {
  const primaryApplicant = loanApp.value.applicants.find((a) => a.type === 'primary');
  let applicants: Applicant[];
  if (primaryApplicant) {
    applicants = [primaryApplicant, ...loanApp.value.applicants.filter((a) => a.type !== 'primary')];
  } else {
    applicants = loanApp.value.applicants;
  }
  send({
    type: 'UPDATE',
    loanApp: {
      ...loanApp.value,
      applicants,
      utmMetrics: {
        ...loanApp.value.utmMetrics,
        referralTypeId: values.referralTypeId,
        referralOtherDescription: values.referralOtherDescription,
      },
    },
  });
  submitApplication();
});

const showModal = ref(false);
const modalType = ref<'canCancel' | 'cannotProceed'>('canCancel');
const appApi = useApplicationApi();
const isLoading = appApi.isLoading;

const submitApplication = async () => {
  if (isLoanAppComplete(loanApp.value)) {
    const result = await appApi.submitApplication({ loanApp: loanApp.value });
    if (result.success) {
      toast({ type: 'success', title: 'Application submitted successfully' });
      // clear token after generating loan application?
      updateToken(null);
      saveAndGoNext();
    } else {
      toast({
        type: 'danger',
        title: 'Error',
        description: `A system error has occurred. Please try again otherwise contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support`,
      });
    }
  } else {
    console.error('loan incomplete!', loanApp.value);
    toast({
      type: 'danger',
      title: 'Error',
      description: `Could not submit your application. It looks like something is missing in the application. Please contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support.`,
    });
  }
};

const onConfirm = async () => {
  showModal.value = false;
  await submitApplication();
};

onBeforeMount(async () => {
  isReady.value = false;
  try {
    const referralTypesResponse = await api.backend.public.listReferralTypes();
    if (!referralTypesResponse.data.referralTypes?.length) {
      return;
    }
    referralTypesResponse.data.referralTypes.sort((a, b) => {
      const sortOrderA = a.sortOrder || 0;
      const sortOrderB = b.sortOrder || 0;
      return sortOrderA - sortOrderB;
    });
    referralTypes.value = referralTypesResponse.data.referralTypes;
  } finally {
    isReady.value = true;
  }
});
</script>

<template>
  <div v-if="isReady" class="w-full">
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">
        <BxHighlight v-if="loanApp.userType === 'Broker' || loanApp.actionType === 'updateExisting'">
          Confirmation
        </BxHighlight>
        <template v-else> How did you <BxHighlight>hear about us</BxHighlight>? </template>
      </h2>
    </div>
    <div class="mb-20 mt-8">
      <BxField v-slot="{ field }" name="referralTypeId">
        <BxRadio
          v-bind="field"
          id="submission-referral-type"
          :options="referralTypeList"
          label=""
          label-type="default"
          block
        />
      </BxField>
      <Transition name="fade">
        <div v-show="values.referralTypeId === '1c92f9f7-874f-4471-ab9d-1b2f64eda007'">
          <BxField v-slot="{ field }" name="referralOtherDescription">
            <BxInput
              id="submission-referral-note"
              class="ml-7 mt-3"
              v-bind="field"
              label=""
              placeholder="How did you discover Bridgit?"
            />
          </BxField>
        </div>
      </Transition>
    </div>
    <div>
      <div>
        <h2 class="mr-1 inline text-lg font-medium">
          Please provide any additional information regarding
          {{ loanApp.userType === 'Primary' ? 'your' : 'the' }} loan application
        </h2>
        <span class="text-xs">(optional)</span>
      </div>
      <div>
        <p>
          Sharing additional information can help speed up the application process for you, and it gives our team a
          better picture of your unique situation. If there's anything you'd like to add, particularly about your asset
          position, feel free to share in the space below.
        </p>
      </div>
      <BxTextarea id="submission-reason-for-fund" v-model="loanApp.summaryNotes" class="mt-2 w-full" :rows="5" />
    </div>
    <div class="mt-12">
      <BxButton id="submission-submit" variant="primary" :loading="isLoading" @click="saveAndContinue()"
        >Submit application</BxButton
      >
      <BxButton variant="link" class="ml-4" :disabled="isLoading" @click="goBack()">Back</BxButton>
    </div>
    <BxModal
      :open="showModal && modalType === 'canCancel'"
      :icon="ExclamationTriangleIcon"
      title="Warning"
      confirm-label="Yes"
      cancel-label="No"
      @close="showModal = false"
      @confirm="onConfirm()"
    >
      <h4>
        You have an existing active application, are you sure you want to proceed and cancel the previous application?
      </h4>
    </BxModal>
    <BxModal
      :open="showModal && modalType === 'cannotProceed'"
      :icon="ExclamationTriangleIcon"
      title="Warning"
      confirm-label="Yes"
      cancel-label="No"
      @close="showModal = false"
      @confirm="onConfirm()"
    >
      <h4>
        Our records indicate that there is currently an active application with these contact details. Please call us on
        {{ AppValues.CONTACT }} to discuss changing your application.
      </h4>
      <template #buttons>
        <BxButton variant="primary" @click="showModal = false">Close</BxButton>
      </template>
    </BxModal>
  </div>
</template>
