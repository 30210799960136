import type { operations } from '@/types/schemas/api.main';
import { POST } from '../../_http';
type controllerV1 = operations['CustomerApplicationsSubmitApplicantsControllerV1_patch'];
type controllerV2 = operations['CustomerApplicationsSubmitApplicantsControllerV2_patch'];

export type CustomerSubmitApplicationBodyV1 = controllerV1['requestBody']['content']['application/json'];
export type CustomerSubmitApplicationsResponseV1 = controllerV1['responses']['200']['content']['application/json'];
export type CustomerSubmitApplicationBodyV2 = controllerV2['requestBody']['content']['application/json'];
export type CustomerSubmitApplicationsResponseV2 = controllerV2['responses']['200']['content']['application/json'];

export const submitApplicationV1 = POST('/v1/users/applications/submit-application')<
  CustomerSubmitApplicationBodyV1,
  CustomerSubmitApplicationsResponseV1
>;

export const submitApplicationV2 = POST('/v2/users/applications/submit-application')<
  CustomerSubmitApplicationBodyV2,
  CustomerSubmitApplicationsResponseV2
>;

export const submitApplication = async (
  applicationBody: CustomerSubmitApplicationBodyV1 | CustomerSubmitApplicationBodyV2,
  structuralVersionNumber?: number,
): Promise<CustomerSubmitApplicationsResponseV1 | CustomerSubmitApplicationsResponseV2> => {
  structuralVersionNumber = structuralVersionNumber || parseInt(import.meta.env.VITE_STRUCTURAL_VERSION_NUMBER || '0');
  if (structuralVersionNumber === 1) {
    const { data } = await submitApplicationV1(applicationBody);
    return data;
  }
  const { data } = await submitApplicationV2(applicationBody);
  return data;
};
