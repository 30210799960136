import type { operations } from '@/types/schemas/api.main';
import { POST } from '../../_http';
type controller = operations['BrokerAuthenticateRequestOtpController_post'];

export type BrokerAuthenticationRequestOtpBody = controller['requestBody']['content']['application/json'];
export type BrokerAuthenticationRequestOtpResponse = controller['responses']['200']['content']['application/json'];

export const requestOtp = POST('/brokers/authenticate/request-otp')<
  BrokerAuthenticationRequestOtpBody,
  BrokerAuthenticationRequestOtpResponse
>;
