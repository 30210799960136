import type { ApplicationDto } from '@/api/backend/users/applications';
import type { PropertyPurpose } from '@/api/enums/property-purpose.enum';
import { SAVINGS_CONTRIBUTION_TO_PURCHASE } from '@/helpers/const';
import { mapFromApi } from '@/mappers';
import { periodic } from '@/mappers/helpers';
import type { ApplicantAsset, AssetAccount, AssetTypeEnum, PropertyAsset } from '@/types';
import type { ApiSchema, Institute } from '@/types/api';

type FundsAssetDto = ApiSchema['FundsAssetDto'] & {
  assetAmount?: number;
  endDebtContribution?: number;
  towardsPurchase?: number;
  institution?: Institute | string;
};

const mapAsset = (data: FundsAssetDto): AssetAccount => {
  return {
    id: data.id ?? '',
    type: data.assetType as Exclude<AssetTypeEnum, 'property'>,
    institution: (<Institute>data.institution)?.name || <string>data.institution || '',
    value: data.assetAmount ?? 0,
    assetNumber: 0,
  };
};

type AssetsPropertyDto = ApiSchema['AssetsPropertyDto'] & {
  amountOwed?: number;
  liabilityLimit?: number;
  payoutAmount?: number;
};

const mapPropertyAsset = (data: AssetsPropertyDto): PropertyAsset => {
  return {
    id: data.id ?? '',
    type: 'property',
    address: mapFromApi.address(data),
    propertyValue: data.propertyValue ?? 0,
    propertyPurpose: (data.propertyPurpose as PropertyPurpose) ?? 'owner_occupied',
    rentalIncome: periodic(data.rentalIncome, 'monthly'),
    amountOwing: data.amountOwedApplicationValue ?? 0,
    assetNumber: 0,
  };
};

export const assets = (applicantId: string, application: ApplicationDto): ApplicantAsset[] => {
  const assets: AssetAccount[] = (application.fundsAssets ?? [])
    .filter((a: FundsAssetDto) => a.applicantId == applicantId)
    .map(mapAsset)
    // Exclude "Savings to contribute to purchase" from assets (this is the savingsAmount for loanDetails)
    .filter((a: AssetAccount) => a.institution !== SAVINGS_CONTRIBUTION_TO_PURCHASE);

  const properties: PropertyAsset[] = (application.assetsProperties ?? [])
    .filter((a: AssetsPropertyDto) => a.applicantId === applicantId)
    .map(mapPropertyAsset);

  return [...assets, ...properties];
};
