<script setup lang="ts">
import { useRequestIncomeExpenseProcess } from '@/helpers/useRequestIncomeExpenseProcess';
import {
  RequestIncomeExpenseProcessStepNames,
  RequestIncomeExpenseProcessSteps,
  StepStatus,
  type RequestIncomeExpenseProcessStepEnum,
  type RequestIncomeExpenseProcessStepStatus,
} from '@/types';
import { computed, ref, watch } from 'vue';

const props = defineProps<{
  steps: RequestIncomeExpenseProcessStepStatus[];
  modelValue: RequestIncomeExpenseProcessStepEnum;
  endDebt: number;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', v: RequestIncomeExpenseProcessStepEnum): void;
}>();

const { loanApp, goBack, saveAndGoNext } = useRequestIncomeExpenseProcess();

const processSteps = computed(() => props.steps);
const isActive = (step: RequestIncomeExpenseProcessStepStatus) => step.id === props.modelValue;
const stepStatusClass = (step: RequestIncomeExpenseProcessStepStatus) => {
  const isActiveStep = isActive(step);
  return {
    'nav-step--active': isActiveStep,
    'nav-step--completed': step.status === StepStatus.Completed && !isActiveStep,
    'nav-step--ready': step.status === StepStatus.Ready && !isActiveStep,
    'nav-step--skipped': step.status === StepStatus.Skipped,
  };
};

const navigateToStep = (step: RequestIncomeExpenseProcessStepStatus | undefined) => {
  if (step?.status === StepStatus.Ready || step?.status === StepStatus.Completed) {
    emit('update:modelValue', step.id);
  }
};
const activeIndex = computed(() => props.steps.findIndex((s) => s.id === props.modelValue));
const progress = computed(() => (((activeIndex.value + 1) / props.steps.length) * 100).toString() + '%');

const direction = ref('fade');
watch(
  () => props.modelValue,
  (curr, prev) => {
    const previous =
      prev && RequestIncomeExpenseProcessStepNames.includes(prev) ? prev : RequestIncomeExpenseProcessStepNames[0];
    const current = RequestIncomeExpenseProcessStepNames.includes(curr)
      ? curr
      : RequestIncomeExpenseProcessStepNames[0];
    direction.value =
      RequestIncomeExpenseProcessSteps[previous].order < RequestIncomeExpenseProcessSteps[current].order
        ? 'slide-right'
        : 'slide-left';
  },
  { immediate: true },
);
</script>

<template>
  <nav aria-label="Progress" class="nav-progress nav-progress--horizontal">
    <div v-if="loanApp.userType === 'Broker'" class="nav-progress__broker">
      <span class="nav-progress__broker__label">Broker ID: {{ loanApp.brokerReferenceNumber }}</span>
    </div>
    <ol role="list" class="overflow-hidden">
      <li v-for="step in processSteps" :key="step.id" class="nav-progress__step">
        <span
          class="nav-step"
          :class="stepStatusClass(step)"
          :aria-current="isActive(step) ? 'step' : undefined"
          @click="navigateToStep(step)"
        >
          <span class="nav-step__image">
            <BxIcon :name="step.icon" class="nav-step__icon" />
          </span>
          <span class="nav-step__label">{{ step.label }}</span>
        </span>
      </li>
    </ol>
    <div class="mobile-nav">
      <div class="mobile-nav__control">
        <button class="nav-button" :disabled="activeIndex === 0" @click="goBack()">
          <BxIcon name="circleChevronLeft" />
        </button>
        <div class="mobile-nav__bar">
          <div class="mobile-nav__progress" :style="{ width: progress }"></div>
        </div>
        <button
          class="nav-button"
          :disabled="processSteps[activeIndex]?.status !== StepStatus.Completed"
          @click="saveAndGoNext()"
        >
          <BxIcon name="circleChevronRight" />
        </button>
      </div>
      <div class="block h-8 truncate py-1 text-center text-base">
        <TransitionGroup :name="direction">
          <template v-for="step in processSteps" :key="step.id">
            <div v-if="step.id === props.modelValue" class="nav-progress__current">{{ step.label }}</div>
          </template>
        </TransitionGroup>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.nav-progress {
  @apply w-full;
  @apply flex-initial flex-shrink-0 bg-bridgit-navy;
  @apply cursor-default;

  &__step {
    @apply relative pb-4 last:pb-0;
  }

  &__current {
    @apply text-bridgit-lightBlue;
    text-align: center;
  }

  ol {
    display: none;
  }

  &__broker {
    @apply mb-5 mt-5;
    text-align: center;
    &__label {
      color: var(--color-white);
    }
    @screen md {
      text-align: left;
    }
  }

  @screen md {
    width: clamp(200px, 25vw, 270px);

    ol {
      display: block;
    }
  }
}

.nav-step {
  --nav-label: var(--color-bridgit-navBarLabel);
  --nav-icon: var(--color-bridgit-navBarIconStroke);
  --nav-icon-bg: var(--color-bridgit-navbarIconFill);

  @apply relative flex h-9 items-center;
  @apply cursor-not-allowed;

  &__image {
    @apply rounded;
    @apply p-2;
    background-color: var(--nav-icon-bg);
  }

  &__icon {
    @apply h-4 w-5;
    color: var(--nav-icon);
  }

  &__label {
    @apply ml-3 text-sm font-medium;
    color: var(--nav-label);
  }

  &--ready {
    --nav-icon: var(--color-white);
    --nav-label: var(--color-bridgit-navBarLabelCompleted);
    @apply cursor-pointer;

    .nav-step__label {
      @apply group-hover:text-inherit;
    }
  }

  &--skipped {
    --nav-label: var(--color-gray-300);
  }

  &--completed {
    --nav-icon: var(--color-navy);
    --nav-icon-bg: var(--color-bridgit-navbarIconCompletedFill);
    --nav-label: var(--color-bridgit-navBarLabelCompleted);

    @apply cursor-pointer;
  }

  &--active {
    --nav-icon: var(--color-navy);
    --nav-icon-bg: var(--color-bridgit-navbarIconActiveFill);
    --nav-label: var(--color-bridgit-navBarLabelActive);
    @apply cursor-default;
  }

  @screen md {
    &__icon {
      @apply h-5 w-5;
    }
  }
}

.mobile-nav {
  @apply w-full px-4;

  &__control {
    @apply flex items-center gap-2;
    @apply text-bridgit-vibrantGreen;
  }

  &__bar {
    @apply flex-grow;
    @apply relative flex h-2 items-center;
    @apply bg-bridgit-navy-75;
  }

  &__progress {
    @apply h-full;
    @apply bg-bridgit-vibrantGreen;
  }

  @screen md {
    display: none;
  }
}

.nav-button {
  @apply hover:text-bridgit-green active:text-green-500;
  @apply disabled:text-bridgit-navy-75;
}
</style>
