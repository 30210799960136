import { GET, setParams } from '../_http';
import type { operations } from '@/types/schemas/calcs.main';
type controller = operations['StampDutyController_byPostCode'];

export type StampDutyParams = controller['parameters']['path'];
export type StampDutyResponse = controller['responses']['200']['content']['application/json'];

export const stampDuty = GET(
  '/formulas/stamp-duty/post-code/{postCode}/{purchasePrice}',
  setParams<StampDutyParams>,
)<StampDutyResponse>;
