<script setup lang="ts">
import { computed, ref } from 'vue';
import { useTemplateRefsList } from '@vueuse/core';
import { PlusCircleIcon } from '@heroicons/vue/20/solid';
import type { ApplicantAsset, ApplicantLiability, AssetDataEntry, LiabilityDataEntry } from '@/types';
import { AssetType, LiabilityType } from '@/helpers/const';
import { defaultAddress, defaultPeriodicValue } from '@/helpers/builder';
import AssetCard from './AssetCard.vue';
import LiabilityCard from '../Liabilities/LiabilityCard.vue';
const props = withDefaults(
  defineProps<{
    applicantId: string;
    assets: ApplicantAsset[];
    liabilities: ApplicantLiability[];
    showButtons?: boolean;
    animate?: boolean;
  }>(),
  {
    showButtons: false,
    animate: true,
  },
);

const emit = defineEmits<{
  (e: 'update:assets', v: ApplicantAsset[]): void;
  (e: 'update:liabilities', v: ApplicantLiability[]): void;
  (e: 'save'): void;
}>();

const assetList = ref<{ item: AssetDataEntry }[]>(
  props.assets.map((item, index) => {
    item.assetNumber = index;
    return { item };
  }),
);
const assetRefs = useTemplateRefsList<InstanceType<typeof AssetCard>>();
const liabilityList = ref<{ item: LiabilityDataEntry }[]>(
  props.liabilities.map((item, index) => {
    item.liabilityNumber = index;
    return { item };
  }),
);
const liabilityRefs = useTemplateRefsList<InstanceType<typeof LiabilityCard>>();

const validateAssets = async () => {
  let isValid = true;
  for (const assetRef of assetRefs.value) {
    isValid = isValid && !!(await assetRef.update());
  }
  return isValid;
};

const addAsset = async () => {
  if (await validateAssets()) {
    assetList.value.push({ item: { assetNumber: assetList.value.length } });
  }
};

const removeAsset = (assetNumber: number) => {
  assetList.value = assetList.value
    .filter((asset) => asset.item.assetNumber !== assetNumber)
    .map((asset, index) => {
      return {
        ...asset,
        elementId: `asset-${index}`,
      };
    });
  for (const item of assetList.value) {
    item.item.assetNumber = assetList.value.indexOf(item);
  }
};

const validateLiabilities = async () => {
  let isValid = true;
  for (const liabilityRef of liabilityRefs.value) {
    isValid = isValid && !!(await liabilityRef.update());
  }
  return isValid;
};

const addLiability = async () => {
  if (await validateLiabilities()) {
    liabilityList.value.push({ item: { liabilityNumber: liabilityList.value.length } });
  }
};

const removeLiability = (liabilityNumber: number) => {
  liabilityList.value = liabilityList.value.filter((liability) => liability.item.liabilityNumber !== liabilityNumber);
};

const update = async () => {
  const isAssetsValid = await validateAssets();
  const isLiabilitiesValid = await validateLiabilities();
  if (isAssetsValid && isLiabilitiesValid) {
    const assets: ApplicantAsset[] = assetList.value.map(({ item }, index) =>
      item.type === AssetType.PROPERTY
        ? {
            id: item.id ?? undefined,
            type: AssetType.PROPERTY,
            address: item.address ?? defaultAddress(),
            propertyValue: item.propertyValue ?? 0,
            propertyPurpose: item.propertyPurpose ?? 'investment_property',
            rentalIncome: item.rentalIncome ?? defaultPeriodicValue(),
            amountOwing: item.amountOwing ?? 0,
            assetNumber: index,
            propertyOwnership: item.propertyOwnership,
          }
        : {
            id: item.id ?? undefined,
            type: item.type ?? AssetType.SAVINGS,
            institution: item.institution ?? '',
            value: item.value ?? 0,
            assetNumber: index,
          },
    );

    const liabilities: ApplicantLiability[] = liabilityList.value.map(({ item }, index) =>
      item.type === LiabilityType.CARD
        ? {
            id: item.id ?? undefined,
            type: LiabilityType.CARD,
            institution: item.institution ?? '',
            creditLimit: item.creditLimit ?? 0,
            amountOwing: item.amountOwing ?? 0,
            liabilityNumber: index,
          }
        : item.type === LiabilityType.VEHICLE
        ? {
            id: item.id ?? undefined,
            type: LiabilityType.VEHICLE,
            make: item.make ?? '',
            model: item.model ?? '',
            yearBought: item.yearBought ?? '',
            assetValue: item.assetValue ?? 0,
            liabilityLimit: item.liabilityLimit ?? 0,
            amountOwing: item.amountOwing ?? 0,
            periodicRepayment: item.periodicRepayment ?? defaultPeriodicValue(),
            liabilityNumber: index,
          }
        : {
            id: item.id ?? undefined,
            type: LiabilityType.OTHER,
            details: item.details ?? '',
            liabilityLimit: item.liabilityLimit ?? 0,
            amountOwing: item.amountOwing ?? 0,
            periodicRepayment: item.periodicRepayment ?? defaultPeriodicValue(),
            liabilityNumber: index,
          },
    );

    emit('update:assets', assets);
    emit('update:liabilities', liabilities);
  }
  return isAssetsValid;
};

const assetLiabilityMsg = computed(() => {
  const msg: string[] = [];
  if (!assetList.value.length) msg.push('no assets');
  if (!liabilityList.value.length) msg.push('no liabilities');
  return msg.length ? `You have currently listed ${msg.join(' and ')}` : '';
});

const save = async () => {
  if (await update()) {
    emit('save');
  }
};

defineExpose({ update });
</script>

<template>
  <div>
    <div v-if="assetLiabilityMsg" class="mb-4">{{ assetLiabilityMsg }}</div>
    <template v-for="asset in assetList" :key="asset.elementId">
      <AssetCard
        :ref="assetRefs.set"
        v-model="asset.item"
        :asset-number="asset.item.assetNumber"
        :applicant-id="applicantId"
        class="mb-4"
        :static="!props.animate"
        @remove="removeAsset"
      />
    </template>
    <template v-for="liability in liabilityList" :key="liability.elementId">
      <LiabilityCard
        :ref="assetRefs.set"
        v-model="liability.item"
        :liability-number="liability.item.liabilityNumber"
        :applicant-id="applicantId"
        class="mb-4"
        :static="!props.animate"
        @remove="removeLiability"
      />
    </template>

    <div class="mt-10 flex gap-4">
      <BxButton :id="applicantId + '-assets-add-asset'" variant="tertiary" @click="addAsset()">
        <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Add asset
      </BxButton>
      <BxButton :id="applicantId + '-assets-add-liability'" variant="tertiary" @click="addLiability()">
        <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Add liability
      </BxButton>
      <BxButton
        v-if="props.showButtons"
        :id="applicantId + '-assets-done'"
        variant="secondary"
        class="done-button"
        @click="save()"
        >Done</BxButton
      >
    </div>
    <div class="done-button-mobile mt-5 flex gap-4">
      <BxButton v-if="props.showButtons" variant="secondary" @click="save()">Done</BxButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.done-button-mobile {
  @media (min-width: 767px) {
    display: none;
  }

  justify-content: center;
}
.done-button {
  @media (max-width: 767px) {
    display: none;
  }
}
</style>
