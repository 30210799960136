<script setup lang="ts">
import { reactive, ref } from 'vue';
import { refAutoReset } from '@vueuse/core';
import { updateToken } from '@/api/backend/_http';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import { useAuth } from '@/helpers/useAuth';
import { useApplicationApi } from '@/helpers/useApplicationApi';
import { toast } from '@/helpers/toast';
import PrivacyConsentModal from '@/components/shared/PrivacyConsentModal.vue';
import api from '@/api';
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';
import { AppValues } from '@/helpers/const';
const { authorisedUser, loanApp, saveAndGoNext, goBack } = useLoanAppProcess();
const onlyValidPhoneKeys = (event: KeyboardEvent) => {
  if ((event.key < '0' || event.key > '9') && event.key !== '+') {
    event.preventDefault();
  }
};
const mobile = ref(
  loanApp.value.actionType === 'createNew'
    ? loanApp.value.applicants[0].mobile
    : loanApp.value.applicants.find((a) => a.id === authorisedUser.id)?.mobile ?? '',
);
const verificationCode = ref('');

const auth = useAuth();
const loading = ref(false);
const showSendCodeError = ref(false);
const highlightError = refAutoReset(false, 1000);

const loanFundedModal = reactive({
  show: false,
  onConfirm: () => {
    loanFundedModal.show = false;
  },
});

const manualWithdrawalModal = reactive({
  show: false,
  onConfirm: () => {
    manualWithdrawalModal.show = false;
  },
});

const invalidCrmStatusModal = reactive({
  show: false,
  onConfirm: () => {
    invalidCrmStatusModal.show = false;
  },
});

const cancellationPossibleModal = reactive({
  show: false,
  onConfirm: () => {
    loanApp.value.cancelPriorApplications = true;
    saveAndGoNext();
  },
});

const sendOtpCode = async () => {
  const result =
    loanApp.value.actionType === 'createNew'
      ? await auth.sendNewApplicantOtp({ mobilePhoneNumber: mobile.value })
      : await auth.sendExistingApplicantOtp({ mobilePhoneNumber: mobile.value });

  showSendCodeError.value = !result.success;
  if (result.success) {
    toast({ type: 'success', title: 'Success', description: 'One time password sent successfully' });
  } else {
    highlightError.value = true;
    toast({ type: 'danger', title: 'Unable to send code', description: 'Your OTP failed to send' });
  }
};

const verifyOtpCode = async () => {
  try {
    loading.value = true;
    const payload = {
      mobilePhoneNumber: mobile.value,
      otp: verificationCode.value,
    };
    const result =
      loanApp.value.actionType === 'createNew'
        ? await auth.verifyNewApplicant(loanApp.value.applicants[0], verificationCode.value)
        : await auth.verifyExistingApplicant(payload);
    if (result.success) {
      updateToken(result.value);
      toast({ type: 'success', title: 'Success', description: 'Phone number verified' });
    } else {
      updateToken(null);
      toast({ type: 'danger', title: 'Unable to verify', description: result.error });
    }
    return result.success;
  } catch (err) {
    if (err) {
      toast({ type: 'danger', title: 'Unable to verify', description: 'Contact Support' });
    }
  } finally {
    loading.value = false;
  }
};

const saveAndContinue = async () => {
  if (await verifyOtpCode()) {
    let activeApplications = [];
    const mobilePhoneNumbers = mobile.value.startsWith('+61') ? '0' + mobile.value.substring(3) : mobile.value;
    if (loanApp.value.brokerId) {
      const response = await api.backend.brokers.applications.activeApplications({ mobilePhoneNumbers });
      activeApplications = response.activeApplications;
    } else {
      const response = await api.backend.users.applications.activeApplications({ mobilePhoneNumbers });
      activeApplications = response.activeApplications;
    }
    if (!activeApplications.length) {
      return saveAndGoNext({ verificationCode: verificationCode.value });
    }
    const activeApplicationIndex = activeApplications.findIndex((a) => a.mobilePhoneNumber === mobile.value);
    loanApp.value.applicationId = activeApplications[activeApplicationIndex]?.applicationId ?? '';
    const loanFunded = activeApplications.find((application) => application.cancellationStatus === 'loan_funded');
    if (loanFunded) {
      loanFundedModal.show = true;
      return;
    }
    const manualWithdrawal = activeApplications.find(
      (application) => application.cancellationStatus === 'manual_withdrawal',
    );
    if (manualWithdrawal) {
      manualWithdrawalModal.show = true;
      return;
    }
    const invalidCrmStatus = activeApplications.find(
      (application) => application.cancellationStatus === 'crm_status_invalid',
    );
    if (invalidCrmStatus) {
      invalidCrmStatusModal.show = true;
      return;
    }
    cancellationPossibleModal.show = true;
  }
};

const showPrivacyModal = ref(false);

// This will backup applications created by primary and secondary applicants before submission
// Brokers will bypass the verification step so broker applications will be captured on the submission step.
const { backupApplication } = useApplicationApi();
async function backup() {
  try {
    const result = await backupApplication(loanApp.value);
    if (result?.data.message !== 'success') {
      setTimeout(backup, 1000);
      return;
    }
  } catch (error) {
    setTimeout(backup, 1000);
    return;
  }
}

backup();
</script>

<template>
  <div>
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">
        Mobile number
        <BxHighlight>verification</BxHighlight>
      </h2>
    </div>
    <div>
      <div class="form-control flex">
        <BxInput
          v-model="mobile"
          inputmode="tel"
          label="Mobile number"
          label-type="floating"
          minlength="10"
          :maxlength="mobile.startsWith('+') ? 12 : 10"
          disabled
          @keypress="onlyValidPhoneKeys"
        />
        <BxButton id="verification-send-otp" class="ml-2 sm:ml-4" variant="secondary" @click="sendOtpCode()"
          >Send code</BxButton
        >
      </div>
      <div v-if="showSendCodeError" class="mb-8 mt-2 pr-2 text-rose-600">
        <p :class="{ 'animate-shakeX': highlightError }">
          Please check that your number has been entered correctly before resending the OTP code. If you are unable to
          proceed, please get in touch with us on <span class="font-medium">{{ AppValues.CONTACT }}</span> and quote
          your mobile phone number.
        </p>
      </div>
      <BxInput
        id="verification-otp-code"
        v-model="verificationCode"
        inputmode="numeric"
        class="form-control"
        label="6 digit verification code"
        label-type="floating"
        maxlength="6"
      />
    </div>
    <div>
      <div v-if="loanApp.actionType === 'updateExisting'" class="mb-8">
        By clicking "Next" you confirm that you have read and agree to {{ AppValues.COMPANY_NAME + `'s` }}
        <a class="link" @click="showPrivacyModal = true"> Privacy Consent and Electronic Authorisation</a> and consent
        to the collection, use, holding and disclosure of my/our personal information as set out in that document, and
        to receiving notices, statements, disclosures and other documents from
        {{ AppValues.COMPANY_NAME }} electronically.
      </div>
      <ol
        class="otp-instruction mb-4 mt-4 rounded-md border-black px-4 py-4 text-sm text-bridgit-navy transition focus:outline-none focus-visible:ring-2 focus-visible:ring-bridgit-royalBlue focus-visible:ring-offset-2"
      >
        <h3>To complete your mobile number verification:</h3>
        <li>Click the "send code" button above.</li>
        <li>You'll receive a 6-digit verification code on your mobile.</li>
        <li>Enter the code in the provided box above.</li>
        <li>Click "Next" to complete the verification and continue your application.</li>
        <p>
          If you need help with this step please contact us at {{ AppValues.CONTACT }} or
          <a :href="`emailto:${AppValues.EMAIL}`">{{ AppValues.EMAIL }}</a>
        </p>
      </ol>
      <div class="flex gap-4">
        <BxButton id="verification-next" variant="primary" :loading="loading" @click="saveAndContinue()">Next</BxButton>
        <BxButton variant="link" @click="goBack()">Back</BxButton>
      </div>
    </div>
    <PrivacyConsentModal v-model="showPrivacyModal" />
  </div>
  <BxModal
    :open="loanFundedModal.show"
    :icon="ExclamationTriangleIcon"
    title="Warning"
    confirm-label="Cancel"
    cancel-label=""
    @close="loanFundedModal.show = false"
    @confirm="loanFundedModal.onConfirm"
  >
    <p>
      You currently have an active loan with us that will first need to be repaid before a new application can be
      submitted. Please contact us on {{ AppValues.CONTACT }} to discuss.
    </p>
  </BxModal>
  <BxModal
    :open="cancellationPossibleModal.show"
    :icon="ExclamationTriangleIcon"
    title="Warning"
    confirm-label="Confirm"
    @close="cancellationPossibleModal.show = false"
    @confirm="cancellationPossibleModal.onConfirm"
  >
    <p>
      You currently have an active application with us, submitting a new one will withdraw your previous application. Do
      you wish to proceed?
    </p>
  </BxModal>
  <BxModal
    :open="manualWithdrawalModal.show"
    :icon="ExclamationTriangleIcon"
    title="Warning"
    confirm-label="Cancel"
    cancel-label=""
    @close="manualWithdrawalModal.show = false"
    @confirm="manualWithdrawalModal.onConfirm"
  >
    <p>
      You currently have an active application with us that will first need to be withdrawn before a new one can be
      submitted. Please contact us on {{ AppValues.CONTACT }} to discuss.
    </p>
  </BxModal>
  <BxModal
    :open="invalidCrmStatusModal.show"
    :icon="ExclamationTriangleIcon"
    title="Warning"
    confirm-label="Cancel"
    cancel-label=""
    @close="invalidCrmStatusModal.show = false"
    @confirm="invalidCrmStatusModal.onConfirm"
  >
    <p>
      You currently have an active application with us that will first need to be withdrawn before a new one can be
      submitted. Please contact us on {{ AppValues.CONTACT }} to discuss.
    </p>
  </BxModal>
</template>

<style lang="scss" scoped>
.otp-instruction {
  background: #ebf1f5;

  li {
    list-style-type: decimal;
    margin-left: 2.1rem;
    line-height: 1.7rem;
  }

  a {
    font-weight: 500;
  }
}

.link {
  @apply cursor-pointer text-bridgit-navy underline hover:text-bridgit-vibrantGreen;
}
</style>
