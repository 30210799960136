import { CurrentKey, LoanType, RelationshipType } from '@/helpers/const';
import { formatAddress } from '@/helpers/format';
import { includesEnum } from '@/helpers/includes-enum';
import {
  initMachine,
  loanApplicationMachineBroker,
  type BrokerLoanAppMachineContext,
} from '@/helpers/loanBrokerAppMachine';
import type {
  Address,
  Applicant,
  ApplicantId,
  AssetId,
  BrokerApplicationProcessStepStatus,
  BrokerProcessStepEnum,
  LoanApplication,
  PropertyId,
} from '@/types';
import { BrokerApplicationProcessSteps, BrokerProcessStep, BrokerProcessStepNames, StepStatus } from '@/types';
import { useActor } from '@xstate/vue';
import { computed } from 'vue';
import { interpret } from 'xstate';

const appKey = localStorage.getItem(CurrentKey) ?? '';
const savedState = JSON.parse(localStorage.getItem(appKey) as string);
const initialState: BrokerLoanAppMachineContext = savedState ? savedState : initMachine();

const service = interpret(loanApplicationMachineBroker.withContext(initialState)).start();

service.onTransition((state) => {
  if (state.value !== 'gotoReady' && state.value !== 'start') {
    const currentStep = state.value as BrokerProcessStepEnum;
    if (state.context.status[currentStep] === StepStatus.Incomplete) {
      state.context.status[currentStep] = StepStatus.Ready;
    }

    state.context.current = currentStep;
    const loanApp = state.context.loanApp;
    if (loanApp.applicationId && loanApp.userType) {
      const key = `${loanApp.actionType}-${loanApp.applicationId}`;
      const appState = JSON.stringify(state.context);
      localStorage.setItem(CurrentKey, key);
      localStorage.setItem(key, appState);
    }
  }
});

const processSteps = Object.entries(BrokerApplicationProcessSteps).map(([step, info]) => ({
  id: step as BrokerProcessStepEnum,
  ...info,
}));

export function useBrokerLoanAppProcess() {
  const { state, send } = useActor(service);
  const currentStep = computed(() => state.value.context.current);
  const loanApp = computed(() => state.value.context.loanApp);
  const authorisedUser = state.value.context.authorisedUser;

  const secondaryApplicant = computed(() => {
    return loanApp.value.actionType === 'updateExisting'
      ? loanApp.value.applicants.find((a) => a.id === state.value.context.authorisedUser.id && a.type === 'secondary')
      : undefined;
  });

  const isSecondaryMarried = computed(
    () => secondaryApplicant.value?.relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO,
  );

  const stepper = computed<BrokerApplicationProcessStepStatus[]>(() => {
    const currentStep = BrokerProcessStepNames.includes(state.value.context.current)
      ? state.value.context.current
      : BrokerProcessStepNames[0];
    return processSteps
      .filter(
        (step) =>
          loanApp.value.userType &&
          step.actions.includes(loanApp.value.actionType) &&
          step.id !== BrokerProcessStep.Finished &&
          ((loanApp.value.endDebt > 0 && !isSecondaryMarried.value) ||
            state.value.context.from === 'crm' ||
            !includesEnum(step.id, [BrokerProcessStep.Income, BrokerProcessStep.Expenses, BrokerProcessStep.Assets]) ||
            // For Secondary declaration
            (loanApp.value.userType === 'Secondary' &&
              !authorisedUser.id &&
              loanApp.value.actionType === 'updateExisting') ||
            (loanApp.value.endDebt <= 0 &&
              !includesEnum(step.id, [
                BrokerProcessStep.Income,
                BrokerProcessStep.Expenses,
                BrokerProcessStep.Assets,
              ])) ||
            (loanApp.value.loanType === LoanType.SINGLE_SECURITY && step.id === BrokerProcessStep.Assets)),
      )
      .map((step) => {
        const stepStatus = state.value.context.status[step.id];
        const status =
          stepStatus === StepStatus.Incomplete &&
          BrokerApplicationProcessSteps[step.id].order < BrokerApplicationProcessSteps[currentStep].order
            ? StepStatus.Skipped
            : stepStatus;
        return { ...step, status };
      })
      .filter((step) => step.status !== StepStatus.Skipped);
  });

  const loanProperties = computed(() =>
    [...loanApp.value.incomingProperties, ...loanApp.value.outgoingProperties].reduce((obj, item) => {
      obj[item.id] = formatAddress(item.address as Address);
      return obj;
    }, {} as Record<PropertyId | AssetId, string>),
  );
  const loanApplicants = computed<Record<string, Applicant>>(() =>
    loanApp.value.applicants.reduce((obj, item) => {
      obj[item.id as string] = item;
      return obj;
    }, {} as Record<ApplicantId, Applicant>),
  );

  const saveAndGoNext = (updatedData: Partial<LoanApplication> = {}) => {
    if (currentStep.value === BrokerProcessStep.SubmitApplication) {
      localStorage.setItem(CurrentKey, '');
    }
    send({ type: 'NEXT', loanApp: { ...state.value.context.loanApp, ...updatedData } });
  };

  const goBack = () => {
    send('BACK');
  };

  const restart = (isBroker?: boolean) => {
    send(isBroker === undefined ? 'RESTART' : 'RESTART_BROKER');
  };

  return {
    state,
    service,
    stepper,
    currentStep, // remove?
    authorisedUser,
    loanApp,
    // targetApplicant: state.value.context.targetApplicant,
    loanProperties,
    loanApplicants,
    send,
    saveAndGoNext,
    goBack,
    restart,
  };
}
