import type { operations } from '@/types/schemas/calcs.main';
import { POST } from '../_http';
type controllerV1 = operations['EndDebtControllerV1_calculate'];
type controllerV2 = operations['EndDebtControllerV2_calculate'];

export type EndDebtBodyV1 = controllerV1['requestBody']['content']['application/json'];
export type EndDebtResponseV1 = controllerV1['responses']['200']['content']['application/json'];
export type EndDebtBodyV2 = controllerV2['requestBody']['content']['application/json'];
export type EndDebtResponseV2 = controllerV2['responses']['200']['content']['application/json'];

export const endDebtV1 = POST('/v1/formulas/end-debt')<EndDebtBodyV1, EndDebtResponseV1>;
export const endDebtV2 = POST('/v2/formulas/end-debt')<EndDebtBodyV2, EndDebtResponseV2>;

export const endDebt = async (
  endDebtBody: EndDebtBodyV1 | EndDebtBodyV2,
  structuralVersionNumber?: number,
): Promise<EndDebtResponseV1 | EndDebtResponseV2> => {
  structuralVersionNumber = structuralVersionNumber || parseInt(import.meta.env.VITE_STRUCTURAL_VERSION_NUMBER || '0');
  if (structuralVersionNumber === 1) {
    const { data } = await endDebtV1(endDebtBody);
    return data;
  }
  const { data } = await endDebtV2(endDebtBody);
  return data;
};
