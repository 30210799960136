<script setup lang="ts">
import { possessive } from '@/helpers/format';
import type { Applicant, ApplicantId, AssetId, PropertyId, PropertyOwnership } from '@/types';
import { computed } from 'vue';

const props = defineProps<{
  data: PropertyOwnership[];
  properties: Record<PropertyId | AssetId, string>;
  applicants: Record<ApplicantId, Applicant>;
}>();

const propertyOwnerships = computed(() => props.data.filter((ownership) => ownership.type != 'asset'));
</script>

<template>
  <section class="summary-details">
    <h2>Property ownership</h2>
    <template v-for="item in propertyOwnerships" :key="item.id">
      <h3>{{ item.type === 'incoming' ? 'Buying' : 'Selling' }} - {{ properties[item.id] }}</h3>
      <dl>
        <template v-for="(value, applicantId) in item.share" :key="applicantId">
          <dt>{{ possessive(applicants[applicantId].preferredName) }} share</dt>
          <dd>{{ value }}%</dd>
        </template>
      </dl>
    </template>
  </section>
</template>
