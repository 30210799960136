<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  inheritAttrs: false,
});
</script>

<script setup lang="ts">
import type { InputHTMLAttributes } from 'vue';
import { computed, ref } from 'vue';
import { nanoid } from 'nanoid';

const props = withDefaults(
  defineProps<{
    id?: string;
    modelValue?: string;
    inputmode?: InputHTMLAttributes['inputmode'];
    type?: string;
    label?: string;
    labelType?: 'default' | 'overlapping' | 'floating';
    placeholder?: string;
    leadingSymbol?: string;
    errorMessage?: string;
    highlightError?: boolean;
    helpText?: string;
    disabled?: boolean;
    onBeforeInput?: (e: Event) => void;
    class?: string | string[] | object;
    maxLength?: number;
  }>(),
  {
    id: undefined,
    modelValue: undefined,
    inputmode: undefined,
    type: 'text',
    label: '',
    labelType: 'default',
    placeholder: undefined,
    leadingSymbol: undefined,
    errorMessage: undefined,
    highlightError: false,
    helpText: undefined,
    disabled: false,
    onBeforeInput: undefined,
    class: undefined,
    maxLength: undefined,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', v: string | undefined): void;
  (e: 'oninput:modelValue', v: Event | undefined): void;
}>();

function handleInput(event: Event) {
  emit('update:modelValue', (event.target as HTMLInputElement).value);
}

function handleOnInput(event: Event) {
  emit('oninput:modelValue', event as Event);
}

const inputId = computed(() => props.id ?? nanoid(8));
const inputRef = ref<HTMLElement>();
const inputSelected = ref(false);
</script>

<template>
  <div :class="props.class">
    <label v-if="labelType === 'default'" :for="inputId" class="block text-sm font-medium text-gray-700">
      {{ label }}
    </label>
    <div class="group relative">
      <div
        v-if="$slots.leading || leadingSymbol"
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
      >
        <slot name="leading">
          <span class="text-gray-500">{{ leadingSymbol }}</span>
        </slot>
      </div>
      <input
        :id="inputId"
        ref="inputRef"
        :data-test-id="inputId"
        :type="type"
        :value="modelValue"
        :oninput="handleOnInput"
        :maxlength="maxLength"
        class="peer w-full rounded-md border-gray-300 text-base placeholder-gray-500 shadow-sm focus:border-bridgit-royalBlue focus:outline-none focus:ring-bridgit-royalBlue disabled:bg-gray-50 disabled:text-gray-300 disabled:placeholder:text-gray-300"
        :class="[
          errorMessage ? '!border-rose-500 focus:!ring-rose-500' : null,
          labelType === 'floating' ? 'placeholder-transparent group-focus-within:placeholder-gray-500' : null,
          { 'pl-7': leadingSymbol },
        ]"
        :placeholder="labelType === 'floating' ? placeholder || ' ' : placeholder"
        :inputmode="inputmode"
        autocomplete="off"
        :aria-invalid="!!errorMessage"
        :aria-describedby="`${inputId}-error`"
        :disabled="disabled"
        v-bind="$attrs"
        @beforeinput="onBeforeInput"
        @input="handleInput"
        @focus="() => (inputSelected = !inputSelected)"
        @blur="() => (inputSelected = !inputSelected)"
      />
      <div v-if="$slots.trailing" class="absolute inset-y-0 right-0 flex items-center">
        <slot name="trailing"></slot>
      </div>
      <label
        v-if="labelType !== 'default'"
        for="id"
        class="pointer-events-none absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
        :class="
          labelType === 'floating'
            ? [
                'transition-all',
                'peer-placeholder-shown:top-2 peer-placeholder-shown:mt-0 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-placeholder-shown:text-gray-700',
                'group-focus-within:!-top-2 group-focus-within:!-mt-px group-focus-within:!text-xs group-focus-within:!font-medium group-focus-within:!text-gray-900',
              ]
            : []
        "
      >
        {{ label }}
      </label>
    </div>
    <p v-if="inputSelected && helpText" class="mt-2 text-sm">{{ helpText }}</p>
    <Transition :enter-active-class="!highlightError ? 'animate-fadeIn' : ''">
      <p
        v-show="!inputSelected && !!errorMessage"
        :id="`${inputId}-error`"
        :data-test-id="`${inputId}-error`"
        class="mt-1 text-sm text-rose-500"
        :class="{ 'animate-shakeX': highlightError }"
      >
        {{ errorMessage }}
      </p>
    </Transition>
  </div>
</template>
