<script setup lang="ts">
import { updateToken } from '@/api/backend/_http';
import { RelationshipType } from '@/helpers/const';
import { toast } from '@/helpers/toast';
import { isLoanAppComplete } from '@/helpers/typeGuards';
import { useApplicationApi } from '@/helpers/useApplicationApi';
import { useRequestIncomeExpenseProcess } from '@/helpers/useRequestIncomeExpenseProcess';
import type { Applicant } from '@/types';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';
import { useForm } from 'vee-validate';
import { ref } from 'vue';
import { AppValues } from '@/helpers/const';

const { authorisedUser, loanApp, state, send, saveAndGoNext, goBack } = useRequestIncomeExpenseProcess();
const { handleSubmit } = useForm({
  initialValues: { isRequired: loanApp.value.userType === 'Primary' },
  validateOnMount: false,
});

const isIncomeExpenseRequest =
  loanApp.value.userType === 'Secondary' &&
  state.value.context.from === 'crm' &&
  loanApp.value.actionType === 'updateExisting';

const applicantsMarried = loanApp.value.applicants.some(
  (applicant) => applicant.relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO,
);

const saveAndContinue = handleSubmit(() => {
  const primaryApplicant = loanApp.value.applicants.find((a) => a.type === 'primary');
  let applicants: Applicant[];
  if (primaryApplicant) {
    applicants = [primaryApplicant, ...loanApp.value.applicants.filter((a) => a.type !== 'primary')];
  } else {
    applicants = loanApp.value.applicants;
  }

  send({
    type: 'UPDATE',
    loanApp: {
      ...loanApp.value,
      applicants,
    },
  });

  if (loanApp.value.isResubmission) {
    submitApplicantData();
  } else {
    submitApplication();
  }
});

const showModal = ref(false);
const modalType = ref<'canCancel' | 'cannotProceed'>('canCancel');
const appApi = useApplicationApi();
const isLoading = appApi.isLoading;

const submitApplication = async () => {
  if (isLoanAppComplete(loanApp.value)) {
    const result = await appApi.submitApplication({ loanApp: loanApp.value });
    if (result.success) {
      toast({ type: 'success', title: 'Application submitted successfully' });
      // clear token after generating loan application?
      updateToken(null);
      saveAndGoNext();
    } else {
      const errMsg = `A system error has occurred. Please try again otherwise contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support`;
      toast({ type: 'danger', title: 'Error', description: errMsg });
    }
  } else {
    console.error('loan incomplete!', loanApp.value);
    toast({
      type: 'danger',
      title: 'Error',
      description: `Could not submit your application. It looks like something is missing in the application. Please contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support.`,
    });
  }
};

const onConfirm = async () => {
  showModal.value = false;
  await submitApplication();
};

const submitApplicantData = async () => {
  if (isLoanAppComplete(loanApp.value)) {
    const applicantIndex = loanApp.value.applicants.findIndex(
      (a) => loanApp.value.userType === 'Broker' || a.id === authorisedUser.id,
    );
    const result =
      (isIncomeExpenseRequest && applicantsMarried) || loanApp.value.userType === 'Broker'
        ? await appApi.submitApplicants({
            applicant: loanApp.value.applicants[applicantIndex],
            loanApp: { ...loanApp.value },
          })
        : await appApi.submitApplicant({
            applicant: loanApp.value.applicants[applicantIndex],
            loanApp: { ...loanApp.value },
          });
    if (result.success) {
      toast({ type: 'success', title: 'Application submitted successfully' });
      // clear token after generating loan application?
      updateToken(null);
      saveAndGoNext();
    } else {
      toast({ type: 'danger', title: 'Error', description: result.error });
    }
  } else {
    console.error('loan incomplete!', loanApp.value);
    toast({
      type: 'danger',
      title: 'Error',
      description: `Could not submit your application. It looks like something is missing in the application. Please contact ${AppValues.COMPANY_NAME} on ${AppValues.CONTACT} for support.`,
    });
  }
};

// This will backup applications created by the broker before submission.
// Broker backup is done here since brokers bypass the verification step.
if (loanApp.value.userType === 'Broker') {
  appApi.backupApplication(loanApp.value);
}
</script>

<template>
  <div class="w-full">
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">
        <BxHighlight>Confirmation</BxHighlight>
      </h2>
    </div>
    <div class="my-8">Please click <strong>"Submit application"</strong> below to finalise your application.</div>
    <div class="mt-12">
      <BxButton variant="primary" :loading="isLoading" @click="saveAndContinue()">Submit application</BxButton>
      <BxButton variant="link" class="ml-4" :disabled="isLoading" @click="goBack()">Back</BxButton>
    </div>
    <BxModal
      :open="showModal && modalType === 'canCancel'"
      :icon="ExclamationTriangleIcon"
      title="Warning"
      confirm-label="Yes"
      cancel-label="No"
      @close="showModal = false"
      @confirm="onConfirm()"
    >
      <h4>
        You have an existing active application, are you sure you want to proceed and cancel the previous application?
      </h4>
    </BxModal>
    <BxModal
      :open="showModal && modalType === 'cannotProceed'"
      :icon="ExclamationTriangleIcon"
      title="Warning"
      confirm-label="Yes"
      cancel-label="No"
      @close="showModal = false"
      @confirm="onConfirm()"
    >
      <h4>
        Our records indicate that there is currently an active application with these contact details. Please call us on
        {{ AppValues.CONTACT }} to discuss changing your application.
      </h4>
      <template #buttons>
        <BxButton variant="primary" @click="showModal = false">Close</BxButton>
      </template>
    </BxModal>
  </div>
</template>
