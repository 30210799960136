<script setup lang="ts">
import type { PropertyPurpose } from '@/api/enums';

defineProps<{
  id: string;
}>();

const propertyPurposeOptions: Record<PropertyPurpose, string> = {
  owner_occupied: 'A home to live in',
  investment_property: 'An investment property',
};
</script>
<template>
  <BxField v-slot="{ field }" name="propertyPurpose">
    <BxRadio
      v-bind="field"
      :id="id + '-property-purpose'"
      :options="propertyPurposeOptions"
      label="What is the purpose of this property?"
      label-type="default"
      class="form-control incoming-margin-top"
      block
    />
  </BxField>
</template>
