import type { Applicant, AsyncResult } from '@/types';
import { computed, ref } from 'vue';
import api from '@/api';
import type {
  CustomerRegistrationRequestOtpBody,
  CustomerRegistrationVerifyOtpBody,
} from '@/api/backend/users/registration';
import type {
  CustomerAuthenticationRequestOtpBody,
  CustomerAuthenticationVerifyOtpBody,
} from '@/api/backend/users/authentication';
import { useCookies } from '@vueuse/integrations/useCookies';
import type { BrokerAuthenticationRequestOtpBody } from '@/api/backend/brokers/authenticate';
import { AppValues } from '@/helpers/const';

const cookies = useCookies(['app-token']);

export function useAuth() {
  const isProcessing = ref(0);

  const sendNewApplicantOtp = async (data: CustomerRegistrationRequestOtpBody): AsyncResult<string> => {
    isProcessing.value++;
    try {
      const response = await api.backend.users.registration.requestOtp(data);
      return { success: true, value: response.data.message };
    } catch (error) {
      console.error(error);
      return { success: false, error: api.getErrorMsg(error) };
    } finally {
      isProcessing.value--;
    }
  };

  const verifyNewApplicant = async (data: Applicant, otp: string): AsyncResult<string> => {
    const applicantRegistration: CustomerRegistrationVerifyOtpBody = {
      mobilePhoneNumber: data.mobile,
      otp,
    };

    isProcessing.value++;
    try {
      await api.backend.users.registration.verifyOtp(applicantRegistration);
      return { success: true, value: '' };
    } catch (error) {
      console.error(error);
      return { success: false, error: api.getErrorMsg(error) };
    } finally {
      isProcessing.value--;
    }
  };

  const sendExistingApplicantOtp = async (data: CustomerAuthenticationRequestOtpBody): AsyncResult<string> => {
    isProcessing.value++;
    try {
      const response = await api.backend.users.authentication.requestOtp(data);
      return { success: true, value: response.data.message };
    } catch (error) {
      console.error(error);
      return { success: false, error: api.getErrorMsg(error) };
    } finally {
      isProcessing.value--;
    }
  };

  const verifyExistingApplicant = async (data: CustomerAuthenticationVerifyOtpBody): AsyncResult<string> => {
    isProcessing.value++;
    try {
      const response = await api.backend.users.authentication.verifyOtp(data);
      return { success: true, value: response.data.token ?? '' };
    } catch (err) {
      console.error(err);
      return {
        success: false,
        error: `Invalid credentials. Please try again or contact support on ${AppValues.CONTACT}`,
      };
    } finally {
      isProcessing.value--;
    }
  };

  const verifyBrokerCredentials = async (email: string, otp: string): AsyncResult<string> => {
    try {
      const { data } = await api.backend.brokers.authenticate.verifyCredentials({
        email,
        otp,
      });
      if (data.token) {
        cookies.set('app-token', data.token, { path: '/' });
        return { success: true, value: 'Logged in successfully' };
      } else {
        return { success: false, error: 'Unable to login' };
      }
    } catch (error) {
      return { success: false, error: 'Unable to login' };
    }
  };

  const sendBrokerOtp = async (data: BrokerAuthenticationRequestOtpBody): AsyncResult<string> => {
    isProcessing.value++;
    try {
      const response = await api.backend.brokers.authenticate.requestOtp(data);
      return { success: true, value: response.data.message };
    } catch (error) {
      console.error(error);
      return { success: false, error: api.getErrorMsg(error) };
    } finally {
      isProcessing.value--;
    }
  };

  return {
    isLoading: computed(() => isProcessing.value > 0),
    verifyBrokerCredentials,
    sendBrokerOtp,
    sendNewApplicantOtp,
    verifyNewApplicant,
    sendExistingApplicantOtp,
    verifyExistingApplicant,
  };
}
