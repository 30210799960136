import type { operations } from '@/types/schemas/api.JS-2012';
import { POST } from '../../_http';
type controllerV1 = operations['BrokerApplicationsSubmitApplicationControllerV1_post'];
type controllerV2 = operations['BrokerApplicationsSubmitApplicationControllerV2_post'];

export type BrokerSubmitApplicationBodyV1 = controllerV1['requestBody']['content']['application/json'];
export type BrokerSubmitApplicationResponseV1 = controllerV1['responses']['200']['content']['application/json'];
export type BrokerSubmitApplicationBodyV2 = controllerV2['requestBody']['content']['application/json'];
export type BrokerSubmitApplicationResponseV2 = controllerV2['responses']['200']['content']['application/json'];

export const submitApplicationV1 = POST('/v1/brokers/applications/submit-application')<
  BrokerSubmitApplicationBodyV1,
  BrokerSubmitApplicationResponseV1
>;

export const submitApplicationV2 = POST('/v2/brokers/applications/submit-application')<
  BrokerSubmitApplicationBodyV2,
  BrokerSubmitApplicationResponseV2
>;

export const submitApplication = async (
  applicationBody: BrokerSubmitApplicationBodyV1 | BrokerSubmitApplicationBodyV2,
  structuralVersionNumber?: number,
): Promise<BrokerSubmitApplicationResponseV1 | BrokerSubmitApplicationResponseV2> => {
  structuralVersionNumber = structuralVersionNumber || parseInt(import.meta.env.VITE_STRUCTURAL_VERSION_NUMBER || '0');
  if (structuralVersionNumber === 1) {
    const { data } = await submitApplicationV1(applicationBody);
    return data;
  }
  const { data } = await submitApplicationV2(applicationBody);
  return data;
};
