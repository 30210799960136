<template>
  <svg width="22" height="21" viewBox="0 0 22 21" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7887 18.6654C13.7887 19.4222 13.2001 20.0107 12.4434 20.0107H1.9332C1.17646 20.0107 0.587891 19.4222 0.587891 18.6654V1.9332C0.587891 1.17646 1.17646 0.587891 1.9332 0.587891H12.5275C13.2842 0.587891 13.8728 1.17646 13.8728 1.9332V6.55769"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.11914 4.37109H10.5934"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.11914 8.91211H10.5934"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.116 15.638L10.7617 15.7221L10.9299 13.3678L18.245 6.05273L20.5152 8.23886L13.116 15.638Z"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
