<template>
  <svg width="28" height="24" viewBox="0 0 28 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7676 14.1924H16.9617" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M13.8652 17.2969V11.0947" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M22.0451 12.7686V22.9204H5.97266V12.7686"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.13867 12.3659L13.6153 0.75L26.5988 12.6636"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
