import { LiabilityType } from '@/helpers/const';
import { yupNumber, yupObject, yupPeriodicValue, yupString } from '../../yup';

export const liabilitySchema = yupObject({
  type: yupString().required().label('Asset type'),
  institution: yupString() // creditCard
    .when('type', { is: LiabilityType.CARD, then: (schema) => schema.required() })
    .label('Institution'),
  creditLimit: yupNumber() // creditCard
    .when('type', { is: LiabilityType.CARD, then: (schema) => schema.required() })
    .label('Credit limit'),
  make: yupString() // vehicle
    .when('type', { is: LiabilityType.VEHICLE, then: (schema) => schema.required() })
    .label('Vehicle make'),
  model: yupString() // vehicle
    .when('type', { is: LiabilityType.VEHICLE, then: (schema) => schema.required() })
    .label('Vehicle model'),
  yearBought: yupString() // vehicle
    .when('type', { is: LiabilityType.VEHICLE, then: (schema) => schema.required() })
    .matches(/^[0-9]*$/, 'Numbers only')
    .length(4)
    .label('Year bought'),
  assetValue: yupNumber() // vehicle
    .when('type', { is: LiabilityType.VEHICLE, then: (schema) => schema.required() })
    .label('Asset value'),
  details: yupString() // other
    .when('type', { is: LiabilityType.OTHER, then: (schema) => schema.required() })
    .label('Description of liability'),
  liabilityLimit: yupNumber() // vehicle/other
    .when('type', { is: (val: string) => val !== LiabilityType.CARD, then: (schema) => schema.required() })
    .label('Asset value'),
  periodicRepayment: yupPeriodicValue(yupNumber().required()) // vehicle/other
    .when('type', { is: (val: string) => val !== LiabilityType.CARD, then: (schema) => schema.required() })
    .label('Periodic repayment'),
  amountOwing: yupNumber().required().label('Amount owing'), // creditCard/vehicle/other
});
