<template>
  <svg viewBox="0 0 30 30" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.7467 3V6.84C17.7467 7.37333 18.1734 7.8 18.7067 7.8H22.6"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.6 10.7333V7.8L17.7467 3H5.64001C4.84001 3 4.20001 3.64 4.20001 4.44V25.56C4.20001 26.36 4.84001 27 5.64001 27H21.16C21.96 27 22.6 26.36 22.6 25.56"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.3467 23.3733C22.3217 23.3733 24.7334 20.9616 24.7334 17.9867C24.7334 15.0117 22.3217 12.6 19.3467 12.6C16.3717 12.6 13.96 15.0117 13.96 17.9867C13.96 20.9616 16.3717 23.3733 19.3467 23.3733Z"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M26.3866 25.0266L23.1866 21.8266" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
