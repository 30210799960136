<template>
  <svg width="22" height="23" viewBox="0 0 22 23" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2808 18.2471C16.6169 18.2471 18.5108 16.3533 18.5108 14.0171C18.5108 11.6809 16.6169 9.78711 14.2808 9.78711C11.9446 9.78711 10.0508 11.6809 10.0508 14.0171C10.0508 16.3533 11.9446 18.2471 14.2808 18.2471Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M17.752 16.4326L21.202 18.8401" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M15 7.4025V3.03749C15.0001 2.63614 14.8432 2.25063 14.5629 1.96335C14.2827 1.67607 13.9013 1.50979 13.5 1.5H2.25002C1.84878 1.50979 1.46728 1.67607 1.187 1.96335C0.906726 2.25063 0.749901 2.63614 0.750021 3.03749V20.5575C0.747937 20.9601 0.903864 21.3475 1.18435 21.6364C1.46483 21.9253 1.84748 22.0927 2.25002 22.1025H13.5C13.9026 22.0927 14.2851 21.9253 14.5656 21.6364C14.8461 21.3475 15.0021 20.9601 15 20.5575"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M4.74805 5.19727H10.928" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M4.74805 8.19727H10.928" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
