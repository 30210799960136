<script setup lang="ts">
import { AppValues } from '@/helpers/const';
const customLogo = import.meta.env?.VITE_APP_CUSTOM_THEME_LOGO;
</script>

<template>
  <div class="app-header">
    <div class="flex w-full items-center justify-between">
      <RouterLink to="/">
        <img v-if="customLogo" :src="customLogo" class="h-10 w-auto" :alt="`${AppValues.COMPANY_NAME_SHORT} logo`" />
        <BxIcon v-else name="bridgit" class="h-10 w-auto" alt="Bridgit logo" />
      </RouterLink>
      <a :href="`tel:${AppValues.CONTACT}`" class="flex items-center gap-3">
        <BxIcon name="phone" />
        <div class="text-center">
          <div class="text-2xl">{{ AppValues.CONTACT }}</div>
          <div class="border-t border-gray-700 pt-[2px] text-xs">Weekdays 9am - 5:30pm</div>
        </div>
      </a>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.app-header {
  @apply mx-auto flex h-full w-full max-w-5xl px-4 sm:px-6 lg:px-8 xl:max-w-6xl 2xl:max-w-7xl;
}
</style>
