/* eslint-disable @typescript-eslint/no-explicit-any */
import type { LoanDetails } from '@/types';

export const additionalFunds = (data?: LoanDetails): any => ({
  hasAdditionalSavings: data ? data.savingsAmount > 0 : false,
  additionalSavingsAmount: 0, // data.savingsAmount,
  hasGiftedFunds: data ? data.giftedAmount > 0 : false,
  giftedSavings: data?.giftedAmount ?? 0,
  giftedSavingsPercentage:
    !data || data.savingsAmount === 0 ? 0 : Math.round((data.giftedAmount / data.savingsAmount) * 100),
});
