import { yupNumber, yupObject, yupPeriodicValue, yupString } from '../../yup';

export const expensesSchemaV1 = yupObject({
  numberOfDependants: yupNumber().required().label('Number of dependants'),
  groceries: yupPeriodicValue(yupNumber().required()).required().label('Groceries'),
  clothing: yupPeriodicValue(yupNumber().required()).required().label('Clothing & personal care'),
  education: yupPeriodicValue(yupNumber().required()).required().label('Education'),
  insurance_premium: yupPeriodicValue(yupNumber().required()).required().label('Insurance premiums'),
  childcare: yupPeriodicValue(yupNumber().required()).required().label('Child care'),
  medical_health: yupPeriodicValue(yupNumber().required()).required().label('Medicare/health'),
  recreation: yupPeriodicValue(yupNumber().required()).required().label('Recreation/entertainment'),
  communication: yupPeriodicValue(yupNumber().required()).required().label('Communication costs'),
  home_property_cost: yupPeriodicValue(yupNumber().required()).required().label('Home property costs'),
  investment_property_cost: yupPeriodicValue(yupNumber().required()).required().label('Investment property costs'),
  transport: yupPeriodicValue(yupNumber().required()).required().label('Transportation'),
  others: yupPeriodicValue(yupNumber().required()).required().label('Other costs'),
  expenseChange: yupString().required('You must select an option'),
  expenseChangeAmount: yupPeriodicValue(yupNumber().required())
    .when('expenseChange', { is: (val: string) => val !== 'noChange', then: (schema) => schema.required() })
    .label('Change in living expenses'),
});
