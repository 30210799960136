import type { operations } from '@/types/schemas/api.JS-2012';
import { GET } from '../../_http';
type controller = operations['PublicAdditionalFundsPurposesListAdditionalFundsPurposesController_get'];

export type ListAdditionalFundsPurposeResponse = controller['responses']['200']['content']['application/json'];
export type ListAdditionalFundsPurposes =
  controller['responses']['200']['content']['application/json']['additionalFundsPurposes'];

export const listAdditionalFundsPurposesResult = GET(
  '/public/additional-funds-purposes/list-additional-funds-purposes',
)<ListAdditionalFundsPurposeResponse>;

export const listAdditionalFundsPurposes = async (): Promise<ListAdditionalFundsPurposes> => {
  const { data } = await listAdditionalFundsPurposesResult();
  return data?.additionalFundsPurposes ?? [];
};
