<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import type { Component } from 'vue';
import { ref } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

withDefaults(
  defineProps<{
    open: boolean;
    icon?: Component;
    title: string;
    confirmLabel?: string;
    cancelLabel?: string;
  }>(),
  {
    icon: undefined,
    confirmLabel: '',
    cancelLabel: '',
  },
);

const emit = defineEmits(['close', 'confirm']);

const cancelButtonRef = ref<HTMLButtonElement>();
const confirm = () => emit('confirm');
const close = () => emit('close');
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" v-bind="$attrs" :initial-focus="cancelButtonRef" class="relative z-50" @close="emit('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-screen items-center justify-center p-4 text-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative w-full transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-xl sm:p-6 md:max-w-2xl lg:max-w-3xl"
            >
              <div class="sm:flex sm:items-start">
                <div
                  v-if="icon"
                  class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-sky-100 sm:mx-0 sm:h-10 sm:w-10"
                >
                  <component :is="icon" v-if="icon" class="h-6 w-6 text-sky-600" aria-hidden="true" />
                </div>
                <div class="mt-3 flex-1 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                    {{ title }}
                  </DialogTitle>
                  <div class="mt-2 text-left">
                    <slot></slot>
                  </div>
                </div>
              </div>
              <div class="mt-4 sm:ml-10 sm:mt-8 sm:flex sm:pl-4">
                <slot name="buttons" :confirm="confirm" :close="close">
                  <BxButton variant="primary" @click="confirm()">
                    {{ confirmLabel }}
                  </BxButton>
                  <BxButton ref="cancelButtonRef" variant="link" class="ml-6" @click="close()">
                    {{ cancelLabel }}
                  </BxButton>
                </slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
