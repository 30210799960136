import dayjs from '@/helpers/dayjs';
import { yupAddress, yupBoolean, yupMixed, yupNumber, yupObject, yupPeriodicValue, yupString } from '../../yup';
import type { DepositTypeEnum, IncomingProperty, PropertyTypeEnum, StampDutyStatusEnum } from '@/types';
import { DepositType, StampDutyStatus } from '@/helpers/const';

export const incomingPropertySchemaV1 = yupObject<IncomingProperty>({
  propertyType: yupMixed<PropertyTypeEnum>()
    .notOneOf(['vacant_land'], 'We currently do not accept vacant land as security')
    .required()
    .label('Property type'),
  address: yupAddress().required().label('Property address'),
  propertyPurpose: yupString().required().label('Property purpose'),
  contractForSale: yupBoolean().required().label('Contract for sale response'),
  settlementDate: yupString()
    .required()
    .isoDate()
    .test('validDate', '${path} must be within 12 months', (value) => {
      const date = dayjs(value);
      return date.isAfter(dayjs(), 'day') && date.isBefore(dayjs().add(1, 'year'), 'day');
    })
    .label('Purchase date'),
  purchaseAmount: yupNumber().required().label('Purchase price'),
  receiveRentalIncome: yupBoolean().required().label('Rental income response'),
  rentalIncome: yupPeriodicValue(yupNumber().required())
    .when('receiveRentalIncome', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Expected rental income'),
  depositAmount: yupNumber()
    .when('purchaseAmount', ([purchaseAmount], schema) =>
      schema.max(purchaseAmount, `The deposit cannot be more than the purchase price`),
    )
    .required()
    .label('Deposit amount'),
  depositType: yupMixed<DepositTypeEnum>().oneOf(Object.values(DepositType)).required().label('Deposit payment method'),
  stampDutyStatus: yupMixed<StampDutyStatusEnum>()
    .oneOf(Object.values(StampDutyStatus))
    // already_paid is only valid if contract for sale exists
    .when('contractForSale', {
      is: true,
      then: (schema) => schema.oneOf(Object.values(StampDutyStatus)),
      otherwise: (schema) =>
        schema.oneOf(Object.values(StampDutyStatus).filter((item) => item !== StampDutyStatus.ALREADY_PAID)),
    })
    .required()
    .label('Stamp duty response'),
});
