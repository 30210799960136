<script setup lang="ts">
import { computed, ref } from 'vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxLabel,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue';
import { nanoid } from 'nanoid';

const props = withDefaults(
  defineProps<{
    id?: string;
    label: string;
    labelType?: 'default' | 'overlapping' | 'floating';
    placeholder?: string;
    modelValue?: Record<string, unknown>;
    resultItems: Record<string, unknown>[];
    itemId: (item: Record<string, unknown>) => string;
    itemDisplayValue: (item: Record<string, unknown>) => string;
    errorMessage?: string;
    highlightError?: boolean;
  }>(),
  {
    id: undefined,
    labelType: 'default',
    placeholder: undefined,
    modelValue: undefined,
    errorMessage: undefined,
    highlightError: false,
  },
);

const emit = defineEmits<{
  (e: 'inputChange', value: string): void;
  (e: 'update:modelValue', value: { [key: string]: unknown } | undefined): void;
}>();

const inputId = computed(() => props.id ?? nanoid(8));

function displayValue(item: unknown) {
  if (!item) {
    return '';
  }
  return props.itemDisplayValue(item as Record<string, unknown>);
}

const wrapperRef = ref<HTMLDivElement>();
function handleFocusOut(event: Event) {
  // cancel event if focus remains within component
  const target = (event as FocusEvent).relatedTarget as HTMLElement;
  if (target && wrapperRef.value?.contains(target)) {
    event.stopImmediatePropagation();
  }
}
</script>

<template>
  <div ref="wrapperRef">
    <Combobox
      :model-value="((modelValue || null) as any)  /* note: value must be set to null to clear */"
      as="div"
      nullable
      @update:model-value="emit('update:modelValue', $event || undefined)"
      @focusout="handleFocusOut"
    >
      <ComboboxLabel v-if="labelType === 'default'" class="block text-sm font-medium text-gray-700">
        {{ label }}
      </ComboboxLabel>
      <div class="group relative mt-1">
        <ComboboxInput
          :id="inputId"
          :data-test-id="inputId"
          :display-value="displayValue"
          class="peer w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-base shadow-sm focus:border-bridgit-royalBlue focus:outline-none focus:ring-1 focus:ring-bridgit-royalBlue"
          :class="[
            errorMessage ? '!border-rose-500 focus:!ring-rose-500' : null,
            labelType === 'floating' ? 'placeholder-transparent group-focus-within:placeholder-gray-500' : null,
          ]"
          :placeholder="labelType === 'floating' ? placeholder || ' ' : placeholder"
          autocomplete="off"
          @change="emit('inputChange', $event.target.value)"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex cursor-default items-center rounded-r-md px-2 focus:outline-none"
          :class="{ 'pointer-events-none': !resultItems?.length }"
          :disabled="!resultItems?.length"
        >
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
        </ComboboxButton>
        <label
          v-if="labelType !== 'default'"
          :for="inputId"
          class="pointer-events-none absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
          :class="
            labelType === 'floating'
              ? [
                  'transition-all',
                  'peer-placeholder-shown:top-2 peer-placeholder-shown:mt-0 peer-placeholder-shown:text-base peer-placeholder-shown:font-normal peer-placeholder-shown:text-gray-700',
                  'group-focus-within:!-top-2 group-focus-within:!-mt-px group-focus-within:!text-xs group-focus-within:!font-medium group-focus-within:!text-gray-900',
                ]
              : []
          "
        >
          {{ label }}
        </label>
        <ComboboxOptions
          v-if="resultItems.length > 0"
          class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        >
          <ComboboxOption
            v-for="item in resultItems"
            v-slot="{ active, selected }"
            :key="itemId(item)"
            :value="(item as Record<string, unknown>)"
            as="template"
          >
            <li
              :class="[
                'relative cursor-default select-none py-2 pl-3 pr-9',
                active ? 'bg-sky-600 text-white' : 'text-gray-900',
              ]"
              :id-="item.id"
            >
              <span :class="['block', { 'font-semibold': selected }]">
                {{ itemDisplayValue(item) }}
              </span>
              <span
                v-if="selected"
                :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-sky-600']"
              >
                <CheckIcon class="h-5 w-5" aria-hidden="true" />
              </span>
            </li>
          </ComboboxOption>
        </ComboboxOptions>
      </div>
    </Combobox>
    <transition :enter-active-class="!highlightError ? 'animate-fadeIn' : ''">
      <p
        v-show="!!errorMessage"
        :id="`${inputId}-error`"
        class="mt-2 text-sm text-rose-500"
        :class="{ 'animate-shakeX': highlightError }"
      >
        {{ errorMessage }}
      </p>
    </transition>
  </div>
</template>
