import type { ComponentPublicInstance, Directive, DirectiveBinding, VNode } from 'vue';
import {
  Comment,
  Teleport,
  Text,
  computed,
  defineComponent,
  h,
  onMounted,
  onUnmounted,
  shallowRef,
  watch,
  ref,
} from 'vue';
import type { Instance, Props } from 'tippy.js';
import tippy from 'tippy.js';

type TippyElement = Element & {
  _tippy: Instance;
};

type VueRef = Element | ComponentPublicInstance | null;

function createOptions(value: string | Partial<Props> | undefined): Partial<Props> {
  if (typeof value === 'string') {
    return { content: value };
  } else if (typeof value === 'undefined') {
    return {};
  } else {
    return value;
  }
}

export const TippyDirective: Directive<TippyElement, string | Partial<Props> | undefined> = {
  mounted(el: TippyElement, binding: DirectiveBinding<string | Partial<Props> | undefined>): void {
    if (el._tippy) {
      el._tippy.destroy();
    }
    const tip = tippy(el, createOptions(binding.value));
    if (!tip.props.content) {
      tip.disable();
    }
  },
  unmounted(el: TippyElement): void {
    if (el._tippy) {
      el._tippy.destroy();
    }
  },
  updated(el: TippyElement, binding: DirectiveBinding<string | Partial<Props> | undefined>): void {
    if (el._tippy) {
      el._tippy.setProps(createOptions(binding.value));
      if (el._tippy.props.content) {
        el._tippy.enable();
      } else {
        el._tippy.disable();
      }
    }
  },
};

export const ToolTip = defineComponent({
  /*
    <ToolTip v-slot="{ setTarget }" content="Tooltip">
      <div :ref="setTarget">Target</div>
    </ToolTip>

    -- or --

    <ToolTip content="Tooltip">
      <div>Target</div>
    </ToolTip>

    -- or --

    <ToolTip>
      <template #default="{ setTarget }">
        <div :ref="setTarget">Target</div>
      </template>
      <template #content>
        <div>Tooltip</div>
      </template>
    </ToolTip>
  */

  name: 'ToolTip',

  props: {
    content: {
      type: String,
      default: undefined,
    },
    options: {
      type: Object,
      default: undefined,
    },
  },

  setup(props, { slots }) {
    const targetRef = shallowRef<VueRef | null>(null);
    const contentRef = shallowRef<VueRef | null>(null);
    const slotVNode = shallowRef<VNode | null>(null);
    const attached = ref(false);
    const targetElement = computed(() => {
      if (targetRef.value) {
        if (targetRef.value instanceof Element) {
          return targetRef.value;
        }
        if (targetRef.value.$el instanceof Element) {
          return targetRef.value.$el;
        }
        return null;
      }
      // note: use DOM element of rendered child node if target not set
      // warning - undocumented API!
      if (slotVNode.value && slotVNode.value.el instanceof Element) {
        return slotVNode.value.el;
      }
      return null;
    });
    const content = computed(() => {
      if (contentRef.value) {
        if (contentRef.value instanceof Element) {
          return contentRef.value;
        }
        return null;
      }
      return props.content ?? null;
    });

    const setTargetRef = (el: VueRef) => (targetRef.value = el);

    let _tippy: Instance | null = null;

    function tippyOptions() {
      return {
        ...props.options,
        ...(content.value && { content: content.value }),
      };
    }

    function init() {
      if (_tippy) {
        _tippy.destroy();
        _tippy = null;
      }
      if (targetElement.value) {
        _tippy = tippy(targetElement.value, tippyOptions());
        if (!_tippy.props.content) {
          _tippy.disable();
        }
        attached.value = true;
      } else {
        attached.value = false;
      }
    }

    function update() {
      if (_tippy) {
        _tippy.setProps(tippyOptions());
        if (_tippy.props.content) {
          _tippy.enable();
        } else {
          _tippy.disable();
        }
      }
    }

    onMounted(() => {
      init();
    });

    onUnmounted(() => {
      if (_tippy) {
        _tippy.destroy();
        _tippy = null;
        attached.value = false;
      }
    });

    watch(targetElement, () => {
      if (attached.value) {
        init();
      }
    });

    watch([content, () => props.options], () => {
      if (attached.value) {
        update();
      }
    });

    return () => {
      let result: VNode[];
      if (!slots.default) {
        slotVNode.value = null;
        result = [];
      } else {
        const slotContent = slots.default({
          setTarget: setTargetRef,
        });
        slotVNode.value =
          slotContent.length === 1 && slotContent[0].type !== Text && slotContent[0].type !== Comment
            ? slotContent[0]
            : null;
        result = slotContent;
      }
      if (slots.content) {
        result.push(
          h(Teleport, { to: 'body' }, [
            h(
              'div',
              {
                ref: (el: VueRef) => (contentRef.value = el),
                class: 'tippy-content-slot',
                style: `display: ${attached.value ? 'unset' : 'none'}`,
              },
              slots.content(),
            ),
          ]),
        );
      }
      return result;
    };
  },
});
