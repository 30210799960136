<script setup lang="ts">
import { LoanType } from '@/helpers/const';
import { useCalcApi } from '@/helpers/useCalcApi';
import { useBrokerLoanAppProcess } from '@/helpers/useBrokerLoanAppProcess';
import OwnershipForm from './modules/PropertyOwnership/OwnershipForm.vue';
import { StepStatus, type ApplicantId, type PropertyOwnership, BrokerProcessStep } from '@/types';
import { computed, onBeforeMount, ref } from 'vue';

const { send, stepper, loanApp, loanProperties, saveAndGoNext, goBack } = useBrokerLoanAppProcess();

const app = loanApp.value.applicants.reduce((obj, item) => {
  obj[item.id] = Math.floor(100 / loanApp.value.applicants.length);
  return obj;
}, {} as Record<ApplicantId, number>);

const properties = [
  ...(loanApp.value.loanType === LoanType.BRIDGING ? loanApp.value.incomingProperties : []),
  ...loanApp.value.outgoingProperties,
];

const po: PropertyOwnership[] = properties.map((item) => ({
  id: item.id,
  type: 'contractForSale' in item ? 'incoming' : 'outgoing',
  share: { ...app },
}));

const origOwnership = loanApp.value.propertiesOwnership;
const origApplicants = origOwnership.length ? Object.keys(origOwnership[0].share) : [];
const newApplicants = Object.keys(app);

if (origApplicants.length === newApplicants.length && newApplicants.every((a) => origApplicants.includes(a))) {
  for (let i = 0; i < po.length; i++) {
    const property = origOwnership.find((o) => o.id === po[i].id);
    if (property) {
      po[i].share = property.share;
    }
  }
}

const propertyOwnership = ref<PropertyOwnership[]>(po);

const fullOwnership = computed(() =>
  propertyOwnership.value.every((p) => {
    const total = Object.values(p.share).reduce((sum, share) => sum + share, 0);
    return total === 100;
  }),
);

const singleApplicant = computed(() => loanApp.value.applicants.length === 1);

const validateOwnership = () =>
  propertyOwnership.value.every((p) => {
    const total = Object.values(p.share).reduce((sum, share) => sum + share, 0);
    return total > 0 && total <= 100;
  });

const saveAndContinue = () => {
  if (validateOwnership()) {
    saveAndGoNext({
      propertiesOwnership: propertyOwnership.value,
    });
  }
};

const isReady = ref(false);
const calc = useCalcApi();
onBeforeMount(async () => {
  isReady.value = false;
  try {
    if (
      loanApp.value.loanDetails &&
      stepper.value.find((s) => s.id === BrokerProcessStep.PropertyOwnership)?.status === StepStatus.Ready
    ) {
      const endDebt = (await calc.getEndDebt(loanApp.value)) ?? 0;
      send({
        type: 'UPDATE',
        loanApp: { ...loanApp.value, endDebt },
      });
    }
  } finally {
    isReady.value = true;
  }
});
</script>

<template>
  <div>
    <div class="mb-8 pb-2">
      <h2 class="text-2xl font-medium">
        Tell us about the
        <BxHighlight>ownership percentage</BxHighlight>
        of the properties you are buying and selling?
      </h2>
    </div>

    <template v-for="(property, propertyIndex) in propertyOwnership" :key="property.id">
      <div class="mt-10">
        <h4 class="text-lg">
          <div class="font-medium">Property being {{ property.type === 'incoming' ? 'purchased' : 'sold' }}</div>
          <div>{{ loanProperties[property.id] }}</div>
        </h4>
        <OwnershipForm v-model="property.share" :asset-number="propertyIndex" class="mt-2" />
      </div>
    </template>
    <div v-if="!fullOwnership" class="mt-6 text-rose-600">
      <p v-if="singleApplicant">
        You must include all applicants to account for 100% ownership for all properties listed above.
      </p>
      <p v-else>Please note that ownership for all property listed above must each total to 100%</p>
    </div>
    <div class="mt-6 flex gap-4">
      <BxButton v-if="!fullOwnership && singleApplicant" variant="primary" @click="goBack()"
        >Add new applicant</BxButton
      >
      <BxButton v-else id="property-ownership-continue" :loading="!isReady" variant="primary" @click="saveAndContinue()"
        >Save and continue</BxButton
      >
      <BxButton variant="link" @click="goBack()">Back</BxButton>
    </div>
  </div>
</template>
