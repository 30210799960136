<script setup lang="ts">
import { computed, watch } from 'vue';
import { useForm } from 'vee-validate';
import { newSalarySchema, updateSalarySchema } from '@/validation';
import type { EmploymentEntry, SalaryTypeEmum } from '@/types';
import { forceScrollToTop } from '@/helpers/forceScrollToTop';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';

const props = withDefaults(
  defineProps<{
    applicantId: string;
    modelValue: EmploymentEntry;
    type: SalaryTypeEmum;
  }>(),
  {},
);

const emit = defineEmits<{
  (e: 'update:modelValue', v: EmploymentEntry): void;
}>();

const { loanApp } = useLoanAppProcess();
const salarySchema = computed(() => (loanApp.value.actionType === 'createNew' ? newSalarySchema : updateSalarySchema));

const { meta, submitCount, handleSubmit, resetForm } = useForm<EmploymentEntry>({
  validationSchema: salarySchema,
  validateOnMount: false,
});

watch(
  () => props.modelValue,
  (data) => {
    const values = { ...data };
    resetForm({ values });
  },
  { immediate: true, deep: true },
);

const update = handleSubmit(
  (values) => {
    emit('update:modelValue', { ...values, type: props.type });
    forceScrollToTop(0);
    return true;
  },
  () => false,
);
const cancel = () => {
  resetForm();
  forceScrollToTop(0);
};

defineExpose({ update, cancel });
</script>

<template>
  <div>
    <BxField v-slot="{ field }" name="salary">
      <BxPeriodicAmount
        :id="applicantId + '-salary'"
        v-bind="field"
        label="Salary"
        label-type="floating"
        leading-symbol="$"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="commission">
      <BxPeriodicAmount
        :id="applicantId + '-commission'"
        v-bind="field"
        label="Commissions"
        label-type="floating"
        leading-symbol="$"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="bonuses">
      <BxPeriodicAmount
        :id="applicantId + '-bonus'"
        v-bind="field"
        label="Bonuses"
        label-type="floating"
        leading-symbol="$"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="overtime">
      <BxPeriodicAmount
        :id="applicantId + '-overtime'"
        v-bind="field"
        label="Overtime pay"
        label-type="floating"
        leading-symbol="$"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="salarySacrifice">
      <BxRadio
        :id="applicantId + '-salary-sacrifice'"
        v-bind="field"
        label="Do you salary sacrifice with this employer?"
        label-type="default"
        class="form-control"
        boolean
      />
    </BxField>
    <BxField v-slot="{ field }" name="employedSince">
      <BxDatePicker
        v-bind="field"
        :id="applicantId + '-employed-since'"
        label="Date employment commenced"
        label-type="floating"
        placeholder="DD/MM/YYYY"
        :max-date="new Date()"
        class="form-control"
      />
    </BxField>
    <BxField v-slot="{ field }" name="employerName">
      <BxInput
        :id="applicantId + '-employer-name'"
        v-bind="field"
        label="Name of current employer"
        label-type="floating"
        class="form-control"
      />
    </BxField>
    <BxField :id="applicantId + '-employer-address'" v-slot="{ field }" name="employerAddress">
      <BxAddressInput
        :id="applicantId + '-employer-address'"
        v-bind="field"
        label="Employer Address"
        label-type="floating"
        class="form-control"
      />
    </BxField>

    <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
      Please review the error messages above.
    </BxErrorPanel>
  </div>
</template>
