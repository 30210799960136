<script setup lang="ts">
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import type { ApplicantId } from '@/types';
import { ref, watch } from 'vue';

export interface ApplicantPropertyShare {
  id: ApplicantId;
  share?: number;
}

const { loanApplicants } = useLoanAppProcess();

const props = defineProps<{
  modelValue: Record<ApplicantId, number>;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', v: Record<ApplicantId, number>): void;
}>();

const applicants = ref<ApplicantPropertyShare[]>([]);

const checkInput = (id: number) => {
  const share = applicants.value[id].share ?? 0;
  if (share > 100) {
    applicants.value[id].share = 100;
  }

  if (applicants.value.length > 1) {
    const total = applicants.value.reduce((sum, item) => sum + (item.share ?? 0), 0);
    if (total > 100) {
      const otherIndex = id === 0 ? 1 : 0;
      applicants.value[otherIndex].share = 100 - share;
    }
  }

  const newValue = applicants.value.reduce((obj, item) => {
    obj[item.id] = item.share ?? 0;
    return obj;
  }, {} as Record<ApplicantId, number>);
  emit('update:modelValue', newValue);
};

const onlyNumbers = (event: KeyboardEvent) => {
  if (event.key < '0' || event.key > '9') {
    event.preventDefault();
  }
};

watch(
  () => props.modelValue,
  (val) => {
    applicants.value = Object.entries(val).map(([id, share]) => ({ id, share }));
  },
  { immediate: true },
);
</script>

<template>
  <div class="flex w-full gap-4">
    <div v-for="(applicant, index) in applicants" :key="applicant.id">
      <BxInput
        :model-value="applicant.share?.toString()"
        :label="applicants.length > 1 ? loanApplicants[applicant.id].preferredName : ''"
        :maxlength="applicant.share?.toString().startsWith('1') ? '3' : '2'"
        @update:model-value="applicant.share = $event === '' ? undefined : Number($event)"
        @keypress="onlyNumbers"
        @blur="checkInput(index)"
      >
        <template #trailing>
          <span class="mx-4 text-gray-400">%</span>
        </template>
      </BxInput>
    </div>
  </div>
</template>
