<script setup lang="ts">
import { computed, ref } from 'vue';
import { cloneDeep } from 'lodash-es';
import { useTemplateRefsList } from '@vueuse/core';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import { PlusCircleIcon } from '@heroicons/vue/20/solid';
import { ApplicantType } from '@/helpers/const';
import { createApplicant, createFinancials, defaultApplicant } from '@/helpers/builder';
import type { Applicant, ApplicantEntry, ApplicantFinancials } from '@/types';
import ApplicantCard from './modules/ApplicantDetails/ApplicantCard.vue';
import PrivacyConsentModal from '@/components/shared/PrivacyConsentModal.vue';
import LogRocket from 'logrocket';
import { AppValues } from '@/helpers/const';

const { loanApp, saveAndGoNext, goBack } = useLoanAppProcess();

const applicants: (Applicant | ApplicantEntry)[] = loanApp.value.applicants.length
  ? cloneDeep(loanApp.value.applicants)
  : [createApplicant(ApplicantType.PRIMARY)];

const primary = ref<ApplicantEntry>(applicants[0]);
const secondaries = ref<ApplicantEntry[]>(
  applicants.filter((a: Applicant | ApplicantEntry) => a.type === ApplicantType.SECONDARY),
);
const primaryRef = ref<InstanceType<typeof ApplicantCard>>();
const secondaryRefs = useTemplateRefsList<InstanceType<typeof ApplicantCard>>();

const allowAdditionalApplicants = computed(() => secondaries.value.length === 0);

const validateApplicants = async () => {
  const validatePrimary = (await primaryRef.value?.update()) ?? false;
  const validateSecondary = secondaries.value.length === 0 || (await secondaryRefs.value[0].update());
  return validatePrimary && validateSecondary;
};

const addApplicant = async () => {
  if (await validateApplicants()) {
    // primaryRef.value?.$el.scrollIntoView(true);
    secondaries.value.push(createApplicant(ApplicantType.SECONDARY));
  }
};

const removeApplicant = (applicantId: string) => {
  secondaries.value = secondaries.value.filter((a) => a.id !== applicantId);
};

const saveAndContinue = async () => {
  if (await validateApplicants()) {
    const applicants: Applicant[] = [primary.value, ...secondaries.value].map((app) =>
      Object.assign(defaultApplicant(), app),
    );
    const original = loanApp.value.financials;
    const financials = applicants.reduce((fin, app) => {
      fin[app.id] = original[app.id] ?? createFinancials();
      return fin;
    }, {} as ApplicantFinancials);
    if (window.location.host === 'apply.bridgit.com.au' && primary.value.email?.length) {
      LogRocket.identify(primary.value.email, {
        name: `${primary.value.firstName} ${primary.value.lastName}`,
        email: primary.value.email as string,
        mobilePhoneNumber: primary.value.mobile as string,
      });
    }
    saveAndGoNext({
      applicants,
      financials,
    });
  }
};

const showPrivacyModal = ref(false);
</script>

<template>
  <div>
    <div class="mb-8 pb-2">
      <h2 class="text-2xl font-medium">
        Provide
        <BxHighlight>your details</BxHighlight>
      </h2>
      <p v-if="allowAdditionalApplicants" class="mt-2">
        You can add a second applicant by clicking 'add another applicant' below.
      </p>

      <p class="mt-2">
        If there are more than two applicants, we will request the information for the remaining applicants after the
        application is submitted.
      </p>
    </div>

    <div class="mt-6">
      <ApplicantCard ref="primaryRef" v-model="primary" type="primary" />
    </div>
    <div v-if="allowAdditionalApplicants" class="mt-6">
      <BxButton id="applicants-add" variant="tertiary" @click="addApplicant()">
        <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        Add another applicant
      </BxButton>
    </div>
    <div v-else class="mt-6">
      <ApplicantCard
        :ref="secondaryRefs.set"
        v-model="secondaries[0]"
        type="secondary"
        :primary="primary"
        @remove="removeApplicant"
      />
    </div>

    <p v-if="loanApp.userType !== 'Broker'" class="mt-10 text-sm">
      By clicking <strong>"Save and continue"</strong> you confirm that you have read and agree to
      {{ AppValues.COMPANY_NAME + `'s` }}
      <a class="link" @click="showPrivacyModal = true"> Privacy Consent and Electronic Authorisation</a> and consent to
      the collection, use, holding and disclosure of my/our personal information as set out in that document, and to
      receiving notices, statements, disclosures and other documents from {{ AppValues.COMPANY_NAME }} electronically.
    </p>

    <div class="mt-10">
      <BxButton id="applicants-continue" variant="primary" @click="saveAndContinue()">Save and continue</BxButton>
      <BxButton variant="link" class="ml-4" @click="goBack()">Back</BxButton>
    </div>
    <PrivacyConsentModal v-model="showPrivacyModal" />
  </div>
</template>

<style lang="scss" scoped>
.link {
  @apply cursor-pointer text-bridgit-navy underline hover:text-bridgit-vibrantGreen;
}
</style>
