<script setup lang="ts">
import type { ExpenseTypeDto } from '@/api/backend/public/expense-types/expense-type.dto';
import type { FundsExpenseDto } from '@/api/backend/users/applications';
import { defaultPeriodicValue } from '@/helpers/builder';
import { expenseTypeDescriptions } from '@/helpers/const';
import { useBrokerLoanAppProcess } from '@/helpers/useBrokerLoanAppProcess';
import type {
  ApplicantExpense,
  ApplicantExpenseEntry,
  ExpensePeriodicValue,
  ListOptions,
  PeriodicValue,
} from '@/types';
import { expensesSchemaV1 } from '@/validation/schemas/v1/expenses';
import { expensesSchemaV2 } from '@/validation/schemas/v2/expenses';
import { useForm } from 'vee-validate';
import { watch } from 'vue';

const { authorisedUser, loanApp, loanApplicants } = useBrokerLoanAppProcess();

const props = withDefaults(
  defineProps<{
    applicantId: string;
    modelValue: ApplicantExpense | null;
    showButtons?: boolean;
    disabled?: boolean;
    expenseTypes?: ExpenseTypeDto[];
  }>(),
  {
    showButtons: false,
    expenseTypes: undefined,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', v: ApplicantExpense): void;
  (e: 'save'): void;
  (e: 'cancel'): void;
}>();

const dependantOptions: ListOptions = {
  '0': '0 dependants',
  '1': '1 dependant',
  '2': '2 dependants',
  '3': '3+ dependants',
};

const expenseChangeOptions: ListOptions = {
  noChange: 'No, there will be no changes to my expenses',
  decrease: 'Yes, there will be a decrease to my expenses',
  increase: 'Yes, there will be an increase to my expenses',
};

const { values, meta, submitCount, handleSubmit, resetForm } = useForm<ApplicantExpenseEntry>({
  validationSchema:
    loanApp.value.structuralVersionNumber === 1 ? expensesSchemaV1 : expensesSchemaV2(props.expenseTypes || []),
  validateOnMount: false,
});

values.numberOfDependants = loanApplicants?.value[authorisedUser?.id]?.numberOfDependants;

const getExpense = (data?: FundsExpenseDto): PeriodicValue => ({
  amount: data?.expenseAmountApplicationValue ?? 0,
  frequency: 'month',
});

watch(
  () => props.modelValue,
  (data) => {
    const values: ApplicantExpenseEntry = {
      ...data,
      ...data?.fundsExpenses?.reduce(
        (acc: FundsExpenseDto & Record<string, PeriodicValue | FundsExpenseDto>, expense: FundsExpenseDto) => {
          const expenseType = props.expenseTypes?.find(
            (effectiveExpense) => effectiveExpense.id === expense.expenseTypeId,
          );
          if (expenseType?.type) {
            acc[expenseType.type] = getExpense(expense);
          } else if (expense.expenseTypeId) {
            acc[expense.expenseTypeId.toString()] = expense;
          }
          return acc;
        },
        {},
      ),
    };
    if (data !== null) {
      const predictedExpenseChangeAmount = data.predictedExpenseChange.amount;
      values.expenseChange =
        predictedExpenseChangeAmount > 0 ? 'increase' : predictedExpenseChangeAmount < 0 ? 'decrease' : 'noChange';
      if (values.predictedExpenseChange) {
        values.expenseChangeAmount = {
          amount: Math.abs(predictedExpenseChangeAmount),
          frequency: values.predictedExpenseChange.frequency,
        };
      }
    }
    resetForm({ values });
  },
  { immediate: true, deep: true },
);

const update = handleSubmit(
  (values) => {
    const newValues: ExpensePeriodicValue = {} as ExpensePeriodicValue;

    if (props.expenseTypes) {
      for (const expense of props.expenseTypes) {
        if (expense.type) {
          newValues[expense.type] = { ...(values[expense.type] as PeriodicValue), expenseTypeId: expense.id ?? '' };
        }
      }
    }

    emit('update:modelValue', {
      ...newValues,
      numberOfDependants: values.numberOfDependants ?? 0,
      predictedExpenseChange:
        !values.expenseChangeAmount || values.expenseChange === 'noChange'
          ? defaultPeriodicValue()
          : values.expenseChange === 'decrease'
          ? { amount: -values.expenseChangeAmount.amount, frequency: values.expenseChangeAmount.frequency }
          : values.expenseChangeAmount,
    });
    return true;
  },
  () => false,
);

const save = async () => {
  if (await update()) {
    emit('save');
  }
};
const cancel = () => {
  resetForm();
  emit('cancel');
};

defineExpose({ update, cancel });
</script>

<template>
  <div>
    <BxField v-slot="{ field }" name="numberOfDependants">
      <BxRadio
        v-bind="field"
        :id="applicantId + '-dependants'"
        :disabled="disabled"
        :options="dependantOptions"
        :model-value="values.numberOfDependants?.toString() ?? undefined"
        label="How many dependants do you have?"
        label-type="default"
        block
        class="form-control mt-10"
      />
    </BxField>

    <h2 class="mb-4 mt-10 text-xl font-medium">Regular expenses</h2>

    <template v-for="expenseType in expenseTypes" :key="expenseType.type">
      <BxField v-slot="{ field }" :name="expenseType.type || ''" :disabled="disabled">
        <BxPeriodicAmount
          :id="applicantId + '-expenses-' + expenseType.type"
          :disabled="disabled"
          v-bind="field"
          :label="expenseTypeDescriptions[expenseType.type ?? ''].shortFormTitle || ''"
          :help-text="expenseTypeDescriptions[expenseType.type ?? ''].tooltip || ''"
          label-type="floating"
          leading-symbol="$"
          class="form-control"
        />
      </BxField>
    </template>
    <h2 class="mb-4 mt-12 text-xl font-medium">Changes in your living expenses</h2>
    <BxField v-slot="{ field }" name="expenseChange">
      <BxRadio
        v-bind="field"
        :id="applicantId + '-expenses-change'"
        :disabled="disabled"
        :options="expenseChangeOptions"
        label="After taking out this loan, do you envisage an increase or decrease to your living expenses?"
        label-type="default"
        block
        class="form-control"
      />
    </BxField>
    <BxField
      v-if="values.expenseChange && values.expenseChange !== 'noChange'"
      v-slot="{ field }"
      name="expenseChangeAmount"
    >
      <BxPeriodicAmount
        v-bind="field"
        :id="applicantId + '-expenses-change-amount'"
        :label="`How much do you think your expenses will ${values.expenseChange}?`"
        label-type="default"
        leading-symbol="$"
        class="form-control"
      />
    </BxField>

    <BxErrorPanel v-show="!meta.valid && submitCount > 0" class="mt-6">
      Please review the error messages above.
    </BxErrorPanel>

    <div v-if="props.showButtons" class="mt-10 flex gap-4">
      <BxButton variant="secondary" @click="save()">Save expense details</BxButton>
      <BxButton variant="link" @click="cancel()">Cancel</BxButton>
    </div>
  </div>
</template>
