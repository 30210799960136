<script setup lang="ts">
import type { IncomingProperty } from '@/types';
import { pluralise } from '@/helpers/pluralise';
import { formatAddress, formatCurrency, formatDate } from '@/helpers/format';
import { propertyTypeOptions, stampDutyStatusOptions } from '@/helpers/const';
import type { DepositPaidType } from '@/api/enums';

const depositTypeOptions: Record<DepositPaidType, string> = {
  ['cash_payment']: 'Cash payment',
  ['deposit_bond']: 'Deposit bond',
};

defineProps<{
  data: IncomingProperty[];
  settlementDate: string;
}>();
</script>

<template>
  <section class="summary-details">
    <h2>Buying</h2>
    <h3>Number of properties</h3>
    <dl>
      <dt>How many properties are you buying?</dt>
      <dd>{{ pluralise(data.length, 'property', 'properties') }}</dd>
    </dl>
    <template v-for="(item, index) in data" :key="item.id">
      <h3>Property {{ index + 1 }} - Information</h3>
      <dl>
        <dt>Type</dt>
        <dd>{{ item.propertyType && propertyTypeOptions[item.propertyType] }}</dd>
        <dt>Do you have a contract for sale?</dt>
        <dd>{{ item.contractForSale ? 'Yes' : 'No' }}</dd>
        <dt>Address</dt>
        <dd>{{ formatAddress(item.address) }}</dd>
      </dl>
      <h3>Property {{ index + 1 }} - Deposit</h3>
      <dl>
        <dt>{{ item.contractForSale ? 'Deposit' : 'Estimated deposit' }} amount</dt>
        <dd>{{ formatCurrency(item.depositAmount) }}</dd>
        <dt>{{ item.contractForSale ? 'Purchase' : 'Estimated purchase' }} price</dt>
        <dd>{{ formatCurrency(item.purchaseAmount) }}</dd>
        <dt>{{ item.contractForSale ? 'Settlement' : 'Estimated purchase' }} date</dt>
        <dd>{{ formatDate(settlementDate) }}</dd>
        <dt>{{ item.contractForSale ? 'How was your deposit paid?' : 'How will you pay your deposit?' }}</dt>
        <dd>{{ item.depositType && depositTypeOptions[item.depositType] }}</dd>
        <dt>How will stamp duty to be paid?</dt>
        <dd>{{ item.stampDutyStatus && stampDutyStatusOptions[item.stampDutyStatus] }}</dd>
      </dl>
    </template>
  </section>
</template>
