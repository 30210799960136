import type { ApplicationDto } from '@/api/backend/users/applications';
import type { LoanDetails } from '@/types';
import type { ApiSchema } from '@/types/api';

const mapAdditionalFunds = (data?: ApiSchema['AdditionalFundDto']) => {
  return {
    savingsAmount: data?.additionalSavingsAmount ?? 0,
    giftedAmount: data?.giftedFundsAmount ?? 0,
  };
};

export const additionalFunds = (data: ApplicationDto): LoanDetails => {
  return {
    ...mapAdditionalFunds(data.additionalFunds),
    reasonDescription: '',
    brokerFeePercentage: data.calculatedValue?.brokerFee ?? 0,
    borrowAmount: 0,
    calcLoanAmount: data.calculatedValue?.loanAmount ?? 0,
  };
};
