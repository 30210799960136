<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { OutgoingPropertyEntry } from '@/types/Property';
import { formatAddress } from '@/helpers/format';
import { TrashIcon } from '@heroicons/vue/24/outline';
import OutgoingPropertyForm from './OutgoingPropertyForm.vue';

const props = withDefaults(
  defineProps<{
    modelValue: OutgoingPropertyEntry;
    isMultiple?: boolean;
  }>(),
  {
    isMultiple: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', v: OutgoingPropertyEntry): void;
  (e: 'remove', id: string): void;
}>();

const inputVal = ref<OutgoingPropertyEntry>(props.modelValue);
const newItem = ref(!props.modelValue.address);
const showEntry = ref(!props.modelValue.address);
const propertyRef = ref<InstanceType<typeof OutgoingPropertyForm>>();
const address = computed(() =>
  props.modelValue.address ? formatAddress(props.modelValue.address) : 'the new property',
);

const editProperty = () => {
  showEntry.value = true;
};

const update = async () => {
  const saved = (await propertyRef.value?.update()) ?? false;
  if (saved) {
    showEntry.value = false;
  }
  return saved;
};
const cancel = () => {
  if (newItem.value) {
    removeItem();
  } else {
    propertyRef.value?.cancel();
    showEntry.value = false;
  }
};

const showModal = ref(false);

const removeItem = () => {
  showModal.value = false;
  emit('remove', props.modelValue.id);
};

watch(
  () => props.modelValue,
  (val) => {
    inputVal.value = val;
    newItem.value = !props.modelValue.address;
    showEntry.value = !props.modelValue.address;
  },
  { immediate: true },
);

watch(
  () => props.isMultiple,
  (val) => {
    showEntry.value = !val || newItem.value;
  },
  { immediate: true },
);

defineExpose({ update });
</script>

<template>
  <div>
    <BxExpander v-model="showEntry" separator>
      <template #header>
        <div class="px-4 py-5 sm:px-6">
          <template v-if="newItem">
            <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
              <h3 class="text-lg font-medium leading-6 text-gray-900">Property details</h3>
            </div>
          </template>
          <template v-else-if="showEntry">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Edit property</h3>
          </template>
          <template v-else>
            <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div class="ml-4 mt-4">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  {{ [modelValue.address?.addressLine, modelValue.address?.addressLine2].filter((x) => x).join(', ') }}
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  {{ modelValue.address?.locality }}, {{ modelValue.address?.state }}
                  {{ modelValue.address?.postcode }}
                </p>
              </div>
              <div class="ml-4 mt-4 flex-shrink-0">
                <BxButton variant="secondary" @click="editProperty()">Edit</BxButton>
                <BxButton v-if="isMultiple" variant="link" class="ml-4" @click="showModal = true">Remove</BxButton>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template #body>
        <div class="p-6">
          <OutgoingPropertyForm
            ref="propertyRef"
            :model-value="inputVal"
            @update:model-value="emit('update:modelValue', $event)"
          />
          <div v-if="isMultiple" class="mt-10 flex-shrink-0">
            <BxButton :id="inputVal.id + '-save'" variant="secondary" @click="update()">Save</BxButton>
            <BxButton variant="link" class="ml-4" @click="cancel()">Cancel</BxButton>
          </div>
        </div>
      </template>
    </BxExpander>
    <BxModal
      :open="showModal"
      :icon="TrashIcon"
      title="Remove property"
      confirm-label="Remove"
      cancel-label="Cancel"
      @close="showModal = false"
      @confirm="removeItem()"
    >
      Do you want to remove
      <span class="text-bridgit-green">{{ address }}</span
      >?
    </BxModal>
  </div>
</template>
