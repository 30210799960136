import { createToast, type ToastType } from 'mosha-vue-toastify';

export interface ToastOptions {
  type?: ToastType;
  title: string;
  description?: string;
  timeout?: number;
}

export const toast = ({ title, description, type, timeout = 3000 }: ToastOptions) => {
  if (type === 'danger') timeout = 10_000;
  createToast({ title, description }, { hideProgressBar: true, showIcon: true, type, timeout });
};
