import type { EmploymentTypeEnum, PeriodicValue, PeriodKey } from '@/types';
import { periodType } from '@/helpers/const';

const multiplier = (frequency: PeriodKey, jobType?: EmploymentTypeEnum) =>
  frequency === 'week' && jobType === 'casual' ? 46 : periodType[frequency].multiplier;

export const convertExpensePeriod = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any | undefined,
  toPeriod: PeriodKey,
  jobType?: EmploymentTypeEnum,
): PeriodicValue => {
  if (value === undefined) {
    return { amount: 0, frequency: 'month' };
  }
  if (value.expenseAmount === undefined || isNaN(value.expenseAmount) || toPeriod === 'month') {
    return { amount: value.expenseAmount, frequency: toPeriod };
  }
  return {
    amount: (value.expenseAmount * multiplier('month', jobType)) / multiplier(toPeriod, jobType),
    frequency: toPeriod,
  };
};
