<script setup lang="ts">
import { computed, ref } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import { nanoid } from 'nanoid';

const props = withDefaults(
  defineProps<{
    id?: string;
    label: string;
    labelType?: 'default' | 'overlapping' | 'floating';
    placeholder?: string;
    modelValue?: string | Record<string, unknown>;
    listItems: string[] | Record<string, unknown>[] | Record<string, string>;
    itemId?: (item: unknown) => string;
    itemLabel?: (item: unknown) => string;
    truncate?: boolean;
    errorMessage?: string;
    highlightError?: boolean;
  }>(),
  {
    id: undefined,
    labelType: 'default',
    placeholder: undefined,
    modelValue: undefined,
    itemId: undefined,
    itemLabel: undefined,
    truncate: true,
    errorMessage: undefined,
    highlightError: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | Record<string, unknown>): void;
}>();

const inputId = computed(() => props.id ?? nanoid(8));

const listOptions = computed(() => {
  if (Array.isArray(props.listItems)) {
    const entries = props.listItems.map((item) => {
      if (typeof item === 'string') {
        return { id: item, label: item, value: item };
      } else {
        const id = props.itemId ? props.itemId(item) : '';
        const label = props.itemLabel ? props.itemLabel(item) : '';
        return { id, label, value: { id, label } };
      }
    });
    return entries;
  } else {
    return Object.entries(props.listItems).map(([id, label]) => ({ id, label, value: id }));
  }
});

const keyValue = computed(() =>
  listOptions.value.reduce((obj, item) => {
    obj[item.id] = item.label;
    return obj;
  }, {} as Record<string, string>),
);

const wrapperRef = ref<HTMLDivElement>();
function handleFocusOut(event: Event) {
  // cancel event if focus remains within component
  const target = (event as FocusEvent).relatedTarget as HTMLElement;
  if (target && wrapperRef.value?.contains(target)) {
    event.stopImmediatePropagation();
  }
}
</script>

<template>
  <div ref="wrapperRef">
    <Listbox
      v-slot="{ open, value }"
      :model-value="((modelValue || null) as any)  /* note: value must be set to null to clear */"
      as="div"
      @update:model-value="emit('update:modelValue', $event)"
      @focusout="handleFocusOut"
    >
      <ListboxLabel v-if="labelType === 'default'" class="block text-sm font-medium text-gray-700">
        {{ label }}
      </ListboxLabel>
      <div class="relative my-1">
        <ListboxButton
          :id="props.id"
          :data-test-id="props.id"
          class="relative w-full cursor-default rounded-md border border-gray-300 bg-white pr-9 text-left shadow-sm focus:border-bridgit-royalBlue focus:outline-none focus:ring-1 focus:ring-bridgit-royalBlue sm:text-sm"
          :class="errorMessage ? '!border-rose-500 focus:!border-rose-500 focus:!ring-rose-500' : null"
        >
          <span class="block h-10 truncate px-3 py-2 text-base" :class="{ 'text-gray-500': !value }">
            {{ value && itemLabel ? itemLabel(value) : keyValue[value] }}
          </span>
          <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </ListboxButton>
        <ListboxLabel
          v-if="labelType !== 'default'"
          class="pointer-events-none absolute -top-2 left-2 -mt-px inline-block bg-white px-1 text-xs font-medium text-gray-900"
          :class="
            labelType === 'floating'
              ? ['transition-all', !value && !open ? '!top-2 !mt-0 !text-base !font-normal !text-gray-700' : '']
              : []
          "
        >
          {{ label }}
        </ListboxLabel>
        <transition
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            <ListboxOption
              v-for="item in listOptions"
              :id="`${props.id}-${item.id}`"
              :key="item.id"
              v-slot="{ active, selected }"
              :data-test-id="`${props.id}-${item.id}`"
              as="template"
              :value="item.value"
            >
              <li
                :class="[
                  active ? 'bg-bridgit-royalBlue text-white' : 'text-gray-900',
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                ]"
              >
                <span
                  :id="`${props.id}-${item.id}-label`"
                  :data-test-id="`${props.id}-${item.id}-label`"
                  :class="[selected ? 'font-semibold' : 'font-normal', 'block ', { truncate: truncate }]"
                  >{{ item.label }}</span
                >
                <span
                  v-if="selected"
                  :class="[
                    active ? 'text-white' : 'text-bridgit-royalBlue',
                    'absolute inset-y-0 right-0 flex items-center pr-4',
                  ]"
                >
                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>
    <transition :enter-active-class="!highlightError ? 'animate-fadeIn' : ''">
      <p
        v-show="!!errorMessage"
        :id="`${inputId}-error`"
        :data-test-id="`${inputId}-error`"
        class="text-sm text-rose-500"
        :class="{ 'animate-shakeX': highlightError }"
      >
        {{ errorMessage }}
      </p>
    </transition>
  </div>
</template>
