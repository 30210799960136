<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.2954 4.53463H11.4488C11.1569 4.4919 10.859 4.51791 10.5789 4.61057C10.2988 4.70322 10.0442 4.85997 9.83532 5.06835L1.54725 12.5589C1.17388 12.9677 0.977845 13.5078 1.00199 14.0609C1.02614 14.614 1.26856 15.135 1.67613 15.5097L8.12377 22.1659C8.49975 22.5757 9.02299 22.8195 9.57861 22.8437C10.1342 22.8678 10.6767 22.6704 11.0868 22.2948L19.0191 14.3932C19.2188 14.187 19.3706 13.9394 19.4639 13.668C19.5572 13.3966 19.5897 13.1081 19.559 12.8227L19.7001 8.06826C19.7001 7.26461 19.8902 6.45479 19.5099 5.70022C19.3575 5.39022 19.1279 5.12469 18.8432 4.92914C18.5585 4.73359 18.2282 4.61461 17.8841 4.58368"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.2504 9.75C23.1004 9.34955 22.9363 9.49137 23.4355 8.69534C23.9346 7.89931 24.1948 6.97675 24.185 6.03721C24.1706 4.70376 23.6337 3.42917 22.6896 2.48737C21.7455 1.54557 20.4696 1.01173 19.1361 1.00056C18.4802 0.990667 17.8291 1.11234 17.221 1.35841C16.613 1.60447 16.0604 1.96994 15.596 2.43319C15.1317 2.89644 14.7649 3.44808 14.5173 4.05551C14.2697 4.66294 14.1464 5.31381 14.1547 5.9697C14.161 6.94535 14.4509 7.89813 14.989 8.71196"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
