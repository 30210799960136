<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { ApplicantEntry, ApplicantTypeEnum } from '@/types/Applicant';
import { ApplicantType } from '@/helpers/const';
import { getFullName } from '@/helpers/format';
import { applicantSchema } from '@/validation';
import { TrashIcon } from '@heroicons/vue/24/outline';
import ApplicantForm from './ApplicantForm.vue';

const props = withDefaults(
  defineProps<{
    modelValue: ApplicantEntry;
    type: ApplicantTypeEnum;
    primary?: ApplicantEntry;
  }>(),
  {
    primary: undefined,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', v: ApplicantEntry): void;
  (e: 'edit', id: string): void;
  (e: 'remove', id: string): void;
}>();

const showEntry = ref(!props.modelValue.firstName || !props.modelValue.lastName);
const applicantRef = ref<InstanceType<typeof ApplicantForm>>();
const applicant = ref<ApplicantEntry>(props.modelValue);
const editItem = () => {
  showEntry.value = true;
};
const fullName = computed(() => getFullName(props.modelValue));

const saveChanges = async () => {
  const saved = (await applicantRef.value?.update()) ?? false;
  if (saved) {
    emit('update:modelValue', applicant.value);
    showEntry.value = false;
  }
  return saved;
};
const cancelChanges = () => {
  applicantRef.value?.cancel();
  showEntry.value = false;
};

const showModal = ref(false);
const openModal = () => {
  showModal.value = true;
};
const removeItem = () => {
  showModal.value = false;
  emit('remove', props.modelValue.id);
};

defineExpose({ update: saveChanges });

watch(
  () => props.modelValue,
  async (val) => {
    applicant.value = val;
    showEntry.value = !(await applicantSchema(props.primary).isValid(val));
  },
  { immediate: true },
);
</script>

<template>
  <div>
    <BxExpander v-model="showEntry" separator>
      <template #header>
        <div class="px-4 py-5 sm:px-6">
          <template v-if="!modelValue.firstName">
            <div class="flex flex-wrap items-center justify-between sm:flex-nowrap">
              <h3 class="text-lg font-medium leading-6 text-gray-900">
                {{ props.type === ApplicantType.PRIMARY ? 'Primary applicant' : 'New applicant' }} details
              </h3>
            </div>
          </template>
          <template v-else-if="showEntry">
            <h3 class="text-lg font-medium leading-6 text-gray-900">{{ fullName }}</h3>
          </template>
          <template v-else>
            <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
              <div class="ml-4 mt-4">
                <h3 class="text-lg font-medium leading-6 text-gray-900">
                  {{ fullName }}
                </h3>
              </div>
              <div class="ml-4 mt-4 flex-shrink-0">
                <BxButton variant="secondary" @click="editItem()">Edit</BxButton>
                <BxButton
                  variant="link"
                  class="ml-4"
                  :disabled="props.type !== ApplicantType.SECONDARY"
                  @click="openModal()"
                >
                  Remove
                </BxButton>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template #body>
        <div class="p-6">
          <ApplicantForm ref="applicantRef" v-model="applicant" :type="props.type" :primary="primary" />
          <div class="mt-10 flex-shrink-0">
            <BxButton :id="`${applicant.id}-applicant-save`" variant="secondary" @click="saveChanges()">Save</BxButton>
            <BxButton v-if="modelValue.firstName" variant="link" class="ml-4" @click="cancelChanges()">Cancel</BxButton>
            <BxButton v-else variant="link" class="ml-4" @click="openModal()">Cancel</BxButton>
          </div>
        </div>
      </template>
    </BxExpander>
    <BxModal
      :open="showModal"
      :icon="TrashIcon"
      title="Remove applicant"
      confirm-label="Remove"
      cancel-label="Cancel"
      @close="showModal = false"
      @confirm="removeItem()"
    >
      Do you want to remove
      <span class="text-bridgit-green">{{ fullName || 'the new applicant' }}</span
      >?
    </BxModal>
  </div>
</template>
