<template>
  <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.06006 15C2.06006 7.54415 8.10422 1.5 15.5601 1.5C23.0159 1.5 29.0601 7.54415 29.0601 15C29.0601 22.4558 23.0159 28.5 15.5601 28.5C8.10421 28.5 2.06006 22.4558 2.06006 15Z"
      stroke="#0C1744"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M15.5601 21L15.5601 15" stroke="#0C1744" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.5601 9L15.5445 9" stroke="#0C1744" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>
