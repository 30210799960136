<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import type { PeriodKey, PeriodicValue } from '@/types';
import { periodEnum, periodType } from '@/helpers/const';
import BxNumberInput from '@/components/common/BxNumberInput.vue';
import { nanoid } from 'nanoid';

const props = withDefaults(
  defineProps<{
    id?: string;
    modelValue?: Partial<PeriodicValue>;
    periodOptions?: Array<PeriodKey>;
    disabled?: boolean;
    helpText?: string;
  }>(),
  {
    id: undefined,
    modelValue: () => ({ amount: undefined, frequency: 'month' }),
    periodOptions: () => [...periodEnum],
    disabled: false,
    helpText: undefined,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: Partial<PeriodicValue>): void;
}>();

const inputId = computed(() => props.id ?? nanoid(8));
const data = ref<Partial<PeriodicValue>>(props.modelValue);

watch(
  () => props.modelValue,
  (periodicValue) => {
    // note: use Object.is to allow comparison of NaN values
    if (!Object.is(data.value.amount, periodicValue.amount)) {
      data.value.amount = periodicValue.amount;
    }
    if (data.value.frequency !== periodicValue.frequency) {
      data.value.frequency = periodicValue.frequency;
    }
  },
);

function handleValueChange(value: number | undefined) {
  data.value.amount = value;
  emit('update:modelValue', data.value);
}

function handlePeriodChange(event: Event) {
  data.value.frequency = (event.target as HTMLInputElement).value as PeriodKey;
  emit('update:modelValue', data.value);
}

function handleFocusOut(event: Event) {
  // cancel event if focus remains within component
  const targetId = ((event as FocusEvent).relatedTarget as HTMLElement)?.id;
  if (targetId && (targetId === props.id || targetId === `${props.id}-period`)) {
    event.stopImmediatePropagation();
  }
}
</script>

<template>
  <BxNumberInput
    :id="inputId"
    :data-test-id="inputId"
    :model-value="data.amount"
    :class="[disabled && 'opacity-50', 'b-periodic-input']"
    :disabled="disabled"
    :help-text="helpText"
    @update:model-value="handleValueChange"
    @focusout="handleFocusOut"
  >
    <template #trailing>
      <select
        :id="`${inputId}-period`"
        :data-test-id="`${inputId}-period`"
        :value="data.frequency"
        class="h-full rounded-md border-transparent bg-transparent py-0 pl-3 pr-6 text-right focus:border-bridgit-royalBlue focus:ring-bridgit-royalBlue group-focus-within:block"
        :tabindex="data.amount === undefined ? -1 : 0"
        :disabled="disabled"
        @change="handlePeriodChange"
      >
        <option v-for="item in periodOptions" :key="item" :value="item">{{ periodType[item].label }}&nbsp;</option>
      </select>
    </template>
  </BxNumberInput>
</template>

<style lang="scss" scoped>
.b-periodic-input {
  --select-color: transparent;

  select {
    color: var(--select-color);

    &:focus {
      --select-color: var(--color-gray-500);
    }
  }

  input[type='text'] {
    &:focus + div select,
    &:not(:placeholder-shown) + div select {
      --select-color: var(--color-gray-500);
    }
  }
}
</style>
