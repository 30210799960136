import type { Applicant, ApplicantExpense, ApplicantId, Financials } from '@/types';
import type { ApiSchema } from '@/types/api';
import { convertPeriod } from '@/helpers/convertPeriod';
import { mapToApi } from '@/mappers';

export const applicant = (
  data: Applicant,
  financials: Financials,
):
  | ApiSchema['CustomerApplicationsSubmitApplicationApplicantDtoV1']
  | ApiSchema['CustomerApplicationsSubmitApplicationApplicantDtoV2'] => {
  const expenses = financials.expenses;
  const monthlyLivingExpensesChange = convertPeriod(expenses?.predictedExpenseChange, 'month').amount;

  const monthlyExpenses = expenses
    ? Object.values(expenses as Record<string, ApplicantExpense | null>)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map((expense: any | null) => {
          if (expense !== null) {
            const convertedAmount = convertPeriod(expense, 'month').amount;
            if (isNaN(convertedAmount)) {
              console.error('convertPeriod returned NaN for:', expense);
            }
            return isNaN(convertedAmount) ? 0 : convertedAmount;
          } else {
            return 0;
          }
        })
        .reduce((acc, curr) => acc + curr, 0)
    : 0;

  const relationshipWithPrimaryApplicant = data.type === 'secondary' ? data.relationshipToPrimary : undefined;

  const additionalDetails = {
    gender: data.gender,
    referralNote: data.referralNote,
  };

  return {
    ...additionalDetails,

    applicantType: data.type,
    firstName: data.firstName,
    middleName: data.middleName,
    lastName: data.lastName,
    preferredName: data.preferredName,

    dateOfBirth: data.birthdate,
    relationshipStatus: data.relationshipStatus,
    relationshipWithPrimaryApplicant,
    otherRelationshipDescription: data.relationshipOtherInfo,

    line1: data.address.addressLine,
    line2: data.address.addressLine2 ?? '',
    city: data.address.locality,
    state: mapToApi.australianState(data.address.state),
    postCode: data.address.postcode,
    country: 'au',

    mobilePhoneNumber: data.mobile.startsWith('+61') ? '0' + data.mobile.substring(3) : data.mobile,
    email: data.email,

    isApplicantDeclared: false,
    isRetired: financials.income?.employment.some((e) => e.type === 'retired') ?? false,
    isUnemployed: financials.income?.employment.some((e) => e.type === 'unemployed') ?? false,

    numberOfDependants: Number(financials.expenses?.numberOfDependants) || 0,
    totalExpenses: monthlyExpenses,
    expenseDecreaseMonthlyAmount: monthlyLivingExpensesChange < 0 ? Math.abs(monthlyLivingExpensesChange) : 0,
    expenseIncreaseMonthlyAmount: monthlyLivingExpensesChange > 0 ? monthlyLivingExpensesChange : 0,
  };
};

export const applicantIndex = (applicantId: ApplicantId, applicants: Applicant[]) =>
  applicants.findIndex((a) => a.id === applicantId);
