<script setup lang="ts">
import { watch, ref, computed } from 'vue';
import { CheckIcon } from '@heroicons/vue/20/solid';
import { nanoid } from 'nanoid';

const props = withDefaults(
  defineProps<{
    id?: string;
    modelValue?: boolean;
    label?: string;
    disabled?: boolean;
    highlightError?: boolean;
    errorMessage?: string;
  }>(),
  {
    id: undefined,
    modelValue: false,
    label: '',
    disabled: false,
    highlightError: false,
    errorMessage: undefined,
  },
);

const inputId = computed(() => props.id ?? nanoid(8));
const isChecked = ref(props.modelValue);
const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean | undefined): void;
}>();

const onCheckChanged = () => {
  emit('update:modelValue', !isChecked.value);
};

watch(
  () => props.modelValue,
  (val) => {
    isChecked.value = val;
  },
  { immediate: true },
);
</script>

<template>
  <div>
    <div class="wrapper-checkbox">
      <div class="checkbox">
        <label>
          <input
            :id="inputId"
            v-model="isChecked"
            :data-test-id="inputId"
            type="checkbox"
            :disabled="props.disabled"
            @input="onCheckChanged"
          />
          <div class="check">
            <div class="icon">
              <CheckIcon class="h-5 w-5 text-green-600" />
            </div>
          </div>
        </label>
      </div>
      <div class="label">
        <label :for="inputId">{{ props.label }}</label>
      </div>
    </div>
    <transition :enter-active-class="!highlightError ? 'animate-fadeIn' : ''">
      <p
        v-show="!!errorMessage"
        :id="`${inputId}-error`"
        class="mt-2 text-sm text-rose-500"
        :class="{ 'animate-shakeX': highlightError }"
      >
        {{ errorMessage }}
      </p>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.wrapper-checkbox {
  --border-radius: 0.4rem;
  --color-selected: #172b79;

  display: inline-flex;
  align-items: stretch;
  height: auto;
  width: 100%;

  .checkbox {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: var(--border-radius);
    border: 1px solid #d5d8de;
  }

  .label {
    display: inline-flex;
    align-items: center;
    padding-left: 0.5rem;
  }

  .check {
    width: 100%;
    height: 100%;
    visibility: hidden;
    transform: scale(0.3);
    transition: transform 0.1s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    width: 1.2rem;
    height: 1.2rem;
  }

  input {
    display: none;
    &:checked ~ .check {
      visibility: visible;
      transform: scale(1);
    }
  }
}
</style>
