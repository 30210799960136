import { AxiosError } from 'axios';
import backend from './backend';
import calculator from './calculator';
import * as enums from './enums';

const getErrorMsg = (error: unknown, defaultErrorMsg = 'Something went wrong'): string =>
  error instanceof AxiosError ? error.response?.data?.message ?? error.message : defaultErrorMsg;

export default {
  backend,
  calculator,
  enums,
  getErrorMsg,
};
