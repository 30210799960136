<script setup lang="ts">
import { onMounted, ref } from 'vue';
const trustpilot = ref();
const customLogo = import.meta.env?.VITE_APP_CUSTOM_THEME_LOGO;
const aggregator = import.meta.env?.VITE_APP_CUSTOM_THEME_AGGREGATOR;
onMounted(() => {
  if (window.Trustpilot) {
    window.Trustpilot.loadFromElement(trustpilot.value, true);
  }
});
</script>

<template>
  <div class="footer2">
    <div class="footer mx-auto h-full max-w-5xl px-4 sm:px-6 lg:px-8 xl:max-w-6xl 2xl:max-w-7xl">
      <div class="footer__logo">
        <img v-if="customLogo" :src="customLogo" class="h-10 w-auto" :alt="`${aggregator} logo`" />
        <BxIcon v-else name="bridgit" class="h-10 w-auto" alt="Bridgit logo" />
      </div>
      <div class="footer__section1 text-[10px]">
        <p>
          Eligibility and approval is subject to standard credit assessment and not all amounts, term lengths or rates
          will available to all applications. Fees, terms and conditions apply.
        </p>
        <p class="mt-4">We use bank level encryption and security across our platform.</p>
      </div>
      <div class="footer__section2 flex items-center justify-around gap-4 text-[10px] md:justify-end">
        <div class="">
          <!-- TrustBox widget - Mini -->
          <div
            ref="trustpilot"
            class="trustpilot-widget"
            data-locale="en-US"
            data-template-id="53aa8807dec7e10d38f59f32"
            data-businessunit-id="6216ad9ba106dfb98a8b5eaf"
            data-style-height="80px"
            data-style-width="100px"
            data-theme="light"
          >
            <a href="https://www.trustpilot.com/review/bridgit.com.au" target="_blank" rel="noopener">Trustpilot</a>
          </div>
          <!-- End TrustBox widget -->
        </div>
        <div class="flex basis-[14.3rem] flex-wrap items-center gap-y-3">
          <a href="https://www.bridgit.com.au/terms-conditions" target="_blank" class="basis-1/2">
            Terms &amp; Conditions
          </a>
          <a href="https://www.bridgit.com.au/privacy-policy" target="_blank" class="basis-1/2 text-right">
            Privacy Policy
          </a>
          <div>Copyright © 2023 Bridgit. All rights reserved.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer2 {
  @apply border-t border-gray-100;
  @apply bg-white text-gray-500;
  @apply py-8;

  a {
    @apply text-bridgit-navy hover:text-bridgit-green;
  }
}

.footer {
  display: grid;
  grid-template:
    'logo' auto
    'section1' auto
    'section2' auto;
  gap: 2rem;

  &__logo {
    grid-area: logo;
  }

  &_section1 {
    grid-area: section1;
  }

  &_section2 {
    grid-area: section2;
  }

  @screen md {
    grid-template:
      'logo logo' 50px
      'section1 section2' 100px
      / 4fr 5fr;
  }

  @screen lg {
    grid-template:
      'logo section1 section2' 1fr
      / 200px 1fr 1fr;
  }
}
</style>
