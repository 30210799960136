<script setup lang="ts">
import BrokerApplicationLayout from '@/layouts/BrokerApplicationLayout.vue';
import CustomerApplicationLayout from '@/layouts/CustomerApplicationLayout.vue';
import DeclarationLayout from '@/layouts/DeclarationLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';
import RequestIncomeExpenseLayout from '@/layouts/RequestIncomeExpenseLayout.vue';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const layout = computed(() => {
  const layout = route.meta.layout;
  switch (layout) {
    case 'EmptyLayout':
      return EmptyLayout;
    case 'BrokerApplicationLayout':
      return BrokerApplicationLayout;
    case 'DeclarationLayout':
      return DeclarationLayout;
    case 'RequestIncomeExpenseLayout':
      return RequestIncomeExpenseLayout;
    default:
      return CustomerApplicationLayout;
  }
});

onMounted(() => {
  const link = document?.querySelector("link[rel~='icon']") as unknown as { href: string };
  const customIcon = import.meta.env?.VITE_APP_CUSTOM_THEME_ICON;
  if (link && customIcon) {
    link.href = customIcon;
  }
});
</script>

<template>
  <component :is="layout">
    <RouterView />
  </component>
</template>
