import { object, ValidationError } from 'yup';
import { addressSchema } from '../schemas/v1';

export function yupAddress() {
  return object().test(
    'address',
    ({ label }) => `${label} is not valid`,
    async (value, ctx) => {
      try {
        if (value) {
          await addressSchema.validate(value, { abortEarly: false });
        }
        return true;
      } catch (err) {
        if (err instanceof ValidationError) {
          const errorResult = ctx.createError();
          errorResult.errors.push(...err.errors);
          return errorResult;
        }
        throw err;
      }
    },
  );
}
