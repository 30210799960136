import { yupNumber, yupObject, yupPeriodicValue, yupString } from '../../yup';
import type { ExpenseTypeDto } from '@/api/backend/public';
import { expenseTypeDescriptions } from '@/helpers/const';
import type { ObjectShape } from 'yup';

export const expensesSchemaV2 = (expenseTypes: ExpenseTypeDto[]) => {
  const yupConfiguration: ObjectShape = {
    numberOfDependants: yupNumber().required().label('Number of dependants'),
    expenseChange: yupString().required('You must select an option'),
    expenseChangeAmount: yupPeriodicValue(yupNumber().required())
      .when('expenseChange', { is: (val: string) => val !== 'noChange', then: (schema) => schema.required() })
      .label('Change in living expenses'),
  };

  for (const expenseType of expenseTypes) {
    yupConfiguration[expenseType.type || ''] = yupPeriodicValue(yupNumber().required())
      .required()
      .label(expenseTypeDescriptions[expenseType.type || ''].shortFormTitle);
  }
  return yupObject(yupConfiguration);
};
