import {
  CustomerApplicationProcessStep,
  CustomerApplicationProcessStepNames,
  CustomerApplicationProcessSteps,
  StepStatus,
  type ActionType,
  type AppUser,
  type CustomerApplicationProcessStepEnum,
  type LoanApplication,
  type ProcessStatus,
} from '@/types';
import { assign, createMachine } from 'xstate';
import { createLoanApp } from './builder';
import { LoanType } from './const';

export interface LoanAppMachineContext {
  loanApp: LoanApplication;
  from?: string;
  authorisedUser: Record<string, string>;
  // targetApplicant: ApplicantId;
  status: ProcessStatus;
  current: CustomerApplicationProcessStepEnum;
}

export const initMachine = (params?: {
  actionType: ActionType;
  userType: AppUser;
  brokerId?: string;
}): LoanAppMachineContext => ({
  loanApp: createLoanApp(params),
  // targetApplicant: '',
  authorisedUser: {},
  status: Object.fromEntries(
    CustomerApplicationProcessStepNames.map((step) => [
      step,
      step === CustomerApplicationProcessStep.SelectCustomerType
        ? StepStatus.Completed
        : step === CustomerApplicationProcessStep.SelectLoanType
        ? StepStatus.Ready
        : StepStatus.Incomplete,
    ]),
  ) as ProcessStatus,
  current: CustomerApplicationProcessStep.SelectCustomerType,
});

export type LoanAppMachineEvent =
  | { type: 'NEXT'; loanApp: LoanApplication }
  | { type: 'UPDATE'; loanApp: LoanApplication }
  | { type: 'BACK' }
  | { type: 'READY' }
  | { type: 'GOTO'; step: CustomerApplicationProcessStepEnum }
  | { type: 'RELOAD'; state: LoanAppMachineContext }
  | { type: 'RESTART' }
  | { type: 'RESTART_CUSTOMER' }
  | { type: 'RESTART_BROKER'; brokerId: string };

export const loanApplicationMachine = createMachine<LoanAppMachineContext, LoanAppMachineEvent>(
  {
    id: 'loanApplication',
    predictableActionArguments: true,
    schema: {
      context: {} as LoanAppMachineContext,
    },
    context: initMachine(),
    initial: 'start',
    states: {
      start: {
        on: {
          NEXT: {
            target: 'gotoReady',
          },
        },
      },
      [CustomerApplicationProcessStep.SelectCustomerType]: {
        on: {
          NEXT: [
            {
              cond: (ctx) => ctx.loanApp.actionType === 'updateExisting',
              target: CustomerApplicationProcessStep.Summary,
            },
            {
              target: CustomerApplicationProcessStep.SelectLoanType,
            },
          ],
        },
      },
      [CustomerApplicationProcessStep.SelectLoanType]: {
        on: {
          NEXT: [
            {
              cond: (ctx, evt) => evt.loanApp.loanType === LoanType.EQUITY_RELEASE,
              target: CustomerApplicationProcessStep.OutgoingProperties,
              actions: 'saveData',
            },
            {
              target: CustomerApplicationProcessStep.IncomingProperties,
              actions: 'saveData',
            },
          ],
        },
      },
      [CustomerApplicationProcessStep.IncomingProperties]: {
        on: {
          NEXT: {
            target: CustomerApplicationProcessStep.OutgoingProperties,
            actions: 'saveData',
          },
        },
      },
      [CustomerApplicationProcessStep.OutgoingProperties]: {
        on: {
          NEXT: {
            target: CustomerApplicationProcessStep.LoanDetails,
            actions: 'saveData',
          },
        },
      },
      [CustomerApplicationProcessStep.LoanDetails]: {
        on: {
          NEXT: {
            target: CustomerApplicationProcessStep.ApplicantDetails,
            actions: 'saveData',
          },
        },
      },
      [CustomerApplicationProcessStep.ApplicantDetails]: {
        on: {
          NEXT: {
            target: CustomerApplicationProcessStep.PropertyOwnership,
            actions: 'saveData',
          },
        },
      },
      [CustomerApplicationProcessStep.PropertyOwnership]: {
        on: {
          NEXT: {
            target: 'checkDebt',
            actions: 'saveData',
          },
        },
      },
      checkDebt: {
        always: [
          {
            target: CustomerApplicationProcessStep.Income,
            cond: 'showIncomeExpenses',
          },
          {
            target: CustomerApplicationProcessStep.Assets,
            cond: 'showAssets',
          },
          {
            target: CustomerApplicationProcessStep.VerifyIdentity,
            cond: 'hasNoBroker',
          },
          {
            target: CustomerApplicationProcessStep.SubmitApplication,
          },
        ],
      },
      [CustomerApplicationProcessStep.Summary]: {
        on: {
          NEXT: [
            {
              target: CustomerApplicationProcessStep.Income,
              actions: 'saveData',
              cond: 'showIncomeExpenses',
            },
            {
              cond: (ctx) =>
                ctx.loanApp.userType === 'Broker' ||
                !!ctx.loanApp.brokerId ||
                ctx.loanApp.actionType === 'updateExisting',
              target: CustomerApplicationProcessStep.SubmitApplication,
              actions: 'saveData',
            },
          ],
        },
      },
      [CustomerApplicationProcessStep.Income]: {
        on: {
          NEXT: {
            target: CustomerApplicationProcessStep.Expenses,
            actions: 'saveData',
          },
        },
      },
      [CustomerApplicationProcessStep.Expenses]: {
        on: {
          NEXT: {
            target: CustomerApplicationProcessStep.Assets,
            actions: 'saveData',
          },
        },
      },
      [CustomerApplicationProcessStep.Assets]: {
        on: {
          NEXT: [
            {
              cond: (ctx) =>
                ctx.loanApp.userType === 'Broker' ||
                !!ctx.loanApp.brokerId ||
                ctx.loanApp.actionType === 'updateExisting',
              target: CustomerApplicationProcessStep.SubmitApplication,
              actions: 'saveData',
            },
            {
              target: CustomerApplicationProcessStep.VerifyIdentity,
              actions: 'saveData',
            },
          ],
        },
      },
      [CustomerApplicationProcessStep.VerifyIdentity]: {
        on: {
          NEXT: {
            target: CustomerApplicationProcessStep.SubmitApplication,
            actions: 'saveData',
          },
        },
      },
      [CustomerApplicationProcessStep.SubmitApplication]: {
        on: {
          NEXT: {
            target: CustomerApplicationProcessStep.Finished,
            actions: 'saveData',
          },
        },
      },
      [CustomerApplicationProcessStep.Finished]: {
        on: {
          NEXT: {
            target: CustomerApplicationProcessStep.SelectCustomerType,
            actions: 'resetLoanApplication',
          },
        },
      },
      prevStage: {
        always: [...CustomerApplicationProcessStepNames]
          .sort((a, b) => CustomerApplicationProcessSteps[b].order - CustomerApplicationProcessSteps[a].order)
          .map((step) => ({
            target: step,
            cond: (ctx) =>
              CustomerApplicationProcessSteps[ctx.current].order > CustomerApplicationProcessSteps[step].order &&
              ctx.status[step] === StepStatus.Completed,
          })),
      },
      gotoReady: {
        always: CustomerApplicationProcessStepNames.map((step) => ({
          target: step,
          cond: (ctx) => ctx.status[step] === StepStatus.Ready,
        })),
      },
    },
    on: {
      BACK: {
        target: 'prevStage',
        cond: (ctx) => ctx.current !== CustomerApplicationProcessStep.SelectCustomerType,
      },
      READY: {
        target: 'gotoReady',
      },
      UPDATE: {
        actions: 'saveData',
      },
      GOTO: CustomerApplicationProcessStepNames.map((step) => ({
        target: step,
        cond: (ctx, evt) => evt.step === step,
      })),
      RELOAD: {
        target: 'gotoReady',
        actions: 'updateContext',
      },
      RESTART: {
        target: CustomerApplicationProcessStep.SelectCustomerType,
        actions: 'resetLoanApplication',
      },
      RESTART_CUSTOMER: {
        target: CustomerApplicationProcessStep.SelectLoanType,
        actions: 'resetCustomerApplication',
      },
      RESTART_BROKER: {
        target: CustomerApplicationProcessStep.SelectLoanType,
        actions: 'resetBrokerApplication',
      },
    },
  },
  {
    actions: {
      saveData: assign((context, event) => {
        return event.type === 'NEXT' || event.type === 'UPDATE'
          ? {
              loanApp: event.loanApp,
              status: Object.fromEntries(
                Object.entries(context.status).map(([step, status]) => [
                  step,
                  step === context.current
                    ? StepStatus.Completed
                    : CustomerApplicationProcessSteps[step as CustomerApplicationProcessStepEnum].order <
                      CustomerApplicationProcessSteps[context.current].order
                    ? status
                    : StepStatus.Incomplete,
                ]),
              ) as ProcessStatus,
            }
          : {};
      }),
      updateContext: assign((context, event) => (event.type === 'RELOAD' ? event.state : {})),
      resetLoanApplication: assign(() => initMachine({ userType: 'Primary', actionType: 'createNew' })),
      resetCustomerApplication: assign(() => initMachine({ userType: 'Primary', actionType: 'createNew' })),
      resetBrokerApplication: assign((context, event) =>
        initMachine({
          userType: 'Broker',
          brokerId: event.type === 'RESTART_BROKER' ? event.brokerId : '',
          actionType: 'createNew',
        }),
      ),
    },
    guards: {
      hasEndDebt: (ctx) => ctx.loanApp.endDebt > 0,
      showIncomeExpenses: (ctx) => {
        return (
          (ctx.loanApp.endDebt > 0 || (ctx.from ? ctx.from === 'crm' : false)) &&
          ctx.loanApp.loanType !== LoanType.SINGLE_SECURITY
        );
      },
      showAssets: (ctx) => {
        return ctx.loanApp.loanType === LoanType.SINGLE_SECURITY;
      },
      hasNoBroker: (ctx) => ctx.loanApp.userType !== 'Broker',
    },
  },
);
