import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { HttpGetRoutes, HttpPostRoutes } from './_routes';
import axios from 'axios';

type ExtractRouteParams<T extends string> = string extends T
  ? Record<string, string>
  : T extends `${string}{${infer Param}}/${infer Rest}`
  ? { [K in Param | keyof ExtractRouteParams<Rest>]: string }
  : T extends `${string}{${infer Param}}`
  ? { [K in Param]: string }
  : // eslint-disable-next-line @typescript-eslint/ban-types
    {};

export const httpCalc = axios.create({
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
  responseType: 'json',
  baseURL: import.meta.env.VITE_TECHLEND_CALCS_URL,
});

const createUrl = (str: string, obj: Record<string, string | number | boolean> = {}) =>
  str.replace(/\{(.*?)}/g, (x, g) => (obj[g] as string) ?? '');

const getRouteParams = (path: string): string[] => {
  const matches = path.match(/{([^}]+)}/g);
  return matches?.map((match) => match.slice(1, -1)) ?? [];
};

const removeKeys = (config: AxiosRequestConfig, keys: string[]): AxiosRequestConfig => {
  const params = { ...config.params };
  for (const key of keys) {
    delete params[key];
  }
  return { ...config, params };
};

export function GET<T extends HttpGetRoutes, ConfigOpts = unknown>(
  path?: T,
  config?: (options: ConfigOpts) => AxiosRequestConfig,
): T extends `${string}{${string}`
  ? <ResponseData = void>(params: ExtractRouteParams<T> & ConfigOpts) => Promise<AxiosResponse<ResponseData>>
  : ConfigOpts extends object
  ? <ResponseData = void>(params: ConfigOpts) => Promise<AxiosResponse<ResponseData>>
  : <ResponseData = void>() => Promise<AxiosResponse<ResponseData>>;

export function GET<T extends HttpGetRoutes, ConfigOptions = unknown>(
  path: T,
  config?: (options: ConfigOptions) => AxiosRequestConfig,
) {
  const routeParams = getRouteParams(path);
  return path.indexOf('{') < 0 && !config
    ? <ResponseData>() => httpCalc.get<ResponseData>(path)
    : <ResponseData>(params: ExtractRouteParams<T> & ConfigOptions) =>
        httpCalc.get<ResponseData>(
          createUrl(path, params),
          config && params && removeKeys(config(params), routeParams),
        );
}

export function POST<T extends HttpPostRoutes, ConfigOpts = unknown>(
  path?: T,
  config?: (options: ConfigOpts) => AxiosRequestConfig,
): T extends `${string}{${string}`
  ? <PostData, ResponseData = void>(
      params: ExtractRouteParams<T> & ConfigOpts,
      data?: PostData,
    ) => Promise<AxiosResponse<ResponseData>>
  : ConfigOpts extends object
  ? <PostData, ResponseData = void>(params: ConfigOpts, data?: PostData) => Promise<AxiosResponse<ResponseData>>
  : <PostData, ResponseData = void>(data?: PostData) => Promise<AxiosResponse<ResponseData>>;

export function POST<T extends HttpPostRoutes, ConfigOptions = unknown>(
  path: T,
  config?: (options: ConfigOptions) => AxiosRequestConfig,
) {
  const routeParams = getRouteParams(path);
  return path.indexOf('{') < 0 && !config
    ? <PostData, ResponseData = void>(data?: PostData) => httpCalc.post<ResponseData>(path, data)
    : <PostData, ResponseData = void>(params: ExtractRouteParams<T> & ConfigOptions, data?: PostData) =>
        httpCalc.post<ResponseData>(
          createUrl(path, params),
          data ?? {},
          config && params && removeKeys(config(params), routeParams),
        );
}

export const setParams = <T>(params: T) => ({ params });
