<script setup lang="ts">
import api from '@/api';
import { LoanType } from '@/helpers/const';
import { useDeclarationProcess } from '@/helpers/useDeclarationProcess';
import ApplicantSummary from './ApplicantSummary.vue';
import BuyingSummary from './BuyingSummary.vue';
import LoanDetailSummary from './LoanDetailSummary.vue';
import PropertyOwnershipSummary from './PropertyOwnershipSummary.vue';
import SellingSummary from './SellingSummary.vue';
import type { ListAdditionalFundsPurposes } from '@/api/backend/public';
import { ref, onBeforeMount } from 'vue';

const { loanApp, loanProperties, loanApplicants } = useDeclarationProcess();
let listAdditionalFundsPurposes: ListAdditionalFundsPurposes = [];

const loanPurpose = ref('');
onBeforeMount(async () => {
  try {
    listAdditionalFundsPurposes = await api.backend.public.listAdditionalFundsPurposes();
  } finally {
    loanPurpose.value =
      listAdditionalFundsPurposes?.find(
        (item) => item.id === loanApp.value.additionalFundsRequired?.additionalFundsPurposeId,
      )?.title ?? '';
  }
});
</script>

<template>
  <div>
    <section v-if="loanApp.loanType === LoanType.BRIDGING" class="summary-details">
      <h2>Loan Purpose</h2>
      <h3>Loan type</h3>
      <dl>
        <dt>Your loan purpose</dt>
        <dd>{{ loanApp.loanType === LoanType.BRIDGING ? 'Buy now, sell later' : 'Unlock equity before I sell' }}</dd>
      </dl>
      <br />
      <dl v-if="loanApp.additionalFundsRequired?.description">
        <dt>Details</dt>
        <dd>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="loanApp.additionalFundsRequired.description"></div>
        </dd>
      </dl>
    </section>
    <section v-else-if="loanApp.loanType === LoanType.SINGLE_SECURITY" class="summary-details">
      <h2>Loan Purpose</h2>
      <h3>Loan type</h3>
      <dl>
        <dt>Your loan purpose</dt>
        <dd>{{ loanPurpose }}</dd>
      </dl>
      <br />
      <dl>
        <dt>Details</dt>
        <dd>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="loanApp.additionalFundsRequired?.description"></div>
        </dd>
      </dl>
    </section>

    <BuyingSummary
      v-if="loanApp.incomingProperties.length"
      :data="loanApp.incomingProperties"
      :settlement-date="loanApp.settlementDate || ''"
    />
    <SellingSummary :data="loanApp.outgoingProperties" />
    <LoanDetailSummary v-if="loanApp.loanDetails" :data="loanApp.loanDetails" />
    <ApplicantSummary :data="loanApp.applicants" />
    <PropertyOwnershipSummary
      :data="loanApp.propertiesOwnership"
      :properties="loanProperties"
      :applicants="loanApplicants"
    />
    <!-- <IncomeSummary :data="loanApp.financials" :applicants="loanApplicants" /> -->
  </div>
</template>
