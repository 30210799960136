import { yupAddress, yupBoolean, yupNumber, yupObject, yupPeriodicValue, yupString } from '../../yup';

export const employmentSchema = yupObject({
  type: yupString().required().label('Employment type'),
});

export const newSalarySchema = yupObject({
  salary: yupPeriodicValue(yupNumber().required()).label('Salary'),
  commission: yupPeriodicValue().label('Commissions'),
  bonuses: yupPeriodicValue().label('Bonuses'),
  overtime: yupPeriodicValue().label('Overtime pay'),
  salarySacrifice: yupBoolean().required().label('Salary sacrifice'),
  employerName: yupString().required().label('Name of employer'),
  employerAddress: yupAddress().required().label('Employer address'),
  employedSince: yupString().required().label('Date commenced'),
});

export const updateSalarySchema = yupObject({
  salary: yupPeriodicValue(yupNumber().required()).label('Salary'),
  commission: yupPeriodicValue().label('Commissions'),
  bonuses: yupPeriodicValue().label('Bonuses'),
  overtime: yupPeriodicValue().label('Overtime pay'),
  salarySacrifice: yupBoolean().required().label('Salary sacrifice'),
  employerName: yupString().required().label('Name of employer'),
  employerAddress: yupAddress().required().label('Employer address'),
  employedSince: yupString().required().label('Date commenced'),
});

export const selfEmployedSchema = yupObject({
  salary: yupPeriodicValue().required().label('Salary'),
  businessAddress: yupAddress().required().label('Business address'),
  businessType: yupString().required().label('Business type'),
  tradingName: yupString().required().label('Trading name'),
  occupation: yupString().required().label('Occupation'),
  industry: yupString().required().label('Industry'),
  gstRegistered: yupBoolean().required('You must select an option'),
  abn: yupString()
    .matches(/^[0-9]*$/, 'Numbers only')
    .length(11)
    .required()
    .label('ABN'),
  soleTrader: yupBoolean().required('You must select an option'),
  hasTaxReturns: yupBoolean().required('You must select an option'),
  employedSince: yupString().required().label('Date commenced'),
});

export const additionalIncomeSchema = yupObject({
  shortTermChange: yupBoolean().required().label('This question'),
  shortTermEmploymentChange: yupString()
    .when('shortTermChange', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Future employment details'),
  additionalIncome: yupBoolean().required().label('This question'),
  centrelinkPayments: yupPeriodicValue().label('Centrelink payments'),
  childSupportMaintenance: yupPeriodicValue().label('Child support maintenance'),
  familyAllowance: yupPeriodicValue().label('Family allowance'),
  familyTaxBenefit: yupPeriodicValue().label('Family tax benefit'),
  governmentPension: yupPeriodicValue().label('Government pension'),
  dividendsInvestments: yupPeriodicValue().label('Dividends and investments'),
  superannuation: yupPeriodicValue().label('Superannuation'),
  other: yupPeriodicValue().label('Other'),
  expectIncomeChange: yupBoolean().required().label('Income expectation'),
  effectOnIncomeChange: yupString()
    .when('expectIncomeChange', {
      is: true,
      then: (schema) => schema.required(),
    })
    .label('Expected income change'),
});
