<script setup lang="ts">
import { ApplicantType, AssetType, RelationshipType } from '@/helpers/const';
import { getFullName } from '@/helpers/format';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import AssetForm from './modules/Assets/AssetForm.vue';
import type { ApplicantId, PropertyAsset } from '@/types';
import { useTemplateRefsList } from '@vueuse/core';
import { cloneDeep } from 'lodash-es';
import { ref } from 'vue';
import { toast } from '@/helpers/toast';

const { authorisedUser, loanApp, loanApplicants, saveAndGoNext, goBack } = useLoanAppProcess();

const isSecondaryDeclaration = false;
const isIncomeExpenseRequest = false;

const applicantsMarried = loanApp.value.applicants.some(
  (applicant) => applicant.relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO,
);

const validApplicants = loanApp.value.applicants
  .filter(
    (a) =>
      loanApp.value.userType === 'Broker' ||
      (loanApp.value.userType === 'Primary' && (a.type === ApplicantType.PRIMARY || applicantsMarried)) ||
      (loanApp.value.userType === 'Secondary' && a.type === ApplicantType.SECONDARY && applicantsMarried) ||
      (isIncomeExpenseRequest && applicantsMarried) ||
      a.id === authorisedUser.id ||
      (isSecondaryDeclaration && applicantsMarried),
  )
  .map((a) => a.id);

const financials = ref(cloneDeep(loanApp.value.financials));
const assetRefs = useTemplateRefsList<InstanceType<typeof AssetForm>>();
const activeTab = ref<ApplicantId | undefined>(validApplicants.length === 1 ? validApplicants[0] : undefined);

const validateAssets = async () => {
  let isValid = true;
  const refCount = assetRefs.value.length;
  for (let i = 0; i < refCount; i++) {
    isValid = (await assetRefs.value[i].update()) ?? false;
    if (!isValid) {
      window.scrollTo(0, 0);
      break;
    }
  }

  const addressLines = Object.values(financials.value).flatMap((applicant) =>
    applicant.assets
      .filter((asset) => asset.type === 'property' && asset.address)
      .map((asset) => (asset as PropertyAsset).address.addressLine),
  );

  const hasDuplicateAddress = addressLines.some((addressLine, index) => addressLines.indexOf(addressLine) !== index);
  if (hasDuplicateAddress) {
    isValid = false;
    window.scrollTo(0, 0);
    toast({
      type: 'danger',
      title: 'Error',
      description: 'Please only enter a property asset once.',
    });
  }

  return isValid;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cardTitle = (item: any) => {
  let description;
  if (item.type === AssetType.PROPERTY) {
    description = item.address?.addressLine;
  } else if (item.institution) {
    description = item.institution;
  } else if (item.model) {
    description = `${item.make} ${item.model}`;
  }

  return `${item.type.charAt(0).toUpperCase() + item.type.slice(1).replace('_', ' ')}: ${description}`;
};

const cardDescription = (applicantId: ApplicantId) => {
  const assets = financials.value[applicantId].assets.map((item) => {
    return cardTitle(item);
  });
  const liabilities = financials.value[applicantId].liabilities.map((item) => {
    return cardTitle(item);
  });

  return [...assets, ...liabilities];
};

const saveAndContinue = async () => {
  if (await validateAssets()) {
    saveAndGoNext({
      financials: financials.value,
    });
  }
};
</script>

<template>
  <div class="w-full">
    <div class="mb-4 pb-2">
      <h2 class="text-2xl font-medium">
        What
        <BxHighlight>assets</BxHighlight>
        and
        <BxHighlight>liabilities</BxHighlight>
        do you have?
      </h2>
    </div>
    <div v-if="loanApp.applicants.length > 1" class="my-6">
      <p
        v-if="
          loanApp.applicants[1].relationshipToPrimary === RelationshipType.MARRIED_OR_DEFACTO &&
          validApplicants.length > 1
        "
      >
        Please only include combined assets and liabilities once.
      </p>
      <p v-else-if="loanApp.actionType === 'createNew'">
        Only include your assets and liabilities, we will request this information from
        {{ loanApp.applicants[1].preferredName }} via email.
      </p>
    </div>

    <div class="my-6 grid gap-4">
      <div v-for="applicantId in validApplicants" :key="applicantId">
        <BxCard :class="applicantId !== activeTab && 'hidden'">
          <h2 class="mb-4 text-xl font-medium">
            {{ getFullName(loanApplicants[applicantId]) }}
          </h2>
          <AssetForm
            :ref="assetRefs.set"
            v-model:assets="financials[applicantId].assets"
            v-model:liabilities="financials[applicantId].liabilities"
            :applicant-id="applicantId"
            :animate="applicantId === activeTab"
            :show-buttons="validApplicants.length > 1"
            @save="activeTab = undefined"
          />
        </BxCard>
        <BxCard :class="`${applicantId === activeTab && 'hidden'} asset-card`">
          <div class="flex items-center">
            <div class="card-details">
              <div class="mb-2 font-medium">{{ getFullName(loanApplicants[applicantId]) }}</div>
            </div>
            <div class="asset-descriptions ml-6">
              <div v-for="description in cardDescription(applicantId)" :key="description">
                <span class="text-gray-400">{{ description }}</span>
              </div>
            </div>

            <div class="card-button">
              <BxButton
                :id="applicantId + '-assets-start-add'"
                variant="secondary"
                :disabled="
                  (activeTab && activeTab !== applicantId) ||
                  (loanApplicants[applicantId].type === 'primary' && isSecondaryDeclaration)
                "
                @click="activeTab = applicantId"
              >
                {{ financials[applicantId].assets.length ? 'Edit' : 'Add' }} assets
              </BxButton>
            </div>
          </div>
        </BxCard>
        <BxCard :class="`${applicantId === activeTab && 'hidden'} asset-card-mobile`">
          <div class="items-left flex flex-col">
            <div class="card-details">
              <div class="mb-2 font-medium">{{ getFullName(loanApplicants[applicantId]) }}</div>
            </div>
            <div class="asset-descriptions ml-6">
              <div v-for="description in cardDescription(applicantId)" :key="description">
                <span class="text-gray-400">{{ description }}</span>
              </div>
            </div>
          </div>
          <div class="card-button">
            <BxButton
              :id="applicantId + '-assets-start-add'"
              variant="secondary"
              :disabled="
                (activeTab && activeTab !== applicantId) ||
                (loanApplicants[applicantId].type === 'primary' && isSecondaryDeclaration)
              "
              @click="activeTab = applicantId"
            >
              {{ financials[applicantId].assets.length ? 'Edit' : 'Add' }} assets
            </BxButton>
          </div>
        </BxCard>
      </div>
    </div>

    <div class="mt-6">
      <BxButton id="assets-continue" variant="primary" @click="saveAndContinue()">Save and continue</BxButton>
      <BxButton variant="link" class="ml-4" @click="goBack()">Back</BxButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.asset-descriptions {
  @media (max-width: 767px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.asset-card {
  @media (max-width: 767px) {
    display: none;
  }
}

.asset-card-mobile {
  @media (min-width: 767px) {
    display: none;
  }
}

.card-button {
  flex-grow: 1;
  @media (min-width: 767px) {
    text-align: right;
  }
  @media (max-width: 767px) {
    text-align: center;
  }
  flex-basis: 200px;
}
</style>
