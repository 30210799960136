<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useLoanAppProcess } from '@/helpers/useLoanAppProcess';
import { Tab, TabGroup, TabList } from '@headlessui/vue';
import type { ApplicantId } from '@/types';

const props = defineProps<{
  modelValue?: ApplicantId;
  applicants: ApplicantId[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', v: ApplicantId): void;
}>();

const { loanApplicants } = useLoanAppProcess();
const activeTab = ref(0);
const activeId = computed(() => props.applicants[activeTab.value]);

watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      const index = props.applicants.indexOf(val);
      activeTab.value = index < 0 ? 0 : index;
    }
  },
);

watch(
  activeId,
  (val) => {
    emit('update:modelValue', val);
  },
  { immediate: true },
);
</script>

<template>
  <div>
    <TabGroup :selected-index="activeTab" @change="activeTab = $event">
      <TabList class="flex gap-1 space-x-1 rounded-xl bg-sky-900/20 p-1 text-lg">
        <Tab v-for="applicantId in applicants" :key="applicantId" v-slot="{ selected }" as="template">
          <button
            :class="[
              'w-full rounded-lg  py-4 leading-5 ',
              'ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-600 focus:outline-none focus:ring-2',
              selected
                ? 'text-bridgit-white bg-sky-600 text-xl font-bold shadow'
                : 'bg-sky-50 font-normal text-sky-500 hover:bg-white hover:text-sky-500',
            ]"
          >
            {{ loanApplicants[applicantId].preferredName }}
          </button>
        </Tab>
      </TabList>
    </TabGroup>
    <h1 class="mt-6 text-2xl font-medium">
      {{ loanApplicants[activeId].firstName }} {{ loanApplicants[activeId].lastName }}
    </h1>
  </div>
</template>
